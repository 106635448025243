/*
 *   File : risk-assessment.js
 *   Author URI : https://evoqins.com
 *   Description : Risk assessment page
 *   Integrations : null
 *   Version : v1.1
 */

import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { updateNavigation } from "../../Store/Action/action";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

import { InvestmentHeader } from "../../Component/Header"
import Icon from "../../Component/Icon/icon";
import { RadioGroup, CustomAmountInput } from "../../Component/Form-elements";
import { Fragment, useEffect, useState } from "react";
import CustomSlider from "../../Component/Other/slider";
import { GradientButton } from "../../Component/CTA";
import Footer from "../../Component/Footer/footer";
import { RiskScoreModal } from "../../Component/Modal";
import APIService from "../../Service/api-service";
import { CustomLoader } from "../../Component/Other";
import { _getProfile } from "../../Helper/api";
import Store from "../../Store";

import Colors from '../../Styles/color.module.scss';

const RISK_SCALE_COLOR_COMPOSITION = [
    Colors.pale_jade,
    Colors.pale_lime,
    Colors.vivid_lime,
    Colors.blonde,
    Colors.sun_lit_yellow,
    Colors.sunflower,
    Colors.canary_yellow,
    Colors.bisque,
    Colors.peachy_pink,
    Colors.apricoat
]

const RiskAssessment = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [questionData, setQuestionData] = useState([]);
    const [answersData, setAnswersData] = useState([]);
    const [showRiskScoreModal, setShowRiskScoreModal] = useState("");
    const [loader, setLoader] = useState(true);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        _getRiskAssessmentsQuestions();
    }, []);

    // Update questionIdSelectedAnswers whenever questionData changes
    useEffect(() => {
        console.log(questionData);
        setAnswersData(prevAnswersData => {
            const updated_answer_data = questionData.map(question => ({
                
                question_id: question.question_id,
                answer_id: question.answers.length !== 0 ? question.selected_answer : 0,
                answer: question.question_type == 2 ? question.answer : question.selected_answer,
                is_input: question.config.postfix_enabled,
                min_amount: question.config.min_max?.min
            }));

            return updated_answer_data;
        });
    }, [questionData]);

    const _findLabel = (id, answers) => {
        const index = answers.findIndex(answer => answer.id === id);
        return index !== -1 ? answers[index].label : ""; // Return an empty string if answer with the given id is not found
    }

    // listener for opening unlock modal
    useEffect(() => {
        if (showRiskScoreModal.length !== 0) {
            _openRiskModal();
        }
    }, [showRiskScoreModal]);

    const _handleNavigateToDashboard = () => {
        navigate("/profile");
        dispatch(updateNavigation("/profile"));
    }

    // Function to update selected_answer based on question_id
    const _updateSelectedAnswer = (questionId, value) => {
        console.log('value', value);
        // Create a copy of the questionData array
        const updated_question_data = [...questionData];

        // Find the question with the specified question_id
        const question_to_update = updated_question_data.find(question => question.question_id === questionId);
        const selected_index = updated_question_data.findIndex(question => question.question_id === questionId);

        // Update the selected_answer property
        if (question_to_update) {
            question_to_update.selected_answer = value;
            question_to_update.answer = value;
            console.log('selected_index :: ', selected_index)
            if (selected_index != -1) {
                updated_question_data[selected_index].answer = value;
            }
            setQuestionData([...updated_question_data]);
        }

        // console.log('answersData :',answersData,value );
    };

 
    const _formatNumber = (number) => {
        if (number >= 10000000) {
            const crores = number / 10000000; // 1 crore = 10,000,000
            return crores + ' Cr';
        } else if (number >= 100000) {
            const lakhs = number / 100000; // 1 lakh = 100,000
            return lakhs + 'L';
        } else {
            return number.toString();
        }
    }

    //to open funds modal
    const _openRiskModal = () => {
        const modal = new Modal(document.getElementById("risk-score"), {});
        modal.show();
    };

    // API - Get profile details 
    const _fetchProfile = async (investment_style) => {
        try {
            const profile_data = await _getProfile();
            Store.dispatch({
                type: 'UPDATE_PROFILE_DATA',
                payload: profile_data
            });
            setShowRiskScoreModal(investment_style);
        } catch (error) {

        }
    };

    // API - get risk assessment questions
    const _getRiskAssessmentsQuestions = () => {
        let url = "risk/list-questions";
        APIService(false, url).then((response) => {
            console.log('response:: ', response.data)
            if (response.status_code === 200) {
                let selected_questionnaire = {};
                let processed_questionnaire = [];
                let questionnaire = response.data;
                for (let i = 0; i < questionnaire.length; i++) {
                    selected_questionnaire = questionnaire[i];
                    selected_questionnaire.answer = questionnaire[i].selected_answer;
                    processed_questionnaire.push(selected_questionnaire)
                }

                setQuestionData(processed_questionnaire);
            }
            setLoader(false);
        });

    }

    // Validate form 
    function _validateForm() {
        let error = true;
        answersData.forEach((answer, index) => {
            if (answer.is_input === false && parseInt(answer.answer) < answer.min_amount) {
                error = false;
                _scrollToErrorElement(`question_input_${index}`);
            }
        });
        return error;
    }

    const _scrollToErrorElement = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    // API - save answer
    const _saveAnswer = async () => {
        let valid_form = await _validateForm();
        if (valid_form === false) {
            return;
        }
        let url = 'risk/save-answer';
        let payload = JSON.stringify({
            answers: answersData
        })
        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                _fetchProfile(response.data.investment_style);
            } else {
                toast.error(response.message, {
                    type: "error",
                });
            }
        })

    }

    return (

        loader === true ?
            <CustomLoader />
            :
            <Fragment>
                <div className="e-risk-assessment e-page-container margin-80px-bottom">

                    {/* Heading */}
                    <InvestmentHeader />

                    <p className="mb-0">
                        <span className="color-gray e-poppins-regular e-font-12 cursor-pointer"
                            onClick={_handleNavigateToDashboard}>
                            Profile
                            <Icon icon="gray-right-arrow"
                                size={16} />
                        </span>
                        <span className="color-black e-poppins-medium e-font-12">Risk assessment</span>
                    </p>

                    <div className="row">
                        <div className="col-12">
                            <div className="margin-32px-top">
                                {
                                    questionData.map((item, key) =>
                                        <div className="mb-4" key={key}>
                                            <p className="color-black e-poppins-medium e-font-18-sm-14 mb-3">
                                                <span className="e-poppins-regular">{key + 1}. </span>
                                                {item.question}
                                                <span className="color-red e-poppins-medium e-font-18-sm-14">*</span>
                                            </p>
                                            {
                                                item.question_type === 1 ?

                                                    <RadioGroup data={item.answers}
                                                        id={item.selected_answer}
                                                        className="e-risk-radio-span"
                                                        type={2}
                                                        radioHandler={(id) => _updateSelectedAnswer(item.question_id, id)} />

                                                    :
                                                    item.question_type === 2 ?
                                                        <div className="border-radius-16px border-all border-bright-gray px-3 padding-14px-top pb-2 e-risk-range">
                                                            <div className="d-flex gap-16px  ">
                                                                <div className="e-range-slider">
                                                                    <CustomSlider defaultValue={item.config.min_max.default}
                                                                        min={item.config.min_max.min}
                                                                        max={item.config.min_max.max}
                                                                        step={item.config.min_max.step}
                                                                        value={item.selected_answer}
                                                                        id={`risk-slider-${key}`}
                                                                        _handleChange={(value) => _updateSelectedAnswer(item.question_id, value)} />
                                                                    <div className='d-flex justify-content-between align-items-center e-labels'>
                                                                        {
                                                                            item.config.postfix_enabled === false ?
                                                                                <Fragment>
                                                                                    <p className='color-old-silver e-poppins-regular e-font-12 mb-0'>
                                                                                        <span className="e-inter-regular">₹</span>{_formatNumber(item.config.min_max.min)}
                                                                                    </p>
                                                                                    <p className='color-old-silver e-poppins-regular e-font-12 mb-0'>
                                                                                        <span className="e-inter-regular">₹</span>{_formatNumber(item.config.min_max.max)}</p>
                                                                                </Fragment>
                                                                                :
                                                                                <Fragment>
                                                                                    <p className='color-old-silver e-poppins-regular e-font-12 mb-0'>{item.config.min_max.min} {item.config.singular_postfix}</p>

                                                                                    <p className='color-old-silver e-poppins-regular e-font-12 mb-0'>{item.config.min_max.max} {item.config.plural_postfix}</p>
                                                                                </Fragment>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    item.config.postfix_enabled === true ? (
                                                                        <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-22px mb-0">
                                                                            {`${item.selected_answer} ${item.selected_answer > 1 ? item.config.plural_postfix : item.config.singular_postfix}`}
                                                                        </p>)
                                                                        : <CustomAmountInput value={item.selected_answer}
                                                                            className="w-150 "
                                                                            inputClass="bg-transparent pe-3 padding-8px-tb  text-end"
                                                                            showRupeePrefix={true}
                                                                            maxValue={item.config.min_max.max}
                                                                            error={item.selected_answer < item.config.min_max.min ? "min date" : ""}
                                                                            fixedRupee={true}
                                                                            id={`question_input_${key}`}
                                                                            showMinAmountIndicator={true}
                                                                            hideErrorMessage={true}
                                                                            handleChange={(e) => {
                                                                                _updateSelectedAnswer(item.question_id, e);
                                                                            }}
                                                                        />
                                                                }

                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="d-flex flex-column e-financial">
                                                            <div className="d-flex gap-8px">
                                                                {
                                                                    item.answers.map((answer, key) =>
                                                                        <div className={`border-radius-8px e-financial-risk d-flex justify-content-center align-items-center cursor-pointer
                                                                    ${item.selected_answer === answer.id ? " border-all-1px border-primary-color" : ""}`}
                                                                            style={{ backgroundColor: RISK_SCALE_COLOR_COMPOSITION[key] }}
                                                                            key={key}
                                                                            onClick={() => _updateSelectedAnswer(item.question_id, answer.id)}>
                                                                            <span className="color-black-all-theme e-poppins-regular e-font-16">{answer.label}</span>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                            <div className="d-flex justify-content-between mt-2">
                                                                <p className="color-green-crayola e-poppins-regular e-font-12 mb-0">Low risk - low returns</p>
                                                                <p className="color-orange-soda e-poppins-regular e-font-12 mb-0">High risk - high returns</p>
                                                            </div>
                                                        </div>
                                            }
                                        </div>)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="w-100 text-center margin-32px-top pb-sm-0 pb-5">
                        <GradientButton label="Calculate my risk"
                            className="padding-12px-tb padding-18px-lr e-font-16"
                            handleClick={_saveAnswer} />
                    </div>
                </div>

                <Footer />

                {
                    showRiskScoreModal.length !== 0 ?
                        <RiskScoreModal investmentStyle={showRiskScoreModal}
                            close={() => setShowRiskScoreModal("")} />
                        :
                        null
                }
            </Fragment>

    )
}

export default RiskAssessment