/*
 *   File : riskscore.js
 *   Author URI : https://evoqins.com
 *   Description : Risk Score questionnaire Modal
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GradientButton } from "../CTA";
import { _getColorForInvestmentStyle } from "../../Helper";

import style from "../../Styles/Component/riskscore.module.scss"

const RiskScoreModal = (props) => {
    const { close } = props;

    const navigate = useNavigate();

    const [colour, setColour] = useState('#FCD34D');

    useEffect(() => {
        var my_modal = document.getElementById("risk-score");
        my_modal.addEventListener("hidden.bs.modal", () => {
            navigate("/goal");
            close();
        });
    }, [close]);

    useEffect(() => {
        setColour(_getColorForInvestmentStyle(props.investmentStyle));
    }, [props.investmentStyle]);

    const _handleNavigate = () => {

        navigate("/goal");
    }

    return (
        <div className={`modal fade ${style.e_risk_score}`}
            id="risk-score"
            tabIndex="-1"
            aria-labelledby="risk-score"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_risk_dialog}`}>
                <div className={`modal-content bg-modal position-relative text-center d-flex align-items-center ${style.e_risk_content} `}>
                    <img src={require("../../Assets/Images/risk-assessment/risk-score-modal-icon.png")}
                        alt="Risk assessment modal"
                        width={172}
                        height={126} />
                    <p className="color-black e-poppins-semi-bold e-font-18 mt-3 mb-4">
                        According to your answers we recommending
                        <span style={{ color: colour }}> {props.investmentStyle} </span>
                        investment style
                    </p>
                    <p className="color-black e-poppins-regular e-font-16 mx-2 mb-4">
                        Feel free to change your preferences whenever you want in future.
                    </p>

                    <GradientButton label="Start investment"
                        className="padding-12px-tb padding-16px-lr e-font-16"
                        cancel="modal"
                        handleClick={_handleNavigate} />
                </div>
            </div>
        </div>
    )
}

export default RiskScoreModal