/*
 *   File : profile.js
 *   Author URI : https://evoqins.com
 *   Description : Profile info of the customer
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useRef, useState, useContext } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Modal } from "bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import parse from 'html-react-parser';

import { BreadCrumb } from "../../Component/BreadCrumb";
import Icon from "../../Component/Icon/icon";
import { CustomTab } from "../../Component/Tab";
import { GradientButton, LinkText, OutlineButton, SecondaryButton } from "../../Component/CTA";
import { DataTable } from "../../Component/Form-elements";
import { InvestmentHeader } from "../../Component/Header";
import Footer from "../../Component/Footer/footer";
import { MandateListModal, RemoveAccountModal, AddBankModal, CreateMandateModal, DeleteConfirmation, ManageNomineeModal, ManagePhoneModal, ManageEmailModal, PaymentStatusModal, UpdateMobileRelation, UploadSignedMandate } from "../../Component/Modal";
import { CustomLoader, EmptyScreen } from "../../Component/Other";
import { CompleteRiskScore } from "../../Component/Card";
import { CustomToolTip } from "../../Component/Popups";

import { _getColorForInvestmentStyle, logOut } from "../../Helper";
import { _getProfile } from "../../Helper/api";
import APIService from "../../Service/api-service";

import Color from "../../Styles/color.module.scss"
import Store from "../../Store";
import { DarkModeContext } from "../../Context/dark-mode-context";

import NoDataNominee from '../../Assets/Images/nodata/general.svg';
import NoDataBank from '../../Assets/Images/nodata/bank.svg';
import NoDataMandate from '../../Assets/Images/nodata/mandate.svg';
import { PROFILE_DATA } from "../../Store/types";

// Bread crumbs data
const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Account', url: null },
];

// Tab data
const TAB_DATA = ['Personal', 'Risk profile', 'Nominee', 'Bank accounts', 'Mandates', 'Update NFT']; // 'Update NFT', ,

// mandate type 
const MANDATE_TYPE = ['E-mandate', 'Physical mandate'];
// Demat data
const DEMAT = {
    ckyc_no: 2980902009809,
    demat_id: 2980902,
    dp_id: 2980902,
    status: false
}

// Nominee data table
const NOMINEE = [
    {
        name: 'Name',
        selector: row => row['name'],
        sortable: false,
        // width: "150px",
    },
    {
        name: 'Relation',
        selector: row => row['relationship'],
        sortable: false,
        width: "120px",
    },
    {
        name: 'Date of Birth',
        selector: row => row['date_of_birth_str'],
        sortable: false,
        width: "120px",
    },
    {
        name: 'Allocation%',
        selector: row => row['allocation'],
        sortable: false,
        width: "120px",
    }

];

// nominee table style
const NOMINEE_STYLES = {
    table: {
        style: {
            paddingLeft: "0px",
            paddingRight: "0px"
        }
    },
    headRow: {
        style: {
            gap: "56px",
        }
    },
    headCells: {
        style: {
            color: Color.black,
            fontSize: "1rem",
            fontFamily: "Poppins-Medium",
            fontWeight: "500",
            lineHeight: "1.125rem",
            '&:not(:first-child)': {
                justifyContent: 'center',
            },
        }
    },
    rows: {
        style: {
            cursor: "default",
            gap: "56px",
            '&:last-child': {
                marginBottom: '0',
            },
        },
    },
    cells: {
        style: {
            color: Color.black,
            fontFamily: "Poppins-Medium",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "20px",
            justifyContent: "center",
        }
    }
};

// bank table style
const BANk_STYLES = {
    table: {
        style: {
            paddingLeft: "0px",
            paddingRight: "0px",
            paddingBottom: '34px',
        }
    },
    headRow: {
        style: {
            padding: "0 1.5rem 1rem 1.5rem",
            gap: "56px",
            justifyContent: "flex-start",
        }
    },
    headCells: {
        style: {
            color: Color.outer_space_dark,
            fontSize: "1rem",
            fontFamily: "Poppins-regular",
            fontWeight: "400",
            lineHeight: "1rem",
        }
    },
    rows: {
        style: {
            gap: "56px",
            padding: "1.5rem",
            marginBottom: "2rem",
            cursor: 'default',
            "&:last-child": {
                marginBottom: "0"
            },
            "&:hover" : {
                backgroundColor: 'transparent !important',
                borderColor: `${Color.gunmetal_gray} !important`,
                border: `1px solid ${Color.gunmetal_gray} !important`
            }
        },
    },
    cells: {
        style: {
            color: Color.black,
            fontFamily: "Poppins-Medium",
            fontSize: "16px",
            lineHeight: "16px",
            justifyContent: "flex-start",
        }
    }
};

const SUCCESS_MESSAGE = {
    title: 'Mandate created',
    message: 'Your mandate has been successfully created'
}

const FAILED_MESSAGE = {
    title: 'Mandate creation failed',
    body: 'We regret to inform you that your mandate creation was failed.'
}


const Profile = (props) => {

    const { darkMode } = useContext(DarkModeContext); 
    const navigate = useNavigate();
    const location = useLocation();

    const bankRef = useRef();
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const KYC_STEP = useSelector(state => state.generalData.KYC_PROGRESS_DATA);

    const TOOL_TIP = useSelector(state => state.generalData.TOOL_TIP_DATA);

    // state variables for storing data
    const [showEdit, setShowEdit] = useState(0);
    const [tabIndex, setTabIndex] = useState(1);
    const [bankData, setBankData] = useState([]);
    const [showMandateInfo, setShowMandateInfo] = useState(1);
    const [dematEditHover, setDematEditHover] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [viewMandates, setViewMandates] = useState(null);
    const [openAddBankModal, setOpenAddBankModal] = useState(false);
    const [makeDefaultBankApiLoading, setMakeDefaultBankApiLoading] = useState(false);
    const [tabLoader, setTabLoader] = useState(false);
    const [selectedBank, setSelectedBank] = useState(0);
    const [showMandateModal, setShowMandateModal] = useState(false);
    const [mandateList, setMandateList] = useState([]);
    const [riskScoreData, setRiskScoreData] = useState({});
    const [loader, setLoader] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showMandateDeleteModal, setShowMandateDeleteModal] = useState({});
    const [nomineeData, setNomineeData] = useState(null)
    const [openNomineeModal, setOpenNomineeModal] = useState(false);
    const [hoveredMandateId, setHoveredMandateId] = useState(null);
    const [profileModalType, setProfileModalType] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [showPersonalDataTooltip, setShowPersonalDataTooltip] = useState(false);
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [openRelationUpdateModal, setOpenRelationUpdateModal] = useState(false);
    const [relationType, setRelationType] = useState(1);
    const [colour, setColour] = useState('#FCD34D');
    const [tabData, setTabData] = useState(TAB_DATA);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [mouseEnter, setMouseEnter] = useState(false);
    const [openLeaveFamilyModal, setOpenLeaveFamilyModal] = useState(false);
    const [familyApiLoading, setFamilyApiLoading] = useState(false);
    const [mandateType, setMandateType] = useState(1);
    const [physicalMandate, setPhysicalMandate] = useState(false);
    const [openUploadDocModal, setOpenUploadDocModal] = useState(false);
    const [selectedMandate, setSelectedMandate] = useState("");

    // Bank data table
    const BANK = [{
        name: 'Linked bank',
        selector: row => row['bank_name'],
        sortable: false,
        // width: "30%",
        cell: row =>
            <div className="d-flex align-items-center gap-12px e-fund-name ">
                <div className="padding-12px-tb px-2 border-radius-8px border-all border-bright-gray e-bank-image">
                    <img src={row['bank_image']}
                        alt={row['name']}
                        width={64}
                        height={28}
                        className="object-fit-contain" />
                </div>
                <div className="d-flex flex-column gap-8px">
                    <div className="d-flex  flex-wrap ">
                        <p className="color-black e-poppins-medium e-font-16 line-height-32px mb-0 white-space-nowrap me-2">{row['bank_name']}</p>
                        {
                            row.primary === true &&
                            <p className="border-radius-24px e-primary-bank-tag e-poppins-regular e-font-12 line-height-16px mb-2 p-2  white-space-nowrap">Primary bank</p>
                        }
                    </div>
                    <p className="color-outer-space e-poppins-medium e-font-16 line-height-16px mb-0">{row['masked_account_number']}</p>
                </div>

            </div>
    }, {
        name: 'Type',
        selector: row => row['account_type_label'],
        sortable: false,
        width: "120px",
        // cell: row => <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0s"></p>
    }, {
        name: 'IFSC',
        selector: row => row['ifsc_code'],
        sortable: false,
        width: "130px",
    }, {
        name: 'Mandate mapped',
        selector: row => row['mandate_mapped'],
        sortable: false,
        width: window.innerWidth < 575 ? "190px" : "150px",
        cell: row => <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['mandates_available'] === true ? "Yes" : "No"}</p>
    }, {
        name: '',
        sortable: false,
        width: "130px",
        cell: row =>
            <div className={`d-flex position-relative ${row['mandates_available'] === true ? "align-items-center" : "justify-content-end w-100"}`}>
                {
                    row['mandates_available'] === true &&
                    <LinkText title="View mandate"
                        className="me-2 "
                        onClick={() => _viewMandates(row.bank_id)} />
                }

                {/* show more icon only if there are more than one banks in teh account. It will allow us to keep the bank and hence the investment flow */}
                {
                    bankData.length > 1 &&
                    <>
                        {
                            row.is_default === true && row.primary === true ?
                                null
                                :
                                <img src={require('../../Assets/Images/profile/bank-menu-dots.png')}
                                    alt="View more"
                                    width={24}
                                    height={24}
                                    className="cursor-pointer e-filter-invert-1"
                                    onClick={() => setSelectedBank(row)} />
                        }

                        {
                            selectedBank.bank_id === row.bank_id && (
                                <>
                                    <div className="position-absolute border-radius-12px border-width-2px border-solid border-bright-gray padding-10px-lr py-2 bg-cards white-space-nowrap w-fit-content e-bank-options z-index-12"
                                        ref={bankRef}>
                                        {
                                            row.primary !== true &&
                                            <p className={`color-black e-poppins-regular e-font-14  cursor-pointer hover-primary-color mb-0 line-height-22px  ${makeDefaultBankApiLoading === true && 'e-text-loader'}`}
                                                onClick={() => _makeDefaultBank()}>{makeDefaultBankApiLoading ? 'Loading' : 'Make primary account'}</p>
                                        }
                                        {
                                            row.is_default !== true &&
                                            <p className={`color-black e-poppins-regular e-font-14 mb-0 cursor-pointer hover-primary-color line-height-22px ${tabLoader === true && 'e-text-loader'}`}
                                                onClick={() => setShowDeleteConfirmation(true)}>{tabLoader === true ? 'Loading' : 'Remove bank account'}</p>
                                        }

                                    </div>
                                    <div className="position-fixed w-100 z-index-10 h-100 left-0 top-0 cursor-default"
                                        onClick={() => setSelectedBank(0)}></div>
                                </>
                            )
                        }
                    </>
                }
            </div>

    }];

    // Mandate data
    const MANDATE_TABLE = [{
        name: 'Linked bank',
        selector: row => row['bank_name'],
        sortable: false,
        // width: "30%",
        cell: row =>
            <div className="d-flex align-items-center gap-12px e-fund-name ">
                <div className="padding-12px-tb px-2 border-radius-8px border-all border-bright-gray e-bank-image">
                    <img src={row['bank_image']}
                        alt={row['name']}
                        width={64}
                        height={28}
                        className="object-fit-contain" />
                </div>
                <div className="d-flex flex-column gap-8px">
                    <div className="d-flex gap-16px  ">
                        <p className="color-black e-poppins-medium e-font-16 line-height-32px mb-0 white-space-nowrap">{row['bank_name']}</p>

                    </div>
                    <p className={`border-radius-24px  border-radius-24px e-poppins-regular e-font-14 line-height-16px mb-0 mt-2 px-2 py-1 white-space-nowrap w-max-content  e-mandate-status ${row.status_str === 'SUCCESS' ? 'color-sea-green bg-pastel-green' : 'color-vivid-orange bg-pale-orange'}`}>{row['status_str']}</p>
                </div>

            </div>
    }, {
        name: 'Mandate ID',
        selector: row => row['mandate_id'],
        sortable: false,
        width: "120px",
        // cell: row => <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0s"></p>
    }, {
        name: 'Mandate amt/day',
        selector: row => row['mandate_limit'],
        sortable: false,
        width: "160px",
        cell: row => <p><span className="e-inter-medium color-black">₹</span><span className="color-black">{row.mandate_limit?.toLocaleString('en-IN')}</span></p>
    }, {
        name: 'Start date',
        selector: row => row['created'],
        sortable: false,
        width: window.innerWidth > 575 ? "120px" : "150px",
        cell: row => <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['created']}</p>
    }, {
        name: 'Expiry date',
        sortable: false,
        width: window.innerWidth > 575 ? "120px" : "150px",
        cell: row => <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['end_date']}</p>
    },
    {
        name: 'Remark',
        sortable: false,
        width: "180px",
        cell: row => <p className="color-black e-poppins-medium e-font-16 line-height-18px mb-0">{row['remark'] ? row['remark'] : '-'}</p>
    }];

    useEffect(() => {

        function _handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleResize);

        window.removeEventListener('resize', _handleResize);
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            setTabIndex(location.state.tab);
            if (location.state.tab && location.state.type) {
                setMandateType(location.state.type);
            }
        }
    }, [location]);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        _fetchProfile();
    }, []);

    useEffect(() => {
        console.log('inside');
        if (Object.keys(USER_DATA).length) {
            let PROFILE_TABS = [...tabData];
            if (USER_DATA.banks !== undefined) {
                setBankData(USER_DATA.banks);
            }
            if (USER_DATA.minor_flag === true) {
                PROFILE_TABS.push('Guardian details');
            }
            if (USER_DATA.account_type == 'Joint account') {
                PROFILE_TABS.push('Joint holders');
            }
            if (USER_DATA.account_type == 'Anyone or Survivor') {
                PROFILE_TABS.push('Other holders');
            }
            if (USER_DATA.account_type == 'Either or Survivor') {
                PROFILE_TABS.push('Other holders');
            }
            if (USER_DATA.is_deletable === true) {
                if (!tabData.includes('Delete account')) {
                    PROFILE_TABS.push('Delete account');
                }
            }
            setTabData(PROFILE_TABS);
        }
    }, [USER_DATA]);

    useEffect(() => {
        console.log(tabData);
    }, [tabData])

    // listener for open add bank modal
    useEffect(() => {
        if (openAddBankModal === true) {
            const modal = new Modal(document.getElementById("add-bank"), {});
            modal.show();
        }
    }, [openAddBankModal]);

    useEffect(() => {
        if (showMandateModal === true) {
            const modal = new Modal(document.getElementById("show-mandate"), {});
            modal.show();
        }
    }, [showMandateModal]);

    useEffect(() => {
        if (showDeleteConfirmation === true) {
            const modal = new Modal(document.getElementById("delete-confirmation"), {});
            modal.toggle();
        }
    }, [showDeleteConfirmation]);

    useEffect(() => {
        if (openUploadDocModal === true) {
            const modal = new Modal(document.getElementById("upload-mandate"), {});
            modal.toggle();
        }
    }, [openUploadDocModal]);

    // listener for opening delete modal
    useEffect(() => {
        if (showDeleteModal === true) {
            _openDeleteModal();
        }
    }, [showDeleteModal]);

    // listener for opening delete modal for mandates
    useEffect(() => {
        if (Object.keys(showMandateDeleteModal).length !== 0) {
            const modal = new Modal(document.getElementById("delete-confirmation"), {});
            modal.toggle();
        }
    }, [showMandateDeleteModal]);


    // listener for open manage nominee modal
    useEffect(() => {
        if (openNomineeModal === true) {
            const modal = new Modal(document.getElementById("manage-nominee"), {});
            modal.show();
        }
    }, [openNomineeModal]);

    // listener for open payment status modal after mandate creation
    useEffect(() => {
        if (openStatusModal === true) {
            const modal = new Modal(document.getElementById("payment-status"), {});
            modal.show();
        }
    }, [openStatusModal]);

    useEffect(() => {
        if (openRelationUpdateModal === true) {
            const modal = new Modal(document.getElementById("update-relation"), {});
            modal.show();
        }
    }, [openRelationUpdateModal]);

    useEffect(() => {
        if (openLeaveFamilyModal === true) {
            const modal = new Modal(document.getElementById("delete-confirmation"), {});
            modal.show();
        }
    }, [openLeaveFamilyModal])

    // listener for open manage phone and manage email modal
    useEffect(() => {
        switch (profileModalType) {
            case 1:
                const modal_phone = new Modal(document.getElementById("manage-email"), {});
                modal_phone.show();
                break;
            case 2:
                const modal_email = new Modal(document.getElementById("manage-phone"), {});
                modal_email.show();
                break;

            default:
                break;
        }

    }, [profileModalType]);


    // listener for opening delete modal
    useEffect(() => {
        if (viewMandates !== null) {
            _getMandateList()
            _openMandateModal();
        }
    }, [viewMandates]);

    useEffect(() => {
        switch (tabIndex) {
            case 2:
                _getRiskData();
                break;
            case 3:
                _getNomineeData();
                break;
            case 4:
                _getBankList();
                break;
            case 5:
                _getMandateList();
                break;
            default: return;
        }
    }, [tabIndex])

    // handler for tab change
    const _handleTabSelect = (index) => {
        setTabIndex(index);
        navigate('/profile', { state: { tab: index } }, { replace: true });
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        // switch (index) {
        //     case 2:
        //         setLoader(true);
        //         _getRiskData();
        //         break;
        //     case 3:
        //         setLoader(true);
        //         _getNomineeData();
        //         break;
        //     case 4:
        //         setLoader(true);
        //         _getBankList();
        //         break;
        //     // case 5:
        //     //     setLoader(true);
        //     //      _getMandateList();
        //     //     break;
        //     default: return;
        // }
    }

    function _handleMandateType(value) {
        setMandateType(value);
        _getMandateList(value);
    }

    // handler to show more info about the linked mandates
    const _showMandateInfo = (id) => {
        setShowMandateInfo(id)
    }

    // handler to show delete modal
    const _handleDelete = () => {
        setShowDeleteModal(true);
    }

    // handler to show mandate modal
    const _viewMandates = (id) => {
        setViewMandates(id)
    }

    // handler to open mandate modal
    const _showMandateModal = () => {
        setShowMandateModal(true)
    }
    //to open delete modal
    const _openDeleteModal = () => {
        const modal = new Modal(document.getElementById("delete-confirmation"), {});
        modal.show();
    };

    //to open mandate modal
    const _openMandateModal = () => {
        const modal = new Modal(document.getElementById("available-mandates"), {});
        modal.show();
    };


    // to open phone and email modal
    const _handleModals = (index) => {
        if (index === profileModalType) {
            setProfileModalType(0)
        } else {
            setProfileModalType(index);
        }
    }

    const _handleNavigate = (type) => {
        switch (type) {
            case 1:
                _navigateToUpdateNFT("/bank-account");
                break;
            case 2:
                navigate("/family-investment-detail");
                break;
        }
    }

    const _navigateToUpdateNFT = (type) => {
        navigate('update-nft', {
            state: {
                type,
                tabIndex
            }
        })
    }

    // close mandate mapped modal
    const _closeMandateListModal = () => {
        setViewMandates(null);
        // setMandateList([]);
    }

    const _openRelationUpdateModal = (type) => {
        setRelationType(type);
        setOpenRelationUpdateModal(true);
    }

    const _handleDeleteMandate = (data) => {
        setShowMandateDeleteModal(data);
    }

    // open nominee modal
    const _handleManageNominee = () => {
        setOpenNomineeModal(true);
    }

    // close nominee modal
    const _handleCloseNomineeModal = () => {
        setOpenNomineeModal(false);
    }

    // close nominee modal
    const _handleCloseProfileModal = () => {
        setProfileModalType(null);
    }

    const _setHoveredItem = (id) => {
        setHoveredMandateId(id);
    }

    const _removeHoveredItem = () => {
        setHoveredMandateId(null);
    }

    // handle redirect support 
    const _handleSupport = () => {
        navigate('/support');
    }

    const _redirectToKYC = () => {
        navigate('/kyc')
    }

    const _navigateToLanding = (path) => {
        let url = process.env.REACT_APP_LANDING_PAGE_URL + '/terms';
        window.open(url, "_blank", "noreferrer");
    }

    function _openConfirmModal() {
        setOpenLeaveFamilyModal(true);
    }

    function _handleNoNominee() {
        if (USER_DATA.is_kyc_done == true) {
            _handleManageNominee();
        } else {
            navigate('/kyc');
        }
    }

    function _updateMandateStatus() {
        setOpenUploadDocModal(false);
        setSelectedMandate("");
        _getMandateList(2);
    }

    // API - Get profile details 
    const _fetchProfile = async () => {
        try {
            const profile_data = await _getProfile();
            Store.dispatch({
                type: 'UPDATE_PROFILE_DATA',
                payload: profile_data
            });
        } catch (error) {
            toast.dismiss();
            toast.error(error, {
                type: "error"
            });
        }
    };

    // API : List banks
    function _getBankList(return_to_mandate = false) {
        setLoader(true);
        let url = 'profile/banks/list';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setBankData(response.data);
            } else {
                setBankData([]);
            }
            setLoader(false);
            return;
        })
    };

    // API : Set primary account
    function _makeDefaultBank() {
        setMakeDefaultBankApiLoading(true);
        let url = 'profile/banks/set-primary';
        let payload = JSON.stringify({
            bank_id: selectedBank.bank_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success(`Successfully set ${selectedBank.bank_name} as primary account`, {
                    type: "success"
                });
                _getBankList();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setSelectedBank(0);
            setTimeout(() => {

                setMakeDefaultBankApiLoading(false);
            }, 1000);

        })
    };

    // API : Remove bank account 
    async function _removeBankAccount() {
        setTabLoader(true);
        let status = true;
        let url = 'profile/banks/delete';
        let payload = JSON.stringify({
            bank_id: selectedBank.bank_id
        });
        await APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success(`${selectedBank.bank_name} removed successfully`, {
                    type: "success"
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
                status = false;
            }
            setTimeout(() => {
                setSelectedBank(0);
                setTabLoader(false);
            }, 1000);
        });
        return status;
    }

    // API : List mandate
    async function _getMandateList(tab = mandateType) {
        if (bankData.length === 0) {
            await _getBankList(true);
        }
        setTabLoader(true);
        let url = 'profile/mandates/list';
        let payload = JSON.stringify({
            bank_id: tabIndex === 5 ? null : viewMandates,
            mandate_type: tab
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setMandateList(response.data);
            }
            else {
                setMandateList([]);
            }
            setTabLoader(false);
        })
    };

    // API - get risk score
    function _getRiskData() {
        setLoader(true);
        let url = 'profile/get-risk';
        APIService(false, url).then((response) => {
            console.log(response.data)
            if (response.status_code === 200) {
                setRiskScoreData(response.data);

                setColour(_getColorForInvestmentStyle(response.data.investment_style))
                setLoader(false);
            }
        })
    }

    // API : Remove mandate  
    async function _removeMandate() {
        setTabLoader(true);
        let status = true;
        let url = 'profile/mandates/cancel';
        let payload = JSON.stringify({
            // id: showMandateDeleteModal.id
            id: showMandateInfo
        });
        await APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success(`Mandate from ${showMandateDeleteModal.bank_name} removed successfully`, {
                    type: "success"
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
                status = false;
            }
            setTabLoader(false);
        });
        return status;
    }

    // API - Delete account from modal 
    const _confirmDelete = () => {
        setTabLoader(true);
        let url = 'profile/delete';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                logOut();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setTabLoader(false);
        });
    };

    // API - get nominee data
    function _getNomineeData() {
        setLoader(true);
        let url = 'profile/nominee/list';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setNomineeData(response.data);
            } else {
                setNomineeData(null)
            }
            setLoader(false);
        })
    }

    // API - leave from family
    async function _leaveFamily() {
        setFamilyApiLoading(true);
        let status = false;
        let url = 'family/leave';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                status = true;
                toast.dismiss();
                toast.success('Successfully left');
                _fetchProfile();
            } else {
                toast.dismiss();
                toast.success(response.message);
            }
            setFamilyApiLoading(false);
        });
        return status;
    }


    function _closeRelationUpdateModal() {
        setOpenRelationUpdateModal(false);
        setRelationType(1);
    }

    function _updateMandateList() {
        setShowMandateModal(false);
        _getMandateList(2);
    }

    function _getPaymentStatus(status) {
        setPaymentStatus(status);
        setOpenStatusModal(true);
    }

    function _handleStatusModalClose() {
        setPaymentStatus(false);
        setOpenStatusModal(false);
        _getMandateList();
    }

    function _togglePersonalDetailTootTip() {
        setShowPersonalDataTooltip(!showPersonalDataTooltip);
    }

    function _handleMandateUploadButton(id) {
        setSelectedMandate(id);
        setOpenUploadDocModal(true);
    }

    // Update UI after successful relation update
    async function _callBackRelationSuccess() {
        let profile = await _getProfile();
        // profile.is_kyc_done = true;
        Store.dispatch({
            type: 'UPDATE_PROFILE_DATA',
            payload: profile
        });
    }

    function _fileDownloader(url, file_name) {
        const link = document.createElement('a');
        link.href = url;
        link.download = file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    function _downloadMandate(id) {
        let url = 'profile/v2/mandates/re-download';
        let payload = JSON.stringify({
            mandate_id: id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                _fileDownloader(response.data.url, 'Mandate form');
            } else {
                toast.error(response.message);
            }
        })
    }

    // common inline component for profile banner information
    const ProfileBannerInfo = (props) => {
        return (
            <div className="d-flex flex-wrap">
                <p className="color-gray e-poppins-medium e-font-14 line-height-14px mb-0 margin-2px-right mt-sm-3 mt-0">{props.heading}</p>
                <p className="charleston-green e-poppins-medium e-font-14 line-height-14px mb-0 mt-sm-3 mt-0">{props.description}</p>
            </div>
        )
    }

    // common inline component for edit fields
    const BannerEdit = (props) => {
        return (
            USER_DATA.is_kyc_done === true ?
                <div className="border-radius-8px mb-2 d-flex align-items-center gap-24px-sm-8 cursor-pointer ">
                    {props.label !== "-" && (<p className="color-black e-poppins-regular e-font-14 line-height-14px mb-0 text-decoration-none">{props.label}</p>)}
                    <div className="d-flex align-items-center position-relative e-profile-credential ms-sm-2 ms-0"
                        data-tooltip="Edit">
                        {
                            screenWidth > 576 &&
                            <span className="e-kyc-address-text e-poppins-medium e-font-16 line-height-16px me-1"
                                onClick={() => _handleModals(props.index)}>
                                {
                                    props.label !== "-" ?
                                        'Edit'
                                        :
                                        `Add ${props.placeholder}`
                                }
                            </span>
                        }

                        <Icon icon="Pen-square"
                            size={screenWidth > 576 ? 24 : 20}
                            onClick={() => _handleModals(props.index)} />

                    </div>

                </div>
                :
                props.label !== "-" &&
                <div className="border-radius-8px padding-12px-tb d-flex justify-content-between align-items-center gap-24px e-profile-credential">
                    <p className="color-black e-poppins-regular e-font-14 line-height-14px mb-0">{props.label}</p>
                </div>
        )
    }

    // common inline component for personal information
    const PersonalInformation = (props) => {
        return (
            <Fragment>
                <p className="color-black e-poppins-regular e-font-14 line-height-14px mb-2">{props.heading}</p>
                <p className="color-black e-poppins-medium e-font-14 line-height-22px mb-0 word-break-all">{props.description}</p>
            </Fragment>
        )
    }

    const Description = (props) => {
        return (
            <>
                {

                    <div className={`d-flex ${props.className}`}>
                        <span className="color-gray e-poppins-medium e-font-12 line-height-16px">{props.title}&nbsp;</span>
                        <span className={`e-poppins-medium e-font-12 line-height-16px color-outer-space`}>
                            {props.symbol === true && <span className="e-inter-medium">₹</span>}
                            {props.description}
                        </span>
                    </div>
                }
            </>

        )
    }


    // common inline component for demat information
    const DematInfo = (props) => {
        return (
            <div>
                <p className="color-black e-poppins-regular e-font-14 line-height-14px mb-2 margin-6px-right">{props.heading}</p>
                <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0">{props.description}</p>
            </div>
        )
    }

    // common inline component for family info
    const FamilyInfo = (props) => {
        return (
            <div className={`p-2 e-family-info ${props.border === true && "border-right-1px"}`}>
                <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px margin-10px-bottom">{props.heading}</p>
                <p className={`${props.descriptionColor} e-poppins-medium e-font-14 line-height-16px mb-0`}>
                    {props.rupee === true && <span className="e-inter-medium">₹</span>}
                    {props.description.toLocaleString('en-IN')}
                    {props.percent === true && <span className="e-inter-medium">%</span>}
                </p>
            </div>
        )
    }

    return (
        <Fragment>
            <div className="e-profile e-page-container margin-72px-bottom pb-lg-0 pb-md-0 pb-sm-0 pb-4">
                {/* Header */}
                <InvestmentHeader />

                <BreadCrumb data={PAGE_NAVIGATION} />

                {/* Preloading empty data images */}
                <img src={NoDataBank} className="d-none" />
                <img src={NoDataMandate} className="d-none" />
                <img src={NoDataNominee} className="d-none" />

                <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px mt-4 mb-3">Profile</p>

                {/* Banner */}
                <div className="border-radius-16px bg-profile-summary-card py-4 px-lg-4 px-md-4 px-sm-4 px-3 gap-24px margin-28px-bottom">
                    <div className="row">
                        <div className="col-lg-1  col-2">
                            {/* short name */}
                            <div className="bg-ocean-boat-blue  d-flex justify-content-center color-light-cyan  align-items-center e-inter-bold e-font-22-sm-18 h-64px-sm-48 min-width-60-sm-50 padding-12px-tb-sm-8">
                                {USER_DATA.short_name}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8 col-10 ps-lg-3 ps-md-3 ps-3">
                            <div className="w-100 me-4">
                                <p className="color-black e-poppins-semi-bold e-font-18-sm-16 line-height-22px mb-2 text-transform-capitalize">{USER_DATA.name}</p>
                                {
                                    USER_DATA.demat === true &&
                                    <p className="border-radius-16px color-polished-pine w-max-content e-poppins-medium e-font-10 line-height-18px padding-6px-lr py-1 border-polished-pine border-all bg-pale-turquoise mb-sm-0 mb-3">Demat</p>
                                }
                                <div className="d-flex flex-wrap gap-16px">
                                    <ProfileBannerInfo heading="Mastertrust ID:"
                                        description={USER_DATA.mastertrust_id} />
                                    <ProfileBannerInfo heading="Account created on:"
                                        description={USER_DATA.account_created_on} />
                                    {
                                        USER_DATA.demat === true &&
                                        <ProfileBannerInfo heading="Demat account number:"
                                            description={USER_DATA.demat_account_number} />
                                    }

                                </div>
                                <div className="d-flex flex-wrap gap-sm-16px-0px mt-sm-4 mt-2">
                                    <div className="mb-sm-0 mb-2">
                                        <BannerEdit label={USER_DATA.email}
                                            placeholder="email"
                                            index={1} />
                                        {
                                            USER_DATA.email !== '-' &&
                                            <div className="d-flex align-items-center">
                                                <p className="mt-sm-2 mt-0 color-black e-poppins-regular e-font-14 line-height-14px text-decoration-none mb-0">
                                                    Email relation: {USER_DATA.email_relation}
                                                </p>
                                                {
                                                    USER_DATA.is_email_rel_editable === true &&
                                                    <Icon icon="Pen-square"
                                                        className="ms-2 mt-sm-2 mt-0 cursor-pointer"
                                                        size={20}
                                                        onClick={() => _openRelationUpdateModal(1)} />
                                                }

                                            </div>
                                        }

                                    </div>
                                    <div className="">
                                        <BannerEdit label={USER_DATA.mobile}
                                            placeholder="number"
                                            index={2} />
                                        {
                                            USER_DATA.mobile !== '-' &&
                                            <div className="d-flex align-items-center">
                                                <p className="mt-sm-2 mt-0 color-black e-poppins-regular e-font-14 line-height-14px mb-0">
                                                    Mobile relation: {USER_DATA.mobile_relation}
                                                </p>
                                                {
                                                    USER_DATA.is_mobile_rel_editable === true &&
                                                    <Icon icon="Pen-square"
                                                        className="ms-2 mt-sm-2 mt-0 cursor-pointer"
                                                        size={20}
                                                        onClick={() => _openRelationUpdateModal(2)} />
                                                }


                                            </div>
                                        }

                                    </div>
                                </div>
                                <div className="col-12 d-lg-none  d-flex h-max-content align-items-center">
                                    {/* show create KYC button for non  */}
                                    {
                                        USER_DATA.is_kyc_done === false && (
                                            <GradientButton label={
                                                KYC_STEP.kyc_step === 0 ? "Create an account" :
                                                    KYC_STEP.kyc_step <= 8 ? "Resume KYC" :
                                                        KYC_STEP.kyc_step === 9 ? "View KYC" : "Update KYC"
                                            }
                                                className={`padding-12px-tb padding-32px-lr h-fit-content ${screenWidth > 576 && 'margin-34px-top'}`}
                                                handleClick={_redirectToKYC} />
                                        )
                                    }
                                    {
                                        USER_DATA.already_family_member === true &&
                                        USER_DATA.family_admin === false &&
                                        <span className="color-primary-color e-font-16 line-height-16px e-poppins-medium cursor-pointer me-3"
                                            onMouseEnter={() => setMouseEnter(true)}
                                            onMouseLeave={() => setMouseEnter(false)}
                                            onClick={_openConfirmModal}>
                                            <Icon icon={mouseEnter === true ? "delete-red" : "delete"} size={22} className='me-2' />
                                            Leave family
                                        </span>
                                    }

                                    <p className="border-radius-24px e-profile-type  mt-3 e-poppins-medium e-font-12 line-height-16px mb-0 p-2 white-space-nowrap h-max-content w-fit-content">{USER_DATA.account_type}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-5 text-end d-lg-flex h-max-content align-items-center d-none justify-content-end">

                            {/* show create KYC button for non  */}
                            {
                                USER_DATA.is_kyc_done === false && (
                                    <GradientButton label={
                                        USER_DATA.kyc_step === 0 ? "Create an account" :
                                            USER_DATA.kyc_step <= 8 ? "Resume KYC" :
                                                USER_DATA.kyc_step === 9 ? "View KYC" : "Update KYC"
                                    }
                                        className="padding-12px-tb padding-32px-lr  h-fit-content "
                                        handleClick={_redirectToKYC} />
                                )
                            }
                            {
                                USER_DATA.already_family_member === true &&
                                USER_DATA.family_admin === false &&
                                <span className="color-primary-color e-font-16 line-height-16px e-poppins-medium cursor-pointer me-3"
                                    onMouseEnter={() => setMouseEnter(true)}
                                    onMouseLeave={() => setMouseEnter(false)}
                                    onClick={_openConfirmModal}>
                                    <Icon icon={mouseEnter === true ? "delete-red" : "delete"} size={22} className='me-2' />
                                    Leave family
                                </span>
                            }
                            <p className="border-radius-24px e-profile-type ms-2 e-poppins-medium e-font-12 line-height-16px mb-0 p-2 white-space-nowrap h-max-content w-fit-content">{USER_DATA.account_type}</p>
                        </div>
                    </div>
                </div>


                {/* Tabs to select */}
                <div className="e-tap-wrapper">
                    <CustomTab data={tabData}
                        wrapperClass={'gap-16px'}
                        selectedTab={tabIndex}
                        className="px-0 padding-10px-tb e-order-tab mb-lg-4 mb-md-4 mb-3"
                        onSelectTab={_handleTabSelect} />
                </div>


                {/* uncomment when demat and family tab gets added and delete section below this */}
                {/* {
                    loader === true ?
                        <CustomLoader />
                             
                                    :
                                     to show demat information
                                    tabIndex === 4 ?
                                        <div className="border-radius-16px border-all border-bright-gray py-4 padding-32px-lr">
                                            <div className="d-flex justify-content-between align-items-center mb-4">
                                                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">Demat information</p>
                                                <Icon icon="info"
                                                    size={24} />
                                            </div>
                                            <div className="d-flex gap-100px position-relative w-fit-content">
                                                <DematInfo heading="CKYC no"
                                                    description={DEMAT.ckyc_no} />
                                                <DematInfo heading="Demat id"
                                                    description={DEMAT.ckyc_no} />
                                                <DematInfo heading="DP id"
                                                    description={DEMAT.ckyc_no} />
                                                <DematInfo heading="POA/DDPI status"
                                                    edit={true}
                                                    description={DEMAT.ckyc_no === true ? "Yes" : "No"} />

                                                <div className="position-absolute e-demat-edit"
                                                    onMouseEnter={() => setDematEditHover(true)}
                                                    onMouseLeave={() => setDematEditHover(false)} >
                                                    <Icon icon="Pen-square"
                                                        size={16}
                                                        className="cursor-pointer" />
                                                    {
                                                        dematEditHover === true &&
                                                        <span className="position-absolute cursor-pointer px-2 border-radius-4px color-outer-space e-poppins-regular bg-white e-font-12 line-height-12px e-edit">Edit</span>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                         
                                                :
                                                 to show family details
                                                tabIndex === 7 ?
                                                    <Fragment>
                                                        <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-4">Family accounts</p>
                                                        <div className="border-radius-24px border-all border-bright-gray bg-pure-white p-4">
                                                            <div className="d-flex align-items-center mb-3 gap-8px">
                                                                <img src={require("../../Assets/Images/profile/family-icon.png")}
                                                                    alt="Family accounts"
                                                                    width={24}
                                                                    height={24} />
                                                                <p className="color-black e-poppins-semi-bold e-font-16 mb-0">Family investment summary</p>
                                                            </div>
                                                            <div className="d-flex gap-16px flex-wrap align-items-center">
                                                                <FamilyInfo heading="Members"
                                                                    descriptionColor="color-black"
                                                                    border={true}
                                                                    description={7} />
                                                                <FamilyInfo heading="Total invested"
                                                                    descriptionColor="color-black"
                                                                    rupee={true}
                                                                    border={true}
                                                                    description={6525000} />
                                                                <FamilyInfo heading="Current value"
                                                                    descriptionColor="color-black"
                                                                    rupee={true}
                                                                    border={true}
                                                                    description={6525000} />
                                                                <FamilyInfo heading="Returns"
                                                                    descriptionColor="color-black"
                                                                    rupee={true}
                                                                    border={true}
                                                                    description={6525000} />
                                                                <FamilyInfo heading="Returns %"
                                                                    descriptionColor={100.03 < 0 ? "color-red" : "color-sea-green"}
                                                                    percent={true}
                                                                    border={true}
                                                                    description={100.03} />
                                                                <FamilyInfo heading="XIRR"
                                                                    descriptionColor={90.190 < 0 ? "color-red" : "color-sea-green"}
                                                                    percent={true}
                                                                    description={90.190} />
                                                                <GradientButton label="View details"
                                                                    className="px-3 py-2"
                                                                    handleClick={() => _handleNavigate(2)} />
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                    :
                                     
                } */}
                {
                    loader === true ?
                        <CustomLoader />
                        :
                        // to show personal detail
                        tabIndex === 1 ?
                            <div className="col-lg-8 col-12 mt-lg-0 mt-md-0 mt-3">
                                <div className="border-radius-16px border-all border-bright-gray padding-32px-lr py-4">
                                    <div className="d-flex justify-content-between">
                                        <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">Personal information</p>
                                        {
                                            Object.keys(TOOL_TIP).length !== 0 &&
                                            <div className="position-relative">
                                                <Tooltip id="profile-tooltip"
                                                    arrowColor='transparent'
                                                    effect="float"
                                                    place="bottom"
                                                    className='e-tooltip-general'>
                                                    {parse(TOOL_TIP.profile_personal_details)}
                                                </Tooltip>
                                                <Icon icon="info"
                                                    size={24}
                                                    data-tooltip-id="profile-tooltip" />

                                            </div>
                                        }

                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-6 margin-24px-top" >
                                                    <PersonalInformation heading="Name"
                                                        description={USER_DATA.name} />
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-6 margin-24px-top" >
                                                    <PersonalInformation heading="PAN number"
                                                        description={USER_DATA.pan} />
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-6 margin-24px-top" >
                                                    <PersonalInformation heading="Date of birth"
                                                        description={USER_DATA.dob} />
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-6 margin-24px-top" >
                                                    <PersonalInformation heading="Gender"
                                                        description={USER_DATA.gender} />
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-6 margin-32px-top">
                                                    <PersonalInformation heading="Marital status"
                                                        description={USER_DATA.marital_status} />
                                                </div>
                                                <div className="col-lg-7 col-md-7 col-12 margin-32px-top">
                                                    <PersonalInformation heading="Address"
                                                        description={USER_DATA.address} />
                                                    {/* <p className="color-black e-poppins-regular e-font-14 line-height-14px mb-2 ">Address</p>
                                                <p className="color-black e-poppins-medium e-font-14 line-height-22px mb-0 margin-6px-right">{USER_DATA.address}</p> */}
                                                    {/* <div className="d-flex align-items-end position-relative w-fit-content"> */}
                                                    {/* <Icon icon="Pen-square"
                                                        size={24}
                                                        className="cursor-pointer"
                                                        onMouseEnter={() => setShowEdit(3)}
                                                        onMouseLeave={() => setShowEdit(0)} />
                                                    {
                                                        showEdit === 3 &&
                                                        <span className="color-outer-space e-poppins-regular e-font-12 line-height-12px bg-white position-absolute px-2 e-edit border-radius-4px">Edit</span>
                                                    } */}
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            // to show risk profile
                            tabIndex === 2 ?
                                <>
                                    {
                                        USER_DATA.is_assessment_completed === false ? (
                                            <div className="col-lg-10 col-12 mt-lg-0 mt-md-0 mt-3">
                                                <CompleteRiskScore />
                                            </div>
                                        ) :
                                            <div className="col-lg-8 col-12 mt-lg-0 mt-md-0 mt-3">
                                                <div className="border-radius-16px border-all border-bright-gray padding-32px-lr py-4">
                                                    <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-3">Risk analysis</p>

                                                    <div className="d-flex gap-10px">
                                                        <img src={riskScoreData.image}
                                                            alt="Risk assessment"
                                                            width={60}
                                                            height={30} />
                                                        <div>
                                                            <p className="color-black e-poppins-regular e-font-16 line-height-92px mb-2">Based on the risk analysis, You are in <span className="color-primary-color e-poppins-medium" style={{ color: colour }}>{riskScoreData.investment_style}</span> investment style.</p>
                                                            <div className="mb-4 d-flex flex-wrap gap-8px mt-ms-0 mt-3">
                                                                <p className="color-gray e-poppins-medium e-font-14 line-height-14px mb-0">Risk assessment last submitted</p>
                                                                <p className="color-black e-poppins-medium e-font-14 line-height-14px mb-0 mt-sm-0 mt-0">{riskScoreData.assessment_completed_on}</p>
                                                            </div>
                                                            <GradientButton label={riskScoreData.assessment_completed === true ? "Re-do risk assessments" : "Do risk assessments"}
                                                                className="e-font-16 p-2"
                                                                handleClick={() => navigate("/profile/risk-assessment")} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </>
                                :
                                // to show list of nominee 
                                tabIndex === 3 ?

                                    nomineeData === null || (nomineeData.nominees.length === 0 && nomineeData.skip_nominee === false) ?
                                        <div className="border-radius-16px border-all border-bright-gray py-4 padding-32px-left pe-4 mt-lg-0 mt-md-0 mt-3">
                                            <EmptyScreen image={NoDataNominee}
                                                title={USER_DATA.is_kyc_done == true ? "Add nominee to your investment account" : USER_DATA.kyc_step === 0 ? "Create an account to add nominee" : "Resume with account creation to add nominee"}
                                                description={USER_DATA.is_kyc_done == true ? "Declare your nominees here" : USER_DATA.kyc_step === 0 ? "Declare your nominees after creating account" : "Declare your nominees in account account creation process"}
                                                buttonLabel={USER_DATA.is_kyc_done == true ? "Add new nominee" : USER_DATA.kyc_step === 0 ? "Create account" : "Resume KYC"}
                                                // disabled={USER_DATA.is_kyc_done === false ? true : false}
                                                onPress={_handleNoNominee} />
                                        </div>

                                        :
                                        <div className="border-radius-16px border-all border-bright-gray py-sm-4 py-3 padding-32px-left-sm-8 pe-sm-4 pe-2 col-lg-8 col-12 mt-lg-0 mt-md-0 mt-3">
                                            <div className="d-flex gap-8px flex-wrap justify-content-between align-items-center">
                                                <div>
                                                    <p className="color-black e-poppins-medium e-font-16 line-height-16 mb-3">Nominee type selected</p>
                                                    <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0">{nomineeData.skip_nominee === false ? 'Opt-in' : 'Opt-out'}</p>
                                                </div>

                                                <OutlineButton label="Edit or add "
                                                    icon="Pen-square"
                                                    className="p-sm-2 p-1 e-font-sm-16-14 mt-sm-0 mt-3"
                                                    iconSize={screenWidth > 576 ? 24 : 18}
                                                    iconClass={screenWidth < 576 && "margin-3px-top"}
                                                    disabled={USER_DATA.is_kyc_done === false ? true : false}
                                                    handleClick={_handleManageNominee} />
                                            </div>
                                            {
                                                nomineeData.nominees.length !== 0 && (
                                                    <>
                                                        <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-3 margin-50px-top">Your nominees ({nomineeData.total_nominees})</p>
                                                        {
                                                            screenWidth > 576 ?
                                                                <DataTable columns={NOMINEE}
                                                                    data={nomineeData.nominees}
                                                                    pagination={false}
                                                                    customStyles={NOMINEE_STYLES} />
                                                                :
                                                                nomineeData.nominees.map((nominee, key) => {
                                                                    return (
                                                                        <div className={`border-bright-gray pb-3 ${key !== nomineeData.nominees.length - 1 && 'border-bottom'}`} key={key}>
                                                                            <div className="row mt-3">
                                                                                <div className="col-8 pe-0">
                                                                                    <Description title="Name:" description={nominee.name} />
                                                                                </div>
                                                                                <div className=" col-4 text-end">
                                                                                    <Description title="Relation:" description={nominee.relationship}
                                                                                        className="justify-content-end" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-3">
                                                                                <div className="col-6 pe-0">
                                                                                    <Description title="Date of Birth:" description={nominee.date_of_birth_str} />
                                                                                </div>
                                                                                <div className=" col-6 text-end">
                                                                                    <Description title="Allocation%:" description={nominee.allocation}
                                                                                        className="justify-content-end" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })

                                                        }

                                                    </>
                                                )
                                            }
                                        </div>


                                    :
                                    // to show linked bank accounts
                                    tabIndex === 4 ?
                                        <Fragment>
                                            {
                                                bankData.length !== 0 ?
                                                    <div className="e-profile-bank-table-wrapper">
                                                        <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-4 mt-lg-0 mt-md-0 mt-3">Linked bank accounts({bankData.length})</p>
                                                        {
                                                            screenWidth > 576 ?
                                                                <DataTable columns={BANK}
                                                                    data={bankData}
                                                                    pagination={false}
                                                                    customStyles={BANk_STYLES} />
                                                                :
                                                                <>
                                                                    {
                                                                        bankData.map((bank, key) => {
                                                                            return (
                                                                                <div className=" border-bottom border-bright-gray px-2 py-3" key={key}>
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <div className="d-flex align-items-center gap-8px">
                                                                                            <div className="p-2 border-radius-8px border-all border-bright-gray e-bank-image">
                                                                                                <img src={bank.bank_image}
                                                                                                    alt={bank.name}
                                                                                                    width={24}
                                                                                                    height={24}
                                                                                                    className="object-fit-contain" />
                                                                                            </div>
                                                                                            <div className=" ">
                                                                                                <div className="d-flex">
                                                                                                    <p className="color-black e-poppins-medium e-font-12 line-height-32px mb-0 white-space-nowrap me-2">{bank.bank_name}</p>
                                                                                                    {
                                                                                                        bank.primary === true &&
                                                                                                        <p className="color-primary-color e-poppins-regular e-font-10 mt-2 white-space-nowrap mb-1">(Primary bank)</p>
                                                                                                    }
                                                                                                </div>
                                                                                                <p className="color-outer-space e-poppins-medium e-font-12 line-height-16px mb-0">{bank.masked_account_number}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="position-relative text-end">
                                                                                            {
                                                                                                bankData.length > 1 &&
                                                                                                <>
                                                                                                    {
                                                                                                        bank.is_default === true && bank.primary === true ?
                                                                                                            null
                                                                                                            :
                                                                                                            <img src={require('../../Assets/Images/profile/bank-menu-dots.png')}
                                                                                                                alt="View more"
                                                                                                                width={24}
                                                                                                                height={24}
                                                                                                                className="cursor-pointer e-filter-invert-1"
                                                                                                                onClick={() => setSelectedBank(bank)} />
                                                                                                    }

                                                                                                    {
                                                                                                        selectedBank.bank_id === bank.bank_id && (
                                                                                                            <>
                                                                                                                <div className="position-absolute border-radius-12px border-width-2px border-solid border-bright-gray padding-10px-lr py-2 bg-cards white-space-nowrap w-fit-content e-bank-options z-index-12"
                                                                                                                    ref={bankRef}>
                                                                                                                    {
                                                                                                                        bank.primary !== true &&
                                                                                                                        <p className={`color-black e-poppins-regular e-font-14  cursor-pointer hover-primary-color mb-0 line-height-22px  ${makeDefaultBankApiLoading === true && 'e-text-loader'}`}
                                                                                                                            onClick={() => _makeDefaultBank()}>{makeDefaultBankApiLoading ? 'Loading' : 'Make primary account'}</p>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        bank.is_default !== true &&
                                                                                                                        <p className={`color-black e-poppins-regular e-font-14 mb-0 cursor-pointer hover-primary-color line-height-22px ${tabLoader === true && 'e-text-loader'}`}
                                                                                                                            onClick={() => setShowDeleteConfirmation(true)}>{tabLoader === true ? 'Loading' : 'Remove bank account'}</p>
                                                                                                                    }

                                                                                                                </div>
                                                                                                                <div className="position-fixed w-100 z-index-10 h-100 left-0 top-0 cursor-default"
                                                                                                                    onClick={() => setSelectedBank(0)}></div>
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mt-3">
                                                                                        <div className="col-6 pe-0">
                                                                                            <Description title="Type:" description={bank.account_type_label} />
                                                                                        </div>
                                                                                        <div className=" col-6 text-end">
                                                                                            <Description title="IFSC:" description={bank.ifsc_code}
                                                                                                className="justify-content-end" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mt-1">
                                                                                        <div className="col-6 pe-0">
                                                                                            <div className="d-flex">
                                                                                                <span className="color-gray e-poppins-medium e-font-12 line-height-24px">Mandate mapped:</span>
                                                                                                <p className="color-black e-poppins-medium e-font-12 line-height-16px mb-0 mt-1">{bank.mandates_available === true ? "Yes" : "No"}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 d-flex justify-content-end">
                                                                                            {
                                                                                                bank.mandates_available === true &&
                                                                                                <LinkText title="View mandate"
                                                                                                    onClick={() => _viewMandates(bank.bank_id)} />
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                        }

                                                        <GradientButton label="Add new bank"
                                                            disabled={USER_DATA.is_kyc_done === false ? true : false}
                                                            className="p-2 e-font-16 mt-4"
                                                            handleClick={() => setOpenAddBankModal(true)} />
                                                    </div>
                                                    :
                                                    <div className="col-12 text-center mt-lg-0 mt-md-0 mt-3">
                                                        <EmptyScreen image={NoDataBank}
                                                            title="Link your bank account to start investing."
                                                            description="Initiate your investment journey seamlessly by linking your bank account"
                                                            buttonLabel="Add new bank"
                                                            disabled={USER_DATA.is_kyc_done === false ? true : false}
                                                            onPress={() => setOpenAddBankModal(true)} />
                                                    </div>
                                            }
                                        </Fragment>
                                        :
                                        // to show mandates list
                                        tabIndex === 5 ?
                                            tabLoader === true ?
                                                <CustomLoader />
                                                :
                                                <Fragment>
                                                    <div className="e-tap-wrapper">
                                                        <CustomTab data={MANDATE_TYPE}
                                                            wrapperClass={'gap-16px'}
                                                            selectedTab={mandateType}
                                                            className="px-0 padding-10px-tb e-order-tab mb-lg-4 mb-md-4 mb-3"
                                                            onSelectTab={_handleMandateType} />
                                                    </div>
                                                    {
                                                        mandateType === 1 ?
                                                            <div className="row mt-lg-0 mt-md-0 mt-3">
                                                                {
                                                                    mandateList.length === 0 ? (
                                                                        <div className="col-12">
                                                                            <EmptyScreen image={NoDataMandate}
                                                                                title={bankData.length !== 0 ? "Create bank mandate" : "No banks linked"}
                                                                                description={''}
                                                                                buttonLabel={bankData.length !== 0 && "Create mandate"}
                                                                                disabled={USER_DATA.is_kyc_done === false ? true : false}
                                                                                onPress={() => setShowMandateModal(true)} />
                                                                        </div>

                                                                    ) : (
                                                                        <>
                                                                            <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-4">Linked mandates</p>
                                                                            {
                                                                                screenWidth > 576 ?
                                                                                    <div className="e-profile-bank-table-wrapper">
                                                                                        <DataTable columns={MANDATE_TABLE}
                                                                                            data={mandateList}
                                                                                            pagination={false}
                                                                                            customStyles={BANk_STYLES} />
                                                                                    </div>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            mandateList.map((mandate, key) => {
                                                                                                return (
                                                                                                    <div className=" border-bottom border-bright-gray px-3 py-3" key={key}>
                                                                                                        <div className="d-flex align-items-center gap-12px e-fund-name ">
                                                                                                            <div className="p-2 border-radius-8px border-all border-bright-gray e-bank-image">
                                                                                                                <img src={mandate.bank_image}
                                                                                                                    alt={mandate.name}
                                                                                                                    width={24}
                                                                                                                    height={24}
                                                                                                                    className="object-fit-contain" />
                                                                                                            </div>
                                                                                                            <div className="d-flex flex-column gap-8px">
                                                                                                                <div className="d-flex gap-16px  align-items-center">
                                                                                                                    <p className="color-black e-poppins-medium e-font-12 line-height-16px mb-0 white-space-nowrap">{mandate.bank_name}</p>
                                                                                                                    <p className={`border-radius-24px  border-radius-24px e-poppins-regular e-font-10 line-height-16px mb-0  px-2 py-1 white-space-nowrap w-max-content  e-mandate-status ${mandate.status_str === 'SUCCESS' ? 'color-sea-green bg-pastel-green' : 'color-vivid-orange bg-pale-orange'}`}>{mandate.status_str}</p>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                        <div className="row mt-3">
                                                                                                            <div className="col-6 pe-0">
                                                                                                                <Description title="Mandate ID:" description={mandate.mandate_id} />
                                                                                                            </div>
                                                                                                            <div className=" col-12 text-start mt-3">
                                                                                                                <Description title="Mandate amt/day:" description={mandate.mandate_limit} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="row mt-3">
                                                                                                            <div className="col-6 pe-0">
                                                                                                                <Description title="Start date:" description={mandate.created} />
                                                                                                            </div>
                                                                                                            <div className=" col-6 text-end">
                                                                                                                <Description title="Expiry date:" description={mandate.end_date}
                                                                                                                    className="justify-content-end" />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="row mt-3">
                                                                                                            <div className="col-12 pe-0">
                                                                                                                <Description title="Remark:" description={mandate.remark ? mandate.remark : '-'} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                            }

                                                                            {
                                                                                bankData.length !== 0 &&
                                                                                <div className="d-flex">
                                                                                    <GradientButton label="Manage mandates"
                                                                                        className="p-2 e-font-16 mt-4"
                                                                                        handleClick={_showMandateModal} />
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    )
                                                                }

                                                            </div>
                                                            :
                                                            <>
                                                                {
                                                                    mandateList.length === 0 ?
                                                                        <>
                                                                            {
                                                                                bankData.length === 0 ? (
                                                                                    <div className="col-12">
                                                                                        <EmptyScreen image={NoDataMandate}
                                                                                            title="Create bank mandate"
                                                                                            description={''}
                                                                                            disabled={USER_DATA.is_kyc_done === false ? true : false}
                                                                                            onPress={() => setShowMandateModal(true)} />
                                                                                    </div>

                                                                                ) :
                                                                                    <div className="row mt-lg-0 mt-md-0 mt-3">
                                                                                        <div className="col-12">
                                                                                            <h2 className="e-inter-bold e-font-24 color-black">What is a physical mandate?</h2>
                                                                                            <p className="e-poppins-regular e-font-16 color-outer-space line-height-24px">
                                                                                                Physical mandate is the process of authorization where the customer requires only his signature and bank account details.
                                                                                                There is no requirement to have a debit card / netbanking enabled to set up the mandate.
                                                                                            </p>
                                                                                            <h4 className="e-poppins-medium e-font-16 line-height-24px color-black">
                                                                                                Steps for creating a physical mandate
                                                                                            </h4>
                                                                                        </div>
                                                                                        <div className="col-8 mt-3">
                                                                                            <div className="d-flex align-items-flex-start">
                                                                                                <img src={require('../../Assets/Images/profile/mandate-bank.svg').default}
                                                                                                    className="w-40px h-40px" />
                                                                                                <div className="ms-3">
                                                                                                    <p className="e-poppins-regular e-font-14 mb-3 color-primary-color">Step 1</p>
                                                                                                    <h6 className="e-poppins-medium e-font-16 ">Select Bank account, date and amount</h6>
                                                                                                    <p className="e-poppins-regular e-font-16 color-outer-space">Provide your bank account details, choose the transaction date, and enter the amount to proceed securely to proceed with the mandate creation.</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex align-items-flex-start">
                                                                                                <img src={require('../../Assets/Images/profile/submit-detail.svg').default}
                                                                                                    className="w-40px h-40px" />
                                                                                                <div className="ms-3">
                                                                                                    <p className="e-poppins-regular e-font-14 mb-3 color-primary-color">Step 2</p>
                                                                                                    <h6 className="e-poppins-medium e-font-16 ">Submit the details</h6>
                                                                                                    <p className="e-poppins-regular e-font-16 color-outer-space">Provide the required information accurately to ensure smooth processing and timely updates.</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex align-items-flex-start">
                                                                                                <img src={require('../../Assets/Images/profile/prefilled-mandate.svg').default}
                                                                                                    className="w-40px h-40px" />
                                                                                                <div className="ms-3">
                                                                                                    <p className="e-poppins-regular e-font-14 mb-3 color-primary-color">Step 3</p>
                                                                                                    <h6 className="e-poppins-medium e-font-16 ">Download Pre-filled Mandate Form</h6>
                                                                                                    <p className="e-poppins-regular e-font-16 color-outer-space">Get your pre-filled mandate form with your details.</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex align-items-flex-start">
                                                                                                <img src={require('../../Assets/Images/profile/upload.svg').default}
                                                                                                    className="w-40px h-40px" />
                                                                                                <div className="ms-3">
                                                                                                    <p className="e-poppins-regular e-font-14 mb-3 color-primary-color">Step 4</p>
                                                                                                    <h6 className="e-poppins-medium e-font-16 ">Sign and upload mandate form for processing</h6>
                                                                                                    <p className="e-poppins-regular e-font-16 color-outer-space">Ensure the form is signed with a blue ink pen, scanned clearly, and uploaded in a supported format to avoid delays in processing.</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <GradientButton label="Create scan based mandate"
                                                                                                className="padding-8px-tb padding-16px-lr"
                                                                                                disabled={USER_DATA.is_kyc_done === false ? true : false}
                                                                                                handleClick={_showMandateModal} />
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </>

                                                                        :
                                                                        <div className="row mt-lg-0 mt-md-0 mt-3">
                                                                            <div className="col-12 mt-3">
                                                                                <h6 className="e-poppins-medium e-font-16 color-jett-black">Added physical mandate</h6>
                                                                            </div>
                                                                            {
                                                                                mandateList.map((item, key) => {
                                                                                    return (
                                                                                        <div className="col-12 mt-3">

                                                                                            <div className="border-all border-bright-gray border-radius-16px padding-16px-lr padding-24px-tb">
                                                                                                <div className="row">
                                                                                                    <div className="col-lg col-md-4 col-6 border-right-1px pe-4">
                                                                                                        <label className="e-poppins-medium e-font-14 color-outer-space">Added on</label>
                                                                                                        <p className="e-poppins-medium e-font-14 color-black">{item.created}</p>
                                                                                                    </div>
                                                                                                    <div className="col-lg col-md-4 col-6 border-right-1px pe-4">
                                                                                                        <label className="e-poppins-medium e-font-14 color-outer-space">Mandate reg. on</label>
                                                                                                        <p className="e-poppins-medium e-font-14 color-black">{item.mandate_id}</p>
                                                                                                    </div>
                                                                                                    <div className="col-lg col-md-4 col-6 border-right-1px pe-4">
                                                                                                        <label className="e-poppins-medium e-font-14 color-outer-space">Name</label>
                                                                                                        <p className="e-poppins-medium e-font-14 color-black">{item.account_holder_name}</p>
                                                                                                    </div>
                                                                                                    <div className="col-lg col-md-4 col-6 border-right-1px pe-4">
                                                                                                        <label className="e-poppins-medium e-font-14 color-outer-space">Bank</label>
                                                                                                        <p className="e-poppins-medium e-font-14 color-black">{item.bank_name}</p>
                                                                                                    </div>
                                                                                                    <div className="col-lg col-md-4 col-6 border-right-1px pe-4">
                                                                                                        <label className="e-poppins-medium e-font-14 color-outer-space">Account number</label>
                                                                                                        <p className="e-poppins-medium e-font-14 color-black">{item.account_number}</p>
                                                                                                    </div>
                                                                                                    <div className="col-lg col-md-4 col-6 border-right-1px pe-4">
                                                                                                        <label className="e-poppins-medium e-font-14 color-outer-space">Remarks</label>
                                                                                                        <p className="e-poppins-medium e-font-14 color-black">{item.remarks ? item.remarks : '-'}</p>
                                                                                                    </div>
                                                                                                    <div className="col-lg col-md-4 col-6 pe-4">
                                                                                                        <label className={`e-poppins-medium e-font-14 color-outer-space`}>Status</label>
                                                                                                        <p className={`e-poppins-medium e-font-14 ${item.status_str === 'SUCCESS' ? 'color-sea-green' : item.status_str === 'FAILED' ? 'color-red' : 'color-yellow-orange'}`}>{item.status_str}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col-md-6 col-12">
                                                                                                        {
                                                                                                            item.is_uploaded === false &&
                                                                                                            <div className="d-flex gap-8px bg-mint-cream p-2 border-radius-8px mt-3">
                                                                                                                <div className="w-max-content">
                                                                                                                    <Icon icon="info"
                                                                                                                        size={24} />
                                                                                                                </div>
                                                                                                                <p className="e-font-16 e-poppins-regular line-height-24px color-jett-black mb-0 ">
                                                                                                                    {`You can upload your signed document on or before ${item.start_date}`}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        }

                                                                                                    </div>
                                                                                                    <div className="col-md-6 col-12 text-md-end mt-2">
                                                                                                        <div className="d-flex justify-content-end mt-3">
                                                                                                            <button className={`${item.is_uploaded === false ?  `border-primary-color cursor-pointer color-primary-color-theme-white` : 'color-old-silver border-old-silver e-pointer-events-none'} bg-cards e-scan-mandate-file-button border-all border-radius-8px  padding-8px-tb  padding-12px-lr e-poppins-medium e-font-14  d-flex align-items-center`}
                                                                                                                onClick={() => _handleMandateUploadButton(item.mandate_id)}>
                                                                                                                <Icon icon="cloud-upload"
                                                                                                                    color={item.is_uploaded === false ? darkMode === true ? Color.white : Color.primary : Color.old_sliver}
                                                                                                                    size={18}
                                                                                                                    className="me-1" />
                                                                                                                Upload
                                                                                                            </button>
                                                                                                            <OutlineButton label={item.is_downloaded === false ? "Download" : "Re-download form"}
                                                                                                                disabled={item.is_uploaded === true ? true : false}
                                                                                                                className="ms-3 e-mandate-download"
                                                                                                                handleClick={() => _downloadMandate(item.mandate_id)} />

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                item.is_uploaded === true && item.status === false &&
                                                                                                <div className="d-flex gap-8px bg-pale-sky-blue border-radius-32px p-4 mt-3">
                                                                                                    <div className="w-max-content">
                                                                                                        <Icon icon="info"
                                                                                                            size={30} />
                                                                                                    </div>
                                                                                                    <div >
                                                                                                        <h6 className="e-poppins-medium e-font-16 color-black">Your physical mandate is under review</h6>
                                                                                                        <p className="e-font-16 e-poppins-regular line-height-24px color-outer-space mb-0 ">In case of fund transfer via Net Banking, payment confirmation may take some time due to Clearing Corporation fund received. Fund transfer via UPI is instant</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }

                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <div className="col-12 mt-3">
                                                                                <GradientButton label="Create new mandate"
                                                                                    className={`${mandateList.every(item => item.is_uploaded === true && item.status === true) ? 'bg-prime-gradient color-white' : 'color-old-silver bg-chinese-white e-pointer-events-none'} padding-8px-tb padding-16px-lr `}
                                                                                    handleClick={_showMandateModal} />
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </>

                                                    }


                                                </Fragment>
                                            :
                                            tabIndex === 6 ?
                                                <Fragment>
                                                    <div className="col-md-8 col-12 mt-lg-0 mt-md-0 mt-3">
                                                        <div className="border-radius-16px border-all border-bright-gray padding-32px-lr py-4">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-3">NFT</p>
                                                                {
                                                                    Object.keys(TOOL_TIP).length !== 0 &&
                                                                    <div className="position-relative">
                                                                        <Tooltip id="nft-tooltip"
                                                                            arrowColor='transparent'
                                                                            effect="float"
                                                                            place="bottom"
                                                                            className='e-tooltip-general'>
                                                                            {parse(TOOL_TIP.nft_content)}
                                                                        </Tooltip>
                                                                        <Icon icon="info"
                                                                            size={24}
                                                                            data-tooltip-id="nft-tooltip" />

                                                                    </div>
                                                                }

                                                            </div>
                                                            <div className="e-nft-card mt-3"
                                                                onClick={() => _navigateToUpdateNFT(1)}>
                                                                <div className="d-flex justify-content-between align-items-center cursor-pointer">
                                                                    <p className="e-poppins-medium e-font-16 color-outer-space mb-0">Update nominee</p>
                                                                    <Icon icon="arrow-right1"
                                                                        size={24}
                                                                        color={Color.black} />
                                                                </div>
                                                            </div>
                                                            <div className="e-nft-card "
                                                                onClick={() => _navigateToUpdateNFT(2)}>
                                                                <div className="d-flex justify-content-between align-items-center cursor-pointer">
                                                                    <p className="e-poppins-medium e-font-16 color-outer-space mb-0">Update mail</p>
                                                                    <Icon icon="arrow-right1"
                                                                        size={24}
                                                                        color={Color.black} />
                                                                </div>
                                                            </div>
                                                            <div className="e-nft-card "
                                                                onClick={() => _navigateToUpdateNFT(3)}>
                                                                <div className="d-flex justify-content-between align-items-center cursor-pointer">
                                                                    <p className="e-poppins-medium e-font-16 color-outer-space mb-0">Update mobile</p>
                                                                    <Icon icon="arrow-right1"
                                                                        size={24}
                                                                        color={Color.black} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex gap-8px bg-antique-white border-radius-8px p-2 mt-3">
                                                            <div className="w-max-content">
                                                                <Icon icon="info"
                                                                    size={30} />
                                                            </div>
                                                            {
                                                                Object.keys(TOOL_TIP).length !== 0 &&
                                                                <p className="e-font-16 e-poppins-regular line-height-24px color-jett-black-dark mb-0 ">
                                                                    {parse(TOOL_TIP.nft_content)}
                                                                </p>
                                                            }

                                                        </div>
                                                    </div>
                                                </Fragment>
                                                :
                                                tabIndex === 7 ?
                                                    <>
                                                        {
                                                            USER_DATA.is_deletable === true ?
                                                                <Fragment>
                                                                    <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-4 mt-lg-0 mt-md-0 mt-3">Delete account</p>
                                                                    <div className="border-radius-16px bg-cultured padding-24px-lr padding-24px-top padding-32px-bottom">
                                                                        <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-3">Personal information</p>
                                                                        <p className="color-black e-poppins-regular e-font-14 line-height-24px mb-3">Account closure is permanent and irreversible. Please <span className="color-primary-color cursor-pointer" onClick={_navigateToLanding}>read this</span> before proceeding.</p>
                                                                        <div className="d-flex gap-16px flex-wrap">
                                                                            <div className="d-flex align-items-center gap-8px cursor-pointer"
                                                                                onClick={_handleDelete}>
                                                                                <img src={require("../../Assets/Images/profile/delete-account.png")}
                                                                                    alt="Delete account"
                                                                                    width={24}
                                                                                    height={24} />
                                                                                <p className="color-orange-soda e-poppins-semi-bold e-font-16 line-height-16 mb-0">Delete account</p>
                                                                            </div>
                                                                            <div className="d-flex align-items-center gap-8px cursor-pointer">
                                                                                <img src={require("../../Assets/Images/profile/need-assistance.png")}
                                                                                    alt="Need assistance"
                                                                                    width={24}
                                                                                    height={24} />
                                                                                <p className="color-primary-color e-poppins-medium e-font-16 line-height-16px mb-0"
                                                                                    onClick={_handleSupport}>
                                                                                    Need assistance
                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </Fragment>
                                                                :
                                                                USER_DATA.minor_flag === true ?
                                                                    <Fragment>
                                                                        <div className="row mt-lg-0 mt-md-0 mt-3">
                                                                            <div className="col-12">
                                                                                <h6 className="e-poppins-medium e-font-16 color-black">Minor guardian details</h6>
                                                                                <div className="col-12 mt-4">
                                                                                    <div className="border-bright-gray border-all bg-pure-white border-radius-24px p-4">
                                                                                        <div className="row">
                                                                                            <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                                                                                <h6 className="e-poppins-medium e-font-14 color-outer-space">Name</h6>
                                                                                                <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA.guardian.name}</p>
                                                                                            </div>
                                                                                            <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                                                                                <h6 className="e-poppins-medium e-font-14 color-outer-space">DOB</h6>
                                                                                                <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA.guardian.date_of_birth}</p>
                                                                                            </div>
                                                                                            <div className="col-sm-6 col-12">
                                                                                                <h6 className="e-poppins-medium e-font-14 color-outer-space">PAN</h6>
                                                                                                <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA.guardian.masked_pan}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                    : USER_DATA.account_type === 'Joint account' || USER_DATA.account_type === 'Either or Survivor' || USER_DATA.account_type === 'Anyone or Survivor' ?
                                                                        <Fragment>
                                                                            <div className="row mt-lg-0 mt-md-0 mt-3">
                                                                                <div className="col-12">
                                                                                    <h6 className="e-poppins-medium e-font-16 color-black">{USER_DATA.account_type === 'Joint account' ? 'Joint holders details' : 'Holders details'}</h6>
                                                                                    <div className="col-12 mt-4">
                                                                                        <div className="border-bright-gray border-all bg-pure-white border-radius-24px p-4">

                                                                                            <div className="row mb-4">
                                                                                                <div className="col-12">
                                                                                                    <h5 className="e-poppins-medium e-font-16 color-black mb-2">Holder 1</h5>
                                                                                                </div>
                                                                                                <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                                                                                    <h6 className="e-poppins-medium e-font-14 color-outer-space">Name</h6>
                                                                                                    <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA && USER_DATA.name}</p>
                                                                                                </div>
                                                                                                <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                                                                                    <h6 className="e-poppins-medium e-font-14 color-outer-space">DOB</h6>
                                                                                                    <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA && USER_DATA.dob}</p>
                                                                                                </div>
                                                                                                <div className="col-sm-6 col-12">
                                                                                                    <h6 className="e-poppins-medium e-font-14 color-outer-space">PAN</h6>
                                                                                                    <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA.pan}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                USER_DATA.hasOwnProperty('second_holder') &&
                                                                                                <div className="row mb-4">
                                                                                                    <div className="col-12">
                                                                                                        <h5 className="e-poppins-medium e-font-16 color-black mb-2">Holder 2</h5>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                                                                                        <h6 className="e-poppins-medium e-font-14 color-outer-space">Name</h6>
                                                                                                        <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA && USER_DATA.second_holder.name}</p>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                                                                                        <h6 className="e-poppins-medium e-font-14 color-outer-space">DOB</h6>
                                                                                                        <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA && USER_DATA.second_holder.date_of_birth}</p>
                                                                                                    </div>
                                                                                                    <div className="col-sm-6 col-12">
                                                                                                        <h6 className="e-poppins-medium e-font-14 color-outer-space">PAN</h6>
                                                                                                        <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA.second_holder.masked_pan}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }

                                                                                            {
                                                                                                USER_DATA.hasOwnProperty('third_holder') &&
                                                                                                <div className="row">
                                                                                                    <div className="col-12">
                                                                                                        <h5 className="e-poppins-medium e-font-16 color-black mb-2">Holder 3</h5>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                                                                                        <h6 className="e-poppins-medium e-font-14 color-outer-space">Name</h6>
                                                                                                        <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA.third_holder.name}</p>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                                                                                        <h6 className="e-poppins-medium e-font-14 color-outer-space">DOB</h6>
                                                                                                        <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA.third_holder.date_of_birth}</p>
                                                                                                    </div>
                                                                                                    <div className="col-sm-6 col-12">
                                                                                                        <h6 className="e-poppins-medium e-font-14 color-outer-space">PAN</h6>
                                                                                                        <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA.third_holder.masked_pan}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Fragment>
                                                                        :
                                                                        null
                                                        }
                                                    </>

                                                    :
                                                    <Fragment>
                                                        <div className="row mt-lg-0 mt-md-0 mt-3">
                                                            <div className="col-12">
                                                                <h6 className="e-poppins-medium e-font-16 color-black">{USER_DATA.account_type === 'Joint account' ? 'Joint holders details' : 'Holders details'}</h6>
                                                                <div className="col-12 mt-4">
                                                                    <div className="border-bright-gray border-all bg-pure-white border-radius-24px p-4">

                                                                        <div className="row mb-4">
                                                                            <div className="col-12">
                                                                                <h5 className="e-poppins-medium e-font-16 color-black mb-2">Holder 1</h5>
                                                                            </div>
                                                                            <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                                                                <h6 className="e-poppins-medium e-font-14 color-outer-space">Name</h6>
                                                                                <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA && USER_DATA.name}</p>
                                                                            </div>
                                                                            <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                                                                <h6 className="e-poppins-medium e-font-14 color-outer-space">DOB</h6>
                                                                                <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA && USER_DATA.dob}</p>
                                                                            </div>
                                                                            <div className="col-sm-6 col-12">
                                                                                <h6 className="e-poppins-medium e-font-14 color-outer-space">PAN</h6>
                                                                                <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA.pan}</p>
                                                                            </div>
                                                                        </div>

                                                                        {
                                                                            USER_DATA.hasOwnProperty('second_holder') &&
                                                                            <div className="row mb-4">
                                                                                <div className="col-12">
                                                                                    <h5 className="e-poppins-medium e-font-16 color-black mb-2">Holder 2</h5>
                                                                                </div>
                                                                                <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                                                                    <h6 className="e-poppins-medium e-font-14 color-outer-space">Name</h6>
                                                                                    <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA && USER_DATA.second_holder.name}</p>
                                                                                </div>
                                                                                <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                                                                    <h6 className="e-poppins-medium e-font-14 color-outer-space">DOB</h6>
                                                                                    <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA && USER_DATA.second_holder.date_of_birth}</p>
                                                                                </div>
                                                                                <div className="col-sm-6 col-12">
                                                                                    <h6 className="e-poppins-medium e-font-14 color-outer-space">PAN</h6>
                                                                                    <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA.second_holder.masked_pan}</p>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        {
                                                                            USER_DATA.hasOwnProperty('third_holder') &&
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <h5 className="e-poppins-medium e-font-16 color-black mb-2">Holder 3</h5>
                                                                                </div>
                                                                                <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                                                                    <h6 className="e-poppins-medium e-font-14 color-outer-space">Name</h6>
                                                                                    <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA.third_holder.name}</p>
                                                                                </div>
                                                                                <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                                                                    <h6 className="e-poppins-medium e-font-14 color-outer-space">DOB</h6>
                                                                                    <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA.third_holder.date_of_birth}</p>
                                                                                </div>
                                                                                <div className="col-sm-6 col-12">
                                                                                    <h6 className="e-poppins-medium e-font-14 color-outer-space">PAN</h6>
                                                                                    <p className="e-poppins-medium e-font-14 color-black mb-0">{USER_DATA.third_holder.masked_pan}</p>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>



                }
            </div>

            <Footer />
            {/* to show delete account modal */}

            {
                showDeleteModal === true && (
                    <DeleteConfirmation confirmationText="Are you sure you want to delete your account permanently."
                        cancelButtonName="Cancel"
                        submitButtonName="Delete account"
                        loading={tabLoader}
                        updateList={_getBankList}
                        handleConfirmation={_confirmDelete}
                        close={() => { setShowDeleteModal(false) }} />
                )
            }

            {/* to show mandate modal */}
            {
                viewMandates !== null &&
                <MandateListModal manageMandate={mandateList}
                    handleClick={() => setTabIndex(5)}
                    close={_closeMandateListModal} />
            }

            {
                openAddBankModal === true && (
                    <AddBankModal
                        numberOfBank={bankData.length}
                        close={() => {
                            _getBankList();
                            setOpenAddBankModal(false)
                        }} />
                )
            }

            {/* manage mandate modal */}
            {
                showMandateModal === true && (
                    <CreateMandateModal bankData={bankData}
                        type={mandateType}
                        updateList={_updateMandateList}
                        close={() => setShowMandateModal(false)}
                        getPaymentStatus={_getPaymentStatus} />
                )
            }
            {
                showDeleteConfirmation && (
                    <DeleteConfirmation confirmationText="Are you sure you want to delete this bank account? This action is irreversible."
                        cancelButtonName="No, cancel"
                        submitButtonName="Yes, delete"
                        loading={tabLoader}
                        updateList={_getBankList}
                        handleConfirmation={_removeBankAccount}
                        close={() => { setShowDeleteConfirmation(false); setSelectedBank(0); }} />
                )
            }

            {
                Object.keys(showMandateDeleteModal).length !== 0 &&
                <DeleteConfirmation confirmationText="Are you sure you want to delete this mandate? This action is irreversible."
                    cancelButtonName="No, cancel"
                    submitButtonName="Yes, delete"
                    loading={tabLoader}
                    updateList={_getMandateList}
                    handleConfirmation={_removeMandate}
                    close={() => { setShowMandateDeleteModal({}); setSelectedBank({}) }} />
            }
            {
                openLeaveFamilyModal === true &&
                <DeleteConfirmation confirmationText="Are you sure you want to leave from family?"
                    cancelButtonName="No, cancel"
                    submitButtonName="Yes, delete"
                    loading={familyApiLoading}
                    handleConfirmation={_leaveFamily}
                    close={() => { setOpenLeaveFamilyModal(false); }} />
            }
            {
                openNomineeModal === true ?
                    <ManageNomineeModal data={nomineeData !== null ? nomineeData.nominees : []}
                        update={() => {
                            setOpenNomineeModal(false);
                            _getNomineeData()
                        }}
                        close={_handleCloseNomineeModal} />
                    :
                    null
            }
            {
                profileModalType === 2 ?
                    <ManagePhoneModal phone={USER_DATA.mobile}
                        onCloseModal={_handleCloseProfileModal}
                        phoneAdded={() => {
                            _handleCloseProfileModal();
                            _fetchProfile()
                        }} />
                    :
                    null
            }

            {
                profileModalType === 1 ?
                    <ManageEmailModal email={USER_DATA.email}
                        onCloseModal={_handleCloseProfileModal}
                        mailAdded={() => {
                            _handleCloseProfileModal();
                            _fetchProfile()
                        }} />
                    :
                    null
            }

            {
                openStatusModal === true && (
                    <PaymentStatusModal close={_handleStatusModalClose}
                        message={SUCCESS_MESSAGE}
                        status={paymentStatus}
                        type={3} />
                )
            }

            {
                openRelationUpdateModal === true && (
                    <UpdateMobileRelation type={relationType}
                        success={_callBackRelationSuccess}
                        close={_closeRelationUpdateModal} />
                )
            }

            {
                openUploadDocModal === true && (
                    <UploadSignedMandate close={() => setOpenUploadDocModal(false)}
                        mandateId={selectedMandate}
                        updateMandateStatus={_updateMandateStatus} />
                )
            }

            <PaymentStatusModal />

        </Fragment>
    )
}

export default Profile