/*
 *   File : search-select.js
 *   Author URI : www.evoqins.com
 *   Description : Selectbox with custom text box
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

/* import package */
import React, { Fragment, useEffect, useRef, useState, useContext } from "react";
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { DarkModeContext } from "../../Context/dark-mode-context";
/* import components */
import Icon from "../Icon/icon";

import Colors from '../../Styles/color.module.scss'

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon icon="right-arrow"
                width="20px"
                height="20px"
                color={Colors.space_cadet} />
        </components.DropdownIndicator>
    );
};

const CustomSearchSelectBox = (props) => {
    const creatableRef = useRef(null);
    const { darkMode } = useContext(DarkModeContext);

    const [value, setValue] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setValue(props.value);

        // focusing input if selected other option 
        if (props.creatable) {
            creatableRef.current?.focus();
        }
    }, [props.value, props.creatable]);



    const customStyles = {
        container: (defaultStyles, state) => ({
            ...defaultStyles,
            zIndex: "2"
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            margin: "0"
        }),
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderRadius: props.radius ? props.radius : "6px",
            borderColor: props.error.length !== 0 ? `${Colors.red}` : darkMode === true ? `${Colors.gunmetal_gray}` : `${Colors.columbia_blue}`,
            border: props.error.length !== 0 ? `1px solid ${Colors.red}` : darkMode === true ? `1px solid ${Colors.gunmetal_gray}` : `1px solid ${Colors.columbia_blue}`,
            background: props.background ? props.background : Colors.white,
            padding: props.padding ? props.padding : "8px",
            boxShadow: "0 0.375rem 0.25rem 0px rgba(50, 50, 71, 0.03)",
            "&:hover": {
                border: darkMode === true ? `1px solid ${Colors.dusty_gray}` : `1px solid ${Colors.columbia_blue}`,
                cursor: 'pointer',
            },
        }),
        valueContainer: (defaultStyles) => ({
            ...defaultStyles,
            padding: "0px",
        }),
        menu: (defaultStyles) => ({
            ...defaultStyles,
            width: "100%",
            left: "0",
            border: darkMode === true ? `2px solid ${Colors.gunmetal_gray}` : `2px solid ${Colors.gainsboro}`,
            backgroundColor:  Colors.common_card_color,
            boxShadow: "0 0.375rem 0.25rem 0px rgba(50, 50, 71, 0.03)",
            borderRadius: "12px",
            overflow: "hidden"
        }),

        menuList: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: '0px !important',
            display: "flex",
            flexDirection: "column",
            gap:  "0px",
            maxHeight: "300px",
            "&::-webkit-scrollbar" : {
                width: '0.375rem',
            },
            "&::-webkit-scrollbar-thumb":{
                borderRadius: "0.25rem",
                backgroundColor: Colors.primary
            },
            "&::-webkit-scrollbar-track":{
                backgroundColor: Colors.white
            }

        }),

        dropdownIndicator: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "0",
            marginLeft: "4px",
            transition: "transform 0.2s",
            transform: state.selectProps.menuIsOpen === true ? "rotate(-90deg)" : "rotate(90deg)"
        }),
        option: (defaultStyles, state) => ({

            ...defaultStyles,
            padding: "0",
            color: darkMode === true ? Colors.white : Colors.black,
            fontFamily: "Poppins-regular",
            fontSize: "14px",
            fontStyle: " normal",
            fontWeight: " 400",
            backgroundColor: darkMode === true ? Colors.eerie_black : Colors.white,
            borderRadius: "0px",
            cursor: "pointer",
            "&:active": {
                background: darkMode === true ? Colors.charcoal_gray : Colors.light_cyan,
            },
            "&:selected": {
                padding: '10px 14px',
                backgroundColor: darkMode === true ? Colors.charcoal_gray : Colors.light_cyan
            }

        }),
        indicatorContainer: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: '0px !important',
        }),

        singleValue: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: "14px",
            lineHeight: "24px",
            fontFamily: "Poppins-Regular",
            color: darkMode === true ? Colors.white : Colors.charleston_green

        }),
        input: (defaultStyles) => ({
            ...defaultStyles,
            margin: "0px !important",
            padding: '0px',
            fontFamily: "Poppins-Regular",
            color: darkMode === true ? Colors.white : Colors.charleston_green
        })
    };

    const _onSelectChange = (value) => {
        props.onSelectChange(value)
    }


    const _handleMenuToggle = (isOpen) => {
        setIsOpen(isOpen)
        if (props.onMenuToggle) {
            props.onMenuToggle(isOpen);
        }
    };

    const CustomSingleValue = ({ children, ...props }) => (

        <components.SingleValue {...props}>
            <div className="d-flex gap-8px">
                <span className={`e-poppins-medium ${darkMode === true ? 'color-white' : 'color-charleston-green'}`}>{children}</span>
            </div>
        </components.SingleValue >
    );

    // Custom Option component with the icon
    const CustomOption = ({ children, isSelected, ...props }) => (
        <components.Option {...props}>
            <div className={isSelected ? `${darkMode === true  ? 'bg-charcoal-gray' : 'bg-light-cyan'}  padding-12px-all` : "d-flex align-items-center gap-10px padding-12px-all"}>
                <span className={isSelected ? "e-poppins-medium" : "e-poppins-regular"}>{children}</span>
            </div>
        </components.Option>
    );

    return (
        <Fragment>
            <div className={`${props.className} e-search-select position-relative`}>
                {
                    isOpen ?
                        // transforming label to top if menu is open
                        <span className={"color-outer-space e-poppins-regular e-font-14 line-height-24px position-absolute e-select-label-active"} style={{ backgroundColor: props.labelColor }}>
                            {props.placeholder ? props.placeholder : props.label}<span className='color-red'>
                                {props.postfix}
                            </span>
                        </span>
                        :


                        value !== "" ?
                            // transforming label to top if value is added
                            <span className={"color-outer-space e-poppins-regular e-font-14 line-height-24px e-select-label-active position-absolute"} style={{ backgroundColor: props.labelColor }}>
                                {props.placeholder ? props.placeholder : props.label}<span className='color-red'>
                                    {props.postfix}
                                </span>
                            </span>
                            :

                            // default placeholder style in select
                            <span className={props.error ? "color-red e-poppins-regular e-font-14 line-height-24px e-select-label-placeholder position-absolute" : "color-outer-space e-poppins-regular e-font-14 line-height-24px e-select-label-placeholder position-absolute"} style={{ backgroundColor: props.labelColor }}>
                                {props.placeholder ? props.placeholder : props.label}<span className='color-red'>
                                    {props.postfix}
                                </span>
                            </span>


                }

                {
                    props.creatable ?
                        <CreatableSelect ref={creatableRef}
                            value={value}
                            styles={customStyles}
                            isSearchable={true}
                            options={props.options}
                            placeholder={props.placeholder}
                            // menuIsOpen={true}
                            // isClearable
                            components={{
                                DropdownIndicator,
                                IndicatorSeparator: null,
                                Option: CustomOption,
                                SingleValue: CustomSingleValue,
                            }}
                            onMenuOpen={() => _handleMenuToggle(true)} // Call the function when the menu opens
                            onMenuClose={() => _handleMenuToggle(false)}
                            onChange={(value) => {
                                _onSelectChange(value)
                            }}
                        />
                        :
                        <Select value={value}
                            styles={customStyles}
                            isSearchable={true}
                            options={props.options}
                            placeholder={props.placeholder}
                            // menuIsOpen={true}
                            components={{
                                DropdownIndicator,
                                IndicatorSeparator: null,
                                Option: CustomOption,
                                SingleValue: CustomSingleValue,
                            }}
                            onMenuOpen={() => _handleMenuToggle(true)} // Call the function when the menu opens
                            onMenuClose={() => _handleMenuToggle(false)}
                            onChange={(value) => {
                                _onSelectChange(value)
                            }}
                        />
                }
                {
                    props.hide_error_text === true ?
                        null
                        :
                        props.error && (
                            <div className='position-absolute d-flex align-items-center mt-1 '>
                                <Icon icon="info-circle"
                                    size={16}
                                    className="me-1" />
                                <p className='color-red margin-block-end-0 e-font-12 '>
                                    {props.error}
                                </p>
                            </div>
                        )
                }
            </div>

        </Fragment>
    );
}

CustomSearchSelectBox.defaultProps = {
    error: "",
    creatable: false,
    padding: "",
    hide_error_text: false,
    onMenuToggle: () => { }
}

export default CustomSearchSelectBox;