
/*
 *   File : upload-signed-mandate.js
 *   Author URI : https://evoqins.com
 *   Description : Upload signed mandate
 *   Integrations : null
 *   Version : v1.1
 */

// import package
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

// import components
import style from "../../Styles/Component/upload-signature.module.scss"
import Icon from "../Icon/icon";
import { GradientButton, SecondaryButton } from "../CTA";
import { CustomFilePicker, FilePreview } from "../Form-elements";

import Color from '../../Styles/color.module.scss';

import { _isFileTypeAllowed } from "../../Helper";
import { _uploadDocumentToS3 } from "../../Service/aws-service";
import APIService from "../../Service/api-service";

export default function UploadSignedMandate(props) {

    const [uploadFile, setUploadFile] = useState(null);
    const [uploadFileError, setUploadFileError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [fileLoader, setFileLoader] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);
    const [showStatusScreen, setShowStatusScreen] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(true);

    useEffect(() => {
        var my_modal = document.getElementById("upload-mandate");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    function _handleFileUpload(value, file) {
        //eslint-disable-next-line
        var file_formatted = value.replace(/^.*[\\\/]/, '').split('/');
        //eslint-disable-next-line
        file_formatted = file_formatted[0].replace(/[\(\)\s]/g, '_');
        console.log("File", file);
        console.log("Value", value)
        console.log("File formatted", file_formatted);
        const parts = file_formatted.split('.');
        if (parts.length > 1) {
            const extension = '.' + parts.pop();
            if (_isFileTypeAllowed(extension, ['.jpeg', '.jpg'])) {
                // checking if file size is greater than 5mb
                const file_size = 2 * 1024 * 1024;
                if (file.size < file_size) {
                    _fileUpload(file_formatted, file);
                } else {
                    setUploadFileError("File size should be less than 2mb");
                }
            } else {
                setUploadFileError("File type should be .jpeg, .jpg");
            }
            return extension;
        } else {
            setUploadFileError("No file extension found");
            return ''; // No extension found
        }
    }

    const _getFileType = (url) => {
        let path = url.split('?')[0];
        let filename = path.split('/').pop();
        let extension = filename.split('.').pop().toLowerCase();
        return extension;
    }

    const handleLoad = () => {
        setIsLoading(false);
        console.log('load complete')
    };

    function _removeFile() {
        setUploadFile(null);
    }

    const handleError = () => {
        setIsLoading(false); // Set loading state to false if there's an error loading the PDF
        console.error("Error loading PDF");
    };

    function _updateStatus () {
        props.updateMandateStatus();
    }

    const _fileUpload = (key, file) => {
        setUploadFileError("");
        setFileLoader(true);
        let url = "general/file-upload";
        let body = {
            "key": `mandate/${key}`
        }

        APIService(true, url, body).then((response) => {
            if (response.status_code === 200) {
                setFileLoader(true);

                // s3 helper
                _uploadDocumentToS3(response.data, key, file).then((result) => {
                    setUploadFile(response.data.view_info); // file got uploaded successfully

                    console.log("KEY", key)
                    // setFile(key);
                    setFileLoader(false);
                }).catch(error => {
                    toast.dismiss();
                    toast.error(error, {
                        type: "error",
                    });
                    setFileLoader(false);
                });

            } else {
                setFileLoader(false);
                toast.dismiss();
                toast.error(response, {
                    type: "error",
                });
            }
        })
    }

    function _submitFile() {
        setApiLoader(true);
        let url = 'profile/v2/mandates/upload';
        let payload = JSON.stringify({
            file_url: uploadFile,
            mandate_id: props.mandateId
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setUploadStatus(true);
            } else {
                setUploadStatus(false);
                toast.error(response.message);
            }
            setShowStatusScreen(true);
            setApiLoader(false);
        })
    }

    return (
        <div className={`modal fade ${style.e_upload_signature_modal}`}
            id="upload-mandate"
            tabIndex="-1"
            aria-labelledby="upload-mandate"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>

                <div className={`modal-content padding-32px-all min-height-400px ${style.e_content} `}>
                    {
                        showStatusScreen === false ?
                            <div className="row">
                                <div className="col-11">
                                    <h6 className="e-poppins-semi-bold e-font-18 color-almost-black">Upload your signed document</h6>
                                    <p className="e-poppins-regular e-font-14 color-almost-black ">Submit your signed mandate by uploading a clear scanned copy to complete the process smoothly.</p>
                                </div>
                                <div className="col-1">
                                    <Icon icon="close"
                                        size={24}
                                        data-bs-dismiss="modal"
                                        className={` cursor-pointer e-modal-close-btn ${style.e_close}`} />
                                </div>
                                <div className="col-10 offset-1 mt-3">
                                    {
                                        uploadFile === null ?
                                            < CustomFilePicker accept=".jpeg, .jpg "
                                                className="e-select-file"
                                                type={2}
                                                loading={fileLoader}
                                                error={uploadFileError}
                                                onSelectFile={(path, file) => _handleFileUpload(path, file)} />
                                            :
                                            <div className="w-100 h-160px">
                                                {
                                                    _getFileType(uploadFile) === 'pdf' ?
                                                        <iframe
                                                            title="PDF Viewer"
                                                            src={`https://docs.google.com/viewer?url=${encodeURIComponent(uploadFile)}&embedded=true`}
                                                            style={{ width: '100%', height: '160px', border: 'none' }}
                                                            onLoad={handleLoad}
                                                            onError={handleError}
                                                        />
                                                        :
                                                        <img src={uploadFile}
                                                            className="w-100 h-100" />
                                                }
                                            </div>
                                    }

                                </div>
                                {
                                    uploadFile !== null &&
                                    <div className="col-12 text-center mt-4">
                                        <SecondaryButton label="Remove"
                                            className="padding-12px-tb padding-16px-lr"
                                            disabled={apiLoader}
                                            handleClick={_removeFile} />
                                        <GradientButton label="Submit"
                                            className="padding-12px-tb padding-24px-lr ms-2"
                                            loading={apiLoader}
                                            handleClick={_submitFile} />
                                        {/* <GradientButton label="Upload"
                                    className="padding-12px-tb padding-16px-lr"
                                    icon="cloud-upload"
                                    iconSize={20}
                                    iconColor={Color.white}
                                    iconClass="me-2 mt-1"
                                    iconLeft={true} /> */}
                                    </div>
                                }

                            </div>
                            :
                            <div className="row mt-5">
                                <div className="col-12 text-center">
                                    <img src={uploadStatus === true ? require('../../Assets/Images/orders/order-success.svg').default : require('../../Assets/Images/orders/order-failed.svg').default}
                                        alt="Pause SIP"
                                        width={72}
                                        height={72} />
                                    <h5 className="color-eerie-black e-poppins-medium e-font-18 mt-4">
                                        {
                                            uploadStatus === true ?
                                                'Uploaded successfully'
                                                :
                                                'Failed to upload'
                                        }

                                    </h5>
                                    <p className="color-outer-space e-poppins-regular e-font-14 mb-0  text-center mt-3">
                                        {
                                            uploadStatus === true ?
                                                "We’ve received your document and will begin processing it shortly.You’ll be notified once the review is complete."
                                                :
                                                "We regret to inform you that your recent document upload has failed."
                                        }

                                    </p>
                                    <GradientButton label="Check status" 
                                        cancel="modal"
                                        className="padding-12px-tb padding-64px-lr mt-3"
                                        handleClick={_updateStatus}/>
                                </div>
                            </div>
                    }

                </div>
            </div>
        </div>
    )

}