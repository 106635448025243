/*
 *   File : fund-detail.js
 *   Author URI : https://evoqins.com
 *   Description : Display all the details of selected fund.
 *   Integrations : NA
 *   Version : v1.1
 */

import { Fragment, useEffect, useState, useRef, useContext } from 'react';
import { Modal } from "bootstrap";
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { BreadCrumb } from "../../Component/BreadCrumb"
import { InvestmentHeader } from "../../Component/Header"
import { GradientButton, LinkText, OutlineButton, CartButton } from "../../Component/CTA";
import Icon from "../../Component/Icon/icon";
import { AreaChart, DoughnutChart } from "../../Component/Chart";
import { DataTable } from "../../Component/Form-elements";
import { Chip } from '../../Component/Other';
import { InvestGoalModal, MFHoldingsModal, FundManager, MfSectorAllocation, PaymentStatusModal, FundHouse, MfInProcessStatus } from "../../Component/Modal";
import { CAGRPopup, CustomToolTip } from '../../Component/Popups';
import { CustomLoader } from '../../Component/Other';
import APIService from '../../Service/api-service';
import { _addToWatchList, _getMFInvestmentSummary } from '../../Helper/api';
import { _getDayOfMonth } from '../../Helper';
import Footer from '../../Component/Footer/footer';
import { DarkModeContext } from '../../Context/dark-mode-context';


import Color from "../../Styles/color.module.scss";
import { useStateManager } from 'react-select';

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Mutual Funds', url: '/explore' },
];

const FUND_DETAIL = {
    id: 2,
    title: "Quant Small Cap Fund Direct Plan Growth",
    icon: require("../../Assets/Images/dashboard/escorts.svg").default,
    category: ["Equity- Small Cap"],
    cagr: 58.70,
    aum: "35600Cr",
    risk: "Success",
    status_id: 2,
    rating: "5",
    price: 1000,
    nav: 138.073,
    percent: 33,
    expected_units: 7
};

const FundDetail = (props) => {

    const { state } = useLocation();
    const navigator = useNavigate();
    const { darkMode } = useContext(DarkModeContext);
    const bannerRef = useRef();

    const TOOL_TIP = useSelector(state => state.generalData.TOOL_TIP_DATA);
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const [pageNavigation, setPageNavigation] = useState(PAGE_NAVIGATION);
    const [bannerDropDownSelect, setBannerDropDownSelect] = useState({});
    const [showBannerDropDown, setShowBannerDropDown] = useState(false);
    const [showShare, setShowShare] = useState(false);

    const [showUnitsHeld, setShowUnitsHeld] = useState(false);
    const [showBookMarkHover, setShowBookMarkHover] = useState(false);
    const [sameManager, setSameManager] = useState(null);
    // const [showInvestGoalModal, setShowInvestModal] = useState(false);
    // const [returnsFilter, setReturnsFilter] = useState(null);
    const [showInvestGoalModal, setShowInvestModal] = useState({});
    const [returnsFilter, setReturnsFilter] = useState(null);
    const [dataLoader, setDataLoader] = useState(true);
    const [fundDetail, setFundDetail] = useState({});
    const [navHistory, setNavHistory] = useState({});
    const [navHistoryLoading, setNavHistoryLoading] = useState(true);
    const [chartHeading, setChartHeading] = useState({});
    const [mfId, setMFId] = useState(state.fund_id);
    const [openHoldingsModal, setOpenHoldingsModal] = useState(false);
    const [fundManagerDetail, setFundManagerDetail] = useState(null);
    const [chartFilter, setChartFiler] = useState([]);
    const [mfInvestmentData, setMfInvestmentData] = useState({});
    const [showInfo, setShowInfo] = useState(false);
    const [rankInfo, setRankInfo] = useState(false);
    const [viewAllAllocations, setViewAllAllocations] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [investmentType, setInvestmentType] = useState(1);
    const [mountInvestModal, setMountInvestModal] = useState(false);
    const [openPaymentStatusModal, setOpenPaymentStatusModal] = useState(false);
    const [showShareToolTip, setShowShareToolTip] = useState(false);
    const [openFundHouseModal, setOpenFundHouseModal] = useState(false);
    const [paymentStatusText, setPaymentStatusText] = useState("");
    const [isNextMonth, setIsNextMonth] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [inProcessMessage, setInProcessMessage] = useState('');
    const [showMfInProcessModal, setShowMfInProcessModal] = useState(false);

    const HOLDINGS_TABLE_STYLE = {
        table: {
            style: {
                padding: "0px",
                borderRadius: " 16px",
                backgroundColor: darkMode === true ? `${Color.elegant_onyx} !important` : Color.water,
                border: darkMode === true ? `1px solid ${Color.gunmetal_gray}` : `1px solid ${Color.jet_stream}`,
            },
        },
        tableBody: {
            style: {
                margin: '0px',
                marginTop: "0px !important",
                backgroundColor: darkMode === true ? `${Color.elegant_onyx} !important` : Color.water,
            }
        },
        headRow: {
            style: {
                background: "transparent",
                borderRadius: "16px 16px 0px 0px",
                border: "0",
                padding: "16px 24px",
                color: Color.black,
                fontFamily: "Poppins-Medium",
                fontSize: "14px",
                lineHeight: "22px",
                backgroundColor: darkMode === true ? `${Color.elegant_onyx} !important` : Color.water,
            }
        },
        rows: {
            style: {
                margin: "0",
                background: "transparent",
                border: "0 !important",
                borderTop: darkMode === true ? `1px solid ${Color.gunmetal_gray} !important` : `1px solid ${Color.jet_stream} !important`,
                borderRadius: "0",
                color: Color.black,
                fontFamily: "Poppins-Regular",
                fontSize: "14px",
                lineHeight: "22px",
                padding: "16px 24px",
                cursor: "default",
                backgroundColor: darkMode === true ? `${Color.elegant_onyx} !important` : Color.water,
            },
        },
    };

    const PEER_TABLE_STYLE = {
        table: {
            style: {
                background: Color.baby_power,
                padding: "0px",
                borderRadius: " 16px",
                border: darkMode === true ? `1px solid ${Color.gunmetal_gray}` : `1px solid ${Color.bright_gray}`,
    
            },
        },
        headRow: {
            style: {
                background: Color.pale_buttery_yellow,
                borderRadius: "16px 16px 0px 0px",
                border: "0",
                padding: "16px",
                color: Color.black,
                fontFamily: "Poppins-Medium",
                fontSize: "14px",
                lineHeight: "22px",
            }
        },
        rows: {
            style: {
                border: "0 !important",
                borderTop: darkMode === true ? `1px solid #1C1D21 !important` : `1px solid ${Color.bright_gray} !important`,
                margin: "0",
                background: "transparent",
                borderRadius: "0",
                padding: "12px 16px",
                '&:hover': {
                    background: Color.light_beige
                },
            }
        }
    };

    useEffect(() => {
        const _handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', _handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', _handleResize);
        };
    }, []);

    // Initial data fetch when the component mounts
    useEffect(() => {
        if (mfId != undefined) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            _getData();
        }
    }, [mfId]);

    // Initial data fetch when the component mounts
    useEffect(() => {
        if (state.fund_id == undefined) {
            // force re-direct to explore if someone access the page without fund id
            navigator("/explore");
        } else {
            // re-load MF detail page with updated data when the file is in focus
            if (state.fund_id != mfId) {
                setDataLoader(true);
                setMFId(state.fund_id);
            }
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [state]);

    useEffect(() => {
        if (returnsFilter !== null) {
            _getNAVHistory();
        }
    }, [returnsFilter])

    //updating breadcrumb with fund name
    useEffect(() => {
        if (fundDetail.name) {
            // pageNavigation[2] = { page: `${fundDetail.name}`, url: null };   
            setPageNavigation([...PAGE_NAVIGATION, { page: `${fundDetail.name}`, url: null }]);
        }
    }, [fundDetail]);

    // listener for getting mf investment data
    useEffect(() => {
        if (Object.keys(showInvestGoalModal).length !== 0) {
            if (USER_DATA.kyc_step < 11) {
                toast.dismiss();
                toast.error("Complete KYC to start investing", {
                    type: 'error'
                });
                setShowInvestModal({});
                return;
            }
            const { date } = _getDayOfMonth();
            _getMFInvestmentSummary(showInvestGoalModal.id, true, date)
                .then((response) => {
                    setMfInvestmentData(response);
                    setMountInvestModal(true);
                })
                .catch((response) => {
                    toast.error(response, {
                        type: "error",
                    });
                });
        }
    }, [showInvestGoalModal]);

    useEffect(() => {
        if (mountInvestModal === true) {
            _openInvestGoalModal();
        }
    }, [mountInvestModal]);

    useEffect(() => {
        if (openHoldingsModal === true) {
            _openHoldingsModal();
        }
    }, [openHoldingsModal]);

    useEffect(() => {
        if (viewAllAllocations === true) {
            const modal = new Modal(document.getElementById("view-allocations"), {});
            modal.show();
        }
    }, [viewAllAllocations]);

    // listener fund manager detail
    useEffect(() => {
        if (fundManagerDetail !== null) {
            const modal = new Modal(document.getElementById("fund-manager"), {});
            modal.show();
        }
    }, [fundManagerDetail]);

    useEffect(() => {
        if (openFundHouseModal === true) {
            const modal = new Modal(document.getElementById("fund-house"), {});
            modal.show();
        }
    }, [openFundHouseModal]);

    useEffect(() => {
        if (openPaymentStatusModal === true) {
            const modal = new Modal(document.getElementById("payment-status"), {});
            modal.show();
        }
    }, [openPaymentStatusModal]);

    useEffect(() => {
        if (showMfInProcessModal === true) {
            const modal = new Modal(document.getElementById("mf-inprocess-status"), {});
            modal.show();
        }
    }, [showMfInProcessModal]);

    const _openMfInProcessModal = (message) => {
        setInProcessMessage(message);
        setShowMfInProcessModal(true);
    }

    const _handlePeerDetail = (row) => {
        setMFId(row.mf_id)
    }

    //to open invest goal modal
    const _openInvestGoalModal = () => {
        const modal = new Modal(document.getElementById("invest-goal"), {});
        modal.show();
    };

    // to open holdings modal
    const _openHoldingsModal = () => {
        const modal = new Modal(document.getElementById("view-holdings"), {});
        modal.show();
    }

    const _handleShowDropDown = () => {
        setShowBannerDropDown(!showBannerDropDown);
    }

    const _handleShare = () => {
        setShowShare(!showShare);
    }

    const _showUnitsHeld = () => {
        setShowUnitsHeld(!showUnitsHeld);
    }

    const _showBookMarkHover = () => {
        setShowBookMarkHover(!showBookMarkHover);
    }

    const _handleSameManager = (id) => {
        setSameManager(id)
    }

    const _handleRemoveManager = () => {
        setSameManager(null);
    }

    function _navigateToProfile() {
        navigator('/profile', {
            state: { tab: 5, type: 2 },
        });
    }

    const _handleInvestNow = (type) => {
        setInvestmentType(type)
        setShowInvestModal(fundDetail);
    };

    function _handleReturnsFilter(index, data) {
        setReturnsFilter(index);
        if (fundDetail.returns.length != 0) {
            const item_index = fundDetail.returns.findIndex(item => item.secondary_label === data);
            setChartHeading(fundDetail.returns[item_index]);
        }
    };

    function _compareFund() {
        navigator("/compare-funds", {
            state: {
                fund_ids: [mfId],
            }
        });
    }

    // API : Fund detail
    function _getData() {
        setDataLoader(true);
        let url = 'mf-data/detail';
        let payload = JSON.stringify({
            id: mfId
        });
        APIService(true, url, payload)
            .then((response) => {
                if (response.status_code === 200) {
                    let result = response.data;
                    setFundDetail(result);
                    if (result.returns !== 0) {
                        // taking first not null filter as default filter
                        let default_filter = result.returns.findIndex(item => item.filter_id !== null);
                        // one year filter
                        const one_year_filter = result.returns.findIndex(item => item.filter_id === 4);
                        // one year filter is there make it as default
                        if (one_year_filter !== -1) {
                            default_filter = one_year_filter;
                        }
                        if (default_filter !== -1) {
                            setChartHeading(result.returns[default_filter]);
                            setReturnsFilter(result.returns[default_filter].filter_id);
                            setBannerDropDownSelect(result.returns[default_filter]);
                        }
                        let chart_filter = result.returns.filter(obj => obj.filter_id !== null);
                        setChartFiler(chart_filter);
                    } else {
                        setNavHistoryLoading(false);
                    }

                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        type: "error"
                    });
                    navigator("/explore");
                }
                setDataLoader(false);
            });
    };

    // API : NAV history for a selected tenure
    function _getNAVHistory() {
        setNavHistoryLoading(true);
        let url = 'mf-data/nav-history';
        const filter_id = returnsFilter < 7 ? returnsFilter : -1;
        let payload = JSON.stringify({
            id: mfId,
            filter_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setNavHistory(response.data.data);
            } else {
                setNavHistory({});
            }
            setNavHistoryLoading(false);
        })
    };

    // API - Add item to watchlist
    const _handleAddItemToWatchlist = () => {
        _addToWatchList(fundDetail.id)
            .then(() => {
                if (fundDetail.is_watchlisted === true) {
                    setFundDetail(prevState => ({
                        ...prevState, // Spread the previous state to keep other values unchanged
                        is_watchlisted: false // Update the specific value
                    }));
                    toast.dismiss();
                    toast.success(`${fundDetail.name} removed from watchlist`, {
                        type: "success",
                    });
                }
                else {
                    setFundDetail(prevState => ({
                        ...prevState, // Spread the previous state to keep other values unchanged
                        is_watchlisted: true // Update the specific value
                    }));
                    toast.dismiss();
                    toast.success(`${fundDetail.name} added to watchlist`, {
                        type: "success",
                    });
                }


            })
            .catch((repsonse) => {
                toast.dismiss();
                toast.error(repsonse, {
                    type: "error",
                });
            });
    }

    function _openAMCDetail() {
        setOpenFundHouseModal(true);
        // let site = fundDetail.amc_details.website;
        // if (!site.startsWith("https://")) {
        //     // If not, add "https://"
        //     site = "https://" + site;
        // }
        // window.open(site, '_blank');
    };

    // API:: get payment status after investment 
    function _getPaymentStatus(id, is_next_month, mode_of_payment) {
        let url = 'payment/check-status';
        let payload = JSON.stringify({
            payment_id: id
        });
        APIService(true, url, payload).then((response) => {
            if (id === null) {
                setOpenPaymentStatusModal(true);
                setIsNextMonth(is_next_month);
                setPaymentStatus(true);
                setPaymentStatusText('Success');
                return
            }
            if (response.status_code === 200) {
                setOpenPaymentStatusModal(true);
                setIsNextMonth(is_next_month);
                // making payment success modal status as processing in case of netbanking and made instant payment
                if (mode_of_payment === 2 && is_next_month !== true) {
                    setPaymentStatus(false);
                    setPaymentStatusText('Processing');
                }
                else {
                    setPaymentStatus(response.data.payment_status);
                    setPaymentStatusText(response.data.payment_status_txt);
                }
            }
        })
    }

    const FundDetail = (props) => {
        return (
            <div className="d-flex flex-column gap-8px ">

                <p className="color-gray e-poppins-regular e-font-14 line-height-16px mb-0 position-relative">
                    {props.label}
                    {
                        props.info && (
                            <>
                                <Icon icon="info"
                                    size={20}
                                    className='ms-1 position-relative top--1'
                                    data-tooltip-id={props.tooltip_id} />
                                <Tooltip id={props.tooltip_id}
                                    arrowColor='transparent'
                                    // effect="float"
                                    place="bottom"
                                    className='e-tooltip-general'>
                                    {props.info}
                                </Tooltip>
                            </>
                        )
                    }
                </p>
                <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0 ">
                    <span >
                        {props.prefix === true ? <span className="e-inter-medium">₹</span> : null}
                        {props.value.toLocaleString("en-IN")}
                        {props.postfix === true ? <span>%</span> : null}
                    </span>

                </p>
            </div>
        )
    }

    const SectorAllocation = (props) => {
        return (
            <div className="row gx-0 mt-sm-3 mt-1">
                <div className='col-1'>
                    <div style={{ backgroundColor: props.color, width: windowWidth > 575 ? "10px" : "8px", height: windowWidth > 575 ? "10px" : "8px" }}
                        className="border-radius-100 mt-1 pt-1"></div>
                </div>
                <div className='col-11'>
                    <p className="e-sector-allocation-legend e-poppins-regular e-font-sm-16-14 line-height-24px mb-0">{props.label}&nbsp;
                        <span className="e-poppins-medium">{props.percent}%</span></p>
                </div>
            </div>
        )
    }

    const FundManagerDetail = (props) => {
        return (
            <div className={`d-flex justify-content-between align-items-center py-sm-3 py-2 px-sm-2 px-0 ${props.lastItem === false && "border-bottom-1px border-bright-gray"}`}>
                <div className="d-flex flex-column gap-12px">

                    <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-24px mb-0">
                        {props.data.name}
                    </p>
                    <p className="color-gray e-poppins-semi-bold e-font-14-sm-12 line-height-22px mb-0">
                        {`${props.data.start_date} - ${props.data.end_date}`}
                    </p>
                </div>
                <LinkText title="View details"
                    icon="arrow-right"
                    onClick={() => setFundManagerDetail(props.data)} />

            </div>

        )
    }

    const SameManagerFunds = (props) => {
        return (
            <div className={`d-flex align-items-center justify-content-between px-sm-2 py-2 px-0 border-bottom-1px border-bright-gray cursor-pointer e-hover-manager`}
                onMouseEnter={() => _handleSameManager(props.index)}
                onMouseLeave={_handleRemoveManager}
                onClick={() => setMFId(props.data.mf_id)}>
                <div className="d-flex align-items-center gap-14px me-3">
                    <div className="border-radius-4px border-all border-bright-gray bg-white p-1">
                        <img src={props.data.image}
                            width={38}
                            height={38}
                            className='object-fit-contain' />
                    </div>
                    <p className={`${sameManager === props.index ? "color-primary-color" : "color-black"} e-poppins-medium e-font-sm-16-14 line-height-24px mb-0`}>{props.data.fund_name}</p>
                </div>
                {
                    sameManager === props.index ?
                        <Icon icon="arrow-right1"
                            size={24} />
                        :
                        <Icon icon="arrow-right1"
                            color={"transparent"}
                            size={24} />
                }
            </div>
        )
    }

    function    _shareFund(platform) {
        switch (platform) {
            case 1: window.open(process.env.REACT_APP_FB_SHARE_LINK + window.origin + '/explore/' + fundDetail.isin, '_blank');
                break;
            case 2: window.open(process.env.REACT_APP_WHATSAPP_LINK +'Hi! I was searching for a good mutual fund scheme on MT Wealth for investment and found '+fundDetail.class_name+'. You can also invest by clicking on '+ window.origin + '/explore/' + fundDetail.isin, '_blank');
                break;
            case 3: window.open('mailto:?subject=Invest in ' + fundDetail.name+'&body=Hi! I was searching for a good mutual fund scheme on MT Wealth for investment and found ' +fundDetail.class_name+'. You can also invest by clicking on '+ window.origin + '/explore/' + fundDetail.isin, '_blank');
                break;
            case 4: _copyLink();
            default: return;
        }
    }

    function _copyLink() {
        const link = `${window.origin}/explore/${fundDetail.isin}`;
        if (navigator.clipboard) {
            navigator.clipboard.writeText(link).then(() => {
                toast.dismiss();
                toast.success('Link copied');
            }).catch(err => {
                console.error('Error writing to clipboard, using fallback:', err);
                fallbackCopyToClipboard(link);
            });
        } else {
            fallbackCopyToClipboard(link);
        }
    }

    function fallbackCopyToClipboard(text) {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed"; // Prevent scrolling to the bottom
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
            toast.dismiss();
            toast.success('Link copied');
        } catch (err) {
            console.error('Fallback copy failed:', err);
        }
        document.body.removeChild(textArea);
    }
    const HOLDINGS_COLUMN = [
        {
            name: 'Top holdings',
            selector: row => row['name'],
            sortable: false,
            left: true,
            width: windowWidth > 575 ? "156px" : '200px',
            cell: (row) => <div className="text-start">{row['name']}</div>,
        },
        {
            name: 'Sector',
            selector: row => row['sector'],
            sortable: false,
            left: true,
            width: "150px",
        },
        {
            name: 'Assets',
            selector: row => row['percentage'],
            sortable: false,
            left: true,
            width: windowWidth > 575 ? "50px" : "80px",
            cell: (row) => <div className="text-end">{row['percentage']}</div>,
        }

    ];

    const PEER_COLUMN = [
        {
            name: 'Funds',
            selector: row => row['name'],
            sortable: false,
            left: true,
            width: windowWidth < 575 ? "290px" : "200px",
            cell: (row) => <div className="text-start d-flex align-items-center gap-8px"
                onClick={() => _handlePeerDetail(row)}>
                <p className="color-black e-poppins-regular e-font-14 line-height-24px mb-0 mt-1">
                    {row['name']}
                </p>
            </div>,
        },
        {
            name: 'Rank',
            selector: row => row['rank'],
            sortable: false,
            left: true,
            width: windowWidth < 575 ? "86px" : "46px",
            cell: (row) => <div className="d-flex justify-content-center align-items-top ms-sm-0 ms-3 justify-content-center"
                onClick={() => _handlePeerDetail(row)}>
                <p className="color-black e-poppins-medium e-font-12 line-height-20px mb-0">{row['rank']}{" "}</p>
                <img src={require("../../Assets/Images/dashboard/Star.png")}
                    width={16}
                    height={16}
                    alt={row['rank']} />
            </div>,
        },
        {
            name: '1Y',
            selector: row => row['one_year_return'],
            sortable: false,
            left: true,
            width: "80px",
            cell: (row) => <p className="text-center color-black e-poppins-regular e-font-14 line-height-22px mb-0"
                onClick={() => _handlePeerDetail(row)}>{row['one_year_return']}</p>,
        },
        {
            name: '3Y',
            selector: row => row['three_year_return'],
            sortable: false,
            left: true,
            width: "80px",
            cell: (row) => <p className="text-center color-black e-poppins-regular e-font-14 line-height-22px mb-0"
                onClick={() => _handlePeerDetail(row)}>{row['three_year_return']}</p>,
        },
        {
            name: 'Fund Size (Cr)',
            selector: row => row['aum'],
            sortable: false,
            left: true,
            width: windowWidth > 575 ? "100px" : "130px",
            cell: (row) => <p className="text-end color-black e-poppins-regular e-font-14 line-height-22px mb-0"
                onClick={() => _handlePeerDetail(row)}>{row['aum']}</p>,
        }

    ];

    return (
        <Fragment>
            <div className="e-fund-detail e-page-container margin-72px-bottom">

                {/* Header */}
                <InvestmentHeader />
                {
                    dataLoader === true ? (
                        // page loader
                        <div className='h-80vh '>
                            <CustomLoader />
                        </div>
                    ) :

                        <>
                            {/* Page navigation */}
                            <BreadCrumb data={pageNavigation} />

                            {/* Banner */}
                            <div className="mt-4 p-3 border-radius-16px border-all border-bright-gray bg-milk margin-40px-bottom-sm-16 e-banner-card">
                                <div>
                                    <div className="container-fluid">
                                        {/* Fund name add cart */}
                                        <div className="row">
                                            <div className="col-12 padding-28px-bottom-sm-16 border-bottom-1px border-cream-green px-0">
                                                <div className=" d-flex align-items-center">
                                                    {/* AMC logo */}
                                                    <div className="me-3 pe-0 d-none d-lg-block d-md-block d-sm-block">
                                                        <div className="bg-white text-center w-fit-content p-2 border-radius-4px border-all border-bright-gray ">
                                                            <img src={fundDetail.image}
                                                                alt="icon"
                                                                width={52}
                                                                height={52}
                                                                className='object-fit-contain' />
                                                        </div>
                                                    </div>

                                                    {/* category, rating and holdings count */}
                                                    <div className="w-100 ps-0">
                                                        <div className="d-flex align-items-center gap-8px ">
                                                            <p className="border-radius-16px border-all border-bright-gray color-black e-poppins-medium e-font-12 line-height-18px e-chip mb-0">
                                                                {fundDetail.class_name}
                                                            </p>
                                                            {
                                                                // fundDetail.rank > 0 && (
                                                                    <div className="border-radius-16px border-all border-bright-gray d-flex align-items-center e-chip">
                                                                        <p className=" color-black e-poppins-medium e-font-12 line-height-18px  mb-0 position-relative">
                                                                            CRISIL Rank
                                                                            {
                                                                                Object.keys(TOOL_TIP).length !== 0 &&
                                                                                <>
                                                                                    <Icon icon="info"
                                                                                        size={20}
                                                                                        className="cursor-pointer"
                                                                                        data-tooltip-id="crisil-tooltip" /> {fundDetail.rank}
                                                                                    <Tooltip id="crisil-tooltip"
                                                                                        arrowColor='transparent'
                                                                                        effect="float"
                                                                                        place="bottom"
                                                                                        className='e-tooltip-general'>
                                                                                        {parse(TOOL_TIP.fund_detail_crisil_rank)}
                                                                                    </Tooltip>
                                                                                </>
                                                                            }

                                                                        </p>
                                                                        <img src={require("../../Assets/Images/dashboard/Star.png")}
                                                                            alt="Star"
                                                                            width={14}
                                                                            height={14}
                                                                            className='ms-1' />
                                                                    </div>
                                                                // )
                                                            }

                                                            {
                                                                fundDetail.has_holdings === true &&
                                                                <div className="position-relative"
                                                                    onMouseEnter={_showUnitsHeld}
                                                                    onMouseLeave={_showUnitsHeld}>
                                                                    <img src={require("../../Assets/Images/fund-detail/add-to-basket.png")}
                                                                        alt="Add to basket"
                                                                        width={40}
                                                                        height={40} />
                                                                    {
                                                                        showUnitsHeld &&
                                                                        <p className="border-radius-8px border-all border-bright-gray position-absolute color-jett-black e-poppins-regular e-font-14 line-height-12px p-2 bg-white e-units-held mb-0">
                                                                            {`You are holding ${fundDetail.units} units`}
                                                                        </p>
                                                                    }

                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="row d-flex justify-content-between  ">
                                                            {/* fund name */}
                                                            <div className='col-md-6 '>
                                                                <p className="color-black e-poppins-regular e-font-24-sm-18 line-height-38px mb-0 mt-2">{fundDetail.name}</p>
                                                            </div>

                                                            <div className='col-md-6  text-end'>
                                                                {/* Invest, SIP and Cart */}
                                                                <div className="d-flex align-items-center justify-content-md-end gap-16px mt-2 mb-2">
                                                                    <OutlineButton label="Invest now"
                                                                        className="py-2 px-3 e-font-16"
                                                                        disabled={!fundDetail.lumpsum_enabled}
                                                                        handleClick={() => _handleInvestNow(2)}
                                                                    />
                                                                    <GradientButton label="Start SIP"
                                                                        className="py-2 padding-26px-lr e-font-16"
                                                                        heightType={1}
                                                                        disabled={!fundDetail.sip_enabled}
                                                                        handleClick={() => _handleInvestNow(1)} />

                                                                    {/* cart */}
                                                                    <div data-disabled={`${fundDetail.lumpsum_enabled === false ? 'true' : 'false'}`}>
                                                                        <CartButton data={fundDetail} explore={true} />
                                                                    </div>
                                                                </div>
                                                                {
                                                                    fundDetail.lumpsum_enabled === false && fundDetail.sip_enabled === false ?
                                                                        <p className='e-font-12 color-outer-space mb-0  e-poppins-regular text-md-end text-start'>*Lumpsum and SIP investments are not allowed for this fund.</p>
                                                                        :
                                                                        fundDetail.sip_enabled === false ?
                                                                            <p className='e-font-12 color-outer-space e-poppins-regular mb-0 text-md-end text-start'>*SIP investment is not allowed for this fund.</p>
                                                                            : fundDetail.lumpsum_enabled === false ?
                                                                                <p className='e-font-12 color-outer-space e-poppins-regular mb-0 text-md-end text-start'>*Lumpsum purchase is not allowed for this fund.</p>
                                                                                :
                                                                                null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* NAV and CAGR detail  */}
                                        <div className="row mt-2">
                                            <div className="col-12 px-0">
                                                <div className="row">
                                                    <div className="col-1 pe-0 ">
                                                    </div>
                                                    <div className="col-lg-11 col-md-11 col-sm-11 col-12 d-flex justify-content-between e-fund-detail-banner-footer align-items-center ps-lg-0 ps-md-0 ps-sm-0">
                                                        <div className="d-flex gap-32px-sm-16 flex-wrap">
                                                            <div className='d-flex justify-content-between'>
                                                                {/* latest nav and change % */}
                                                                {
                                                                    fundDetail.nav && (
                                                                        <div className='w-max-content'>
                                                                            <p className="color-gray-light e-poppins-medium e-font-16-sm-12 line-height-22px mb-0 ">Current NAV</p>
                                                                            <div className="d-flex align-items-baseline color-sea-green e-poppins-medium e-font-32-sm-24 line-height-44px e-nav-column">
                                                                                <p className="mb-0 me-2">
                                                                                    <span className="e-inter-medium color-charleston-green">
                                                                                        ₹
                                                                                    </span>
                                                                                    {' '}{fundDetail.nav.current_nav}
                                                                                </p>
                                                                                <p className={`e-font-14 line-height-14px mb-0 ${fundDetail.nav.nav_change < 0 ? 'color-red' : 'color-sea-green'}`}>
                                                                                    {fundDetail.nav.nav_change}
                                                                                    {
                                                                                        fundDetail.nav.nav_change !== '-' &&
                                                                                        <span>%</span>
                                                                                    }
                                                                                </p>
                                                                                <Icon icon="green-up-arrow"
                                                                                    width={14}
                                                                                    color={fundDetail.nav.nav_change < 0 && Color.red}
                                                                                    className={fundDetail.nav.nav_change < 0 && "e-icon-rotate"}
                                                                                    height={8} />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                                {/* CAGR */}
                                                                {
                                                                    chartFilter.length != 0 && (
                                                                        <div className='ms-5'>
                                                                            <div className="d-flex gap-8px ">
                                                                                <p className="color-gray-light e-poppins-medium e-font-16-sm-12 line-height-22px mb-0">{bannerDropDownSelect.returns_label}</p>
                                                                                <div className="d-flex align-items-center cursor-pointer position-relative w-105px"
                                                                                    ref={bannerRef}
                                                                                    onClick={_handleShowDropDown}>
                                                                                    <p className="color-primary-color e-poppins-medium e-font-16-sm-12 line-height-22px mb-0 me-1">{bannerDropDownSelect.label}</p>
                                                                                    <Icon icon="calender-arrow-right"
                                                                                        size={24}
                                                                                        className="e-banner-dropdown-icon"
                                                                                        data-rotate-icon={showBannerDropDown} />

                                                                                    {
                                                                                        showBannerDropDown === true &&
                                                                                        <CAGRPopup filterData={chartFilter}
                                                                                            selectedCAGR={bannerDropDownSelect}
                                                                                            handleSelect={(e) => setBannerDropDownSelect(e)}
                                                                                            closePopUp={() => setShowBannerDropDown(false)} />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <p className={`e-nav-column e-poppins-medium e-font-22 line-height-44px mb-0 ${bannerDropDownSelect.returns_value >= 0 ? 'color-sea-green' : 'color-red'}`}>
                                                                                {bannerDropDownSelect.returns}
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>


                                                            {/* riskometer */}
                                                            <img src={fundDetail.risk_image}
                                                                alt="High risk"
                                                                width={80}
                                                                height={40}
                                                                className='mt-sm-3 mt-0 object-fit-contain' />

                                                            <div className="d-flex align-items-sm-center gap-16px me-3 d-lg-none d-md-none d-block">
                                                                {
                                                                    fundDetail.is_watchlisted === true ?
                                                                        <div className="position-relative margin--10-top-mobile"
                                                                            onMouseEnter={_showBookMarkHover}
                                                                            onMouseLeave={_showBookMarkHover}>
                                                                            <Icon icon={"bg-bookmark"}
                                                                                size={42}
                                                                                className="cursor-pointer"
                                                                                onClick={_handleAddItemToWatchlist} />
                                                                            {
                                                                                showBookMarkHover === true &&
                                                                                <p className="border-radius-8px border-all border-bright-gray position-absolute color-jett-black e-poppins-regular e-font-14 line-height-12px p-2 bg-cards e-bookmark-hover mb-0">
                                                                                    Remove from watchlist
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div className="position-relative  margin--10-top-mobile"
                                                                            onMouseEnter={_showBookMarkHover}
                                                                            onMouseLeave={_showBookMarkHover}>
                                                                            <Icon icon={showBookMarkHover === true ? "bookmark-outline-hover" : "bookmark-outline"}
                                                                                size={42}
                                                                                className="cursor-pointer"
                                                                                onClick={_handleAddItemToWatchlist} />
                                                                            {
                                                                                showBookMarkHover === true &&
                                                                                <p className="border-radius-8px border-all border-bright-gray position-absolute color-jett-black e-poppins-regular e-font-14 line-height-12px p-2 bg-cards e-bookmark-hover mb-0">
                                                                                    Add to watchlist
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                }

                                                                {/* share URL */}
                                                                <div className="position-relative"
                                                                    onMouseEnter={() => setShowShareToolTip(true)}
                                                                    onMouseLeave={() => setShowShareToolTip(false)}>
                                                                    <div className='e-share-icon'
                                                                        onClick={_handleShare}>
                                                                        <Icon icon={showShare === false ? "share" : "close"}
                                                                            size={24}
                                                                            className={`${showShare === true && 'e-filter-invert-1'}`} />
                                                                        {
                                                                            showShareToolTip === true &&
                                                                            <p className="border-radius-8px border-all border-bright-gray position-absolute left--2 color-jett-black e-poppins-regular e-font-14 line-height-12px p-2 bg-cards e-bookmark-hover mb-0">
                                                                                Share scheme
                                                                            </p>
                                                                        }
                                                                    </div>

                                                                    {
                                                                        showShare === true &&
                                                                        <div className="d-flex gap-8px position-absolute e-fund-social-media">
                                                                            <img src={require("../../Assets/Images/explore/facebook.png")}
                                                                                alt="Facebook"
                                                                                width={32}
                                                                                height={32}
                                                                                className='cursor-pointer'
                                                                                onClick={() => _shareFund(1)} />
                                                                            <img src={require("../../Assets/Images/explore/whatsapp.png")}
                                                                                alt="whatsapp"
                                                                                width={32}
                                                                                height={32}
                                                                                className='cursor-pointer'
                                                                                onClick={() => _shareFund(2)} />
                                                                            <div className='bg-white-smoke border-radius-100 e-share-icon-wrapper'>
                                                                                <img src={require("../../Assets/Images/explore/mail.png")}
                                                                                    alt="messenger"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    className='cursor-pointer'
                                                                                    onClick={() => _shareFund(3)} />
                                                                            </div>
                                                                            <div className='bg-white-smoke border-radius-100 e-share-icon-wrapper'>
                                                                                <img src={require("../../Assets/Images/fund-detail/copy.png")}
                                                                                    alt="messenger"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    className='cursor-pointer'
                                                                                    onClick={() => _shareFund(4)} />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='d-lg-block d-md-block d-none'>
                                                            <div className="d-flex align-items-center gap-16px me-3">
                                                                {
                                                                    fundDetail.is_watchlisted === true ?
                                                                        <div className="position-relative"
                                                                            onMouseEnter={_showBookMarkHover}
                                                                            onMouseLeave={_showBookMarkHover}>
                                                                            <Icon icon={"bg-bookmark"}
                                                                                size={42}
                                                                                className="cursor-pointer"
                                                                                onClick={_handleAddItemToWatchlist} />
                                                                            {
                                                                                showBookMarkHover === true &&
                                                                                <p className="border-radius-8px border-all border-bright-gray position-absolute color-jett-black e-poppins-regular e-font-14 line-height-12px p-2 bg-cards e-bookmark-hover mb-0">
                                                                                    Remove from watchlist
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div className="position-relative"
                                                                            onMouseEnter={_showBookMarkHover}
                                                                            onMouseLeave={_showBookMarkHover}>
                                                                            <Icon icon={showBookMarkHover === true ? "bookmark-outline-hover" : "bookmark-outline"}
                                                                                size={42}
                                                                                className="cursor-pointer"
                                                                                onClick={_handleAddItemToWatchlist} />
                                                                            {
                                                                                showBookMarkHover === true &&
                                                                                <p className="border-radius-8px border-all border-bright-gray position-absolute color-jett-black e-poppins-regular e-font-14 line-height-12px p-2 bg-cards e-bookmark-hover mb-0">
                                                                                    Add to watchlist
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                }

                                                                {/* share URL */}
                                                                <div className="position-relative"
                                                                    onMouseEnter={() => setShowShareToolTip(true)}
                                                                    onMouseLeave={() => setShowShareToolTip(false)}>
                                                                    <div className='e-share-icon'
                                                                        onClick={_handleShare}>
                                                                        <Icon icon={showShare === false ? "share" : "close"}
                                                                            size={24} 
                                                                            className={`${showShare === true && 'e-filter-invert-1'}`}/>
                                                                        {
                                                                            showShareToolTip === true &&
                                                                            <p className="border-radius-8px border-all border-bright-gray position-absolute left--2 color-jett-black e-poppins-regular e-font-14 line-height-12px p-2 bg-cards e-bookmark-hover mb-0">
                                                                                Share scheme
                                                                            </p>
                                                                        }
                                                                    </div>

                                                                    {
                                                                        showShare === true &&
                                                                        <div className="d-flex gap-8px position-absolute e-fund-social-media">
                                                                            <img src={require("../../Assets/Images/explore/facebook.png")}
                                                                                alt="Facebook"
                                                                                width={32}
                                                                                height={32}
                                                                                className='cursor-pointer'
                                                                                onClick={() => _shareFund(1)} />
                                                                            <img src={require("../../Assets/Images/explore/whatsapp.png")}
                                                                                alt="whatsapp"
                                                                                width={32}
                                                                                height={32}
                                                                                className='cursor-pointer'
                                                                                onClick={() => _shareFund(2)} />
                                                                            <div className='bg-white-smoke border-radius-100 e-share-icon-wrapper'>
                                                                                <img src={require("../../Assets/Images/explore/mail.png")}
                                                                                    alt="messenger"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    className='cursor-pointer'
                                                                                    onClick={() => _shareFund(3)} />
                                                                            </div>
                                                                            <div className='bg-white-smoke border-radius-100 e-share-icon-wrapper'>
                                                                                <img src={require("../../Assets/Images/fund-detail/copy.png")}
                                                                                    alt="messenger"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    className='cursor-pointer'
                                                                                    onClick={() => _shareFund(4)} />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Chart */}
                            <div className="row margin-40px-bottom-sm-16">
                                <div className="col-lg-7 col-12">

                                    <div className={`border-radius-16px bg-light-blue px-sm-4 px-3 pt-4 pb-3 e-fund-detail-graph position-relative`}>
                                        <>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <h3 className="color-black e-poppins-medium e-font-24-sm-18 text-capitalized">{chartHeading.label}</h3>
                                                    <h4 className={`e-poppins-medium e-font-24-sm-18 margin-block-end-0 ${parseFloat(chartHeading.returns) < 0 ? "color-red" : "color-sea-green"}`}>{chartHeading.returns}
                                                        <span className="color-black e-poppins-medium e-font-14 ms-2">
                                                            {chartHeading.returns_label}
                                                        </span>
                                                    </h4>
                                                </div>
                                                <div className="col-lg-6 text-end mt-4 ">
                                                    <div className='d-flex gap-8px e-filter-wrapper w-100 justify-content-end'>
                                                        {
                                                            chartFilter.map((tag, index) => {
                                                                return (
                                                                    <Chip data={tag.secondary_label}
                                                                        chipIndex={tag.filter_id}
                                                                        activeChipIndex={returnsFilter}
                                                                        className="e-font-10 e-poppins-regular padding-5px-tb padding-10px-lr color-black bg-cards border-may-green border-all border-transparent border-radius-54px h-24px text-nowrap"
                                                                        activeClassName={`e-font-10 e-poppins-semi-bold padding-5px-tb padding-10px-lr color-primary-color border-radius-54px h-24px text-nowrap bg-pearl-aqua ${darkMode === true ? 'border-all border-anti-flash-white' : 'border-transparent'}`}
                                                                        onPress={_handleReturnsFilter} />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex min-height-300px '>
                                                {
                                                    navHistoryLoading === true ? (
                                                        <div className=''>
                                                            <CustomLoader className={"bg-transparent position-absolute top-0 bottom-0 right-0 left-0 margin-auto"} />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {
                                                                Object.keys(navHistory).length !== 0 ?
                                                                    <AreaChart data={navHistory} />
                                                                    :
                                                                    <div className={`row w-100 justify-content-center align-items-center ${windowWidth > 576 && 'min-height-335px'}`}>
                                                                        <p className='text-center'>
                                                                            No data found!
                                                                        </p>
                                                                    </div>
                                                            }
                                                        </>

                                                    )
                                                }
                                            </div>

                                        </>
                                    </div>
                                </div>


                                {/* fund details */}
                                <div className="col-lg-5 ps-sm-0 ps-4">
                                    <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px mb-2 mt-lg-0 mt-sm-4 mt-3">Fund details</p>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-6 mb-sm-4 mb-3 ">
                                            <FundDetail label="Min. SIP amount"
                                                value={fundDetail.min_sip_amount.toLocaleString("en-IN")}
                                                prefix={true} />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-6 mb-sm-4 mb-3 ">
                                            <FundDetail label="Min.Invest amount"
                                                value={fundDetail.min_lumpsum_amount.toLocaleString("en-IN")}
                                                prefix={true} />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-6 mb-sm-4 mb-3 ">
                                            <FundDetail label="Expense ratio"
                                                value={fundDetail.expense_ratio}
                                                postfix={true} />
                                        </div>
                                        {
                                            fundDetail.nav && (
                                                <>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-6 mb-sm-4 mb-3 ">
                                                        <FundDetail label="Highest NAV"
                                                            value={fundDetail.nav.highest_nav.toLocaleString("en-IN")}
                                                            prefix={true} />
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-6 mb-sm-4 mb-3 ">
                                                        <FundDetail label="Lowest NAV"
                                                            value={fundDetail.nav.lowest_nav.toLocaleString("en-IN")}
                                                            prefix={true} />
                                                    </div>
                                                </>
                                            )
                                        }
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-6 mb-sm-4 mb-3">
                                            <FundDetail label="AUM"
                                                value={fundDetail.aum} />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-6 mb-sm-4 mb-3 ">
                                            <FundDetail label="Launch date"
                                                value={fundDetail.launch_date} />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-6 mb-sm-4 mb-3 ">
                                            <FundDetail label="Exit load"
                                                info={fundDetail.exit_load_info}
                                                tooltip_id="exit-load-tooltip"
                                                value={fundDetail.exit_load} />
                                        </div>

                                    </div>

                                    {/* lock in period details */}
                                    {
                                        fundDetail.lock_in > 0 && (
                                            <div className="d-flex gap-8px py-3 padding-12px-lr border-radius-6px border-all border-true-blue w-fit-content">
                                                <img src={require('../../Assets/Images/explore/lock-key.png')}
                                                    alt="Lock and key"
                                                    width={24}
                                                    height={24} />
                                                <p className="color-jett-black e-poppins-regular e-font-12 mb-0"><span className="e-poppins-medium e-font-14">Lock-in</span>{`: Fund has a ${fundDetail.lock_in} days lock-in period`}</p>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>

                            {/* Holding and peer comparison */}
                            <div className="container-fluid border-radius-16px bg-azure p-sm-4 p-3 margin-40px-bottom-sm-16">
                                <div className="row">
                                    {/* holdings */}
                                    {
                                        <div className="col-lg-5 pe-lg-4 e-holdings-table">
                                            {
                                                fundDetail.holdings && fundDetail.holdings.length === 0 ? (
                                                    <p className="color-black e-poppins-regular e-font-14 line-height-32px margin-32px-bottom">
                                                        No holdings found!!
                                                    </p>
                                                ) : (
                                                    <>
                                                        <p className="color-black e-poppins-medium e-font-16  mb-sm-4 mb-2">Holdings ({fundDetail.holdings.length})</p>
                                                        <DataTable
                                                            columns={HOLDINGS_COLUMN}
                                                            data={fundDetail.holdings.slice(0, 4)}
                                                            customStyles={HOLDINGS_TABLE_STYLE}
                                                            pagination={false}
                                                        />

                                                        {/* See more link */}
                                                        {fundDetail.holdings.length > 4 && (
                                                            <div className='col-12 d-flex justify-content-center'>
                                                                <LinkText
                                                                    title="See more"
                                                                    icon="arrow-right"
                                                                    className="text-center mt-3"
                                                                    onClick={() => setOpenHoldingsModal(true)}
                                                                />
                                                            </div>
                                                        )}
                                                    </>
                                                )
                                            }
                                        </div>
                                    }

                                    {/* peers */}
                                    {
                                        <div className="col-lg-7 e-peers-table mt-lg-0 mt-3 text-lg-end">
                                            <p className="color-black e-poppins-medium e-font-20-sm-16  mb-sm-4 mb-2">Peer comparison</p>
                                            {fundDetail.similar_schemes && fundDetail.similar_schemes.length > 0 ?

                                                <DataTable
                                                    columns={PEER_COLUMN}
                                                    data={fundDetail.similar_schemes.slice(0, 3)}
                                                    customStyles={PEER_TABLE_STYLE}
                                                    pagination={false}
                                                    rowClick={(row) => _handlePeerDetail(row)}
                                                />

                                                :
                                                <p className='e-poppins-regular e-font-16 line-height-20px mb-0'>No peer comparison found</p>
                                            }
                                            <LinkText title="Compare Funds"
                                                icon="arrow-right"
                                                className="text-end mt-3 w-100 pe-2"
                                                onClick={_compareFund} />
                                        </div>
                                    }


                                </div>
                            </div>

                            {/* Sector allocation */}
                            {
                                fundDetail.sector_allocation && fundDetail.sector_allocation.length !== 0 ? (
                                    <div className="container-fluid border-radius-16px e-sector-allocation margin-46px-bottom-sm-16">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-12 ps-sm-0 ps-4">
                                                <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px mb-sm-2 mb-0">Sector allocation</p>
                                                <div className="row">
                                                    {
                                                        fundDetail.sector_allocation.name.map((item, key) => {
                                                            if (key < 14) {
                                                                return (
                                                                    <div className="col-sm-6">
                                                                        <SectorAllocation color={fundDetail.sector_allocation.color[key]}
                                                                            label={item}
                                                                            percent={fundDetail.sector_allocation.percentage[key]}
                                                                            key={key} />
                                                                    </div>
                                                                )
                                                            }

                                                        })
                                                    }
                                                </div>
                                                {
                                                    fundDetail.sector_allocation.name.length > 14 && (
                                                        <div className='row mt-3'>
                                                            <div className='col-12 d-flex justify-content-center'>
                                                                <LinkText title="View details"
                                                                    icon="arrow-right"
                                                                    className="d-flex"
                                                                    onClick={() => setViewAllAllocations(true)} />
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4">
                                                <div className="border-radius-28px position-relative  bg-magnolia p-3">
                                                    <p className="e-sector-allocation-legend e-poppins-medium e-font-16 line-height-24px mb-2">Sector breakdown</p>
                                                    <DoughnutChart data={fundDetail.sector_allocation}
                                                        type={2}
                                                        aum={fundDetail.aum}
                                                        className="mb-4" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="container border-radius-16px bg-lotion e-sector-allocation margin-46px-bottom">
                                        <div className="row mt-5">
                                            <div className="col-12 text-center mt-5">
                                                <p className='e-poppins-regular e-font-16 line-height-20px mb-0'>No allocation found</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }


                            {/* Fund manager & AMC detail*/}
                            <div className="container-fluid padding-30px-left-lg margin-32px-bottom-sm-16">
                                <div className="row">

                                    {/* Fund managers detail */}
                                    {
                                        fundDetail.fund_managers && fundDetail.fund_managers.length > 0 ? (
                                            <div className="col-lg-6 border-lg-right-1px pe-lg-4 pe-0 ps-sm-0 ps-4">
                                                <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px mb-sm-2 mb-0">Fund manager</p>
                                                {
                                                    fundDetail.fund_managers.map((item, key) =>
                                                        <FundManagerDetail data={item}
                                                            key={key}
                                                            lastItem={key === fundDetail.fund_managers.length - 1 ? true : false} />
                                                    )
                                                }
                                            </div>
                                        ) : (
                                            <div className="col-lg-6 border-lg-right-1px pe-lg-4 pe-0 ps-sm-0 ps-4">
                                                <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px mb-2">Fund manager</p>
                                                <p className='e-poppins-regular e-font-16 line-height-20px mb-0 mt-sm-5 mt-0'>No fund manager found</p>
                                            </div>
                                        )
                                    }

                                    {/* AMC detail */}
                                    {
                                        fundDetail.amc_details ? (
                                            <div className="col-lg-6 ps-lg-4  mt-lg-0 mt-md-3 mt-3">
                                                <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px mb-sm-2 mb-0 ps-sm-0 ps-3">AMC & RTA details</p>
                                                <div className={`d-lg-flex d-md-flex d-sm-flex justify-content-between align-items-center ps-3 py-sm-3 py-2 pe-lg-3  border-bottom-1px border-bright-gray `}>
                                                    <div className="d-flex align-items-center gap-8px">
                                                        {/* MF logo */}
                                                        <div className="border-radius-4px border-all border-bright-gray bg-white p-1 w-max-content">
                                                            <img src={fundDetail.amc_details.amc_image}
                                                                alt="icon"
                                                                width={38}
                                                                height={38}
                                                                className='object-fit-contain' />
                                                        </div>

                                                        {/* MF name, incorporation date */}
                                                        <div className="d-flex flex-column gap-10px mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                                                            <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-22px mb-0">{fundDetail.amc_details.amc_name}</p>
                                                            <div className="d-flex gap-16px flex-wrap mt-sm-0 mt-0">
                                                                <p className="color-gray e-poppins-regular e-font-sm-16-14 line-height-16px mb-0">Date of incorporation</p>
                                                                <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-16px mb-0">{fundDetail.amc_details.amc_founded}</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-end'>
                                                        <LinkText title="View details"
                                                            icon="arrow-right"
                                                            className="d-flex mt-lg-0 mt-md-0 mt-sm-0 mt-3"
                                                            onClick={_openAMCDetail} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                            : (
                                                <div className="col-lg-6 ps-lg-4  mt-lg-0 mt-5">
                                                    <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px mb-2">AMC & RTA details</p>
                                                    <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px mt-5">No details found</p>
                                                </div>
                                            )
                                    }

                                </div>
                            </div>


                            {/* Funds managed by same fund manager */}
                            {
                                fundDetail.other_funds_managed.length !== 0 ? (
                                    <>
                                        <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-24px mb-sm-3 mb-2  ps-3">Fund managed by same fund managers</p>
                                        <div className="container-fluid">
                                            <div className="row">
                                                {
                                                    fundDetail.other_funds_managed.map((item, key) =>
                                                        <div className="col-lg-5 col-md-6 col-12">
                                                            <SameManagerFunds data={item}
                                                                key={key}
                                                                index={key} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <p className="color-black e-poppins-medium e-font-20 line-height-32px mb-3 ps-sm-0 ps-3">Fund managed by same fund managers</p>
                                        <div className="container-fluid mt-5">
                                            <div className="row text-center">
                                                <p className="color-black e-poppins-regular e-font-16 line-height-32px mb-3">No other funds found under this manager</p>
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                            {/* CTA Button */}

                            <div className="d-flex gap-16px justify-content-center w-100 p-4 border-radius-16px bg-floral-white margin-34px-top">
                                <OutlineButton label="Invest now"
                                    className="py-2 px-3 e-font-16"
                                    disabled={!fundDetail.lumpsum_enabled}
                                    handleClick={() => _handleInvestNow(2)} />
                                <GradientButton label="Start SIP"
                                    className="e-font-16 py-2 padding-26px-lr"
                                    disabled={!fundDetail.sip_enabled}
                                    handleClick={() => _handleInvestNow(1)} />
                            </div>
                        </>
                }
            </div>
            <Footer />
            {
                mountInvestModal === true ?
                    <InvestGoalModal data={showInvestGoalModal}
                        mfInvestmentData={mfInvestmentData}
                        selectedTab={investmentType}
                        close={() => {
                            setMfInvestmentData({})
                            setShowInvestModal({})
                            setMountInvestModal(false);
                        }}
                        navigateToProfile={_navigateToProfile}
                        showInProcessModal={_openMfInProcessModal}
                        getPaymentStatus={_getPaymentStatus} />
                    :
                    null
            }

            {
                openHoldingsModal === true && (
                    <MFHoldingsModal close={() => setOpenHoldingsModal(false)}
                        data={fundDetail.holdings} />
                )
            }
            {
                fundManagerDetail !== null && (
                    <FundManager data={fundManagerDetail}
                        close={() => setFundManagerDetail(null)} />
                )
            }

            {
                viewAllAllocations === true && (
                    <MfSectorAllocation data={fundDetail.sector_allocation}
                        close={() => setViewAllAllocations(false)} />
                )
            }

            {
                openPaymentStatusModal === true && (
                    <PaymentStatusModal status={paymentStatus}
                        paymentStatus={paymentStatusText}
                        type={isNextMonth === true ? 5 : 1}
                        close={() => setOpenPaymentStatusModal(false)} />
                )
            }

            {
                openFundHouseModal === true && (
                    <FundHouse data={fundDetail.amc_details}
                        close={() => setOpenFundHouseModal(false)} />
                )
            }
            {
                showMfInProcessModal === true &&
                <MfInProcessStatus message={inProcessMessage}
                    close={() => setShowMfInProcessModal(false)} />
            }
        </Fragment >
    )
}

export default FundDetail