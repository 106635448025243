
/*
 *   File : view-indices-benchmark.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for list all indices
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect } from 'react';
import Icon from '../Icon/icon';

import style from '../../Styles/Component/view-indices.module.scss';

const ViewIndicesBenchmark = (props) => {

    // Modal close listener
    useEffect(() => {
        var my_modal = document.getElementById("view-indices");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    return (
        <div className={`modal fade ${style.e_indices_modal}`}
            id="view-indices"
            tabIndex="-1"
            aria-labelledby="view-indices"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-modal position-relative text-center d-flex align-items-center p-4 ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <div className="container ">
                        {/* Modal title */}
                        <div className="row">
                            <div className="col-12 text-start">
                                <h4 className="color-black e-poppins-medium e-font-16 cursor-pointer w-max-content">
                                    Benchmark indices
                                </h4>
                            </div>
                        </div>
                        {/* Modal body */}
                        <div className={`${style.e_table_wrapper} text-start border-bright-gray padding-14px-top`}>
                            {
                                props.data.map((item, key) => {
                                    return(
                                        <div className='d-flex'>
                                            <p className='e-poppins-regular e-font-14 color-outer-space mb-2 me-2' key={key}>{`${key+1}. `}</p>
                                            <p className='e-poppins-regular e-font-14 color-outer-space mb-2' key={key}>{`${item}`}</p>
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewIndicesBenchmark;