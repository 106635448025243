/*
 *   File : delete-cart.js
 *   Author URI : https://evoqins.com
 *   Description : Confirmation screen to be displayed before removing fund/s from the cart
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect } from "react";
import style from "../../Styles/Component/delete-cart.module.scss"
import { GradientButton, OutlineButton } from "../CTA";

const DeleteCartModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("delete-cart");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, [props.close]);

    const _handleCancel = () => {
        props.close();
    }

    function _handleDelete() {
        props.confirmDelete();
    }

    return (
        <div className={`modal fade ${style.e_delete_cart}`}
            id="delete-cart"
            tabIndex="-1"
            aria-labelledby="delete-cart"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-body-color text-center d-flex align-items-center padding-32px-lr ${style.e_content} `}>
                    <button id="close-modal-button" className="d-none"></button>
                    <img src={require("../../Assets/Images/invest-in-goals/delete-modal-icon.png")}
                        alt="Delete account"
                        width={72}
                        height={72}
                        className="margin-32px-top" />
                    {
                        props.singleItem === null ?
                            <p className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-0">Are you sure you want to empty your cart? This action is irreversible.</p>
                            :
                            props.singleItem === true ?
                                <p className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-0">Are you sure you want to delete {props.selectedItem[0].name}? This action is irreversible.</p>
                                :
                                <p className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-0">Are you sure you want to delete the selected items? This action is irreversible.</p>
                    }
                    <div className="d-flex gap-8px margin-34px-top margin-32px-bottom  w-100 px-5">
                        <OutlineButton label="No, cancel"
                            cancel="modal"
                            className="padding-12px-tb px-3 white-space-nowrap  e-font-16 e-poppins-regular "
                            handleClick={_handleCancel} />
                        <GradientButton label="Yes, delete"
                            cancel="modal"
                            className="padding-12px-tb e-font-16 w-100 "
                            loading={props.loading}
                            handleClick={_handleDelete} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteCartModal