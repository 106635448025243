
/*
 *   File : news.js
 *   Author URI : https://evoqins.com
 *   Description : To view news detail
 *   Integrations : null
 *   Version : v1.1
 */
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Icon from '../Icon/icon';

import style from '../../Styles/Component/view-mf-holding.module.scss';


const NewsModal = (props) => {

    // Modal close listener
    useEffect(() => {
        var my_modal = document.getElementById("news-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);


    return (
        <div className={`modal fade ${style.e_holdings_modal}`}
            id="news-modal"
            tabIndex="-1"
            aria-labelledby="news-modal"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-modal position-relative text-center d-flex align-items-center p-4 ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <div className="container pb-sm-0">
                        {/* Modal title */}
                        <div className="row">
                            <div className="col-12 text-start">
                                <h4 className="color-black e-poppins-medium e-font-16 cursor-pointer w-max-content">
                                    News
                                </h4>
                            </div>
                        </div>
                        {/* Modal body */}
                        <div className={` mt-3 row padding-14px-top`}>
                            <div className='col-12'>
                                <img src={props.data.image}
                                    alt={props.data.heading}
                                    className=' w-100' />
                            </div>
                            <div className='col-12 mt-3 text-start'>
                                <h2 className='color-outer-space e-poppins-medium e-font-16 line-height-22px truncate-second-line mb-2'>
                                    {props.data.heading}
                                </h2>
                            </div>
                            <div className='col-12'>
                                <div className='d-flex gap-20px'>
                                    {/* <p className={`color-gray e-poppins-regular e-font-14 line-height-22px mb-0 `}>{props.data.section_name}</p> */}
                                    <p className='color-gray e-poppins-regular e-font-14 line-height-22px mb-0'>{props.data.released_on}</p>
                                </div>
                            </div>
                            <div className='col-12 text-start mt-3'>
                                <p className={`color-black e-poppins-regular e-font-14 line-height-22px overflow-auto max-height-250px ${style.e_overflow_container}`}>
                                    {parse(props.data.description)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsModal;