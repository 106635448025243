/*
 *   File : appointment.js
 *   Author URI : https://evoqins.com
 *   Description : Book a call with MT team modal
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { format, parse } from "date-fns";
import { enUS } from 'date-fns/locale';

import Icon from "../Icon/icon";
import { Chip } from "../Other";
import { CustomCalendar } from "../Calender";
import { GradientButton } from "../CTA";
import APIService from "../../Service/api-service";

import style from "../../Styles/Component/appointment.module.scss"

const AppointmentModal = (props) => {
    const [time, setTime] = useState(props.slots[0]);
    const [date, setDate] = useState();
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("appointment-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    useEffect(() => {
        let selected_date = new Date();
        selected_date.setDate(selected_date.getDate() + 1);
        let formatted_date = format(selected_date, "dd-MM-yyyy")
        setDate(formatted_date);
    }, [])

    // handle time slot click
    function _handleChipClick(slot) {
        setTime(slot);
    }


    // handle calendar change
    function _handleDateChange(e) {
        let parsedDate = format(e, "dd-MM-yyyy");
        setDate(parsedDate);
    }

    // API - book appointment with MT
    async function _bookAppointment() {
        setApiLoader(true);
        let date_string = date;
        let url = 'general/book-appointment';
        let payload = JSON.stringify({
            date: date_string,
            time: time.value
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success(`An appointment has been successfully scheduled with our Wealth Management team for ${time.value} at ${date_string}`, {
                    type: "success"
                });
                let close = document.getElementById('modal-close');
                close.click();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        })
    }

    return (
        <div className={`modal fade ${style.e_appointment_modal}`}
            id="appointment-modal"
            tabIndex="-1"
            aria-labelledby="appointment-modal"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-modal position-relative text-center d-flex align-items-center p-4 ${style.e_content} `}>
                    <div className="col-12 d-flex">
                        <img src={require('../../Assets/Images/invest-in-goals/appointment.svg').default}
                            alt="" />
                        <h3 className="color-black e-poppins-medium e-font-18 mt-3 ms-2">Set your preferred time</h3>
                    </div>
                    <Icon icon="close"
                        size={24}
                        className="position-absolute right-18px top-14px e-modal-close-btn cursor-pointer"
                        data-bs-dismiss="modal" />
                    <button id='modal-close' className="d-none" data-bs-dismiss="modal"></button>
                    <div className={`mt-4 ${style.e_content_body}`}>
                        <div className="text-start">
                            <h5 className="color-jett-black e-poppins-regular e-font-16 ">Pick a date</h5>
                        </div>
                        <div className="col-12 mt-4 px-3">
                            <CustomCalendar
                                selctedDate={date}
                                defaultDayAsTomorrow={true}
                                handleChange={_handleDateChange} />
                        </div>
                        <div className="col-12 mt-4 text-start">
                            <h5 className="color-jett-black e-poppins-regular e-font-16 margin-block-end-0">Choose time</h5>
                        </div>
                        <div className="col-12 d-flex flex-wrap ">
                            {
                                props.slots.map((slot, key) => {
                                    return (
                                        <div className="mt-4 pt-1 me-3">
                                            <Chip data={slot.value}
                                                chipIndex={key + 1}
                                                activeChipIndex={time.id}
                                                type={2}
                                                onPress={(e) => _handleChipClick(slot)} />
                                        </div>
                                    )
                                })
                            }
                        </div>

                        {/* book an appointment */}
                        <div className="col-12 text-center mt-4">
                            <GradientButton label="Book appointment"
                                className="padding-12px-tb padding-18px-lr "
                                loading={apiLoader}
                                handleClick={_bookAppointment} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppointmentModal