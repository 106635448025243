/*
 *   File : sip.js
 *   Author URI : https://evoqins.com
 *   Description : Table component which enables customers to view the Active SIP list.
 *   Integrations : null
 *   Version : v1.1
 */

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../Form-elements";
import Icon from "../Icon/icon";

import Colors from "../../Styles/color.module.scss";

const SIPTable = (props) => {
    const navigate = useNavigate();
    const [showArrow, setShowArrow] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {

        function _handleScroll() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('scroll', _handleScroll);

        return () => {
            window.removeEventListener('scroll', _handleScroll)
        }
    }, []);

    const SIP_COLUMN = [
        {
            name: 'Funds',
            selector: row => row['name'],
            sortable: false,
            left: true,
            // width: "650px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name"
                    onClick={() => _handleCardClick(row)}>
                    <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                        <img src={row['image']} alt={row['name']}
                            width={36}
                            height={36}
                            className="object-fit-contain" />
                    </div>
                    <div className="d-flex flex-column gap-12px">
                        <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-24px mb-0 ">{row['name']}</p>
                        {
                            row['sip_tag'] !== null &&
                            <span className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px w-fit-content">
                                {row['sip_tag']}
                            </span>
                        }
                    </div>

                </div>
        },
        {
            name: "Amount",
            width: "130px",
            selector: row => row['amount'],
            sortable: false,
            left: true,
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-medium">₹</span>{row['amount'].toLocaleString('En-IN')}
                </p>
        },
        {
            name: "Frequency",
            width: "130px",
            selector: row => row['frequency'],
            sortable: false,
            left: true,
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    {row['frequency']}
                </p>
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: false,
            left: true,
            width: "130px",
            cell: row =>
                <p className="color-sea-green e-poppins-medium e-font-14 line-height-20px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    {row['status']}
                </p>
        },
        {
            name: 'Next SIP on',
            selector: row => row['next_sip_date'],
            sortable: true,
            left: true,
            width: "150px",
            cell: row => <p className="color-black e-poppins-semi-bold e-font-14 line-height-18px mb-0"
                onClick={() => _handleCardClick(row)}>
                {row['next_sip_date']}
            </p>


        },
        {
            name: '',
            sortable: false,
            left: true,
            width: "24px",
            cell: row =>

                <div className={`e-sip-hover text-start ${showArrow === row.sip_id ? 'show' : ''}`}>
                    <Icon icon="arrow-right1"
                        size={24}
                        color={Colors.black} />
                </div>

        }

    ];

    const SIP_COLUMN_MOBILE = [
        {
            name: 'Funds',
            selector: row => row['name'],
            sortable: false,
            left: true,
            width: "80%",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name"
                    onClick={() => _handleCardClick(row)}>
                    <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                        <img src={row['image']} alt={row['name']}
                            width={24}
                            height={24}
                            className="object-fit-contain" />
                    </div>
                    <div className="d-flex flex-column gap-6px">
                        <p className="color-black e-poppins-medium e-font-sm-12 line-height-22px mb-0  ">{row['name']}</p>
                        {
                            row['sip_tag'] !== null &&
                            <span className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px w-fit-content">
                                {row['sip_tag']}
                            </span>
                        }
                        <span className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px w-fit-content">
                            {row['frequency']}
                        </span>
                    </div>

                </div>
        },
        {
            name: "Amount",
            width: "20%",
            selector: row => row['amount'],
            sortable: false,
            left: true,
            cell: row =>
                <p className="color-black e-poppins-medium e-font-12 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-medium">₹</span>{row['amount'].toLocaleString('En-IN')}
                </p>
        }
    ];

    const _handleCardClick = (row) => {
        console.log('row :: ', row)

        if (row.is_goal) {
            navigate("/sip/sip-goal-summary", { state: { sip_id: row.sip_id } });
        } else {
            navigate("/sip/sip-summary", { state: { sip_id: row.sip_id } });
        }

        props.showSipDetail(row);
    }

    const _handleRowHover = (row) => {
        setShowArrow(row.sip_id)
    }

    const _handleRowRemoveHover = () => {
        setShowArrow(0);
    }

    const customStyles = {
        headRow: {
            style: {
                fontSize: "16px",
                paddingBottom: "8px !important",
            }
        },
        headCells: {
            style: {
                '&:not(:first-child)': {
                    justifyContent: 'center',
                },
            },
        },
        rows: {
            style: {
                padding: "16px",
                '&:hover': {
                    boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
                },
            },
        },
        cells: {
            style: {
                justifyContent: "center",
            }
        }
    };

    const customStylesMobile = {
        headRow: {
            style: {
                paddingRight: "0px",
                fontSize: "14px",
                paddingBottom: "8px !important",
            }
        },
        headCells: {
            style: {
                '&:not(:first-child)': {
                    justifyContent: 'center',
                },
            },
        },
        cells: {
            style: {
                justifyContent: "center",
                '&:first-child': {
                    padding: "0px 0 0px 0px !important"
                },
                '&:last-child': {
                    padding: "0px 0px 0px 0 !important"
                },
            }
        }
    };

    return (
        <div className="e-mobile-table">
            <DataTable columns={screenWidth > 576 ? SIP_COLUMN : SIP_COLUMN_MOBILE}
                data={props.data}
                pagination={false}
                customStyles={screenWidth > 576 ? customStyles : customStylesMobile}
                onRowMouseEnter={_handleRowHover}
                onRowMouseLeave={_handleRowRemoveHover}
                rowClick={_handleCardClick} />
        </div>
    )
}

export default SIPTable;