/*
 *   File : announcement.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for show platform announcement
 *   Integrations : null
 *   Version : v1.1
 */
import { useEffect } from 'react';
import Cookies from 'js-cookie';

import Icon from "../Icon/icon";
import { GradientButton } from "../CTA";

import style from '../../Styles/Component/announcement.module.scss';

export default function AnnouncementModal({data}) {

    useEffect(() => {
        var my_modal = document.getElementById("platform-announcement");
        my_modal.addEventListener("hidden.bs.modal", () => {
            sessionStorage.setItem('announcement_shown', true);
        });
    }, []);

    return (
        <div className={`modal fade ${style.e_announcement_modal}`}
            id="platform-announcement"
            tabIndex="-1"
            aria-labelledby="platform-announcement"
            aria-hidden="true"
            data-bs-backdrop="static" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-modal position-relative text-center d-flex align-items-center p-4 ${style.e_content} `}>
                    <Icon icon="close"
                        size={28}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <div className="container gx-0 mt-3 px-sm-3 px-0">
                        <div className="row">
                            <div className="col-12 text-center">
                                <img src={data.icon}
                                    alt='maintenance'
                                    width={72}
                                    height={72} />
                            </div>
                            <div className="col-12 text-center mt-4">
                                <h5 className="e-font-20 e-poppins-medium color-eerie-black">{data.title}</h5>
                                <p className="e-font-18 e-poppins-regular line-height-28px color-jett-black">{data.description}</p>
                            </div>
                            <div className="col-12">
                                <GradientButton label="Okay" 
                                    cancel="modal"
                                    className="padding-14px-tb padding-59px-lr"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}