/*
 *   File : dark-mode-context.js
 *   Author URI : https://evoqins.com
 *   Description : App theme context 
 *   Version : v1.1
 */


import React, { createContext, useState, useEffect } from "react";
import Cookies from 'js-cookie';

const DarkModeContext = createContext();

function DarkModeProvider(props) {
    const [darkMode, setDarkMode] = useState(false);
    const _toggleDarkMode = () => {
        setDarkMode(!darkMode);
        Cookies.set('theme', !darkMode, { domain: process.env.REACT_APP_DOMAIN, path: '/' });
    };

    // Update the data-theme attribute on body element based on darkMode
    useEffect(() => {
        if (darkMode) {
            document.documentElement.setAttribute('data-theme', 'dark');
        } else {
            document.documentElement.setAttribute('data-theme', 'light');
        }
    }, [darkMode]); 

    return (
        <DarkModeContext.Provider value={{ darkMode, _toggleDarkMode }}>
            {props.children}
        </DarkModeContext.Provider>
    )
};

export { DarkModeContext, DarkModeProvider}