
/*
 *   File : sip-dates.js
 *   Author URI : https://evoqins.com
 *   Description : Date picker component for sip date
 *   Integrations : null
 *   Version : v1.1
 */

import { useState, useEffect, useRef, useMemo, useContext } from 'react';
import ReactDOM from 'react-dom';

import { GradientButton } from '../CTA';
import Icon from '../Icon/icon';

import inputStyle from '../../Styles/Component/custom-textbox.module.scss';
import { DarkModeContext } from '../../Context/dark-mode-context';
import Color from '../../Styles/color.module.scss';

const SIPDateModal = (props) => {
    const { darkMode } = useContext(DarkModeContext);
    const { date, selectedDate, label } = props;
    const [openDatePanel, setOpenDatePanel] = useState(false);
    const [dateSelected, setDateSelected] = useState(null);
    const [confirmedDate, setConfirmedDate] = useState();
    const datePickerRef = useRef(null);
    const [datesAllowed, setDatesAllowed] = useState(props.enabledDates ? props.enabledDates : []);
    const [monthSelected, setMonthSelected] = useState(props.monthString);
    const [panelWidth, setPanelWidth] = useState(null);
    const [panelPosition, setPanelPosition] = useState(null);
    useEffect(() => {
        if (props.monthString !== "") {
            setMonthSelected(props.monthString);
        }
    }, [props.monthString]);
    // Validation and handling of props.date
    useEffect(() => {
        if (date) {
            const dates = date.split(",");
            if (dates.length > 0 && !isNaN(parseInt(dates[0]))) {
                console.log(dates);
                setDateSelected(parseInt(dates[0]));
                setConfirmedDate(parseInt(dates[0]))
            }
        }
    }, [date, selectedDate]);

    // Finding the date input position to avoid date panel position mismatch when scrolling the modal, 
    // and adding the position to the date panel.
    useEffect(() => {
        if (openDatePanel === true) {
                let sip_input = document.getElementById('sip-date-input');
                const rect = sip_input.getBoundingClientRect();
                var width = sip_input.offsetWidth;
                setPanelPosition(rect);
                setPanelWidth(width);
        }
        
    }, [openDatePanel]);

    useEffect(() => {
        if (props.enabledDates) {
            let sip_dates = props.enabledDates;
            // const formatted_sip_dates = sip_dates => sip_dates.map(Number);
            let processed_sip_dates = [];
            for (let i = 0; i < sip_dates.length; i++) {
                processed_sip_dates.push(Number(sip_dates[i]));
            }
            setDatesAllowed(processed_sip_dates);
        }
    }, [props.enabledDates]);


    // Memoized date options generation
    const dateOptions = useMemo(() => {
        const temp = [];
        for (let index = 1; index <= 30; index++) {
            temp.push(
                <span key={index}>{index}</span>
            )
        }
        return temp;
    }, []);

    // input focus
    const _handleFocus = () => {
        setTimeout(() => { setOpenDatePanel(true) }, 410);
        // Scroll to the top of the Investment Goal Modal
        const modalElement = ReactDOM.findDOMNode(props.modalRef.current);
        if (modalElement) {
            modalElement.scrollTop = 0;
        }
    };

    // hide date picker
    const _handleBlur = () => {
        setOpenDatePanel(false);
        setPanelPosition(null);
    };

    //Update selected date
    const handleDateSelection = (index) => {
        setDateSelected(index.key);
    };

    // render date picker title
    const renderTitle = () => {
        // no date selected
        if (!confirmedDate) {
            return <h5 className='color-black e-poppins-medium e-font-20 '>Choose a date</h5>
        }
        if (confirmedDate > 3 && confirmedDate < 21) {//return date+th if selected date bw 3 to 21
            return <h5 className='color-black e-poppins-medium e-font-20 '>{`${confirmedDate}th of every month`}</h5>;
        }
        switch (confirmedDate % 10) { // get reminder of selected date 
            case 1: return <h5 className='color-black e-poppins-medium e-font-20 '>{`${confirmedDate}st of every month`}</h5>; // return date+st;
            case 2: return <h5 className='color-black e-poppins-medium e-font-20 '>{`${confirmedDate}nd of every month`}</h5>; // return date+nd;
            case 3: return <h5 className='color-black e-poppins-medium e-font-20 '>{`${confirmedDate}rd of every month`}</h5>; // return date+rd
            default: return <h5 className='color-black e-poppins-medium e-font-20 '>{`${confirmedDate}th of every month`}</h5>;// return date+th
        }
    };

    const formatValue = () => {
        if (!confirmedDate) {
            return;
        } else {
            const teen = confirmedDate % 100;
            console.log('dateText', props.dateText);
            if (props.dateText == "") {
                switch (true) {
                    case teen >= 11 && teen <= 13:
                        return `${confirmedDate}th of every month`;
                    case confirmedDate % 10 === 1:
                        return `${confirmedDate}st of every month`;
                    case confirmedDate % 10 === 2:
                        return `${confirmedDate}nd of every month`;
                    case confirmedDate % 10 === 3:
                        return `${confirmedDate}rd of every month`;
                    default:
                        return `${confirmedDate}th of every month`;
                }
            } else {
                const current_date = new Date();
                let month = current_date.toLocaleString('default', { month: 'short' });
                if (props.monthString !== "") {
                    month = props.monthString;
                }
                switch (true) {
                    case teen >= 11 && teen <= 13:
                        return ` ${props.dateText} ${confirmedDate}th ${monthSelected}`;
                    case confirmedDate % 10 === 1:
                        return `${props.dateText} ${confirmedDate}st ${monthSelected}`;
                    case confirmedDate % 10 === 2:
                        return `${props.dateText} ${confirmedDate}nd ${monthSelected}`;
                    case confirmedDate % 10 === 3:
                        return `${props.dateText} ${confirmedDate}rd ${monthSelected}`;
                    default:
                        return `${props.dateText} ${confirmedDate}th ${monthSelected}`;
                }
            }

        }
    };

    function _confirmDate() {
        const today = new Date();
        let month = today.getMonth();
        let next_month = month + 1;
        let month_string;

        // Make sure to handle the case where next_month is December (11)
        if (today.getDate() > parseInt(dateSelected)) {
            // Increment month by one, and wrap around if it's December
            if (next_month > 11) {
                next_month = 0; // January of the next year
            }
            month_string = new Date(today.getFullYear(), next_month).toLocaleString('en-US', { month: 'short' });
        } else {
            month_string = today.toLocaleString('en-US', { month: 'short' });
        }
        setMonthSelected(month_string);
        setConfirmedDate(dateSelected);
        setOpenDatePanel(false);
        setPanelPosition(null);
        props.dateSelected(dateSelected)
    }


    return (
        <div className={` position-relative`} ref={datePickerRef}
        >
            {/* Input */}
            <div className={`${props.className} ${inputStyle.e_input_wrapper} position-relative w-100`}>
                <input
                    value={formatValue()}
                    id="sip-date-input"
                    type='text'
                    readOnly={true}
                    className={`${inputStyle.e_sip_input} ${props.inputClass} bg-transparent padding-16px-tb color-charleston-green e-poppins-regular e-font-16 w-100 no-outline border-radius-12px`}
                    onFocus={_handleFocus}
                    required
                />
                {/* label */}
                <label className={`bg-body-color position-absolute left-15px e-poppins-regular e-font-14 cursor-text padding-0-tb padding-0-left padding-30px-right ${props.labelClass}`}
                    htmlFor='sip-date'>
                    {label}
                </label>
                <Icon icon="calendarIcon"
                    size={24}
                    className="position-absolute right-20px top-16px"
                    color={darkMode === true && Color.white}
                    onClick={_handleFocus} />
            </div>
            {/* Drop down */}
            {openDatePanel && panelPosition !== null ? (
                <>
                    <div className='position-fixed w-100 h-100 bg-jet-black opacity-7 z-index-9 top-0 left-0'></div>
                    <div className='position-absolute'>
                        <div className={`bg-cards border-radius-8px pt-4 position-fixed z-index-10 e-sip-date-picker-panel`}
                            style={{ width: window.innerWidth < 575 && 364 + "px",
                                top: window.innerWidth < 575 ? 0 : (panelPosition.top + 60)+"px", 
                                    bottom: window.innerWidth < 575 && 0, 
                                        left: window.innerWidth < 575 ? 0 : (panelPosition.left + 60)+"px", 
                                            right: window.innerWidth < 575 && 0,  
                                                margin: window.innerWidth < 575 && 'auto' }}>
                            {/* Pane title */}
                            <div className={` text-center`}>
                                {renderTitle()}
                                <Icon icon="close"
                                    size={24}
                                    className="position-absolute top-20px right-24 cursor-pointer e-modal-close-btn"
                                    onClick={_handleBlur} />
                            </div>

                            {/* Dates */}
                            <div className='text-center'>
                                {
                                    dateOptions.map((index) => {
                                        let enabled = datesAllowed.includes(Number(index.key));
                                        return (
                                            <span className={` d-inline-flex justify-content-center cursor-pointer padding-7px-top w-50px h-39px e-sip-date-picker-date e-poppins-regular e-font-16 ${(enabled == false && 'e-disabled-date no-events')} ${dateSelected == (Number(index.key)) ? 'bg-vivid-cerulean color-white ' : 'color-black '}`}
                                                key={index}
                                                onClick={() => handleDateSelection(index)}
                                            >
                                                {index.key}
                                            </span>
                                        )
                                    })
                                }
                            </div>

                            {/* Confirm button */}
                            <div className='text-center mt-3'>
                                <GradientButton label="Confirm"
                                    className="padding-10px-tb padding-50px-lr"
                                    handleClick={_confirmDate} />
                            </div>

                        </div>
                    </div>
                </>
            ) : null}
        </div>
    );
};

SIPDateModal.defaultProps = {
    dateText: "",
    monthString: ""
}

export default SIPDateModal;
