/*
 *   File : featured-investment.js
 *   Author URI : https://evoqins.com
 *   Description : Featured investment
 *   Integrations : null
 *   Version : v1.1
 */

import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { LinkText } from '../CTA'
import { SectionTitle } from '../Title'

const FeaturedInvestmentCard = React.memo((props) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [quickInvest, setQuickInvest] = useState([]);
    const [category, setCategory] = useState([])
    useEffect(() => {
        if (props.quickInvest) {
            setQuickInvest(props.quickInvest)
        }
        if (props.mutualFundCategories) {
            setCategory(props.mutualFundCategories)
        }
    }, [props]);


    const _handleNavigate = (data, type) => {
        if (data.name === 'NFO') {
            navigate('/nfo-list');
            return
        }

        // icon
        // : 
        // "https://mastertrust-static-assets.s3.ap-south-1.amazonaws.com/admin_tags/trending_on_mt.png"
        // id
        // : 
        // "popular_funds"
        // name
        // : 
        // "Popular Funds"
        // title_icon
        // : 
        // "https://mastertrust-static-assets.s3.ap-south-1.amazonaws.com/admin_tags/new_icon.png"
        // title_tag
        // : 
        // "New"

        // type - true will be quick invest and false will be MF categories
        if (type == true) {
            navigate("/explore",
                {
                    state: {
                        amc: [null],
                        rating: null,
                        fund_category: [null],
                        fund_type: [null],
                        theme: [null],
                        minimum_investment: null,
                        search_text: "",
                        filter_by: data.id,
                        filter_by_tag: data.name,
                        current: location.pathname,
                        category_name: "",
                        page_num:1,
                        sort_by: {value: 5, label: 'Returns - High to Low'}
                    }
                });
        } else {
            navigate("/explore",
                {
                    state: {
                        amc: [null],
                        rating: null,
                        fund_category: [data.id],
                        fund_type: [null],
                        theme: [null],
                        minimum_investment: null,
                        search_text: "",
                        filter_by: null,
                        filter_by_tag: "",
                        current: location.pathname,
                        category_name: data.name,
                        page_num: 1,
                        sort_by: {value: 5, label: 'Returns - High to Low'}
                    }
                })
        }
    }

    function _redirectToExplore() {
        navigate('/explore');
    }

    return (
        //    Quick investment and categories 
        <Fragment>

            {/* large screen */}
            <div className="e-quick-invest mt-3 d-lg-block  d-none">
                <div className="row ">


                    <div className="col-lg-12" >
                        <div className='d-flex align-items-middle justify-content-between'>
                            <SectionTitle title="Quick Invest"
                                className={`mb-0 margin-24px-top ${props.className} `} />
                            {/* <LinkText icon="arrow-right"
                                className="text-center margin-32px-top"
                                onClick={_redirectToExplore} /> */}
                        </div>

                        <div className='e-quick-invest-scroll'>

                            <div className="row">
                                {
                                    quickInvest.map((item) =>
                                        <div className="col-2 h-100" key={item.id}
                                            onClick={() => _handleNavigate(item, true)}>
                                            <div className='border-radius-24px border-all border-anti-flash-white bg-quick-invest-card padding-18px-tb padding-16px-lr text-center position-relative margin-24px-top e-quick-invest-card min-height-142px-sm-120-sm-120'>

                                                <img src={item.icon} alt={item.name}
                                                    width={40}
                                                    height={40}
                                                    draggable={false} />
                                                <p className='color-black e-poppins-medium e-font-16 line-height-20px mb-0 margin-24px-top'>{item.name}</p>
                                                {
                                                    item.title_tag !== null &&
                                                    <div className='position-absolute e-trending p-1 bg-cornsilk border-tbr-radius-12px'>
                                                        <p className=' e-poppins-regular line-height-12px e-font-10 mb-0 text-capitalize'>
                                                            {item.title_tag}
                                                            <img src={item.title_icon}
                                                                alt={item.name}
                                                                width={12}
                                                                height={12}
                                                                draggable={false} />
                                                        </p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-4" >
                        <div className='d-flex align-items-middle justify-content-between'>
                            <SectionTitle title="Mutual Fund Categories "
                                className={`mb-0 margin-24px-top ${props.className} `} />
                            {/* <LinkText icon="arrow-right"
                                className="text-center margin-32px-top"
                                onClick={_redirectToExplore} /> */}
                        </div>
                        <div className='e-quick-invest-scroll'>
                            <div className="row">
                                {
                                    category.map((item) =>
                                        <div className="col-2 h-100" key={item.id}
                                            onClick={() => _handleNavigate(item, false)}>
                                            <div className='border-radius-24px border-all border-anti-flash-white bg-powder-blue padding-18px-tb padding-16px-lr text-center position-relative margin-24px-top e-quick-invest-card min-height-142px-sm-120'>
                                                <img src={item.icon} alt={item.name}
                                                    width={40}
                                                    height={40}
                                                    draggable={false} />
                                                <p className='color-black e-poppins-medium e-font-16 line-height-20px mb-0 margin-24px-top'>{item.name}</p>
                                                {
                                                    item.title_tag !== null &&
                                                    <div className='position-absolute e-trending p-1 bg-cornsilk border-tbr-radius-12px'>
                                                        <p className='e-poppins-regular line-height-12px e-font-10 mb-0'>{item.title_tag}
                                                            <img src={item.title_icon}
                                                                alt={item.name}
                                                                width={12}
                                                                height={12}
                                                                draggable={false} /></p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* small screen */}
            <div className="e-quick-invest margin-24px-top-sm-16 d-lg-none d-block">
                <div>
                    <div className='col-12 d-flex align-items-middle justify-content-between'>
                        <SectionTitle title="Quick Invest"
                            className={`mb-0 margin-18px-left ${props.className}`} />
                        {/* <LinkText icon="arrow-right"
                            className="text-center "
                            onClick={_redirectToExplore} /> */}
                    </div>
                    <div className={`e-quick-invest-scroll ${props.className} padding-18px-lr`}>
                        <div className="d-flex gap-16px margin-24px-bottom-sm-16">
                            {
                                quickInvest.map((item) =>
                                    <div className='border-radius-24px border-all border-anti-flash-white bg-quick-invest-card padding-18px-tb padding-22px-lr text-center position-relative margin-24px-top-sm-12 e-quick-invest-card min-height-142px-sm-120'
                                        key={item.id}
                                        onClick={() => _handleNavigate(item, true)}>

                                        <img src={item.icon} alt={item.name}
                                            width={40}
                                            height={40}
                                            draggable={false} />
                                        <p className='color-black e-poppins-medium e-font-16 line-height-20px mb-0 margin-16px-top'>{item.name}</p>
                                        {item.title_tag !== null &&
                                            <div className='position-absolute e-trending p-1 bg-cornsilk border-tbr-radius-12px'>
                                                <p className='e-poppins-regular line-height-12px e-font-10 mb-0'>{item.title_tag}
                                                    <img src={item.title_icon}
                                                        alt={item.name}
                                                        width={12}
                                                        height={12}
                                                        draggable={false} /></p>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <div className='col-12 d-flex align-items-middle justify-content-between mt-sm-4 mt-0'>
                        <SectionTitle title="Mutual Fund Categories "
                            className={`mb-0 margin-18px-left ${props.className}`} />
                        {/* <LinkText icon="arrow-right"
                            className="text-center " /> */}
                    </div>
                    <div className={`e-quick-invest-scroll ${props.className} padding-18px-lr`}>
                        <div className="d-flex gap-16px margin-24px-bottom">
                            {
                                category.map((item) =>
                                    <div className='border-radius-24px border-all border-anti-flash-white bg-powder-blue padding-18px-tb padding-22px-lr text-center position-relative margin-24px-top-sm-12 e-quick-invest-card min-height-142px-sm-120'
                                        key={item.id}
                                        onClick={() => _handleNavigate(item, false)}>

                                        <img src={item.icon} alt={item.name}
                                            width={40}
                                            height={40}
                                            draggable={false} />
                                        <p className='color-black e-poppins-medium e-font-16 line-height-20px mb-0 margin-16px-top'>{item.name}</p>
                                        {item.title_tag !== null &&
                                            <div className='position-absolute e-trending p-1 bg-cornsilk border-tbr-radius-12px'>
                                                <p className='e-poppins-regular line-height-12px e-font-10 mb-0'>{item.title_tag}
                                                    <img src={item.title_icon}
                                                        alt={item.name}
                                                        width={12}
                                                        height={12}
                                                        draggable={false} /></p>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
})

// Define default props for the component
FeaturedInvestmentCard.defaultProps = {
    quickInvest: [], // Default value for quickInvest prop
    category: []
};
export default FeaturedInvestmentCard