/*
 *   File : summary.js
 *   Author URI : https://evoqins.com
 *   Description : Summary page for mt-transcations
 *   Integrations : NA
 *   Version : v1.1
 */

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux"
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

import Icon from "../../Component/Icon/icon";
import { updateNavigation } from "../../Store/Action/action";
import BreadCrumb from "../../Component/BreadCrumb/breadcrumb";
import Footer from "../../Component/Footer/footer";
import { InvestmentHeader } from "../../Component/Header";
import { CustomLoader } from "../../Component/Other";
import { CancelSTPSWPModal } from "../../Component/Modal";

import APIService from "../../Service/api-service";

import style from "../../Styles/Component/sip-detail.module.scss";


const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Manage STP & SWP', url: '/manage-stp-swp' },
    { page: 'Summary', url: null }
];

export default function TransactionSummary(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const fundId = location.state && location.state.fund_id;
    const [apiLoader, setApiLoader] = useState(true);
    const [detail, setDetail] = useState({});
    const [orderType, setOrderType] = useState(1);
    const [openCancelConfirmModal, setOpenCancelConfrimModal] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        function _handleScroll() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleScroll);

        window.removeEventListener('resize', _handleScroll)
    }, []);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (location.state.type && location.state.type === 'swp') {
            setOrderType(2);
        }
    }, [location.state]);

    useEffect(() => {
        if (fundId) {
            _getDetail();
        }
        else {
            navigate(-1);
        }
    }, [fundId]);

    useEffect(() => {
        if (openCancelConfirmModal === true) {
            _openCancelModal();
        }
    }, [openCancelConfirmModal]);

    const _openCancelModal = () => {
        const modal = new Modal(document.getElementById("cancel-stp-swp"), {});
        modal.show();
    }

    const _handleNavigate = (order_id) => {
        navigate("/orders/fund-order-summary", { state: { order_id: order_id } });
        dispatch(updateNavigation("/orders"));
    }

    // handle cancel STP
    function _handleCancelTransaction() {
        setOpenCancelConfrimModal(true);
    }

    function _closeConfirmModal() {
        setOpenCancelConfrimModal(false);
    }

    //  API - get detail of STP || SWP
    function _getDetail() {
        let url = 'stp/detail';
        if (location.state.type === 'swp') {
            url = 'swp/detail';
        }

        let payload = JSON.stringify({
            id: fundId
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setDetail(response.data);
            }
            setApiLoader(false);
        });
    }

    // API - Cancel STP || SWP
    function _cancelTransaction() {
        let status = false;
        let url = 'stp/cancel';
        if (orderType === 2) {
            url = 'swp/cancel';
        }
        let payload = JSON.stringify({
            id: fundId
        });
        APIService(true, url, payload).then((response) => {
            console.log('response', response);
            if (response.status_code === 200) {
                status = true;
                toast.success(`${orderType === 1 ? 'STP' : 'SWP'} cancelled`, {
                    type: "success",
                });
                navigate("/manage-stp-swp", { state: { tabIndex: orderType, status: 2 } });

            }
            else {
                toast.error(response.message, {
                    type: "error",
                });
            }
        });
        return status;
    }

    const Heading = (props) => {
        return (
            <div className={`d-flex ${props.className}`}>
                <p className="color-gray e-poppins-medium e-font-16-sm-12 line-height-16px mb-0">{props.heading}&nbsp;</p>
                <p className="color-outer-space e-poppins-medium e-font-16-sm-12 line-height-16px mb-0">{props.answer}</p>
            </div>

        )
    }

    const PaymentAndOtherActivities = (props) => {
        return (
            <div className="mt-sm-3 d-sm-block d-flex mt-0">
                <p className="color-gray e-poppins-medium e-font-16-sm-12 line-height-16px margin-10px-bottom e-payment-other-activities mb-0">
                    {props.heading}
                    <span className="d-lg-none d-inline">:</span>
                </p>
                <p className="color-black e-poppins-semi-bold e-font-16-sm-12 line-height-16px mb-0 ms-sm-0 ms-2 mt-sm-3 mt-0">{props.answer}</p>
            </div>
        )
    }

    return (
        apiLoader === true ? (
            <CustomLoader />
        )
            :
            <div className="e-page-container e-manage-sip margin-80px-bottom">
                <InvestmentHeader />
                <div className="mt-2">
                    <BreadCrumb data={PAGE_NAVIGATION} />
                </div>

                <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px my-sm-4 my-3">{orderType === 1 ? 'My STP' : 'My SWP'}</p>
                <div className="row">
                    <div className="col-lg-10 col-12">
                        <div className="border-radius-24px border-all border-bright-gray p-sm-4 p-3">
                            {
                                orderType === 1 ? (
                                    <div className="border-bottom-1px border-bright-gray pb-3 d-flex justify-content-between  flex-wrap">
                                        <div className="d-flex  gap-12px">
                                            <div className="border-all border-bright-gray border-radius-8px p-2 h-max-content">
                                                <img src={detail.image}
                                                    alt="Quant Small Cap Fund Direct Plan Growth"
                                                    width={36}
                                                    height={36}
                                                    className="object-fit-contain" />
                                            </div>
                                            <div className="">
                                                <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-sm-2 mb-0">
                                                    Transfer Out
                                                </p>
                                                <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-22px-sm-26px mb-0">{detail.name}</p>
                                                <img src={require('../../Assets/Images/orders/stp-arrow.png')}
                                                    className="e-arrow-down my-sm-3 my-2 w-35px" />
                                                <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-sm-2 mb-0">
                                                    Transfer in
                                                </p>
                                                <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-22px-sm-26px mb-0">{detail.switch_in_fund_name}</p>
                                            </div>

                                        </div>
                                        <div className="d-sm-block d-none">
                                            <p className="color-outer-space e-poppins-semi-bold e-font-20-sm-18 line-height-20px mb-0 mt-lg-0 mt-md-0 mt-sm-0 mt-3"><span className="e-inter-semi-bold">₹</span>{detail.amount}
                                            </p>
                                            <p className="color-gray e-poppins-regular e-font-12 line-height-18px mb-0">STP amount</p>
                                        </div>
                                        <div className="d-sm-none d-flex">
                                            <p className="color-gray e-poppins-regular e-font-12 line-height-18px mb-0 mt-2">STP amount</p>
                                            <p className="color-outer-space e-poppins-semi-bold e-font-20-sm-18 line-height-20px mb-0 mt-lg-0 mt-md-0  mt-2 ms-sm-0 ms-2"><span className="e-inter-semi-bold">₹</span>{detail.amount}
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="border-bottom-1px border-bright-gray pb-3 d-flex justify-content-between align-items-center  flex-wrap">
                                        <div className="d-flex align-items-center gap-12px">
                                            <div className="border-all border-bright-gray border-radius-8px p-2 h-max-content">
                                                <img src={detail.image}
                                                    alt="Quant Small Cap Fund Direct Plan Growth"
                                                    width={36}
                                                    height={36}
                                                    className="object-fit-contain" />
                                            </div>
                                            <div className="">
                                                <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-22px-sm-26px mb-0">{detail.name}</p>
                                            </div>

                                        </div>
                                        <div className="d-sm-block d-none">
                                            <p className="color-outer-space e-poppins-semi-bold e-font-20-sm-18 line-height-20px mb-0 mt-lg-0 mt-md-0 mt-sm-0 mt-3"><span className="e-inter-semi-bold">₹</span>{detail.amount}
                                            </p>
                                            <p className="color-gray e-poppins-regular e-font-12 line-height-18px mb-0">SWP amount</p>
                                        </div>
                                        <div className="d-sm-none d-flex">
                                            <p className="color-gray e-poppins-regular e-font-12 line-height-18px mb-0 mt-2">SWP amount</p>
                                            <p className="color-outer-space e-poppins-semi-bold e-font-20-sm-18 line-height-20px mb-0 mt-2 ms-2"><span className="e-inter-semi-bold">₹</span>{detail.amount}
                                            </p>
                                        </div>
                                    </div>
                                )
                            }

                            <div className="mt-3 d-flex gap-16-xs flex-wrap justify-content-between align-items-center">
                                <Heading heading="Last installment:"
                                    answer={detail.last_installment} />
                                <div className="d-flex align-items-center border-all border-primary-color border-radius-30px bg-pale-steel-blue px-sm-3 px-2 py-2 w-max-content ">
                                    <p className="color-gray e-poppins-medium e-font-16-sm-10 line-height-16px mb-0">Next installment &nbsp;</p>
                                    <p className="color-primary-color e-poppins-semi-bold e-font-16-sm-10 mb-0">{orderType === 1 ? detail.next_stp_date : detail.next_swp_date}</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-3 flex-wrap gap-16px">
                                <Heading heading={orderType === 1 ? "STP created on:" : "SWP created on:"}
                                    answer={orderType === 1 ? detail.stp_created_on : detail.swp_created_on}
                                />
                                <Heading heading="Frequency"
                                    answer={detail.frequency}
                                />
                            </div>
                            <div className={`${screenWidth > 576 ? 'border-radius-16px bg-sip-detail-value-card p-3' : 'p-0'}  my-sm-4 my-3 `}>
                                <div className="d-flex justify-content-between flex-wrap gap-16px">
                                    <Heading heading="Start date:"
                                        answer={detail.start_date} />
                                    <Heading heading="End date:"
                                        answer={detail.end_date}
                                    />
                                </div>

                            </div>
                            <div className="border-top-1px border-bright-gray pt-3 d-flex justify-content-between algin-items-center flex-wrap">
                                <div className="d-flex align-items-center gap-16px">
                                    <p className="color-primary-color e-poppins-regular e-font-16 mb-0 cursor-pointer"
                                        onClick={_handleCancelTransaction}>{orderType === 1 ? 'Cancel STP' : 'Cancel SWP'}</p>
                                </div>
                            </div>
                        </div>

                        <p className="color-jett-black e-poppins-medium e-font-16 my-3 ms-sm-0 ms-3">Payments and other activities</p>
                        <div className={`${screenWidth > 576 && 'border-all border-bright-gray'} pb-sm-3 pb-0 pt-sm-0 pt-0 px-sm-4 px-0 ms-sm-0 ms-3 d-flex flex-wrap justify-content-between gap-24px-sm-16 border-radius-16px`}>
                            <PaymentAndOtherActivities heading="Total no. of installments"
                                answer={detail.total_number_of_installments} />
                            <PaymentAndOtherActivities heading="Missed installments"
                                answer={detail.missed_of_installments} />
                            <PaymentAndOtherActivities heading="Rejected installments"
                                answer={detail.rejected_of_installments} />
                            <PaymentAndOtherActivities heading="Remaining installments"
                                answer={detail.remaining_installments} />
                        </div>


                        {
                            detail.installments.length !== 0 &&
                            <div className="border-radius-24px border-all border-bright-gray p-4 mt-3">
                                {
                                    detail.installments.map((item, key) =>
                                        <div key={key}
                                            className={`d-flex gap-8px  cursor-pointer ${detail.installments.length - 1 !== key && "border-bottom-1px border-bright-gray mb-3 pb-3"} ${(item.status === "Upcoming" && 'e-pointer-events-none')}`}
                                            onClick={() => _handleNavigate(item.order_id)}>
                                            <Icon icon={item.status === "Success" ? "check-green" : item.status === "Failed" ? "cancelled" : "upcoming"}
                                                size={24} />
                                            <div className="w-100">
                                                <div className="d-flex justify-content-between align-items-center flex-wrap gap-16px">
                                                    <div>
                                                        <div className="d-flex gap-16px align-items-center mb-2">
                                                            <p className="color-outer-space e-poppins-medium e-font-sm-16-14 line-height-24px mb-0">{item.title}: {item.status === "Upcoming" ? "Not paid yet" : item.status === "Success" ? "Paid" : item.status === "Failed" ? "Failed" : item.status}</p>
                                                            {
                                                                item.status !== "Upcoming" &&
                                                                <p className={`position-relative e-font-14 e-poppins-regular mb-0 ms-lg-0 ms-md-0 ms-sm-0 ms-2 ${style.e_sip}`}>{orderType === 1 ? "STP" : "SWP"}</p>
                                                            }
                                                        </div>
                                                        <p className="color-outer-space e-poppins-regular e-font-14 line-height-16px mb-0">{item.transaction_date}</p>
                                                    </div>
                                                    {
                                                        item.status === "Success" ?
                                                            <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0">Success</p>
                                                            :
                                                            item.status === "Failed" ?
                                                                <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0">Failed</p>
                                                                :
                                                                <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0">{item.status}</p>

                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        }
                    </div>
                </div>
                {
                    openCancelConfirmModal === true &&
                    <CancelSTPSWPModal buttonName={orderType === 1 ? "Yes, Cancel STP" : "Yes, Cancel SWP"}
                        message={orderType === 1 ? "Are you sure you want to cancel your STP?" : "Are you sure you want to cancel your SWP?"}
                        cancel={_cancelTransaction}
                        close={_closeConfirmModal}
                    />
                }
            </div>

    )
}

