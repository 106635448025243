/*
 *   File : riskscore-permission.js
 *   Author URI : https://evoqins.com
 *   Description : Risk Score Permission Modal
 *   Integrations : null
 *   Version : v1.1
 */
import {useEffect} from 'react';
import Cookies from 'js-cookie';

import { GradientButton, OutlineButton } from '../CTA'
import Icon from '../Icon/icon'
import style from '../../Styles/Component/riskscore-permission.module.scss'


const RiskScorePermissionModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("questionnaire-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            Cookies.set('risk_score_shown', true)
            // localStorage.setItem('risk_score_shown', true)
        });
    }, []);

    const _handleContinue = () => {
        props.continue()
    }

    return (
        <div className={`modal fade ${style.e_risk_modal}`}
            id="questionnaire-modal"
            tabIndex="-1"
            aria-labelledby="questionnaire-modal"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_risk_dialog}`}>
                <div className={`modal-content border-radius-16px bg-modal text-center d-flex flex-column align-items-center position-relative ${style.e_risk_content} `}>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <img src={require("../../Assets/Images/risk-assessment/risk-score.svg").default}
                        alt="Risk assessment"
                        width={196}
                        height={196}
                        className='mb-2' />
                    <h6 className='color-black e-poppins-semi-bold e-font-18 line-height-26px mb-3 mx-lg-5 mx-md-5 mx-sm-5'>
                        Hello {props.userName}, thank you for joining MasterTrust
                    </h6>
                    <p className='color-black e-poppins-regular e-font-16 margin-72px-bottom'>
                        Got 60 seconds to answer questions? This helps us in creating risk profile and investment style based on your answers.
                    </p>
                    <div className={`position-absolute d-flex justify-content-end gap-16px w-100 ${style.e_buttons}`}>
                        <OutlineButton label="No, continue"
                            cancel="modal"
                            className="padding-14px-all e-font-16 line-height-14px" />
                        <GradientButton label="Yes, continue"
                            cancel="modal"
                            className="padding-10px-tb padding-16px-lr e-font-16"
                            handleClick={_handleContinue} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RiskScorePermissionModal