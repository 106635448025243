/*
 *   File : funds.js
 *   Author URI : www.evoqins.com
 *   Description : To show the available funds in Master Trust platform.
 *   Integrations : NA
 *   Version : v1.0
 *   Created : 14-09-2023
 */

import { useEffect, useState, memo } from "react";
import { useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";

import { DataTable } from "../Form-elements";
import Icon from "../Icon/icon";
import { CartButton, GradientButton, LinkText } from "../CTA";
import { CustomToolTip } from "../Popups";

import Colors from '../../Styles/color.module.scss';

const CUSTOM_STYLES = {
    headRow: {
        style: {
            paddingRight: "0px",
            fontSize: "16px",
            paddingBottom: "8px !important",
        }
    },
    headCells: {
        style: {
            '&:not(:first-child)': {
                justifyContent: 'center'
            },
        },
    },
    rows: {
        style: {
            padding: "0px",
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },

    cells: {
        style: {
            justifyContent: "center",
            '&:first-child': {
                padding: "24px 0 24px 24px !important"
            },
            '&:last-child': {
                padding: "24px 24px 24px 0 !important"
            },
        }
    }
};

const CUSTOM_STYLES_MOBILE = {
    headRow: {
        style: {
            paddingRight: "0px",
            fontSize: "14px",
            paddingBottom: "8px !important",
        }
    },
    headCells: {
        style: {
            '&:not(:first-child)': {
                justifyContent: 'center'
            },
        },
    },
    rows: {
        style: {
            padding: "0px",
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },

    cells: {
        style: {
            justifyContent: "center",
            '&:first-child': {
                    padding: "0px 0 0px 0px !important"
                },
                '&:last-child': {
                    padding: "0px 0px 0px 0 !important"
                },
        }
    }
};


export default memo(FundTable);
function FundTable(props) {

    const location = useLocation();

    const TOOL_TIP = useSelector(state => state.generalData.TOOL_TIP_DATA);

    const [showArrow, setShowArrow] = useState(0);
    const [fundGoals, setFundGoals] = useState(props.data);
    const [rankInfo, setRankInfo] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const _handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', _handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', _handleResize);
        };
    }, []);

    useEffect(() => {
        setFundGoals(props.data);
    }, [props.data]);


    const FUNDS_COLUMN = [
        {
            id: 1,
            name: 'Funds',
            selector: row => row['name'],
            sortable: true,
            left: true,
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name "
                    onClick={() => _handleCardClick(row)}>
                    <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                        <img src={row['image']} alt={row['name']}
                            width={36}
                            height={36}
                            className="object-fit-contain" />
                    </div>
                    <div className="d-flex flex-column gap-12px">
                        <span className="color-black e-poppins-medium e-font-sm-16-14 line-height-24px mb-0 ">{row['name']}</span>
                        <span className="border-radius-16px border-all border-bright-gray  padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px w-fit-content">
                            {row['type']}
                        </span>
                    </div>

                </div>
        }, {
            id: 2,
            name: "CAGR (3Y)",
            width: windowWidth > 575 ? "110px" : "130px",
            selector: row => row['cagr'],
            sortable: true,
            left: true,
            style: {
                color: Colors.black,
                fontFamily: "Poppins-SemiBold",
                fontSize: "16px",
                lineHeight: "18px",
            },
            cell: row =>
                <p className="color-dark-spring-green e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    {row['cagr'] !== '-' ? `${row['cagr']}%` : row['cagr']}
                </p>
        },
        {
            id: 3,
            name: 'AUM (Cr)',
            selector: row => row['aum'],
            sortable: true,
            left: true,
            width: windowWidth > 575 ? "100px" : "130px",
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    {row['aum']}
                </p>
        },
        {
            id: 4,
            name: 'Risk',
            selector: row => row['risk'],
            sortable: true,
            left: true,
            width: windowWidth > 575 ? "50px" : "80px",
            cell: row =>
                <div className="d-flex w-100 justify-content-center "
                    onClick={() => _handleCardClick(row)}>
                    <img src={row['risk_image']}
                        alt="High risk"
                        width={42}
                        height={40}
                        className="object-fit-contain" />
                </div>

        },
        {
            id: 5,
            name: <div className="postion-relative explore-table-title">
                Rank
                {
                    Object.keys(TOOL_TIP).length !== 0 &&
                    <>
                        <Icon icon="info"
                            size={20}
                            className="cursor-pointer"
                            data-tooltip-id="rank-tooltip"
                            onClick={(e) => e.stopPropagation()} />

                        <Tooltip id="rank-tooltip"
                            arrowColor='transparent'
                            effect="float"
                            place="bottom"
                            className='e-tooltip-general'>
                            {parse(TOOL_TIP.explore_rank_column_heading)}
                        </Tooltip>
                    </>
                }

            </div>,
            selector: row => row['rank'],
            sortable: true,
            left: true,
            width: windowWidth > 575 ? "80px" : "100px",
            style: {
                color: Colors.black,
                fontSize: "14px",
                lineHeight: " 18px"
            },
            cell: row =>
                <div className="d-flex align-items-center justify-content-center "
                    onClick={() => _handleCardClick(row)}>
                    <p className="mb-0 e-poppins-semi-bold">{row['rank']}</p>
                    <img src={require("../../Assets/Images/dashboard/Star.png")}
                        alt="Rank"
                        width={16}
                        height={16} />

                </div>

        },
        {
            id: 6,
            name: '',
            sortable: false,
            left: true,
            width: "200px",
            cell: row => <div className="d-flex align-items-center gap-16px " data-disabled={`${row.sip_enabled === false && row.lumpsum_enabled === false ? 'true' : 'false'}`} >
                <div data-disabled={`${row.lumpsum_enabled === false ? 'true' : 'false'}`}>
                    <CartButton data={row}
                        explore={true} />
                </div>
                <GradientButton label="Invest now"
                    className="py-2 px-3"
                    handleClick={() => props.openModal(row)} />
            </div>

        },
        {
            id: 7,
            name: '',
            sortable: false,
            left: true,
            width: "48px",
            cell: row => <div className={`e-fund-order-hover text-start   ${showArrow === row.id ? 'show ' : ''}`}
                onClick={() => _handleCardClick(row)}>
                <Icon icon="arrow-right1"
                    size={24}
                    color={Colors.black} />
            </div>

        }

    ];

    const FUND_TABLE_MOBILE = [
        {
            id: 1,
            name: 'Funds',
            selector: row => row['name'],
            sortable: true,
            left: true,
            width: '80%',
            cell: row =>
                <div className="d-flex align-items-center gap-12px  "
                    onClick={() => _handleCardClick(row)}>
                    <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                        <img src={row['image']} alt={row['name']}
                            width={24}
                            height={24}
                            className="object-fit-contain" />
                    </div>
                    <div className="d-flex flex-column gap-6px">
                        <span className="color-black e-poppins-medium e-font-12 line-height-22px mb-0 ">{row['name']}</span>
                        <span className="border-radius-16px border-all border-bright-gray  padding-12px-lr color-black e-poppins-medium e-font-8 line-height-18px w-fit-content">
                            {row['type']}
                        </span>
                    </div>

                </div>
        },
        {
            id: 2,
            name: "CAGR(3Y)",
            selector: row => row['cagr'],
            sortable: false,
            left: true,
            width: '20%',
            style: {
                color: Colors.black,
                fontFamily: "Poppins-SemiBold",
                fontSize: "10px",
                lineHeight: "18px",
                justifyContent: 'end'
            },
            cell: row =>
                <div className="d-block">
                    <p className="color-dark-spring-green e-poppins-semi-bold e-font-10 line-height-20px mb-0 text-center"
                        onClick={() => _handleCardClick(row)}>
                        {row['cagr'] !== '-' ? `${row['cagr']}%` : row['cagr']}
                    </p>
                    <GradientButton label="Invest"
                                className="py-2 px-3  e-font-10"
                                handleClick={(e) =>{e.stopPropagation(); props.openModal(row)}} />
                    {/* <p className="e-primary-font-medium e-font-12 cursor-pointer color-ocean-blue mb-0 pb-0 line-height-12px d-block text-decoration-underline"
                        onClick={(e) => { e.stopPropagation(); props.openModal(row) }}>Invest now</p> */}
                </div>

        }
    ]

    const _handleRowHover = (row) => {
        setShowArrow(row.id)
    }

    const _handleRowRemoveHover = () => {
        setShowArrow(0);
    }

    const _handleCardClick = (row) => {
        props.onSelect(row);
    }

    return (

        <DataTable columns={windowWidth > 576 ? FUNDS_COLUMN : FUND_TABLE_MOBILE}
            data={fundGoals}
            pagination={false}
            customStyles={windowWidth > 576 ? CUSTOM_STYLES : CUSTOM_STYLES_MOBILE}
            onRowMouseEnter={_handleRowHover}
            onRowMouseLeave={_handleRowRemoveHover}
            defaultSortAsc={false}
            rowClick={(row) => _handleCardClick(row)}
            defaultSortFieldId={location.state && location.state.filter_by === 'popular_funds' ? -1 : 2} />
    )
}