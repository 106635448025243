
/*
 *   File : annual-growth-filter.js
 *   Author : https://evoqins.com
 *   Description : Popup for CAGR filter
 *   Integrations : null
 *   Version : 1.0.0
*/

import { useEffect, useState } from "react";
import Icon from "../Icon/icon";

const CAGRPopup = (props) => {
    const [selectedId, setSelectedId] = useState(props.selectedCAGR.filter_id);


    const _handleBannerDropDownSelect = (id) => {
        props.handleSelect(id);
    }

    return (
        <>
            <div className="bg-cards gap-12px position-absolute border-radius-12px border-width-2px border-solid border-gainsboro e-banner-dropdown z-index-10">
                {
                    props.filterData.map((item, key) => {
                        return (
                            <div key={key}
                                className={`d-flex align-items-center gap-10px  ${key !== 0 && 'mt-2'}`}
                                onClick={() => _handleBannerDropDownSelect(item)}>
                                <Icon icon={selectedId === item.filter_id ? "radio-selected" : "radio-unselected"}
                                    size={20} />
                                <p className="color-black e-poppins-regular e-font-16 mb-0">{item.label}</p>
                            </div>
                        )
                    })
                }

            </div>
            <div className="position-fixed w-100 z-index-8 h-100 left-0 top-0 cursor-default"
                onClick={() => props.closePopUp()}></div>
        </>
    )
};

export default CAGRPopup;