
/*
 *   File : swp-funds.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for swp transaction
 *   Integrations : null
 *   Version : v1.1,
 */

import { useEffect, useRef, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import { Tooltip } from "react-tooltip";

import Icon from "../Icon/icon";
import { CustomAmountInput, OTPInput, CustomTextInput, RadioGroup } from "../Form-elements";
import SIPDateModal from "./sip-date";
import { GradientButton } from "../CTA";

import { _getDayOfMonth, useClickOutside } from "../../Helper";
import { _consent2FA } from "../../Helper/api";

import APIService from "../../Service/api-service";

import style from "../../Styles/Component/invest-goal.module.scss"
import Color from "../../Styles/color.module.scss";
import { DarkModeContext } from "../../Context/dark-mode-context";

const SIP_DATES = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28"];

const FIRST_INSTALLMENT = [
    { id: 1, label: 'Yes' },
    { id: 2, label: 'No' },
];

export default function SWPFundModal(props) {

    const PROFILE_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const { darkMode } = useContext(DarkModeContext);
    const modalRef = useRef(null);
    const FolioRef = useRef();
    const [step, setStep] = useState(1);
    const [amount, setAmount] = useState("");
    const [amountError, setAmountError] = useState("");
    const [date, setDate] = useState("");
    const [pinString, setPinString] = useState("");
    const [pinStringError, setPinStringError] = useState("");
    const [enabledSIPDates, setEnabledSIPDates] = useState(SIP_DATES);
    const [apiLoader, setApiLoader] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [selectedFolio, setSelectedFolio] = useState([]);
    const [folioError, setFolioError] = useState("");
    const [showFolioDropDown, setShowFolioDropDown] = useState(false);
    const [tax, setTax] = useState(0);
    const [folioData, setFolioData] = useState([]);
    const [installments, setInstallments] = useState('');
    const [installmentsError, setInstallmentsError] = useState("");
    const [selectedBank, setSelectedBank] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const [swpMonthString, setSwpMonthString] = useState("");
    const [firstInvestment, setFirstInvestment] = useState(1);
    const [showBackButton, setShowBackButton] = useState(false);
    const [mfData, setMfData] = useState(props.data);
    const [frequencyList, setFrequencyList] = useState([]);
    const [selectedFrequency, setSelectedFrequency] = useState("");

    useEffect(() => {

        const default_bank_index = props.data.banks.findIndex(bank => bank.is_default === true);
        if (default_bank_index != -1) {
            let default_bank = props.data.banks[default_bank_index];
            setSelectedBank(default_bank);
        }

        setTax(props.data.tax);
        setFolioData(props.data.folios);
        if (props.data?.frequency_list) {
            setFrequencyList(props.data.frequency_list);
            if (props.data.frequency_list.length > 0) {
                setSelectedFrequency(props.data.frequency_list[0]);
            }
    
        }
        // auto select only folio from the list if there is only one item in the list 
        if (props.data.folios.length > 0) {
            setSelectedFolio(props.data.folios[0]);

        }
    }, [props.data]);


    useEffect(() => {
        const { date, month } = _getDayOfMonth(props.data.swp_dates);
        setSwpMonthString(month);
        setDate(`${date}`);
        var my_modal = document.getElementById("swp-fund");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setStep(1);
            setPinString("");
        });
    }, []);

    useEffect(() => {
        if (step === 2) {
            const countdown = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    clearInterval(countdown);
                }
            }, 1000);

            return () => {
                clearInterval(countdown);
            };
        } else {
            setSeconds(30);
        }
    }, [seconds, step]);

    useClickOutside(FolioRef, () => {
        setShowFolioDropDown(false);
    });

    // handle amount input change
    function _handleInputChange(value) {
        setAmount(value);
        setAmountError("");
    }

    // date selected form SWP dates. It will be always between 1-28.
    function _handleDateSelected(date) {
        setDate(date.toString());
    }

    // navigate to landing
    function _navigateToLanding(path) {
        window.open(path, "_blank", "noreferrer");
    }

    function _closeModal() {
        setApiLoader(false);
    }

    // handle form validation 
    function _validateForm() {
        let valid = true;
        if (amount === '') {
            setAmountError('Amount is required');
            valid = false;
        }
        if (installments === '') {
            setInstallmentsError('Installment is required');
            valid = false;
        }
        // if ((props.data.mf_data.nav.current_nav * selectedFolio.units) < (amount * installments)) {
        //     setInstallmentsError(`Total amount should not exceed available folio amount`);
        //     valid = false;
        // }
        if (valid === true) {
            _confirmSWP();
            // setStep(2);
        }
    }

    const _handleFolioSelect = (data) => {
        setShowFolioDropDown(false);
        setSelectedFolio(data);
        _getRedeemData(data, selectedFrequency);
    }

    const _handleFolioClick = () => {
        setShowFolioDropDown(!showFolioDropDown);
    }

    // handle OTP input 
    function _handlePin(value) {
        setPinString(value);
        setPinStringError("");
    }

    function _handleShowMessage() {
        setShowMessage(true);
    }

    function _handleHideMessage() {
        setShowMessage(false);
    }

    function _verifyPin() {

    }

    function _handleResendOTP() {

        _consent2FA("MF")
            .then((response) => {
                toast.dismiss();
                toast.success("An OTP has been sent to your registered phone number", {
                    type: "success",
                });
                setSeconds(30);
                setPinString("");
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error"
                });
            });
    }

    function _handleInstallment(value) {
        setInstallments(value);
        setInstallmentsError("");
    }

    function _scrollBodyToTop() {
        let footer = document.getElementById('modal-body-footer');
        footer.scrollIntoView({ behavior: 'smooth' });
    }

    const _handleRadioInput = (instalment_id) => {
        setFirstInvestment(instalment_id);
    }

    const _handleFrequency = (value) => {
        setSelectedFrequency(value);
        _getRedeemData(selectedFolio, value);
    }

    function _handleGoback() {
        setStep(1);
        setPinString("");
        setPinStringError("");
    }

    // API call confirm swp
    function _confirmSWP() {
        setButtonLoader(true);
        let url = 'mf/confirm-swp';
        let payload = JSON.stringify({
            mf_id: props.data.mf_data.id,
            amount,
            sip_date: date,
            bank_id: selectedBank.bank_id,
            folio_number: selectedFolio.folio_no,
            generate_today: firstInvestment === 1 ? true : false,
            installments,
            sip_frequency: selectedFrequency
        })
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("OTP sent successfully", {
                    type: "success",
                });
                setStep(2);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setButtonLoader(false);
        })
    }

    function _verifySWP() {
        if (pinString === "") {
            setPinStringError("OTP is required");
            return;
        }
        setButtonLoader(true);
        let url = 'mf/swp';
        let payload = JSON.stringify({
            mf_id: props.data.mf_data.id,
            amount,
            sip_date: date,
            installments,
            bank_id: selectedBank.bank_id,
            folio_number: selectedFolio.folio_no,
            generate_today: firstInvestment === 1 ? true : false,
            otp: pinString,
            sip_frequency: selectedFrequency
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.redeemStatus(1);
            } else {
                setShowBackButton(true);
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setButtonLoader(false);
        })
    }

    const _getRedeemData = (folio, frequency) => {
        let url = 'mf/redeem-data';
        let payload = JSON.stringify({
            id: props.data.mf_data.id,
            units: folio.units,
            sip_date: date,
            transaction_type: 5,
            folio: folio.folio_no,
            frequency
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setMfData(response.data);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        });
    }

    return (
        <div className={`modal fade  ${style.e_invest_modal} ${style.mt_transaction_modal}`}
            id="swp-fund"
            tabIndex="-1"
            aria-labelledby="swp-fund"
            aria-hidden="true"
            data-bs-keyboard="false"
            data-bs-backdrop="static" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_invest_dialog}`}>
                <div className={`modal-content p-4 ${style.e_invest_content} `}>
                    {
                        showBackButton === true && step === 2 &&
                        <div className="col-12 position-relative">
                            <Icon icon="arrow-right1"
                                size={24}
                                color={Color.black}
                                className={`position-absolute cursor-pointer top--4 left--7 ${style.e_back}`}
                                onClick={_handleGoback} />
                        </div>
                    }
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                        onClick={_closeModal} />
                    <span id="close-modal" data-bs-dismiss="modal" className='d-none'></span>
                    {
                        step === 1 ? (
                            <>
                                <p className="color-eerie-black e-poppins-medium e-font-16 line-height-16px margin-12px-bottom">
                                    SWP details
                                </p>

                                {/* Modal body */}
                                <div className={`${style.e_modal_body} ${style.e_swp_modal_body}  `} >
                                    <div className="border-radius-16px bg-light-sky-blue pt-3  px-4 ">
                                        <div className="d-flex flex-wrap justify-content-between border-bottom-1px border-shade-light-blue pb-3 mb-3 gap-32px">
                                            <div className="d-flex gap-16px">
                                                <div className="p-2 bg-white border-all border-bright-gray border-radius-4px h-50px">
                                                    <img src={props.data.mf_data.image}
                                                        alt={props.data.mf_data.name}
                                                        width={40}
                                                        height={40}
                                                        className="object-fit-contain" />
                                                </div>
                                                <div>
                                                    <p className="color-black e-poppins-medium e-font-14 line-height-20px mb-2">
                                                        {props.data.mf_data.name}
                                                    </p>
                                                    <p className="color-black e-poppins-black e-font-10 line-height-19px mb-0 border-all border-bright-gray py-1 padding-6px-lr border-radius-16px w-fit-content">
                                                        {props.data.mf_data.class_name}
                                                    </p>
                                                    {
                                                        PROFILE_DATA.demat === true ?
                                                            <span className="border-radius-16px color-polished-pine e-poppins-medium e-font-10 line-height-18px padding-6px-lr py-1 border-polished-pine border-all bg-pale-turquoise">Demat</span>
                                                            :
                                                            null
                                                    }

                                                </div>

                                            </div>

                                            <div className="">
                                                <p className="color-black e-poppins-semi-bold e-font-14 line-height-26px mb-0">
                                                    <span className="e-inter-semi-bold">₹</span>{props.data.mf_data.nav.current_nav.toLocaleString("en-IN")} <span className=
                                                        {`e-font-10 ${props.data.mf_data.nav.nav_change < 0 ? "color-red" : "color-sea-green"}`}>
                                                        ({props.data.mf_data.nav.nav_change < 0 ? `${props.data.mf_data.nav.nav_change_percentage}` : `${props.data.mf_data.nav.nav_change_percentage}`})
                                                    </span>
                                                </p>
                                                <p className="color-gray e-poppins-regular e-font-10 line-height-12px mb-0 text text-end">as on {props.data.mf_data.nav.nav_date}</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 d-flex flex-wrap justify-content-between">
                                                <p className="color-icon-black e-font-16 e-poppins-regular ">Available amount:
                                                    {
                                                        selectedFolio.length !== 0 ?
                                                            <>
                                                                <span className="color-icon-black e-font-16 e-inter-semi-bold ms-2">
                                                                    ₹ {Math.floor(props.data.mf_data.nav.current_nav * selectedFolio.units)}
                                                                </span>
                                                            </>
                                                            :
                                                            '-'
                                                    }

                                                </p>
                                                <p className="color-icon-black e-font-16 e-poppins-regular m">Available units:
                                                    {
                                                        selectedFolio.length !== 0 ?
                                                            <>
                                                                <span className="color-icon-black e-font-16 e-inter-semi-bold ms-1">{selectedFolio.units.toFixed(3)}</span>
                                                            </>
                                                            :
                                                            '-'
                                                    }

                                                </p>
                                            </div>
                                            <div className="col-12">
                                                <p className="mb-3 mt-2">
                                                    <span className="color-icon-black e-font-16 e-poppins-regular ">Exit load:</span>
                                                    <span className="color-icon-black e-font-16 e-inter-semi-bold ms-2"> ₹ {mfData.mf_data.exit_load_amount.toLocaleString('en-IN')}</span>
                                                </p>
                                            </div>

                                            {/* <div className="col-6">
                                                <p className="color-icon-black e-font-16 e-poppins-regular mb-0">Redemption time: <span className="color-icon-black e-font-16 e-inter-semi-bold">{props.data.redemption_time}</span></p>
                                            </div> */}
                                            {/* <div className="col-6 mt-2">
                                                <p className="color-icon-black e-font-16 e-poppins-regular mb-3">Min Redemption out amount:  <span className="color-icon-black e-font-16 e-inter-semi-bold">{props.data.mf_data.swp_minimum_installment_amount !== null ? '₹' + props.data.mf_data.swp_minimum_installment_amount.toLocaleString("en-IN") : '-'}</span></p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="row mt-2 ">
                                        <div className="col-12 ">
                                            {
                                                folioData.length !== 0 &&
                                                <div className="position-relative margin-38px-bottom margin-26px-top mb-4">

                                                    <div ref={FolioRef}>
                                                        <div className={`py-3 padding-22px-lr cursor-pointer border-radius-12px border-all d-flex align-items-center position-relative 
                                                        ${selectedFolio.length !== 0 ? "justify-content-between" : "justify-content-end"}
                                                        ${folioError.length !== 0 ? "border-red" : "border-columbia-blue"}`}
                                                            // data-disabled={withdrawAll || PROFILE_DATA.demat}
                                                            onClick={_handleFolioClick}>

                                                            <p className={`e-poppins-regular e-font-16px line-height-16px mb-0 bg-modal z-index-1 position-absolute
                                                            ${selectedFolio.length !== 0 ? 'e_transition_top' : 'e_transition_center'}
                                                            ${folioError ? "color-red" : "color-black"}`}>
                                                                Select folio<span className="color-red">*</span>
                                                            </p>
                                                            {
                                                                selectedFolio.length !== 0 &&
                                                                <div className="d-flex justify-content-between w-100">
                                                                    <p className="color-black e-poppins-semi-bold e-font-16 line-height-26px mb-0 w-max-content">{selectedFolio.folio_no}</p>
                                                                    <p className="color-outer-space e-poppins-regular e-font-12 line-height-26px mb-0">
                                                                        Available units: <span className="color-black e-poppins-medium">{selectedFolio.units}</span>
                                                                    </p>
                                                                </div>
                                                            }
                                                            <div className="d-flex align-items-center">
                                                                <Icon icon="calender-arrow-right"
                                                                    size={24}
                                                                    color={darkMode === true && Color.white}
                                                                    className={showFolioDropDown === true ? 'e-arrow-up' : 'e-arrow-down'} />
                                                            </div>
                                                        </div>
                                                        {
                                                            folioError.length !== 0 &&
                                                            <div className='position-absolute d-flex align-items-center mt-1 '>
                                                                <Icon icon="info-circle"
                                                                    size={16}
                                                                    className="me-1" />
                                                                <p className='color-red margin-block-end-0 e-font-12 '>
                                                                    {folioError}
                                                                </p>
                                                            </div>
                                                        }
                                                        {
                                                            showFolioDropDown === true &&
                                                            <div className={`border-radius-8px border-all border-bright-gray bg-modal position-absolute w-100 z-index-1 mt-2 ${style.e_folio_dropdown}`}>
                                                                {
                                                                    folioData.map((item, key) => {
                                                                        return (
                                                                            <div key={key}
                                                                                className={`d-flex justify-content-between align-items-center py-3 ps-2 pe-4 ${key !== folioData.length - 1 && "border-bottom-1px border-bright-gray"} cursor-pointer`}
                                                                                onClick={() => _handleFolioSelect(item)}>
                                                                                <div className="d-flex align-items-center gap-8px">
                                                                                    <Icon icon={selectedFolio.folio_no === item.folio_no ? "radio-selected" : "radio-unselected"}
                                                                                        size={16} />
                                                                                    <p className="color-outer-space e-poppins-medium e-font-12 line-height-10px mb-0">
                                                                                        {item.folio_no}
                                                                                    </p>
                                                                                </div>

                                                                                <div>
                                                                                    <p className="color-outer-space e-poppins-regular e-font-12 line-height-10px mb-0">
                                                                                        Available units: <span className="color-black e-poppins-medium">{item.units}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className="col-12 mb-4">
                                            <div className="d-flex align-items-center gap-8px">
                                                <p className="color-black e-poppins-medium e-font-14 mb-0">Generate first installment now</p>
                                                <RadioGroup data={FIRST_INSTALLMENT}
                                                    radioItemClassName="py-2"
                                                    size={14}
                                                    fontSize="e-font-12"
                                                    type={2}
                                                    id={firstInvestment}
                                                    radioHandler={_handleRadioInput} />
                                            </div>
                                        </div>

                                        <div className="col-sm-6 position-relative">
                                            <CustomAmountInput value={amount}
                                                key={"SWP amount"}
                                                label="SWP Amount"
                                                postfix="*"
                                                error={amountError}
                                                inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left padding-16px-tb border-radius-12px"
                                                labelClass="bg-modal e-font-16 line-height-26px"
                                                description={`Min Redemption out amount: ${props.data.mf_data.swp_minimum_installment_amount ? props.data.mf_data.swp_minimum_installment_amount : '0'}, Min SWP Units: ${props.data.mf_data.min_units ? props.data.mf_data.min_units : '0'}`}
                                                handleChange={_handleInputChange} />
                                        </div>
                                        <div className='col-sm-6 mt-sm-0 mt-5 pt-sm-0 pt-1'
                                            onClick={_scrollBodyToTop}>
                                            <SIPDateModal modalRef={modalRef}
                                                labelClass="e-font-16 line-height-26px"
                                                label="SWP date"
                                                date={date}
                                                className="cursor-pointer"
                                                inputClass="cursor-pointer"
                                                type={1}
                                                monthString={swpMonthString}
                                                dateText="Start from"
                                                enabledDates={props.data.swp_dates}
                                                dateSelected={_handleDateSelected} />
                                        </div>
                                    </div>
                                    {
                                        frequencyList.length !== 0 ?
                                            <div className="col-12 gap-8px mt-4 pt-4">
                                                <p className="color-black e-poppins-medium e-font-14 mb-3">Set frequency</p>
                                                <RadioGroup data={frequencyList}
                                                    radioItemClassName="py-2"
                                                    size={14}
                                                    fontSize="e-font-12"
                                                    type={4}
                                                    id={selectedFrequency}
                                                    radioHandler={_handleFrequency} />
                                            </div>
                                            :
                                            null
                                    }
                                    <div className="row mt-2">
                                        <div className="col-12  mt-4" id="modal-body-footer">
                                            <div className="position-relative">
                                                <CustomTextInput label="No. of installment"
                                                    postfix="*"
                                                    type="number"
                                                    maxLength={3}
                                                    value={installments}
                                                    className="margin-32px-bottom border-radius-16px"
                                                    inputClass="padding-16px-tb"
                                                    error={installmentsError}
                                                    description={`Minimum no of installments: ${props.data.mf_data.swp_minimum_installment_numbers ? props.data.mf_data.swp_minimum_installment_numbers : '0'}`}
                                                    handleChange={_handleInstallment} />
                                                <span className="color-steel-gray e-poppins-regular e-font-10 position-absolute right-10px top-0 bottom-0 margin-auto h-fit-content">Installments</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row pt-2">
                                    {
                                        step === 1 &&
                                        <>
                                            <div className="col-12 mt-1 mb-3">
                                                <p className="color-outer-space e-poppins-regular e-font-10 mb-1">a) Lock-in & exit load will be applicable as per scheme details.</p>
                                                <p className="color-outer-space e-poppins-regular e-font-10 mb-0">b) The current amount shown may vary as per NAV applicable to the successful processing of transaction.</p>
                                            </div>
                                            <div className="col-12 text-center  pb-2">
                                                <p className="color-outer-space e-poppins-regular e-font-10 mb-0">By continuing, I agree with the <span className="color-primary-color cursor-pointer" onClick={() => _navigateToLanding('https://www.mastertrust.co.in/disclaimer')}>Disclaimers</span> and <span className="color-primary-color cursor-pointer" onClick={() => _navigateToLanding(`${process.env.REACT_APP_LANDING_PAGE_URL}/terms`)}>T&C</span> of Mastertrust</p>
                                            </div>
                                        </>

                                    }
                                    <div className="col-lg-8 col-md-8 col-sm-8 col-12 offset-lg-2 offset-md-2 offset-sm-2">
                                        <GradientButton label={firstInvestment === 1 ? "Start SWP" : "Schedule SWP"}
                                            className=" e-font-16 padding-12px-tb w-100"
                                            loading={buttonLoader}
                                            handleClick={_validateForm} />
                                        <p className="color-black e-poppins-regular e-font-14 line-height-14px mb-0 d-flex justify-content-center align-items-center mt-2 position-relative">
                                            <div>OTP will be sent on registered mobile/email</div>
                                            {
                                                <div className={`ms-1 cursor-pointer  align-items-center w-max-content ${style.e_mail_icon}`}
                                                    onMouseEnter={_handleShowMessage}
                                                    onMouseLeave={_handleHideMessage}>
                                                    <Icon icon="info"
                                                        size={16}
                                                        data-tooltip-id="email-tooltip" />
                                                    <Tooltip id="email-tooltip"
                                                        arrowColor='transparent'
                                                        effect="float"
                                                        place="bottom"
                                                        className='e-tooltip-general'>
                                                        The OTP will be sent on mobile/email registered in the folio in case of additional purchase.
                                                    </Tooltip>
                                                </div>
                                            }

                                        </p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className={`${style.e_modal_body} ${style.e_swp_modal_body}  my-3`} >
                                <p className="color-eerie-black e-poppins-medium e-font-16 line-height-16px margin-12px-bottom">

                                </p>

                                <div className=" mt-2">
                                    <div className="row text-center">
                                        <div className="col-12">
                                            <img src={require("../../Assets/Images/explore/mt-pin-auth.png")}
                                                alt="Confirm payment"
                                                width={72}
                                                height={72} />
                                            <>
                                                <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">Enter OTP you have received in registered email and phone number</p>

                                                <p className="color-jett-black e-poppins-regular e-font-16 mb-4">You are one step away from making a withdrawal</p>

                                                <OTPInput pinString={pinString}
                                                    id="invest"
                                                    autoFocus={true}
                                                    pinError={pinStringError}
                                                    disabled={apiLoader}
                                                    handleChange={_handlePin} />
                                            </>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-8 col-12 offset-lg-2 offset-md-2 offset-sm-2  mt-4">
                                            <GradientButton label="Verify & Confirm SWP"
                                                className=" e-font-16 padding-12px-tb w-100"
                                                loading={buttonLoader}
                                                handleClick={_verifySWP} />
                                            <div className="d-flex justify-content-center mt-4">
                                                {
                                                    seconds === 0 ?
                                                        <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 e-forgot-pin position-relative w-max-content'
                                                            onClick={_handleResendOTP}>Resend OTP</p>
                                                        :
                                                        <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 '>
                                                            Resend OTP in <span className="color-black">{seconds}s</span>
                                                        </p>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
