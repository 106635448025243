
/*
 *   File : payment.js
 *   Author URI : www.evoqins.com
 *   Description : Page to show all available and pending orders created by Super Admin.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 12-01-2024
 */

import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';

import Icon from "../../Component/Icon/icon";
import { GradientButton } from "../../Component/CTA";
import { CustomLoader } from "../../Component/Other";
import { PendingPayments } from "../../Component/Modal";
import Footer from "../../Component/Footer/footer";
import { DarkModeContext } from "../../Context/dark-mode-context";

import OrderAPIService from "../../Service/order-api";


export default function Payment(props) {

    const navigate = useNavigate();
    const { darkMode } = useContext(DarkModeContext);
    const [apiLoader, setApiLoader] = useState(true);
    const [paymentData, setPaymentData] = useState({});
    const [selectedFund, setSelectedFund] = useState(null);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [showInvalidUserScreen, setShowInvalidUserScreen] = useState(false);
    const [invalidScreenDesc, setInvalidScreenDesc] = useState('');
    const [invalidScreenTitle, setInvalidScreenTitle] = useState('');
    const [isRedeem, setIsRedeem] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        _getPendingPayment();
    }, []);

    // open payment modal
    useEffect(() => {
        if (openPaymentModal === true) {
            const { Modal } = require("bootstrap");
            const modal = new Modal(document.getElementById("payment-modal"), {});
            modal.show();
        }
    }, [openPaymentModal]);

    // call back modal close
    function _unMountModal() {
        setOpenPaymentModal(false);
        setIsRedeem(false);
    }

    // call back payment success modal 
    function _paymentSuccessCallBack() {
        _getPendingPayment();
        setIsRedeem(false);
        setOpenPaymentModal(false);
    }

    // call back after payment modal payment api gives 401
    function _handleInvalidUser() {
        setShowInvalidUserScreen(true);
        setInvalidScreenTitle('Transaction failed');
        setInvalidScreenDesc('You are not able complete the transaction at the moment');
        setTimeout(() => {
            _redirectUser();
        }, 3000);
    }

    // redirect user based on login status
    function _redirectUser() {
        let login_status = Cookies.get('refreshToken');
        if (login_status) {
            window.location.href = '/';
        } else {
            window.location.href = process.env.REACT_APP_LANDING_PAGE_URL;
        }
    }

    // API - get pending data
    async function _getPendingPayment() {
        let url = 'payment/pending-orders';
        OrderAPIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setPaymentData(response.data);
                if (Object.keys(response.data).length === 0) {
                    setShowInvalidUserScreen(true);
                    setInvalidScreenTitle('No data found');
                    setInvalidScreenDesc('No pending payments found at the moment');
                    setTimeout(() => {
                        _redirectUser();
                    }, 3000);
                }
            } else {
                if (response.status_code === 401) {
                    setShowInvalidUserScreen(true);
                    setInvalidScreenTitle('Unable to load data');
                    setInvalidScreenDesc('Cannot load your pending payments at the moment');
                    setTimeout(() => {
                        _redirectUser();
                    }, 3000);
                }
            }
            setApiLoader(false);
        });
    }

    // API - request for OTP
    function _getOTP(type, data) {
        let url = '2FA/get-otp';
        let payload = JSON.stringify({
            type: 'ORDER',
            order_ids: [data.order_id]
        });
        OrderAPIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (type === 5) {
                    setIsRedeem(true);
                }
                setSelectedFund(data);
                setOpenPaymentModal(true);
                toast.success('OTP send to your registered mobile number', {
                    type: 'success'
                });
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
        });
    }


    // inline component to show label value pair 
    function LabelValuePair({ label, value, post_fix, amount }) {
        return (
            <p className={"color-outer-space e-poppins-medium e-font-14"}>
                <label className="color-gray me-2">{label}: </label>
                {
                    amount === true ?
                        <span className="e-inter-medium">{`₹${value.toLocaleString('en-IN')}`}</span>
                        :
                        value
                }
                {
                    post_fix && (
                        <span className="color-outer-space e-poppins-regular e-font-12">({post_fix})</span>
                    )
                }
            </p>
        )
    }

    const Description = (props) => {
        return (
            <>
                {
                    props.status === true ?
                        <div className="d-flex">
                            <span className="color-gray e-poppins-medium e-font-14 line-height-24px">{props.title}&nbsp;</span>
                            <span className={`e-poppins-medium e-font-14 line-height-24px ${props.description === 'Failed' ? 'color-red' : props.description === 'Success' ? 'color-sea-green' : props.description === 'Payment pending' ? 'color-sunset-blaze' : 'color-yellow-orange'}`}>
                                {props.description}
                                {
                                    props.description === 'Failed' ?
                                        <img src={require("../../Assets/Images/orders/failed.png")} alt="Failed"
                                            width={24}
                                            height={24}
                                            draggable={false}
                                            className="ms-1" />

                                        : props.description === 'Success' ?
                                            <img src={require("../../Assets/Images/orders/success.png")} alt="Success"
                                                width={24}
                                                height={24}
                                                draggable={false}
                                                className="ms-1" />
                                            : props.description === 'Payment pending' ?
                                                <img src={require("../../Assets/Images/orders/payment-pending.svg").default} alt="Processing"
                                                    width={24}
                                                    height={24}
                                                    draggable={false}
                                                    className="ms-1" />
                                                :
                                                <img src={require("../../Assets/Images/orders/processing.png")} alt="Processing"
                                                    width={24}
                                                    height={24}
                                                    draggable={false}
                                                    className="ms-1" />

                                }
                            </span>
                        </div>
                        :
                        <div className="d-flex">
                            <span className="color-gray e-poppins-medium e-font-14 line-height-24px">{props.title}&nbsp;</span>
                            <span className={`e-poppins-medium e-font-14 line-height-24px color-outer-space`}>
                                {props.symbol === true && <span className="e-inter-medium">₹</span>}
                                {props.description}
                            </span>
                        </div>
                }
            </>

        )
    }

    // Inline card to show lumpsum | sip order detail
    function LumpsumOrSIPOrder({ data, type }) {
        return (
            <div className="border-bright-gray border-all border-radius-16px p-4">

                <div className="row border-bottom gx-0 pb-3">

                    {/* Fund detail seciton */}
                    <div className="col-lg-8 col-sm-9 col-12 d-flex">
                        <div className="bg-white border-bright-gray border-all border-radius-8px  h-max-content padding-6px-all me-3">
                            <img src={data.image}
                                alt={data.name}
                                width={52}
                                height={52}
                                className="object-fit-contain" />
                        </div>
                        <div className="d-flex flex-wrap">
                            <div className="me-3  mb-md-0 mb-3">
                                <h4 className="color-black e-poppins-medium e-font-16">{data.name}</h4>

                                <span className="border-bright-gray border-all border-radius-16px padding-4px-tb padding-6px-lr color-black e-inter-medium e-font-10">
                                    {data.class_name}
                                </span>
                            </div>
                            <LabelValuePair label="NAV"
                                value={data.fund_data[0].nav.current_nav}
                                post_fix={data.fund_data[0].nav.nav_date}
                                amount={true} />
                        </div>
                    </div>

                    {/* Total amount */}
                    <div className="col-lg-4 col-sm-3 col-12 text-sm-end">
                        <p className="color-light-black e-inter-medium e-font-24-sm-18">
                            {`₹${data.order_amount.toLocaleString('en-IN')}`}
                        </p>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-lg-6 col-md-6 col-12">
                        <LabelValuePair label="Order Id"
                            value={data.order_id} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <LabelValuePair label="Order type"
                            value={data.order_type} />
                    </div>

                    <div className="col-4 col-md-6 col-12">
                        <LabelValuePair label="Created on"
                            value={data.created_on} />
                    </div>
                    <div className="col-8 col-md-6 col-12">
                        <LabelValuePair label="Created by"
                            value={data.created_by}
                            post_fix={data.admin_type} />
                    </div>

                    <div className="col-8 col-md-6 col-12">
                        <LabelValuePair label="Approx. units"
                            value={data.fund_data[0].unit} />
                    </div>
                    {
                        type === 2 && ( // for sip
                            <>
                                <div className="col-4 col-md-6 col-12">
                                    <LabelValuePair label="First purchase"
                                        value={data.generate_today === true ? 'Yes' : 'No'} />
                                </div>
                                <div className="col-8 col-md-6 col-12">
                                    <LabelValuePair label="SIP date"
                                        value={data.sip_date} />
                                </div>

                                <div className="col-4 col-md-6 col-12">
                                    <LabelValuePair label="Mandate Id"
                                        value={data.mandate_id} />
                                </div>
                                <div className="col-8 col-md-6 col-12">
                                    <LabelValuePair label="Mandate bank"
                                        value={data.mandate_bank} />
                                </div>
                            </>
                        )
                    }
                </div>

                <div className="row">

                    <div className="col-4 col-md-6 col-12 mt-2">
                        <GradientButton label={data.generate_today === false ? "Verify & Schedule SIP" : "Verify & Make payment"}
                            className="padding-14px-all"
                            handleClick={() => _getOTP(type, data)} />
                    </div>
                </div>
            </div>
        )
    };

    // Inline card to show goal order detail
    function GoalOrderDetail({ data }) {
        return (
            <div className="border-bright-gray border-all border-radius-16px p-4">

                <div className="row border-bottom gx-0 pb-3">

                    {/* Fund detail seciton */}
                    <div className="col-lg-6 d-flex">
                        <div className="bg-white border-bright-gray border-all border-radius-8px w-52px h-52px padding-6px-all me-3">
                            <img src={data.image}
                                alt={data.name}
                                width={52}
                                height={52}
                                className="object-fit-contain" />
                        </div>
                        <div >
                            <h4 className="color-black e-poppins-medium e-font-16">{data.name}</h4>
                            <span className="border-bright-gray border-all border-radius-16px padding-4px-tb padding-6px-lr color-black e-inter-medium e-font-10">
                                {data.class_name}
                            </span>
                        </div>
                    </div>

                    {/* Total amount */}
                    <div className="col-lg-6 text-lg-end">
                        <p className="color-light-black e-inter-medium e-font-24">
                            {`₹${data.order_amount.toLocaleString('en-IN')}`}
                        </p>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-4 col-md-6 col-12">
                        <LabelValuePair label="Order Id"
                            value={data.order_id} />
                    </div>
                    <div className="col-8 col-md-6 col-12">
                        <LabelValuePair label="Order type"
                            value={data.order_type} />
                    </div>

                    <div className="col-4 col-md-6 col-12">
                        <LabelValuePair label="Created on"
                            value={data.created_on} />
                    </div>
                    <div className="col-8 col-md-6 col-12">
                        <LabelValuePair label="Created by"
                            value={data.created_by}
                            post_fix={data.admin_type} />
                    </div>

                    <div className="col-4 col-md-6 col-12">
                        <LabelValuePair label="Approx. units"
                            value={data.approx_units} />
                    </div>
                    <div className="col-8 col-md-6 col-12">
                        <LabelValuePair label="Risk type"
                            value={data.risk_type} />
                    </div>
                </div>

                {/* table */}
                <div className="e-cart-table">
                    <table className="border-none bg-transparent mt-2">
                        <thead>
                            <tr>
                                <td className="color-light-black e-poppins-medium e-font-sm-16-14 pb-3" width="40%">Fund name</td>
                                <td className="color-light-black e-poppins-medium e-font-sm-16-14 pb-3">Allocation</td>
                                <td className="color-light-black e-poppins-medium e-font-sm-16-14 pb-3">Amount</td>
                                <td className="color-light-black e-poppins-medium e-font-sm-16-14 pb-3">Units</td>
                                <td className="color-light-black e-poppins-medium e-font-sm-16-14 pb-3">NAV</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.fund_data.map((item, key) => {
                                    return (
                                        <tr className="color-light-black e-font-16 e-poppins-regular line-height-160 align-baseline"
                                            key={key}>
                                            <td className="pe-5 d-flex pb-3 color-outer-space">
                                                <div>{key + 1}.</div>
                                                <div className="ps-1 e-font-sm-16-14">{item.fund_name}</div>
                                            </td>
                                            <td className="e-font-sm-16-14">
                                                {`${item.allocation} %`}
                                            </td>
                                            <td className="e-inter-regular e-font-sm-16-14">
                                                {`₹${item.amount.toLocaleString('en-IN')}`}
                                            </td>
                                            <td className="e-font-sm-16-14">
                                                {item.unit}
                                            </td>
                                            <td className="e-inter-regular e-font-sm-16-14">
                                                {`₹${item.nav.current_nav.toLocaleString('en-IN')}`}
                                                <span className="e-font-12">({item.nav.nav_date})</span>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col-4 col-md-6 col-12 mt-2">
                        <GradientButton label={data.generate_today === true || data.generate_today === '-' ? "Verify & Make payment" : "Verify & Schedule"}
                            className="padding-14px-all"
                            handleClick={() => _getOTP(3, data)} />
                    </div>
                </div>
            </div>
        )
    };

    // Inline card to show cart order detail
    function CartOrderDetail({ data }) {
        return (
            <div className="border-bright-gray border-all border-radius-16px p-4">

                <div className="row ">

                    {/* Fund detail seciton */}
                    <div className="col-lg-6 col-md-4 d-flex">
                        <img src={require('../../Assets/Images/Cart Large Minimalistic.png')}
                            alt="cart"
                            width={24}
                            height={24}
                            className="me-2" />
                        <div >
                            <h4 className="color-black e-poppins-medium e-font-20">Cart</h4>
                        </div>
                    </div>

                    {/* Total amount */}
                    <div className="col-lg-6 col-md-8 text-md-end">
                        <p className="color-light-black e-inter-medium e-font-24">
                            <span className="color-gray e-poppins-medium e-font-14">Total cart value: </span>
                            {`₹${data.order_amount.toLocaleString('en-IN')}`}
                        </p>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-4 col-md-6 col-12">
                        <LabelValuePair label="Order Id"
                            value={data.order_ids[0]} />
                    </div>
                    <div className="col-8 col-md-6 col-12">
                        <LabelValuePair label="Created on"
                            value={data.created_on} />
                    </div>

                    <div className="col-4 col-md-6 col-12">
                        <LabelValuePair label="Created by"
                            value={data.created_by}
                            post_fix={data.admin_type} />
                    </div>
                </div>

                {/* table */}
                <div className="e-cart-table">
                    <table className="border-none bg-transparent mt-2 ">
                        <thead>
                            <tr>
                                <td className="color-light-black e-poppins-medium e-font-sm-16-14 pb-3" width="60%">Fund name</td>
                                <td className="color-light-black e-poppins-medium e-font-sm-16-14 pb-3" width="10%">units</td>
                                <td className="color-light-black e-poppins-medium e-font-sm-16-14 pb-3" width="10%">Amount</td>
                                <td className="color-light-black e-poppins-medium e-font-sm-16-14 pb-3" width="15%">NAV</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.fund_data.map((item, key) => {
                                    return (
                                        <tr className="align-top line-height-160 " key={key}>
                                            <td className="pe-5 d-flex pb-3 mb-2">
                                                <div className="bg-white border-bright-gray border-all border-radius-8px w-52px h-52px padding-6px-all me-3">
                                                    <img src={item.image}
                                                        alt={item.fund_name}
                                                        width={52}
                                                        height={52}
                                                        className="object-fit-contain " />
                                                </div>
                                                <div >
                                                    <h4 className="color-black e-poppins-medium e-font-sm-16-14">{item.fund_name}</h4>
                                                    <span className="border-bright-gray border-all border-radius-16px padding-4px-tb padding-6px-lr color-black e-inter-medium e-font-10">
                                                        {item.class_name}
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="color-light-black e-font-sm-16-14 e-poppins-regular">
                                                {`${item.unit} %`}
                                            </td>
                                            <td className="e-inter-regular e-font-sm-16-14 color-light-black">
                                                {`₹${item.amount.toLocaleString('en-IN')}`}
                                            </td>
                                            <td className="e-inter-regular e-font-sm-16-14 color-light-black">
                                                {`₹${item.nav.current_nav.toLocaleString('en-IN')}`}
                                                <span className="e-font-12">({item.nav.nav_date})</span>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>

                <div className="row">
                    <div className="col-4 col-md-6 col-12 mt-2">
                        <GradientButton label="Verify & Make payment"
                            className="padding-14px-all"
                            handleClick={() => _getOTP(4, data)} />
                    </div>
                </div>
            </div>
        )
    };

    //Mf Redeem detial
    function RedeemDetail({ data }) {
        return (
            <div className="border-bright-gray border-all border-radius-16px p-4">

                <div className="row border-bottom gx-0 pb-3">

                    {/* Fund detail seciton */}
                    <div className="col-lg-6 col-12 d-flex">
                        <div className="bg-white border-bright-gray border-all border-radius-8px w-52px h-52px padding-6px-all me-3">
                            <img src={data.image}
                                alt={data.name}
                                width={52}
                                height={52}
                                className="object-fit-contain" />
                        </div>
                        <div >
                            <h4 className="color-black e-poppins-medium e-font-16">{data.name}</h4>

                            <span className="border-bright-gray border-all border-radius-16px padding-4px-tb padding-6px-lr color-black e-inter-medium e-font-10">
                                {data.class_name}
                            </span>
                        </div>
                    </div>

                    {/* Total amount */}
                    <div className="col-6 text-end">
                        <p className="color-light-black e-inter-medium e-font-24">
                            {`₹${data.order_amount.toLocaleString('en-IN')}`}
                        </p>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-4 col-md-6 col-12">
                        <LabelValuePair label="Order Id"
                            value={data.order_id} />
                    </div>
                    <div className="col-8 col-md-6 col-12">
                        <LabelValuePair label="Order type"
                            value={data.order_type} />
                    </div>

                    <div className="col-4 col-md-6 col-12">
                        <LabelValuePair label="Created on"
                            value={data.created_on} />
                    </div>
                    <div className="col-8 col-md-6 col-12">
                        <LabelValuePair label="Created by"
                            value={data.created_by}
                            post_fix={data.admin_type} />
                    </div>

                </div>

                <div className="row">
                    <div className="col-12 mt-4">
                        <h6 className="color-black e-poppins-medium e-font-16">
                            Funds
                        </h6>
                    </div>
                    {
                        data.fund_data.map((item, key) => {
                            return (
                                <div className="row mt-3" key={key}>
                                    <div className="col-lg-6 col-9  d-flex">
                                        <div className="bg-white border-bright-gray border-all border-radius-8px w-52px h-52px padding-6px-all me-3">
                                            <img src={item.image}
                                                alt={item.fund_name}
                                                width={52}
                                                height={52}
                                                className="object-fit-contain" />
                                        </div>
                                        <div >
                                            <h4 className="color-black e-poppins-medium e-font-14">{item.fund_name}</h4>

                                            <span className="border-bright-gray border-all border-radius-16px padding-4px-tb padding-6px-lr color-black e-inter-medium e-font-10">
                                                {item.class_name}
                                            </span>
                                        </div>
                                    </div>

                                    {/* Total amount */}
                                    <div className="col-lg-6 col-3 text-lg-start text-end">
                                        <p className="color-light-black e-inter-medium e-font-14">
                                            {`₹${item.amount.toLocaleString('en-IN')}`}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="row mt-2">
                    <div className="col-lg-4 col-md-6 col-12 mt-2">
                        <GradientButton label="Verify & withdraw"
                            className="padding-14px-all"
                            handleClick={() => _getOTP(5, data)} />
                    </div>
                </div>
            </div>
        )
    }

    // SWP detail
    function SWPDetail({ data }) {
        return (
            <div className="border-bright-gray border-all border-radius-16px p-4">

                <div className="row border-bottom gx-0 pb-3">

                    {/* Fund detail seciton */}
                    <div className="col-lg-6 col-12 d-flex">
                        <div className="bg-white border-bright-gray border-all border-radius-8px w-52px h-52px padding-6px-all me-3">
                            <img src={data.image}
                                alt={data.name}
                                width={52}
                                height={52}
                                className="object-fit-contain" />
                        </div>
                        <div >
                            <h4 className="color-black e-poppins-medium e-font-16">{data.name}</h4>

                            <span className="border-bright-gray border-all border-radius-16px padding-4px-tb padding-6px-lr color-black e-inter-medium e-font-10">
                                {data.class_name}
                            </span>
                        </div>
                    </div>

                    {/* Total amount */}
                    <div className="col-lg-6 col-12 text-lg-end">
                        <p className="color-light-black e-inter-medium e-font-24">
                            {`₹${data.order_amount.toLocaleString('en-IN')}`}
                        </p>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-4 col-md-6 col-12">
                        <LabelValuePair label="Order Id"
                            value={data.order_id} />
                    </div>
                    <div className="col-8 col-md-6 col-12">
                        <LabelValuePair label="Order type"
                            value={data.order_type} />
                    </div>

                    <div className="col-4 col-md-6 col-12">
                        <LabelValuePair label="Created on"
                            value={data.created_on} />
                    </div>
                    <div className="col-8 col-md-6 col-12">
                        <LabelValuePair label="Created by"
                            value={data.created_by}
                            post_fix={data.admin_type} />
                    </div>

                </div>

                <div className="row mt-2">
                    <div className="col-lg-4 col-md-6 col-12 mt-2">
                        <GradientButton label={data.generate_today === false ? "Verify & Schedule SWP" : "Verify & Start SWP"}
                            className="padding-14px-all"
                            handleClick={() => _getOTP(5, data)} />
                    </div>
                </div>
            </div>
        )
    }

    // StP detail
    function STPDetail({ data }) {
        return (
            <div className="border-bright-gray border-all border-radius-16px p-4">

                <div className="row pb-3">

                    {/* Fund detail seciton */}
                    <div className="row pe-0 padding-18px-bottom border-bottom-1px border-bright-gray">
                        <div className="col-lg-10 col-12">
                            <div className="d-flex">
                                <div className="bg-white border-bright-gray border-all border-radius-8px w-52px h-68px padding-6px-all me-3">
                                    <img src={data.image}
                                        alt={data.name}
                                        width={52}
                                        height={52}
                                        className="object-fit-contain" />
                                </div>
                                <div className="">
                                    <div >
                                        <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                                            Transfer Out
                                        </p>
                                        <h4 className="color-black e-poppins-medium e-font-16">{data.name}</h4>

                                        <span className="border-bright-gray border-all border-radius-16px padding-4px-tb padding-6px-lr color-black e-inter-medium e-font-10">
                                            {data.class_name}
                                        </span>
                                    </div>
                                    <img src={require('../../Assets/Images/orders/stp-arrow.png')}
                                        className="e-arrow-down my-3 w-35px" />
                                    <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                                        Transfer In
                                    </p>
                                    <div className="d-flex align-items-center gap-12px">
                                        <div>
                                            <p className="color-black e-poppins-medium e-font-16 line-height-20px mb-2">
                                                {data.fund_data[0].switch_in_fund_name}
                                            </p>
                                            <span className="border-all border-bright-gray border-radius-16px color-black e-poppins-medium e-font-10 line-height-18px e-fund-chip padding-6px-tb ">
                                                {data.fund_data[0].switch_in_fund_category}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Total amount */}
                        <div className="col-lg-2 col-12 text-lg-end pe-0 mt-sm-0 mt-3">
                            <p className="color-light-black e-inter-medium e-font-24">
                                {`₹${data.order_amount.toLocaleString('en-IN')}`}
                            </p>
                        </div>

                    </div>


                </div>

                <div className="row">
                    {
                        data.fund_data.map((fund, key) => {
                            return (
                                <div className="row mt-3" key={key}>
                                    <div className="row my-3">
                                        <div className="col-xl-5 col-sm-6 pe-0">
                                            <Description title="Order id:" description={data.order_id} />
                                        </div>
                                        <div className="col-xl-7 col-sm-6 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                            <Description title="Order type:" description={data.order_type} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-5 col-sm-6 pe-0">
                                            <Description title="Created by:" description={data.created_by} />
                                        </div>
                                        <div className="col-xl-7 col-sm-6 d-flex mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                            <Description title="Created on:"
                                                description={`${data.created_on}`} />
                                            {/* <span className="color-outer-space e-poppins-regular e-font-12 mt-1">&nbsp;(23 Aug 2023)</span> */}
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-xl-5 col-sm-6 pe-0">
                                            <Description title="Transfer out amount:" description={fund.amount}
                                                symbol={true} />
                                        </div>

                                    </div>


                                </div>
                            )
                        })
                    }
                </div>

                <div className="row mt-2">
                    <div className="col-lg-4 col-12 mt-2">
                        <GradientButton label={data.generate_today === false ? "Verify & Schedule STP" : "Verify & Start STP"}
                            className="padding-14px-all"
                            handleClick={() => _getOTP(5, data)} />
                    </div>
                </div>
            </div>
        )
    }

    // StP detail
    function SwitchDetail({ data }) {
        return (
            <div className="border-bright-gray border-all border-radius-16px p-4">

                <div className="row pb-3">

                    {/* Fund detail seciton */}
                    <div className="row pe-0 padding-18px-bottom border-bottom-1px border-bright-gray">
                        <div className="col-lg-10 col-12">
                            <div className="d-flex">
                                <div className="bg-white border-bright-gray border-all border-radius-8px w-52px h-68px padding-6px-all me-3">
                                    <img src={data.image}
                                        alt={data.name}
                                        width={52}
                                        height={52}
                                        className="object-fit-contain" />
                                </div>
                                <div className="">
                                    <div >
                                        <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                                            Switch Out
                                        </p>
                                        <h4 className="color-black e-poppins-medium e-font-16">{data.name}</h4>

                                        <span className="border-bright-gray border-all border-radius-16px padding-4px-tb padding-6px-lr color-black e-inter-medium e-font-10">
                                            {data.class_name}
                                        </span>
                                    </div>
                                    <img src={require('../../Assets/Images/orders/stp-arrow.png')}
                                        className="e-arrow-down my-3 w-35px" />
                                    <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                                        Switch In
                                    </p>
                                    <div className="d-flex align-items-center gap-12px">
                                        <div>
                                            <p className="color-black e-poppins-medium e-font-16 line-height-20px mb-2">
                                                {data.fund_data[0].switch_in_fund_name}
                                            </p>
                                            <span className="border-all border-bright-gray border-radius-16px color-black e-poppins-medium e-font-10 line-height-18px e-fund-chip padding-6px-tb ">
                                                {data.fund_data[0].switch_in_fund_category}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Total amount */}
                        <div className="col-lg-2 col-12 pe-0 text-end mt-sm-0 mt-3">
                            <p className="color-light-black e-inter-medium e-font-24">
                                {`₹${data.order_amount.toLocaleString('en-IN')}`}
                            </p>
                        </div>

                    </div>


                </div>

                <div className="row">
                    {
                        data.fund_data.map((fund, key) => {
                            return (
                                <div className="row mt-3" key={key}>
                                    <div className="row my-3">
                                        <div className="col-xl-5 col-sm-6 pe-0">
                                            <Description title="Order id:" description={data.order_id} />
                                        </div>
                                        <div className="col-xl-7 col-sm-6 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                            <Description title="Order type:" description={data.order_type} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-5 col-sm-6 pe-0">
                                            <Description title="Created by:" description={data.created_by} />
                                        </div>
                                        <div className="col-xl-7 col-sm-6 d-flex mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                            <Description title="Created on:"
                                                description={`${data.created_on}`} />
                                            {/* <span className="color-outer-space e-poppins-regular e-font-12 mt-1">&nbsp;(23 Aug 2023)</span> */}
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-xl-5 col-sm-6 pe-0">
                                            <Description title="Switch out amount:" description={fund.amount} symbol={true} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="row mt-2">
                    <div className="col-lg-4 col-12 mt-2">
                        <GradientButton label={data.generate_today === false ? "Verify & Schedule Switch" : "Verify & Switch"}
                            className="padding-14px-all"
                            handleClick={() => _getOTP(5, data)} />
                    </div>
                </div>
            </div>
        )
    }

    // Goal Redeem detail
    function GoalRedeemDetail({ data }) {
        return (
            <div className="border-bright-gray border-all border-radius-16px p-4">

                <div className="row border-bottom gx-0 pb-3">

                    {/* Fund detail seciton */}
                    <div className="col-lg-6 col-12 d-flex">
                        <div className="bg-white border-bright-gray border-all border-radius-8px w-52px h-52px padding-6px-all me-3">
                            <img src={data.image}
                                alt={data.name}
                                width={52}
                                height={52}
                                className="object-fit-contain" />
                        </div>
                        <div >
                            <h4 className="color-black e-poppins-medium e-font-16">{data.name}</h4>

                            <span className="border-bright-gray border-all border-radius-16px padding-4px-tb padding-6px-lr color-black e-inter-medium e-font-10">
                                {data.risk_type}
                            </span>
                        </div>
                    </div>

                    {/* Total amount */}
                    <div className="col-lg-6 col-12 text-lg-end">
                        <p className="color-light-black e-inter-medium e-font-24">
                            {`₹${data.order_amount.toLocaleString('en-IN')}`}
                        </p>
                    </div>
                </div>

                <div className="row mt-3 border-bottom">
                    <div className="col-4 col-md-6 col-12">
                        <LabelValuePair label="Order Id"
                            value={data.order_id} />
                    </div>
                    <div className="col-8 col-md-6 col-12">
                        <LabelValuePair label="Order type"
                            value={data.order_type} />
                    </div>

                    <div className="col-4 col-md-6 col-12">
                        <LabelValuePair label="Created on"
                            value={data.created_on} />
                    </div>
                    <div className="col-8 col-md-6 col-12">
                        <LabelValuePair label="Created by"
                            value={data.created_by}
                            post_fix={data.admin_type} />
                    </div>

                    <div className="col-4 col-md-6 col-12">
                        <LabelValuePair label="Withdraw amount"
                            value={data.order_amount}
                            amount={true} />
                    </div>
                    <div className="col-8 col-md-6 col-12">
                        <LabelValuePair label="Approx. units"
                            value={data.fund_data[0].unit} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-4">
                        <h6 className="color-black e-poppins-medium e-font-16">
                            Funds
                        </h6>
                    </div>
                    {
                        data.fund_data.map((item, key) => {
                            return (
                                <div className="row mt-3" key={key}>
                                    <div className="col-lg-6 col-12 d-flex">
                                        <div className="bg-white border-bright-gray border-all border-radius-8px w-52px h-52px padding-6px-all me-3">
                                            <img src={item.image}
                                                alt={item.fund_name}
                                                width={52}
                                                height={52}
                                                className="object-fit-contain" />
                                        </div>
                                        <div >
                                            <h4 className="color-black e-poppins-medium e-font-14">{item.fund_name}</h4>

                                            <span className="border-bright-gray border-all border-radius-16px padding-4px-tb padding-6px-lr color-black e-inter-medium e-font-10">
                                                {item.class_name}
                                            </span>
                                        </div>
                                    </div>

                                    {/* Total amount */}
                                    <div className="col-lg-6 col-12 text-end">
                                        <p className="color-light-black e-inter-medium e-font-14">
                                            {`₹${item.amount.toLocaleString('en-IN')}`}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="row">
                    <div className="col-lg-4 col-12 mt-4">
                        <GradientButton label="Verify & withdraw"
                            className="padding-14px-all"
                            handleClick={() => _getOTP(5, data)} />
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>

            {
                apiLoader === true ? (
                    <div className="container h-100vh padding-64px-lr-md">
                        <CustomLoader />
                    </div>

                ) : (
                    <>
                        {
                            showInvalidUserScreen === false ? (
                                <>
                                    <div className="container min-h-100vh padding-64px-lr-md pb-5 mb-5">
                                        {/* Header */}
                                        <div className="row mt-4">
                                            <div className="col-12 text-center">
                                                <img src={darkMode === true ? require("../../Assets/Images/mt-wealth-logo-light.svg").default : require('../../Assets/Images/master-trust-logo.svg').default}
                                                    alt="mt wealth - powered by Master Trust" />
                                            </div>
                                        </div>

                                        {/* Body  */}
                                        <div className="row">
                                            <div className="col-12 mb-3 mt-3">
                                                <h2 className="color-black e-poppins-medium e-font-20">
                                                    Pending orders
                                                </h2>
                                                <div className="col-lg-10 col-12 mb-4">
                                                    <div className="d-flex gap-8px bg-antique-white border-radius-8px p-2 mt-3">
                                                        <Icon icon="info"
                                                            size={30} />
                                                        <ol className="e-font-sm-16-14 e-poppins-regular line-height-24px color-jett-black mb-0 mt-1">
                                                            <li>NAV will be applicable as per fund credit in Clearing Corporation (ICCL) account.</li>
                                                            <li className="mt-2">Initiate payment only if you have not completed payment or the deduction is not showing in your bank for this order.</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* mf lumpsum order */}
                                            {
                                                paymentData.data.mf_data && paymentData.data.mf_data.hasOwnProperty('lumpsum') &&
                                                paymentData.data.mf_data.lumpsum.map((item, key) => {
                                                    return (
                                                        <div className="col-12 mt-3" key={key}>
                                                            <LumpsumOrSIPOrder
                                                                data={item}
                                                                type={1} />
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* mf sip order */}
                                            {
                                                paymentData.data.mf_data && paymentData.data.mf_data.hasOwnProperty('sip') &&
                                                paymentData.data.mf_data.sip.map((item, key) => {
                                                    return (
                                                        <div className="col-12 mt-3" key={key}>
                                                            <LumpsumOrSIPOrder
                                                                data={item}
                                                                type={2} />
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* goal order */}
                                            {
                                                paymentData.data.hasOwnProperty('goal_data') &&
                                                paymentData.data.goal_data.map((item, key) => {
                                                    return (
                                                        <div className="col-12 mt-3" key={key}>
                                                            <GoalOrderDetail
                                                                data={item} />
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* cart order */}
                                            {
                                                paymentData.data.hasOwnProperty('cart_data') &&
                                                paymentData.data.cart_data.map((item, key) => {
                                                    return (
                                                        <div className="col-12 mt-3" key={key}>
                                                            <CartOrderDetail
                                                                data={item} />
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* redeem - mf*/}
                                            {
                                                paymentData.data.hasOwnProperty('mf_redeem_data') &&
                                                paymentData.data.mf_redeem_data.map((item, key) => {
                                                    return (
                                                        <div className="col-12 mt-3" key={key}>
                                                            <RedeemDetail
                                                                data={item} />
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* redeem - goal*/}
                                            {
                                                paymentData.data.hasOwnProperty('goal_redeem_data') &&
                                                paymentData.data.goal_redeem_data.map((item, key) => {
                                                    return (
                                                        <div className="col-12 mt-3" key={key}>
                                                            <GoalRedeemDetail
                                                                data={item}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* redeem - swp*/}
                                            {
                                                paymentData.data.hasOwnProperty('swp') &&
                                                paymentData.data.swp.map((item, key) => {
                                                    return (
                                                        <div className="col-12 mt-3" key={key}>
                                                            <SWPDetail
                                                                data={item} />
                                                        </div>
                                                    )
                                                })
                                            }

                                            {/* redeem - STP */}
                                            {
                                                paymentData.data.hasOwnProperty('stp') &&
                                                paymentData.data.stp.map((item, key) => {
                                                    return (
                                                        <div className="col-12 mt-3" key={key}>
                                                            <STPDetail
                                                                data={item} />
                                                        </div>
                                                    )
                                                })
                                            }

                                            {/* redeem - STP */}
                                            {
                                                paymentData.data.hasOwnProperty('switch') &&
                                                paymentData.data.switch.map((item, key) => {
                                                    return (
                                                        <div className="col-12 mt-3" key={key}>
                                                            <SwitchDetail
                                                                data={item} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                    {/* Footer */}
                                    <Footer />
                                </>
                            ) : (
                                <div className="container h-100vh">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <div className="e-card-401 w-400px border-radius-16px p-4 text-center">
                                            <img src={require('../../Assets/Images/no-transactions.jpg')}
                                                alt="404"
                                                width={200}
                                                height={200} />
                                            <h4>{invalidScreenTitle}</h4>
                                            <p>{invalidScreenDesc}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </>

                )
            }
            {
                openPaymentModal === true && (
                    <PendingPayments data={selectedFund}
                        redeem={isRedeem}
                        close={_unMountModal}
                        validUser={false}
                        adminOrder={true}
                        successCallBack={_paymentSuccessCallBack}
                        handleInvalidToken={_handleInvalidUser} />
                )
            }

        </>
    )
}