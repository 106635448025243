/*
 *   File : funds.js
 *   Author URI : https://evoqins.com
 *   Description : Fund card component
 *   Integrations : null
 *   Version : v1.1
 */

import React, { Fragment, useState, useContext } from "react"
import { useNavigate } from "react-router";

import Icon from "../Icon/icon"
import { SectionTitle } from "../Title"

import Color from "../../Styles/color.module.scss"
import { DarkModeContext } from "../../Context/dark-mode-context";

const FundCard = React.memo((props) => {

    const { darkMode } = useContext(DarkModeContext);
    const navigate = useNavigate();
    const [colorChange, setColorChange] = useState("");

    const _handleSvgColor = (key) => {
        if (props.type === 2) {
            setColorChange(key);
        }
    }

    const _handleSvgDefault = () => {
        if (props.type === 2) {
            setColorChange("");
        }
    }

    const _navigateToFundDetail = (id) => {
        navigate("/explore/fund", {
            state: {
                fund_id: id
            }
        });
    }

    return (
        //   Popular funds 
        <Fragment>
            {/* Large screen */}
            <div className="d-lg-block d-md-block d-sm-block d-none">
                <div className="row ">
                    <div className="col-12">
                        <SectionTitle title="Popular funds"
                            className="mb-0" />
                    </div>

                    {
                        props.data.map((item, key) =>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 padding-24px-top" key={key}>
                                <div className="border-radius-10px border-all border-bright-gray padding-24px-tb padding-16px-lr h-100 cursor-pointer e-fund-card"
                                    onMouseEnter={() => _handleSvgColor(key)}
                                    onMouseLeave={() => _handleSvgDefault(key)}
                                    onClick={() => _navigateToFundDetail(item.id)}>
                                    <div className="d-flex  align-items-center flex-wrap gap-16px position-relative">
                                        <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                                            <img src={item.image}
                                                alt={item.name}
                                                className="e-card-image object-fit-contain"
                                                draggable={false} />
                                        </div>
                                        <p className="color-old-silver e-poppins-regular e-font-14 line-height-24px  mb-0 e-2px" >
                                            Very High Risk - {item.rank}
                                            <img src={require("../../Assets/Images/dashboard/Star.png")}
                                                width={10}
                                                height={10}
                                                alt="rating"
                                                draggable={false} />
                                        </p>

                                        <Icon icon="arrow-right"
                                            color={darkMode === true ? Color.white : props.type === 2 && colorChange === key ? Color.primary : Color.charleston_green}
                                            size={24}
                                            className="position-absolute right-0" />
                                    </div>
                                    {
                                        props.type === 2 ?
                                            <div className="padding-12px-tb d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <img src={require("../../Assets/Images/watchlist/high-risk.png")}
                                                        alt="High risk"
                                                        width={36}
                                                        height={28} />
                                                    <div className="d-flex align-items-center ms-1">
                                                        <p className="color-old-silver e-poppins-regular e-font-14 line-height-24px  mb-0 e-2px" >
                                                            Very High Risk - {item.type} - {item.rank}
                                                        </p>
                                                        <img src={require("../../Assets/Images/dashboard/Star.png")}
                                                            width={10}
                                                            height={10}
                                                            alt="rating"
                                                            draggable={false} />
                                                    </div>
                                                </div>
                                                <p className="color-black e-poppins-medium e-font-10 line-height-18px mb-0  e-type bg-white border-all border-bright-gray border-radius-16px">{item.type}</p>
                                            </div>
                                            :
                                            <div className="d-flex align-items-center mt-3">
                                                <p className="border-radius-16px border-all border-bright-gray  padding-12px-lr color-black e-poppins-medium e-font-8 line-height-18px w-fit-content">
                                                    {item.type}
                                                </p>
                                            </div>
                                    }

                                    <p className="color-black e-poppins-medium e-font-16 line-height-26px margin-24px-bottom e-fund-title truncate-second-line">
                                        {item.name}
                                    </p>
                                    <div className="d-flex justify-content-between flex-wrap gap-16px">
                                        <p className="e-poppins-regular e-font-12 line-height-24px mb-0">
                                            <span className="color-black">CAGR: </span>
                                            <span className="color-ufo-green">{item.cagr}%</span>
                                            <span className="color-old-silver">({item.cagr_label})</span></p>

                                        <p className="color-black e-poppins-regular e-font-12 line-height-24px mb-0">AUM: {item.aum}Cr</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>

            {/* mobile screen */}
            <div className="d-lg-none d-md-none d-sm-none d-block">
                <SectionTitle title="Popular funds"
                    className="mb-0 margin-18px-left" />
                <div className="e-fund-mobile padding-18px-lr pb-3">
                    <div className="d-flex gap-16px e-width-max-content">
                        {
                            props.data.map((item, key) =>
                                <div className="border-radius-10px border-all min-height-169px border-bright-gray position-relative  p-2 h-100 margin-24px-top-sm-16 cursor-pointer e-fund-card"
                                    key={key}
                                    onClick={() => _navigateToFundDetail(item.id)}>
                                    <div className="d-flex align-items-center">
                                        <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                                            <img src={item.image}
                                                alt={item.name}
                                                className="e-card-image object-fit-contain"
                                                draggable={false} />
                                        </div>
                                        <p className="color-old-silver ms-2 e-poppins-regular e-font-10 line-height-24px  mb-0 e-2px" >
                                            Very High Risk - {item.rank}
                                            <img src={require("../../Assets/Images/dashboard/Star.png")}
                                                width={10}
                                                height={10}
                                                alt="rating"
                                                draggable={false} />
                                        </p>
                                    </div>
                                    <p className="border-radius-16px border-all mt-2 border-bright-gray  padding-12px-lr color-black e-poppins-medium e-font-8 line-height-18px w-fit-content">
                                        {item.type}
                                    </p>

                                    <p className="color-black e-poppins-medium e-font-12 line-height-18px margin-24px-bottom e-fund-title truncate-second-line mt-2">
                                        {item.name}
                                    </p>
                                    <div className="d-flex justify-content-between flex-wrap gap-16px position-absolute bottom-10px">
                                        <p className="e-poppins-regular e-font-10 line-height-12px mb-0">
                                            <span className="color-black">CAGR: </span>
                                            <span className="color-ufo-green">{item.cagr}%</span>
                                            <span className="color-old-silver">({item.cagr_label})</span></p>

                                        <p className="color-black e-poppins-regular e-font-10 line-height-12px mb-0">AUM: {item.aum}Cr</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
})

export default FundCard