
/*
 *   File : sip.js
 *   Author URI : https://evoqins.com
 *   Description : SIP calculator component
 *   Integrations : react-tooltip
 *   Version : v1.1
 */

import React, { useEffect, useState, useCallback, useContext } from "react";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

import CustomSlider from "../Other/slider"
import { SectionTitle } from "../Title"
import GradientButton from "../CTA/gradient";
import { Toggle, CustomAmountInput } from "../Form-elements";
import { DarkModeContext } from "../../Context/dark-mode-context";

const SIPCalculator = React.memo(() => {
    const navigator = useNavigate();
    const { darkMode } = useContext(DarkModeContext);
    const [sipAmount, setSipAmount] = useState(500);
    const [sipAmountText, setSipAmountText] = useState("500")
    const [tenure, setTenure] = useState(2);
    const [stepUpPercent, setStepUpPercent] = useState(10);
    const [isInflationEnabled, setIsInflationEnabled] = useState(true);
    const [sipAmountError, setSipAmountError] = useState("");

    const [wealthGained, setWealthGained] = useState(0);
    const [totalReturns, setTotalReturns] = useState(0);
    const [totalInvested, setTotalInvested] = useState(0);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const _updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add event listener to update screen width on window resize
        window.addEventListener('resize', _updateScreenWidth);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', _updateScreenWidth);
        };
    }, []);

    function _handleAmountInputChange(value) {
        setSipAmount(value);
        if (value < 500) {
            setSipAmountError('Amount is less than 500');
        } else {
            setSipAmountError("");
        }
    }

    //  improved the code implementation by incorporating the useCallback hook to address the 
    // useEffect dependency error in the bundler   
    // Refferance url for check calculation with inflation https://sipcalculator.in/result
    // Grow sip calculator link https://groww.in/calculators/sip-calculator
    const _sipCalculator = useCallback(() => {
        const months = tenure * 12;
        let monthly_rate = 0;
        let final_value = 0
        if (isInflationEnabled === false) {
            console.log('inflation true')
            monthly_rate = (stepUpPercent - 6) / 100 / 12;
            final_value = Math.round((sipAmount * (((1 + monthly_rate) ** months) - 1) * (1 + monthly_rate)) / monthly_rate);
        }
        else {
            console.log('inflation false')
            monthly_rate = stepUpPercent / 100 / 12
            final_value = Math.round(sipAmount * ((Math.pow(1 + monthly_rate, months) - 1) / monthly_rate) * (1 + monthly_rate))

        }
        const total_Invested = Math.round(sipAmount * months);
        setTotalInvested(total_Invested.toLocaleString('en-IN'));

        setTotalReturns(final_value.toLocaleString('en-IN'));
        if (isInflationEnabled === true) {
            setWealthGained((final_value - total_Invested).toLocaleString('en-IN'));
        }
        else {
            setWealthGained((final_value - total_Invested).toLocaleString('en-IN'));
        }
    }, [sipAmount, tenure, stepUpPercent, isInflationEnabled]);

    useEffect(() => {
        _sipCalculator();
    }, [_sipCalculator]);

    const _handleChange = (value, type) => {
        switch (type) {
            case 1:
                setSipAmountText(value.toLocaleString('en-IN'));
                setSipAmount(value);
                break;
            case 2:
                setTenure(value);
                break;
            case 3:
                setStepUpPercent(value);
                break;
            default: return;
        }
    };

    function _navigateExplore() {
        navigator('/explore');
    };

    return (

        <div className="row">
            <div className="col-12">
                <div className='border-radius-16px bg-sip-calculator padding-32px-all-sm-16 position-relative e-sip-mobile'>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className='padding-40px-right border-right-2px e-sip-border-none'>
                                <SectionTitle title="SIP Returns" />

                                <div className={`${screenWidth > 575 ? 'border-radius-8px' : 'border-radius-8-0'} py-2 px-3 bg-calculator-value-card  mb-sm-3 mb-0 position-relative z-index-1`} >
                                    <div className='d-flex justify-content-between align-items-center flex-wrap mb-2'>
                                        <p className='color-davys-grey e-poppins-medium e-font-16 mb-0'>Monthly investment</p>
                                        <CustomAmountInput value={sipAmount}
                                            className="w-125 padding-8px-tb"
                                            inputClass="bg-transparent pe-3 padding-8px-tb w-125 text-end"
                                            showRupeePrefix={true}
                                            maxValue={1000000}
                                            fixedRupee={true}
                                            error={sipAmountError}
                                            hideErrorMessage={true}
                                            handleChange={_handleAmountInputChange}
                                        />
                                        {/* <p className='color-charcoal e-poppins-semi-bold e-font-18 mb-0'><span className='e-inter-semi-bold'>₹</span> {sipAmountText}</p> */}
                                    </div>
                                    <CustomSlider defaultValue={sipAmount}
                                        min={500}
                                        step={500}
                                        max={1000000}
                                        value={sipAmount}
                                        _handleChange={(value) => _handleChange(value, 1)} />
                                    <div className='d-flex justify-content-between align-items-center mt-2'>

                                        <p className='color-cadet-grey e-poppins-regular e-font-12 mb-0'><span className="e-inter-regular">₹</span>500</p>
                                        <p className='color-cadet-grey e-poppins-regular e-font-12 mb-0'><span className="e-inter-regular">₹</span>10,00,000</p>
                                    </div>

                                </div>
                                <div className={`${screenWidth > 575 ? 'border-radius-8px' : 'border-radius-0'} py-2 px-3 bg-calculator-value-card mb-sm-3 mb-0 position-relative z-index-1`}>
                                    <div className='d-flex justify-content-between align-items-center flex-wrap mb-2'>
                                        <p className='color-davys-grey e-poppins-medium e-font-16 mb-0'>Expected return rate (p.a)</p>
                                        <p className='color-cadet-grey e-poppins-semi-bold e-font-18 mb-0'>{stepUpPercent}%</p>
                                    </div>
                                    <CustomSlider defaultValue={stepUpPercent}
                                        min={0.5}
                                        max={50}
                                        step={0.5}
                                        value={stepUpPercent}
                                        _handleChange={(value) => _handleChange(value, 3)} />
                                    <div className='d-flex justify-content-between align-items-center mt-2'>
                                        <p className='color-cadet-grey e-poppins-regular e-font-12 mb-0'>0.5%</p>
                                        <p className='color-cadet-grey e-poppins-regular e-font-12 mb-0'>50%</p>
                                    </div>

                                </div>
                                <div className={`${screenWidth > 576 ? 'border-radius-8px' : 'border-radius-0-8 '} py-2 px-3 bg-calculator-value-card mb-3 position-relative z-index-1`}>
                                    <div className='d-flex justify-content-between align-items-center flex-wrap mb-2'>
                                        <p className='color-davys-grey e-poppins-medium e-font-16 mb-0'>Time period</p>
                                        <p className='color-cadet-grey e-poppins-semi-bold e-font-18 mb-0'>{tenure}Y</p>
                                    </div>
                                    <CustomSlider defaultValue={tenure}
                                        min={1}
                                        max={20}
                                        value={tenure}
                                        _handleChange={(value) => _handleChange(value, 2)} />
                                    <div className='d-flex justify-content-between align-items-center mt-2'>

                                        <p className='color-cadet-grey e-poppins-regular e-font-12 mb-0'>1Y</p>
                                        <p className='color-cadet-grey e-poppins-regular e-font-12 mb-0'>20Y</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6  pt-lg-0 pt-md-0  pt-4">

                            <h4 className='color-black e-inter-bold e-font-22-sm-18  mb-2'>
                                <span className='color-outer-space e-poppins-medium me-2 e-font-14 line-height-22px '>
                                    Total value
                                </span>
                                ₹{totalReturns}
                            </h4>
                            <div className={`d-flex flex-wrap ${screenWidth > 576 ? " gap-20px" : "gap-8px"}`}>
                                <p className='color-outer-space e-poppins-medium e-font-14 line-height-22px mb-0'>Invested amount &nbsp;
                                    <span className='color-outer-space e-inter-medium e-font-16 line-height-24px'>
                                        ₹{totalInvested}
                                    </span>
                                </p>
                                <p className='color-outer-space e-poppins-medium e-font-14 line-height-22px mb-0'>Returns &nbsp;
                                    <span className='color-outer-space e-inter-medium e-font-16 line-height-24px'>
                                        ₹{wealthGained} &nbsp;
                                    </span>
                                    <span className='color-ufo-green line-height-24px'>
                                        ({stepUpPercent}%)
                                    </span>
                                </p>
                            </div>


                            <div className="d-flex flex-nowrap bg-sapphire border-radius-8px e-progress-status margin-30px-top-sm-16">
                                <div className='border-radius-8px bg-true-blue z-index-1'
                                    data-tooltip-id="invested"
                                    style={{ width: `${100 - stepUpPercent}%`, height: "1.5rem" }}>
                                </div>
                                <Tooltip id="invested"
                                    className='e-invested-hover'>
                                    <div className='border-radius-6px bg-cards z-index-1 p-1'>
                                        <p className='color-gray e-inter-regular e-font-12 line-height-18px mb-0'>Invested</p>
                                        <p className='color-black e-poppins-medium e-font-12 mb-0'><span className='e-inter-medium'>₹</span>{totalInvested}</p>
                                    </div>
                                </Tooltip>

                                <div className=' border-radius-8px bg-sapphire z-index-1'
                                    style={{
                                        height: "1.5rem",
                                        width: stepUpPercent < 2.5 ? "5.5%" : `${stepUpPercent}%`
                                    }}

                                    data-tooltip-id="returns">
                                </div>
                                <Tooltip id="returns"
                                    className='e-return-hover'>
                                    <div className='border-radius-6px bg-cards  z-index-1  p-1'>
                                        <p className='color-gray e-inter-regular e-font-12 line-height-18px mb-0'>Returns</p>
                                        <p className='color-black e-poppins-medium e-font-12 mb-0'><span className='e-inter-medium'>₹</span>{wealthGained}</p>
                                    </div>
                                </Tooltip>


                            </div>

                            <div className='d-flex gap-8px mt-2'>
                                <p className='color-gray e-poppins-regular e-font-12 mb-0'>
                                    <img src={require("../../Assets/Images/dashboard/light-blue.svg").default}
                                        alt="Light blue"
                                        className='me-2'
                                        draggable={false} />Invested amount</p>
                                <p className='color-gray e-poppins-regular e-font-12 mb-0'>
                                    <img src={require("../../Assets/Images/dashboard/dark-blue.svg").default}
                                        alt="Dark blue"
                                        className='me-2'
                                        draggable={false} />Est. returns</p>
                            </div>

                            <div className={`d-flex align-items-center flex-wrap gap-8px ${screenWidth > 576 ? "margin-22px-top mb-4" : "margin-14px-top margin-22px-bottom"} `}>
                                <p className='color-black e-poppins-regular e-font-14 line-height-22px d-flex align-items-center  mb-0 '>
                                    With inflation of 6%
                                </p>
                                <div className="d-flex align-items-center gap-12px">
                                    <span className={isInflationEnabled === true ? "e-poppins-medium e-font-16 line-height-16px" : "color-old-silver e-poppins-regular e-font-16 line-height-16px"}>
                                        No</span>
                                    <Toggle check={isInflationEnabled}
                                        onChange={(e) => setIsInflationEnabled(e)} />
                                    <span className={isInflationEnabled === false ? "e-poppins-medium e-font-16 line-height-16px" : "color-old-silver e-poppins-regular e-font-16 line-height-16px"}>Yes</span>
                                </div>
                            </div>

                            <GradientButton label="Create SIP today"
                                className="py-2 px-3"
                                handleClick={_navigateExplore} />

                        </div>

                    </div>
                    <img src={darkMode === true ?  require("../../Assets/Images/dashboard/sip-calculator-dark.svg").default : require("../../Assets/Images/dashboard/sip-calculator.svg").default}
                        alt="SPI calculator"
                        className='position-absolute top-0 border-radius-16px e-sip-bg d-lg-block d-md-block d-sm-none d-block e-mobile-sip'
                        draggable={false} />
                </div>
            </div>
        </div>

    )
})

export default SIPCalculator