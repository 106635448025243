/*
 *   File : unlock.js
 *   Author URI : https://evoqins.com
 *   Description : Unlock modal
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

import APIService from "../../Service/api-service";
import { useKeyHandler } from "../../Helper";
import { OTPInput } from "../Form-elements";

import style from "../../Styles/Component/unlock.module.scss"

const Unlock = (props) => {
    const { close } = props;
    const USER_NAME = useSelector(state => state.generalData.PROFILE_DATA.name);

    const [pinString, setPinString] = useState("");
    const [errorMessage, setErrorMessage] = useState('');

    useKeyHandler('unlock-modal', 'a', (event) => {
        if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
        }
    });

    // Removing backdrop from body when comes back from forgot pin modal
    useEffect(() => {
        let backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
            backdrop.remove()
        }
    }, [])

    useEffect(() => {
        if (pinString.length === 4) {
            _verifyPin();
        }
    }, [pinString]);

    const _handlePin = (value) => {
        setPinString(value);
        setErrorMessage("");
    }

    const _handleForgotPin = () => {
        props.forgotPin();
    }

    // API - Verify Master Trust PIN
    const _verifyPin = () => {
        let url = 'auth/verify-pin';
        let payload = JSON.stringify({
            refresh_token: Cookies.get('refreshToken'),
            pin: pinString
        })
        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                sessionStorage.setItem('access_token', response.data.access_token);
                close();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        })
    }

    return (
        <div id="unlock-modal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="unlock-modal"
            aria-hidden="true"
            className={`modal  ${style.e_unlock}`}>
            <div className={`modal-dialog modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-modal ${style.e_content}`}>
                    <span id="close-modal" data-bs-dismiss="modal" className='d-none'></span>
                    <div className="d-flex flex-column align-items-center text-center">
                        <img src={require("../../Assets/Images/dashboard/unlock-icon.png")}
                            alt="Unlock icon"
                            width={72}
                            height={72} />
                        <p className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-2">Hi {props.userName ? props.userName : USER_NAME}</p>
                        <p className="color-jett-black e-poppins-regular e-font-16 margin-40px-bottom">Enter your 4 digit pin to access your mt wealth account</p>

                        <OTPInput pinString={pinString}
                            id="mt-unlock-pin"
                            autoFocus={true}
                            pinError={errorMessage}
                            handleChange={_handlePin} />

                        <p className="color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 mt-3 e-forgot-pin position-relative"
                            // data-bs-dismiss="modal"
                            onClick={_handleForgotPin}>
                            Forgot Pin?</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Unlock;