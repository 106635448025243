/*
 *   File : goal-order-summary.js
 *   Author URI : www.evoqins.com
 *   Description : Order details for invested goal.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 19-09-2023
 */

import { Fragment, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

import { InvestmentHeader } from "../../Component/Header"
import { BreadCrumb } from "../../Component/BreadCrumb";
import Icon from "../../Component/Icon/icon";
import { GradientButton, OutlineButton } from "../../Component/CTA";
import { OrderFunds } from "../../Component/Accordion";
import Footer from "../../Component/Footer/footer";
import { CustomLoader } from "../../Component/Other";
import { PendingPayments } from "../../Component/Modal";


import APIService from "../../Service/api-service";

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Orders', url: '/orders' },
    { page: 'Order summary', url: null }
];


const GoalOrderSummary = () => {
    const location = useLocation();
    const orderId = location.state && location.state.order_id;
    const [apiLoader, setApiLoader] = useState(true);
    const [orderDetail, setOrderDetail] = useState(null);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        _getOrderDetail();
    }, []);

    useEffect(() => {
        if (openPaymentModal === true) {
            _openPendingPaymentModal();
        }
    }, [openPaymentModal]);

    const _openPendingPaymentModal = () => {
        const modal = new Modal(document.getElementById("payment-modal"), {});
        modal.show();
    }

    function _getOrderDetail() {
        let url = 'order/detail';
        let payload = JSON.stringify({
            order_id: orderId
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOrderDetail(response.data);
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false);
        });

    }


    function _getOTP() {
        let url = '2FA/get-otp';
        let payload = JSON.stringify({
            type: 'ORDER',
            order_ids: [orderId]
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOpenPaymentModal(true);
                toast.success('OTP send to your registered mobile number', {
                    type: 'success'
                });
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
        });
    }

    function _unMountModal() {
        setOpenPaymentModal(false);
    }

    const Description = (props) => {
        return (
            <>
                {
                    props.status === true ?
                        <div className="d-flex">
                            <span className="color-gray e-poppins-medium e-font-14 line-height-24px">{props.title}&nbsp;</span>
                            <span className={`e-poppins-medium e-font-14 line-height-24px ${props.description === 'Failed' ? 'color-red' : props.description === 'Success' ? 'color-sea-green' : props.description === 'Payment pending' ? 'color-sunset-blaze' : 'color-yellow-orange'}`}>
                                {props.description}
                                {
                                    props.description === 'Failed' ?
                                        <img src={require("../../Assets/Images/orders/failed.png")} alt="Failed"
                                            width={24}
                                            height={24}
                                            draggable={false}
                                            className="ms-1" />

                                        : props.description === 'Success' ?
                                            <img src={require("../../Assets/Images/orders/success.png")} alt="Success"
                                                width={24}
                                                height={24}
                                                draggable={false}
                                                className="ms-1" />
                                            : props.description === 'Payment pending' ?
                                                <img src={require("../../Assets/Images/orders/payment-pending.svg").default} alt="Processing"
                                                    width={24}
                                                    height={24}
                                                    draggable={false}
                                                    className="ms-1" />
                                                :
                                                <img src={require("../../Assets/Images/orders/processing.png")} alt="Processing"
                                                    width={24}
                                                    height={24}
                                                    draggable={false}
                                                    className="ms-1" />

                                }
                            </span>
                        </div>
                        :
                        <div className="d-flex">
                            <span className="color-gray e-poppins-medium e-font-14 line-height-24px">{props.title}&nbsp;</span>
                            <span className={`e-poppins-medium e-font-14 line-height-24px color-outer-space`}>
                                {props.symbol === true && <span className="e-inter-medium">₹</span>}
                                {props.description}
                            </span>
                        </div>
                }
            </>
        )
    }

    return (
        <Fragment>
            <div className="e-goal-order-summary e-page-container margin-48px-bottom">
                {/* Header */}
                <InvestmentHeader />

                {/* Breadcrumb */}
                <BreadCrumb data={PAGE_NAVIGATION} />

                {
                    apiLoader === true ?
                        <div className="row">
                            <div className="col-12 text-center h-80vh">
                                <CustomLoader />
                            </div>
                        </div>
                        :
                        <>
                            <h5 className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px margin-32px-bottom-sm-16 margin-32px-top-sm-16">Order Summary - {orderDetail.summary.is_purchase ? 'Purchase' : 'Redeem'}</h5>

                            <div className="row pb-sm-0 pb-5">
                                <div className="col-xl-9 ">
                                    <div className="border-radius-16px border-all border-bright-gray p-sm-4 p-3" >
                                        <div className="d-flex justify-content-between align-items-center padding-18px-bottom border-bottom-1px border-bright-gray">
                                            <div className="d-flex align-items-center gap-sm-12px-8px">
                                                <div className="border-all border-bright-gray border-radius-8px py-2 padding-20px-lr">
                                                    <img src={orderDetail.summary.image}
                                                        alt="Quant"
                                                        width={40}
                                                        height={40}
                                                        className="object-fit-contain " />
                                                </div>
                                                <div>
                                                    <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-24px mb-0">
                                                        {orderDetail.summary.name}
                                                    </p>
                                                </div>
                                            </div>
                                            <p className="color-black e-poppins-medium e-font-24-sm-14 line-height-26px mb-0">
                                                <span className="e-inter-medium">₹</span>{orderDetail.summary.amount.toLocaleString('en-IN')}
                                            </p>
                                        </div>

                                        <div className="row mt-3 mb-sm-3 mb-0">
                                            <div className="col-xl-4 col-6">
                                                <Description title="Order id:" description={orderDetail.summary.order_no} />
                                            </div>
                                            <div className="col-xl-8 col-6 ">
                                                <Description title="Order type:" description={orderDetail.summary.type} />
                                            </div>
                                        </div>
                                        <div className="row mt-sm-0 mt-3">
                                            <div className=" col-12">
                                                <Description title="Placed on:" description={`${orderDetail.summary.order_date} ${orderDetail.summary.order_time}`} />
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className=" col-lg-6">
                                                <Description title="Order status:" description={orderDetail.summary.order_status}
                                                    status={true} />
                                            </div>
                                        </div>

                                        <div className="row mt-sm-4 mt-3">
                                            <div className="col-lg-11">
                                                <OrderFunds data={orderDetail} />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            {
                                                orderDetail.summary.order_status === 'Payment pending' &&
                                                <div className="row mt-3">
                                                    <div className="col-12">
                                                        <OutlineButton label="Pay now"
                                                            className="px-3 padding-12px-tb e-font-16 mt-3"
                                                            handleClick={_getOTP} />
                                                    </div>
                                                </div>
                                            }
                                        </div>


                                        {/* <div className="position-relative margin-32px-top w-100 pb-4">
                                            <div className="e-border-bottom bg-bright-gray">
                                            </div>
                                        </div> */}

                                        {/* <div className="row">
                                            <div className="col-lg-11">
                                                <div className="d-flex justify-content-end me-4 ">
                                                    <GradientButton label="Repeat order"
                                                        className="px-3 padding-12px-tb e-font-16" />
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>

                                </div>
                            </div>
                        </>
                }

            </div>

            {
                openPaymentModal === true && (
                    <PendingPayments data={orderDetail}
                        id={[orderId]}
                        redeem={false}
                        validUser={true}
                        close={_unMountModal}
                        successCallBack={_getOrderDetail}
                        handleInvalidToken={() => console.log('')} />
                )
            }

            <Footer />
        </Fragment>
    )
}

export default GoalOrderSummary