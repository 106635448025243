
/*
 *   File : amount-ship.js
 *   Author URI : https://evoqins.com
 *   Description : UI element for clickable chip for filtering the data
 *   Integrations : null
 *   Version : v1.1
 */

import { useState } from "react";

const Amountchip = (props) => {

    const [chipHover, setChipHover] = useState(null);

    const _handleSelect = () => {
        props.handleChipSelect(props.index);
    }

    const _handleMouseEnter = () => {
        setChipHover(props.index);
    }

    const _handleMouseLeave = () => {
        setChipHover(null);
    }

    return (
        <p className={`border-radius-4px border-all bg-cards e-amount-chip color-gray e-poppins-regular e-font-12 line-height-12px mb-0 p-2 cursor-pointer 
        ${props.amount === props.value ? "border-primary-color" : "border-bright-gray"}
        ${chipHover === props.index && "e-chip-hover"}
        ${props.activeChip === props.index && "e-active-amount-chip"}`}
            onClick={_handleSelect}
            onMouseEnter={_handleMouseEnter}
            onMouseLeave={_handleMouseLeave}>
            <span className="e-inter-regular">₹</span>
            {props.value.toLocaleString('en-IN')}
        </p>
    )
}

export default Amountchip;