
/*
 *   File : notification.js
 *   Author URI : https://evoqins.com
 *   Description : Container file to show the notifications.
 *   Integrations : date-fns
 *   Version : v1.1
 */

import { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

import { BreadCrumb } from "../BreadCrumb"
import { InvestmentHeader } from "../Header"
import Chip from "./chip";
import { DateRangeFilterPopup, NotificationFilterPopup } from "../Popups";
import { NotificationCard } from "../Card";
import Icon from "../Icon/icon";
import Footer from "../Footer/footer";
import EmptyScreen from "./empty-screen";

import APIService from "../../Service/api-service";
import CustomLoader from "./loader";
import Store from "../../Store";
import { DarkModeContext } from "../../Context/dark-mode-context";
import Color from '../../Styles/color.module.scss';

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Notification', url: null },
];

const CHIP_DATA = [
    {
        value: null,
        name: "All"
    },
    {
        value: true,
        name: "Read"
    },
    {
        value: false,
        name: "Unread"
    },
];

const FILTER_BY = [{
    value: null,
    label: "All"
},
{
    value: true,
    label: "Transactions"
},
{
    value: false,
    label: "Non-transactions"
}]

const Notifications = () => {
    const { darkMode } = useContext(DarkModeContext); 
    const navigator = useNavigate();
    const [filterId, setFilterId] = useState(CHIP_DATA[0].value);
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [startDate, setStartDate] = useState("Start date");
    const [endDate, setEndDate] = useState("End date");
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [filterBy, setFilterBy] = useState(FILTER_BY[0]);
    const [pageLoader, setPageLoader] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [notificationList, setNotificationList] = useState([]);
    const [lastItemReached, setLastItemStatus] = useState(false);
    const [loader, setLoader] = useState(true);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        _getNotificationList()
    }, [filterId, startDate, endDate, filterBy, pageNumber]);



    // handler for chip filter
    function _handlefilter(value) {
        setFilterId(value);
        setPageNumber(1);
    }


    const _handleDateRangeChange = (newRange) => {
        setPageNumber(1);
        setShowDateFilter(false);
        setStartDate(format(newRange.startDate, "dd MMM yy"));
        setEndDate(format(newRange.endDate, "dd MMM yy"));
        setSelectedRange(newRange);
    };

    const _handleShowDateFilter = (clear) => {
        setShowDateFilter(!showDateFilter);
        if (clear) {
            setStartDate("Start date");
            setEndDate("End date");
            setSelectedRange({
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            })
        }
    }

    // handler for page change
    const _handlePageNumber = () => {
        setPageNumber(prevCount => prevCount + 1);
    }


    // handler for filter dropdown
    const _handleFilter = (value) => {
        setFilterBy(value);
        setPageNumber(1);
    }

    const _getNotificationList = (read = filterId) => {
        setFilterId(read);
        let start_date = format(selectedRange.startDate, "dd-MM-yyyy")
        let end_date = format(selectedRange.endDate, "dd-MM-yyyy")
        let url = 'notification/get';
        let data = {
            "page_num": pageNumber,
            "read_status": read,
            "is_transactional": filterBy.value,
            "start_date": startDate !== "Start date" ? start_date : null,
            "end_date": endDate !== "End date" ? end_date : null
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                if (response.data.length !== 0) { /* ----  Checking if response length is greater than 0---------*/
                    if (pageNumber > 1) {
                        setNotificationList([...notificationList, ...response.data]); /* ---- append data with previous state------*/
                    } else {
                        setNotificationList(response.data) /* ----setting initial response it filter changes ------*/
                    }
                    if (response.data.length < 16) {
                        setLastItemStatus(true);
                    }else{
                        setLastItemStatus(false);
                    }
                    
                } else {
                    setLastItemStatus(true);
                    if (pageNumber === 1) {
                        setNotificationList([]);  /* ---- empty data only if page number is 1 and no data present, in case of no read notification or no unread notifications ---------*/
                    }
                }
            } else {
                setLastItemStatus(true);
                setNotificationList([]);  /* ---- empty data if api fails ---------*/
            }
            setPageLoader(false);
            setLoader(false);
        });
    }

    const _handleClick = (notification) => {
        if (!notification.is_read) {
            _handleReadNotification(notification);
        } else if (notification.is_transactional === true) {
            const navigation_link = notification.is_goal ? '/orders/goal-order-summary' : '/orders/fund-order-summary';
            navigator(navigation_link, { state: { order_id: notification.extra_data } });
        }
    };

    const _handleReadNotification = (item) => {
        let url = 'notification/read';
        let data = {
            "notification_id": item.notification_id
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                Store.dispatch({
                    type: "NOTIFICATION_COUNT",
                    payload: response.data.unread_notification
                });
                _getNotificationList(true);
            } else {
                setNotificationList([]);
                setLastItemStatus(true);
            }
            setPageLoader(false);
            setLoader(false);
        });
        const navigation_link = item.is_goal ? '/orders/goal-order-summary' : '/orders/fund-order-summary';
        if (item.is_transactional) {
            navigator(navigation_link, { state: { order_id: item.extra_data } });
        }

    }



    return (
        <Fragment>
            {

                <div className="e-page-container margin-80px-bottom">

                    {/* Header */}
                    <InvestmentHeader />
                    {
                        pageLoader === true ?
                            <div className="h-80vh">
                                <CustomLoader />
                            </div>
                            :
                            <>
                                <BreadCrumb data={PAGE_NAVIGATION} />

                                <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px my-sm-4 my-3">All notifications</p>
                                <div className="row">

                                    <div className="col-xl-9">
                                        <div className="d-flex justify-content-between align-items-center flex-wrap margin-32px-bottom gap-16px">
                                            <div className="d-flex gap-8px flex-wrap ">
                                                {
                                                    CHIP_DATA.map((tag, index) => {
                                                        return (
                                                            <Chip data={tag.name}
                                                                key={index}
                                                                type={2}
                                                                chipIndex={tag.value}
                                                                activeChipIndex={filterId}
                                                                onPress={_handlefilter} />
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="d-flex gap-16px e-margin-left-auto">
                                                <div className="position-relative">
                                                    <p className={`border-radius-6px border-all bg-body-color p-2 mb-0 color-outer-space e-poppins-regular d-flex justify-content-between align-items-center e-font-14 line-height-24px cursor-pointer ${startDate !== "Start date" && endDate !== "End date" ? 'border-sea-green' : 'border-bright-gray'}`}
                                                        onClick={() => {
                                                            _handleShowDateFilter(false);
                                                        }}>
                                                        {startDate} - {endDate}
                                                        <Icon icon="calendarIcon"
                                                            className='ms-5'
                                                            size={20}
                                                            color={darkMode === true && Color.white} />
                                                    </p>
                                                    {
                                                        showDateFilter === true &&
                                                        <DateRangeFilterPopup
                                                            maxDate={new Date()}
                                                            initialRange={selectedRange}
                                                            cancel={() => {
                                                                _handleShowDateFilter(true)
                                                            }}
                                                            onUpdate={_handleDateRangeChange} />
                                                    }
                                                </div>
                                                {/* Drop down filter */}
                                                <NotificationFilterPopup value={filterBy}
                                                    options={FILTER_BY}
                                                    onSelectChange={_handleFilter}

                                                />
                                            </div>

                                        </div>
                                        {
                                            notificationList.length !== 0 ?
                                                <div className="d-flex flex-column gap-16px">
                                                    {
                                                        notificationList.map((item, key) =>
                                                            <NotificationCard data={item}
                                                                key={key}
                                                                notificationClick={() => {
                                                                    _handleClick(item)
                                                                }} />)
                                                    }
                                                    {
                                                        lastItemReached === false &&
                                                        <div className="d-flex justify-content-center align-items-center w-100 mt-3"
                                                            onClick={() => _handlePageNumber()}>
                                                            <p className="color-sea-green e-poppins-medium e-font-14 line-height-24px mb-0 me-1 cursor-pointer">
                                                                View more
                                                            </p>
                                                            {
                                                                loader === false ?
                                                                    <Icon icon="right-arrow"
                                                                        size={24}
                                                                        className="e-view-pending cursor-pointer" />
                                                                    :
                                                                    <img src={require("../../Assets/Images/loader.gif")}
                                                                        alt="data loader"
                                                                        width={50} />
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                : null

                                        }
                                    </div>

                                    {
                                        notificationList.length === 0 && (
                                            <div className="row mt-4">
                                                <div className="col-12 text-center h-80">
                                                    <EmptyScreen image={require('../../Assets/Images/nodata/notification.svg').default}
                                                        title="You didn't received any new notification yet."
                                                        buttonLabel="Explore fund"
                                                        onPress={() => navigator('/explore')} />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </>
                    }
                </div>
            }
            <Footer />
        </Fragment>
    )
}

export default Notifications