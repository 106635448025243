/*
 *   File : news.js
 *   Author URI : https://evoqins.com
 *   Description : Card component to list the latest news related to the market
 *   Integrations : null
 *   Version : v1.1
 */

import React from 'react'

import style from '../../Styles/Component/news.module.scss'

const NewsCard = React.memo((props) => {
    return (

        // <div className={`border-radius-16px border-all border-bright-gray bg-white cursor-pointer h-100 ${props.className}`}
        //     onClick={()=> props.viewDetail(props.data)}>
        //     <img src={props.data.image}
        //         alt={props.data.heading}
        //         className={`w-100 mb-3 ${style.e_news_image} ${props.imgClass}`}
        //         draggable={false} />
        //     <div className={props.textPadding ? props.textPadding : `padding-16px-lr  padding-24px-bottom`}>

        //         <p className={props.titleClass ? props.titleClass : `color-outer-space e-poppins-medium e-font-14 line-height-22px truncate-second-line mb-2`}>{props.data.heading}</p>
        //         <div className='d-flex gap-20px'>
        //             {/* <p className={`color-gray e-poppins-regular e-font-14 line-height-22px mb-0 ${style.e_news} `}>{props.data.section_name}</p> */}
        //             <p className='color-gray e-poppins-regular e-font-14 line-height-22px mb-0'>{props.data.released_on}</p>
        //         </div>
        //     </div>

        // </div>

        <div className={`border-radius-16px border-all border-bright-gray bg-news-card e-news-card cursor-pointer h-100 h- ${props.className}`}
            onClick={() => props.viewDetail(props.data)}>
            <div className='row d-sm-flex gx-sm-2 gx-3 align-items-sm-center'>
                <div className='col-lg-3 col-md-4 col-sm-4 col-4 d-sm-block d-none position-relative'>
                    <img src={props.data.image}
                        alt={props.data.heading}
                        className={`w-100 h-100 ${style.e_news_image} ${props.imgClass}`}
                        draggable={false} />
                </div>
                <div className='col-lg-9 col-md-8 col-sm-8 col-12 py-sm-0 py-3 px-sm-0 px-4'>
                    <p className={`e-poppins-regular e-font-14 line-height-22px mb-2 ${style.e_card_content}`}>{props.data.released_on} . {props.data.section_name}</p>
                    <p className={props.titleClass ? props.titleClass : ` e-poppins-medium e-font-14 line-height-22px truncate-second-line mb-2 ${style.e_card_content}`}>
                        {props.data.heading}
                    </p>

                </div>
            </div>
        </div>
    )
})

export default NewsCard 