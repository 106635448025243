
/*
 *   File : external-fund-config
 *   Author : https://evoqins.com
 *   Description : Popup component to re-import or un-link external fund tracking from the MT account.
 *   Integrations : null
 *   Version : 1.0.0
*/

import { useState } from "react"

import Icon from "../Icon/icon";

import style from "../../Styles/Component/external-fund-config.module.scss"


const ManageExternalFundConfig = (props) => {

    const [showRedDeleteIcon, setShowRedDeleteIcon] = useState(false);

    const _handleMouseEnter = () => {
        setShowRedDeleteIcon(true);
    }

    const _handleDeleteEnter = () => {
        setShowRedDeleteIcon(false);
    }

    const _handleImportExternalFunds = () => {
        props.importExternalFunds();
    }

    const _handleDeleteFunds = () => {
        props.deleteExternalFunds();
    }

    const _handleUpdateExternalFunds = () => {
        props.updateExternalFunds();
    }

    return (
        <div className={`position-absolute border-radius-12px border-width-2px border-solid border-bright-gray bg-cards z-index-2 right-0 ${style.e_manage_external_fund}`}>
            {
                props.lastCASImport === null ? (
                    <div className="d-flex gap-8px align-items-center py-2 padding-10px-lr cursor-pointer"
                        onClick={_handleImportExternalFunds}>
                        <Icon icon="refresh-external-case"
                            size={24} />
                        <p className="color-black e-poppins-regular e-font-14 mb-0 white-space-nowrap">Import external CAS</p>
                    </div>
                ) : (
                    <div className="d-flex gap-8px align-items-center py-2 padding-10px-lr cursor-pointer"
                        onClick={_handleUpdateExternalFunds}>
                        <Icon icon="refresh-external-case"
                            size={24} />
                        <p className="color-black e-poppins-regular e-font-14 mb-0 white-space-nowrap">Refresh external CAS</p>
                    </div>
                )
            }

            {
                props.isCASImported === true && (
                    <div className="d-flex gap-8px align-items-center py-2 padding-10px-lr cursor-pointer"
                        onClick={_handleDeleteFunds}>
                        <Icon icon={showRedDeleteIcon === true ? "delete-red" : "delete"}
                            size={24}
                            onMouseEnter={_handleMouseEnter}
                            onMouseLeave={_handleDeleteEnter} />
                        <p className="color-black e-poppins-regular e-font-14 mb-0 white-space-nowrap">Delete external funds</p>
                    </div>
                )
            }

        </div>
    )
}

export default ManageExternalFundConfig