/*
 *   File : dashboard.js
 *   Author URI : https://evoqins.com
 *   Description : Dashboard page
 *   Integrations : react-onesignal
 *   Version : v1.1
 */


import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector, } from "react-redux";
import OneSignal from 'react-onesignal';
import { Modal } from "bootstrap";
// import { useNavigate } from "react-router";
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import SIPCalculator from "../../Component/Calculator/sip"
import { FeaturedInvestmentCard, FundCard, GoalCard, NewsCard, InvestmentSummaryCard, StartInvesting, ImportExternalFund } from "../../Component/Card"
import Footer from "../../Component/Footer/footer"
import { SectionTitle } from "../../Component/Title"
import { GradientButton, LinkText, } from "../../Component/CTA"
import { AccountFeesSlider, NotificationCardSlider } from "../../Component/Slider";
import { Chip, CustomLoader } from "../../Component/Other";
import { InvestmentHeader } from "../../Component/Header";
import { BarChart, DoughnutChart, SegmentChart } from "../../Component/Chart";
import { CustomTab } from "../../Component/Tab"
import { ImportFundModal, RiskScorePermissionModal, NewsModal, ViewIndicesBenchmark, KYCConfirmationModal, AnnouncementModal } from "../../Component/Modal"
import { updateNavigation } from "../../Store/Action/action";

import Store from "../../Store";
import APIService from "../../Service/api-service";
import { DarkModeContext } from "../../Context/dark-mode-context";

// listener for session storage
const useSessionStorageListener = (callback) => {
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.type === 'sessionStorageChange') {
                callback(event.detail);
            }
        };

        window.addEventListener('sessionStorageChange', handleStorageChange);

        return () => {
            window.removeEventListener('sessionStorageChange', handleStorageChange);
        };
    }, [callback]);
};

const Dashboard = React.memo(() => {

    const USER_NAME = useSelector(state => state.generalData.PROFILE_DATA.name);
    const PROFILE_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {darkMode} = useContext(DarkModeContext);

    const initialRender = useRef(true);

    const [loader, setLoader] = useState(true);
    const [showFundsModal, setShowFundsModal] = useState(false);
    const [openQuestionnaireModal, setOpenQuestionnaireModal] = useState(false);
    const [portfolioPerformanceTabIndex, setPortfolioPerformanceTabIndex] = useState(1);
    const [chartAccordion, setChartAccordion] = useState(false);
    const [dashboardData, setDashboardData] = useState({});
    const [isFundImported, setIsFundImported] = useState(false);
    const [notificationId, setNotificationId] = useState(null);
    const [performanceFilterId, setPerformanceFilterId] = useState(1);
    const [performanceData, setPerformanceData] = useState(null);
    const [performanceComparisonData, setPerformanceComparisonData] = useState({});
    const [selectedNews, setSelectedNews] = useState({});
    const [openNewsDetail, setOpenNewsDetail] = useState(false);
    const [indicesData, setIndicesData] = useState([]);
    const [openIndicesModal, setOpenIndicesModal] = useState(false);
    const [showKycConfirmModal, setShowKycConfirmModal] = useState(false);
    const [hideNotification, setHideNotification] = useState(false);
    const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        _getPerformanceData();
        if (sessionStorage.getItem('show_sip_reminder')) {
            setHideNotification(true);
        }
    }, []);

    useEffect(() => {
        if (showKycConfirmModal === true) {
            _openKycConfirmModal()
        }
    }, [showKycConfirmModal]);

    useEffect(() => {
        if (initialRender.current == false) {
            _getPerformanceData();
        }
    }, [performanceFilterId]);

    useEffect(() => {
        if (openQuestionnaireModal === true) {
            _openQuestionnaireModal();
        }
    }, [openQuestionnaireModal]);

    useEffect(() => {
        if (notificationId !== null) {
            _saveNotificationDetails();
        }
    }, [notificationId]);

    // listener for opening unlock modal
    useEffect(() => {
        if (showFundsModal === true) {
            _openFundsModal();
        }
    }, [showFundsModal]);

    useEffect(() => {
        if (openIndicesModal === true) {
            _openIndicesModal();
        }
    }, [openIndicesModal]);

    useEffect(() => {
        // show the risk profiler modal if the customer haven't done 
        if (dashboardData.risk_assessment_completed === false && loader == false && dashboardData.announcement_banner === null && PROFILE_DATA.is_kyc_done === true) {
            let shown_risk_modal = Cookies.get('risk_score_shown');
            if (shown_risk_modal == null) {
                setOpenQuestionnaireModal(true);
            }
        }
    }, [dashboardData, loader]);

    useEffect(() => {
        let show_announcement_modal = sessionStorage.getItem('announcement_shown');
        if (showAnnouncementModal === true && show_announcement_modal === null && loader === false) {
            _openAnnouncementModal();
        }
    }, [showAnnouncementModal, loader]);

    /*--------- get user ID from one signal ---------*/
    useEffect(() => {
        if (OneSignal.User.PushSubscription.id) {
            setNotificationId(OneSignal.User.PushSubscription.id);
        }
    }, [OneSignal.User.PushSubscription.id]);


    useEffect(() => {
        if (openNewsDetail === true) {
            const modal = new Modal(document.getElementById("news-modal"), {});
            modal.show();
        }
    }, [openNewsDetail]);

    useEffect(() => {
        if (loader === false) {
            if (PROFILE_DATA.kyc_confirmation_required === true) {
                setShowKycConfirmModal(true);
            }
        }
    }, [loader]);

    useSessionStorageListener((storage) => {
        setHideNotification(storage.value);
    });

    //to open funds modal
    const _openFundsModal = () => {
        const modal = new Modal(document.getElementById("import-fund"), {});
        modal.show();
    };

    const _openKycConfirmModal = () => {
        const modal = new Modal(document.getElementById("kyc-confirmation"), {});
        modal.show();
    }

    function _openIndicesModal() {
        const modal = new Modal(document.getElementById("view-indices"), {});
        modal.show();
    }

    function _openAnnouncementModal() {
        const modal = new Modal(document.getElementById("platform-announcement"), {});
        modal.show();
    }

    function _closeIndicesModal() {
        setOpenIndicesModal(false);
    }

    function _closeAnnouncementModal() {
        setShowAnnouncementModal(false);
    }


    //to open questionnaire modal
    const _openQuestionnaireModal = () => {
        const modal = new Modal(document.getElementById("questionnaire-modal"), {});
        modal.show();
    };

    const _handleContinue = () => {
        setOpenQuestionnaireModal(false);
        navigate("/profile/risk-assessment");
        dispatch(updateNavigation("/profile/risk-assessment"));
    };

    function _handleReturnsFilter(selected_filter_index) {
        // setReturnsFilter(index);
        setPerformanceFilterId(selected_filter_index);
    }

    const _navigateToGoals = (goal_id) => {
        let page = ""
        switch (goal_id) {
            case 1:
                page = "retirement"
                break;
            case 2:
                page = "house"
                break;
            case 3:
                page = "education"
                break;
            case 4:
                page = "car"
                break;
            case 5:
                page = "vacation"
                break;
            case 6:
                page = "custom-goal"
                break;

            default:
                break;
        }
        navigate(`/goal/${page}`, {
            state: {
                id: goal_id
            }
        });
    }

    const _navigateToExplore = () => {
        navigate("/explore",
            {
                state: {
                    amc: [null],
                    rating: null,
                    fund_category: [null],
                    fund_type: [null],
                    theme: [null],
                    minimum_investment: null,
                    search_text: "",
                    filter_by: 'popular_funds',
                    filter_by_tag: "Popular Funds",
                    current: location.pathname,
                    page_num: 1,
                    sort_by: { value: 5, label: 'Returns - High to Low' }
                }
            });
    }



    // API - get dashboard data
    const _getDashboardData = () => {
        let url = "home/get";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setDashboardData(response.data);
                if (response.data.announcement_banner) {
                    setShowAnnouncementModal(true);
                }

            }
            setLoader(false);
            initialRender.current = false;
        });
    }

    // API - Get portfolio performance data
    const _getPerformanceData = () => {
        let is_post = true;
        let url = 'home/portfolio-performance';
        let params = {
            filter_id: performanceFilterId
        };

        APIService(is_post, url, params).then((response) => {
            if (response.status_code === 200) {
                let active_segments = [];
                let returns = response.data.returns;
                let labels = response.data.returns.label;
                for (let index = 0; index < response.data.returns.label.length; index++) {
                    let segment = {
                        x: labels[index],
                        y: 0
                    };
                    active_segments.push(segment);
                }
                returns.activeSegments = active_segments;
                setPerformanceData(returns);
                let chart_data = {
                    labels: ['Index', 'Your returns'],
                    data: [response.data.compare.index_returns, response.data.compare.returns],
                    color: ['#64CFF6', '#756BFF']
                };
                setPerformanceComparisonData(chart_data);
                setIndicesData(response.data.index);
            }
            _getDashboardData();
        });
    }

    // API - Save notification detail 
    const _saveNotificationDetails = () => {
        let url = 'notification/save-id';
        let data = {
            player_id: notificationId
        };

        APIService(true, url, data).then((response) => {
            // if(response.status_code === 200 )
        });
    }

    function _openNewsDetailModal(data) {
        setOpenNewsDetail(true);
        setSelectedNews(data);
    }

    function _unMountNewsDetailModal() {
        setOpenNewsDetail(false);
        setSelectedNews({});
    }

    const _handleNavigateNews = () => {
        navigate("/news")
    }

    const _handleNavigate = () => {
        navigate("/orders", { state: { order_status: 1 } });
    }

    const _closeKycConfrimation = (status) => {
        setShowKycConfirmModal(false);
    }

    const PendingTransaction = (props) => {
        return (
            <div className="border-radius-16px bg-pale-lavender w-mobile-100">
                <div className="d-flex gap-8px align-items-center p-3 border-bottom-1px border-lavender-blue">
                    <img src={props.image}
                        alt={props.title}
                        width={24}
                        height={24} />
                    <p className="color-black e-poppins-medium e-font-16 line-height-20px mb-0">{props.title}</p>
                </div>
                <div className="row">
                    <div className="col-6 text-center border-right-1px-lavender-blue pe-0">
                        <div className="py-2 padding-18px-lr">
                            <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-1">{props.item.no_of_orders}</p>
                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-20px mb-0 ">Total orders</p>
                        </div>
                    </div>
                    <div className="col-6  text-center ps-0">
                        <div className="py-2 padding-18px-lr">
                            <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-1"><span className="e-inter-medium">₹</span>{props.item.amount.toLocaleString("en-IN")}</p>
                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-20px mb-0 white-space-nowrap">Approx. amt</p>
                        </div>
                    </div>
                </div>

            </div>

        )
    }

    return (
        //e-page-container for min height

        <>
            {/* Heading */}
            <div className="e-dashboard padding-10px-mob-top">
                <InvestmentHeader />
            </div>
            {
                loader === true ?
                    <div className="h-100vh">
                        <CustomLoader />
                    </div>
                    :
                    <>
                        <div className="e-dashboard ">

                            <div className="row d-flex justify-content-between margin-120px-lg-top margin-14px-md-top margin-32px-bottom-sm-16">
                                <div className="col-xl-8 col-lg-6 col-md-6 ">
                                    {/* Name */}
                                    <div className="d-flex align-items-center gap-10px mb-3 mt-2">
                                        <img src={require("../../Assets/Images/dashboard/waving-hand.png")} alt="Waving hand"
                                            width={22}
                                            height={22}
                                            draggable={false} />
                                        <h6 className="color-black e-poppins-bold e-font-18-sm-14 mb-0 text-transform-capitalize">Hey {USER_NAME}</h6>
                                    </div>
                                    {
                                        dashboardData && dashboardData.is_invested === true && (
                                            <h3 className="color-black e-inter-bold e-font-24-sm-18 mb-0">Your Investment Summary </h3>
                                        )
                                    }

                                </div>
                                {
                                    dashboardData.upcoming_sip.data.length > 0 && hideNotification === false &&
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10 mt-2">
                                        <NotificationCardSlider data={dashboardData.upcoming_sip.data} />
                                    </div>
                                }

                            </div>

                            {/* Portfolio summary */}
                            {
                                dashboardData && dashboardData.is_invested === false ? (
                                    <div className="row mb-4">
                                        <div className="col-12">
                                            <StartInvesting isCASImported={dashboardData.is_cas_imported} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row mb-sm-4 mb-3">
                                        <div className="col-xl-8  h-100">
                                            <InvestmentSummaryCard data={dashboardData.portfolio} />
                                        </div>


                                        <div className='example col-xl-4 d-xl-block d-none'>
                                            <div className="bg-greenish-yellow border-radius-24px p-4 h-100 max-h-25">
                                                <div className="col-12 text-center mb-3">
                                                    <h6 className="color-black e-poppins-semi-bold e-font-16">Portfolio performance</h6>
                                                </div>

                                                {
                                                    dashboardData.is_portfolio_available === false ? (
                                                        <div className='h-90 d-flex align-items-center justify-content-center'>
                                                            <div className='color-gray e-poppins-regular e-font-14 w-max-content  h-max-content'>
                                                                Your portfolio performance will update here
                                                            </div>
                                                        </div>
                                                    ) :
                                                        <>
                                                            <CustomTab data={['Returns', 'Compare']}
                                                                wrapperClass={'justify-content-center'}
                                                                className="w-50"
                                                                onSelectTab={(index) => setPortfolioPerformanceTabIndex(index)} />
                                                            {
                                                                portfolioPerformanceTabIndex === 1 ?
                                                                    <>
                                                                        <div className="col-12 mt-3 mb-2 pb-3  d-flex gap-8px flex-wrap justify-content-center">
                                                                            {
                                                                                dashboardData.performance_filter_data.map((tag, index) => {
                                                                                    return (
                                                                                        <Chip data={tag.label}
                                                                                            chipIndex={tag.id}
                                                                                            activeChipIndex={performanceFilterId}
                                                                                            className="e-font-12 border-all e-poppins-medium padding-5px-tb padding-10px-lr color-outer-space bg-transparent border-may-green border-radius-54px"
                                                                                            activeClassName={`e-font-12 border-all e-poppins-medium padding-5px-tb padding-10px-lr color-white border-radius-54px border-transparent ${darkMode === true ? 'bg-ocean-boat-blue' : 'bg-may-green' }`}
                                                                                            onPress={_handleReturnsFilter} />
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        {
                                                                            performanceData !== null &&
                                                                            <div className="col-12 ps-4">
                                                                                <SegmentChart data={performanceData}
                                                                                    type={1} />
                                                                            </div>
                                                                        }

                                                                    </>
                                                                    :
                                                                    <>
                                                                        {
                                                                            Object.keys(performanceComparisonData).length !== 0 &&
                                                                            <BarChart data={performanceComparisonData}
                                                                                chartId="portfolioBar" />
                                                                        }
                                                                    </>

                                                            }
                                                            {
                                                                portfolioPerformanceTabIndex === 2 && indicesData.length > 0 &&
                                                                <p className="e-poppins-regular e-font-12 color-outer-space mt-3">
                                                                    *Indices return average is calculated based on the benchmark index of your invested funds.
                                                                    <LinkText title="View benchmark indices"
                                                                        className="e-view-indices"
                                                                        icon="arrow-right"
                                                                        onClick={() => setOpenIndicesModal(true)} />
                                                                </p>
                                                            }

                                                        </>
                                                }


                                            </div>
                                        </div>

                                    </div>
                                )
                            }

                            {
                                isFundImported === true && (
                                    <div className="row">
                                        <div className="col-md-8 col-12 mb-4">
                                            <ImportExternalFund />
                                        </div>
                                    </div>
                                )
                            }

                            {/* chart accordion */}

                            {
                                dashboardData && dashboardData.is_invested === true &&
                                <div class="accordion accordion-flush d-xl-none d-block" id="charts">
                                    <div class="accordion-item e-dashboard-accordion">
                                        <h2 class="accordion-header color-black e-poppins-semi-bold e-font-14" id="chart-one">
                                            <button class="accordion-button collapsed e-chart-accordion"
                                                type="button"
                                                data-show={`${chartAccordion}`}
                                                // data-bs-toggle="collapse" 
                                                // data-bs-target="#collapse-chart-one" 
                                                aria-expanded="false"
                                                aria-controls="collapse-chart-one"
                                                onClick={() => setChartAccordion(!chartAccordion)}>
                                                Portfolio
                                            </button>
                                        </h2>

                                        <div id="collapse-chart-one"
                                            class={`accordion-collapse ${chartAccordion === false && 'collapse'}`}
                                            aria-labelledby="chart-one"
                                            data-bs-parent="#charts">
                                            <div class="accordion-body px-0">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="bg-greenish-yellow border-radius-16px p-3 h-100">

                                                            <h6 className='color-black e-poppins-semi-bold e-font-14'>Portfolio breakdown</h6>
                                                            <div className=' position-relative pb-5 pt-5 d-flex justify-content-center'>
                                                                {
                                                                    dashboardData.portfolio && dashboardData.portfolio.breakdown?.sip !== 0 && dashboardData.portfolio.breakdown?.lumpsum !== 0 ?
                                                                        <DoughnutChart type={3}
                                                                            data={{
                                                                                labels: ['SIP', 'Lumpsum'],
                                                                                data: [dashboardData.portfolio.breakdown?.sip, dashboardData.portfolio.breakdown?.lumpsum],
                                                                                color: ['#64CFF6', '#756BFF']
                                                                            }} />
                                                                        :
                                                                        <div className='color-gray e-poppins-regular e-font-14 w-max-content h-max-content'>
                                                                            No investment exists in your portfolio.
                                                                        </div>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 pt-md-0 pt-4">

                                                        <div className="bg-greenish-yellow border-radius-24px p-4 h-100">
                                                            <div className="col-12  mb-3">
                                                                <h6 className="color-black e-poppins-semi-bold e-font-14">Portfolio performance</h6>
                                                            </div>

                                                            {
                                                                dashboardData.is_portfolio_available === false ? (
                                                                    <div className='pb-5 pt-5 d-flex align-items-center justify-content-center'>
                                                                        <div className='color-gray e-poppins-regular e-font-14  h-max-content'>
                                                                            Your portfolio performance will update here
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <CustomTab data={['Returns', 'Compare']}
                                                                            wrapperClass={'justify-content-center'}
                                                                            className="w-50"
                                                                            onSelectTab={(index) => setPortfolioPerformanceTabIndex(index)} />
                                                                        {
                                                                            portfolioPerformanceTabIndex === 1 ? (
                                                                                <>
                                                                                    <div className="col-12 mt-3 mb-4 pb-3  d-flex gap-8px flex-wrap justify-content-center">
                                                                                        {
                                                                                            dashboardData.performance_filter_data.map((tag, index) => {
                                                                                                return (
                                                                                                    <Chip data={tag.label}
                                                                                                        chipIndex={tag.id}
                                                                                                        activeChipIndex={performanceFilterId}
                                                                                                        className="e-font-12 border-all e-poppins-medium padding-5px-tb padding-10px-lr color-outer-space bg-transparent border-may-green border-radius-54px"
                                                                                                        activeClassName="e-font-12 border-all e-poppins-medium padding-5px-tb padding-10px-lr color-white bg-may-green border-radius-54px border-transparent"
                                                                                                        onPress={_handleReturnsFilter} />
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        performanceData !== null &&
                                                                                        <div className="col-12 ps-4">
                                                                                            <SegmentChart data={performanceData}
                                                                                                type={2} />
                                                                                        </div>
                                                                                    }

                                                                                </>

                                                                            ) : (
                                                                                <>
                                                                                    {
                                                                                        Object.keys(performanceComparisonData).length !== 0 &&
                                                                                        <BarChart data={performanceComparisonData}
                                                                                            chartId="barChart" />
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            }


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                dashboardData.pending_orders.amount !== 0 &&
                                <div className="border-radius-16px bg-lavender p-4 margin-32px-top-sm-16 position-relative">
                                    <div className="d-flex flex-wrap align-items-center gap-24px">
                                        <div>
                                            <p className="color-black e-poppins-semi-bold e-font-16 margin-10px-bottom">Pending transaction</p>
                                            <div className="d-flex align-items-center flex-wrap gap-8px e-pending-transaction-card">
                                                <p className="color-primary-color e-poppins-bold e-font-24 line-height-24px mb-0 me-1"><span className="e-inter-bold">₹</span>{dashboardData.pending_orders.amount.toLocaleString('en-IN')}</p>
                                                <p className="color-outer-space e-poppins-medium e-font-12 line-height-18px mb-0 ">(To receive reflection, wait for T+2 days for equity based schemes and T+1 for debt based schemes)</p>
                                            </div>
                                            <GradientButton label="View orders"
                                                className="px-3 py-2 margin-14px-top"
                                                handleClick={() => _handleNavigate()} />
                                        </div>
                                        <PendingTransaction item={dashboardData.pending_orders.purchase}
                                            image={require("../../Assets/Images/portfolio/purchase-order.png")}
                                            title="Purchase orders" />
                                        <PendingTransaction item={dashboardData.pending_orders.withdrawal}
                                            image={require("../../Assets/Images/portfolio/withdrawal-order.png")}
                                            title="Withdrawal orders" />
                                    </div>
                                    <img src={darkMode === true ? require("../../Assets/Images/dashboard/sip-calculator-dark.svg").default : require("../../Assets/Images/portfolio/transaction-bg-image.svg").default}
                                        alt="Pending transaction"
                                        width="150"
                                        className="position-absolute right-0 top-0 border-radius-right-16px" />
                                </div>
                            }
                        </div>



                        <div className="e-featured-invest">
                            {/* Featured investment */}
                            <FeaturedInvestmentCard quickInvest={dashboardData.quick_invest}
                                mutualFundCategories={dashboardData.category} />
                        </div>
                        {/* large screen */}
                        <div className="e-dashboard-sm-0 pt-0">
                            {/* Life goals */}
                            <div className="padding-24px-all-sm-px-0-py-16 border-radius-30px-sm-0 bg-dashboard-goal-list position-relative margin-56px-top-sm-0">
                                <SectionTitle title="Life goals"
                                    className="position-relative z-index-1 mb-0 ps-sm-0 ps-3 ms-sm-0 ms-2" />
                                <div className=" d-sm-block d-none">
                                    <div className="row">
                                        {
                                            dashboardData.goal_list.map((item, key) =>
                                                <div className="col-lg-3 col-md-6 col-sm-6 padding-24px-top"
                                                    key={key}>
                                                    <GoalCard data={item}
                                                        type={1}
                                                        handleClick={_navigateToGoals} />
                                                </div>
                                            )
                                        }
                                        <div className="col-12 d-flex justify-content-center padding-30px-top">
                                            <LinkText title="See more"
                                                icon="arrow-right"
                                                onClick={_navigateToGoals} />
                                        </div>
                                    </div>
                                </div>

                                <div className=" d-sm-none d-block ps-sm-0 ps-1">
                                    <div className='e-life-goal px-3'>
                                        <div className='d-flex gap-16px margin-16px-bottom'>
                                            {
                                                dashboardData.goal_list.map((item, key) =>
                                                    <div className="mt-3" key={key}>
                                                        <GoalCard data={item}
                                                            type={1}
                                                            handleClick={_navigateToGoals}
                                                            className="w-200px" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex justify-content-center ">
                                        <LinkText title="See more"
                                            icon="arrow-right"
                                            onClick={_navigateToGoals} />
                                    </div>
                                </div>

                                <img src={darkMode === true ? require("../../Assets/Images/dashboard/life-goal-bg-dark.svg").default : require("../../Assets/Images/dashboard/life-goals-bg.svg").default}
                                    alt="Life goals"
                                    draggable={false}
                                    className="position-absolute e-life-goals top-0 h-100 d-lg-block d-none" />
                            </div>
                        </div>

                        {/* small screen */}

                        <div className="e-dashboard  d-lg-block d-md-block d-sm-block d-none pt-0">
                            {/* Account opening fees */}
                            <div className="margin-48px-top e-accound-slider">
                                <AccountFeesSlider data={dashboardData.banner}
                                    timer={dashboardData.banner_time_gap} />
                            </div>
                        </div>

                        {/* large screen */}
                        <div className="e-dashboard  d-lg-block d-md-block d-sm-block d-none pt-0">
                            {/* Popular funds */}
                            <div className="margin-82px-top">
                                <FundCard data={dashboardData.popular_funds} />
                                <div className="col-12 d-flex justify-content-center">
                                    <LinkText icon="arrow-right"
                                        className="text-center margin-34px-top"
                                        onClick={_navigateToExplore} />
                                </div>
                            </div>

                            {/* News */}
                            <div className="margin-56px-top">
                                <SectionTitle title="News"
                                    className="mb-4" />
                                <div className="row">
                                    {
                                        dashboardData.news.map((item, key) =>
                                            <div className="col-12 mb-2"
                                                key={key}>
                                                <NewsCard data={item}
                                                    viewDetail={_openNewsDetailModal} />
                                            </div>
                                        )
                                    }
                                    <div className="col-12 d-flex justify-content-center">
                                        <LinkText title="See all news"
                                            icon="arrow-right"
                                            className="margin-32px-top"
                                            onClick={_handleNavigateNews} />
                                    </div>
                                </div>
                            </div>

                            {/* SIP calculator */}
                            <div className="margin-64px-top margin-80px-bottom">
                                <SIPCalculator />
                            </div>

                            {/* Footer */}
                        </div>

                        {/* small screen */}
                        <div className="overflow-x-hidden d-lg-none d-md-none d-sm-none d-block">
                            {/* Popular funds */}
                            <div className="margin-42px-top-sm-16">
                                <FundCard data={dashboardData.popular_funds} />
                                <div className="col-12 d-flex justify-content-center">
                                    <LinkText icon="arrow-right"
                                        className="text-center "
                                        onClick={_navigateToExplore} />
                                </div>
                            </div>

                            {/* News */}
                            <div className="">
                                <SectionTitle title="News"
                                    className="mb-0 margin-18px-left" />
                                <div className="row padding-18px-lr pt-2">
                                    {
                                        dashboardData.news.map((item, key) =>
                                            <div className="col-12 mb-2"
                                                key={key}>
                                                <NewsCard data={item}
                                                    // imgClass="object-fit-contain"
                                                    viewDetail={_openNewsDetailModal} />
                                            </div>
                                        )
                                    }
                                    <div className="col-12 d-flex justify-content-center">
                                        <LinkText title="See all news"
                                            icon="arrow-right"
                                            className=""
                                            onClick={_handleNavigateNews} />
                                    </div>
                                </div>
                                {/* <div className="d-flex gap-16px padding-16px-lr e-news-mobile-scroll">
            {
                dashboardData.news.map((item, key) =>
                    <div className=" padding-24px-top"
                        key={key}>
                        <NewsCard data={item} className="e-news-mobile"
                            imgClass="e-news-img-mobile"
                            textPadding="pt-2 padding-12px-all"
                            titleClass="color-black e-poppins-medium e-font-12 line-height-22px truncate-second-line mb-1" />
                    </div>
                )
            }
        </div>
    
    <div className=" text-center">
        <LinkText title="See all news"
            icon="arrow-right"
            className="mt-3" />
    </div> */}
                            </div>

                            {/* SIP calculator */}
                            <div className="margin-50px-top-sm-16 margin-56px-bottom pb-sm-0 pb-4">
                                <SIPCalculator />
                            </div>

                            {/* Footer */}
                        </div>


                        <Footer />
                    </>
            }

            {
                showFundsModal === true ?
                    <ImportFundModal
                        close={() => setShowFundsModal(false)} />
                    :
                    null
            }

            {
                openQuestionnaireModal === true ?
                    <RiskScorePermissionModal userName={USER_NAME}
                        continue={_handleContinue} />
                    :
                    null
            }
            {
                openNewsDetail === true && (
                    <NewsModal data={selectedNews}
                        close={_unMountNewsDetailModal} />
                )
            }

            {
                openIndicesModal === true && (
                    <ViewIndicesBenchmark data={indicesData}
                        close={_closeIndicesModal} />
                )
            }

            {
                showKycConfirmModal === true && (
                    <KYCConfirmationModal close={_closeKycConfrimation} />
                )
            }

            {
                showAnnouncementModal === true && (
                    <AnnouncementModal data={dashboardData.announcement_banner}
                        close={_closeAnnouncementModal} />
                )
            }
        </>
    )
})

export default Dashboard