/*
 *   File : listing.js
 *   Author URI : https://evoqins.com
 *   Description : Listing page for mt-transcations
 *   Integrations : NA
 *   Version : v1.1
 */

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Icon from "../../Component/Icon/icon";
import BreadCrumb from "../../Component/BreadCrumb/breadcrumb";
import Footer from "../../Component/Footer/footer";
import { InvestmentHeader } from "../../Component/Header";
import { Chip } from "../../Component/Other";
import { CustomTab } from "../../Component/Tab";
import { CustomLoader, EmptyScreen } from "../../Component/Other";
import { DataTable } from "../../Component/Form-elements";

import Colors from '../../Styles/color.module.scss';

import APIService from "../../Service/api-service";

import NoData from '../../Assets/Images/nodata/order.svg';

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Manage STP & SWP', url: null },
];

const TAB_DATA = ['STP', 'SWP'];

const FILTER_DATA = [
    { id: 1, label: 'Active' },
    { id: 2, label: 'Cancelled' },
];


const customStyles = {
    headCells: {
        style: {
            '&:not(:first-child)': {
                justifyContent: 'center',
            },
        },
    },
    rows: {
        style: {
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            justifyContent: "center",
        }
    }
};


export default function TransactionListing(props) {

    const navigator = useNavigate();
    const location = useLocation();
    const [tableData, setTableData] = useState([]);
    const [showArrow, setShowArrow] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);
    const [orderStatus, setOrderStatus] = useState(1);
    const [apiLoader, setApiLoader] = useState(true);
    const [listData, setListData] = useState([]);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const STP_COLUMNS = [
        {
            name: 'Funds',
            selector: row => row['name'],
            left: true,
            cell: row =>
                <div className="d-flex gap-12px e-fund-name"
                    onClick={() => _handleCardClick(row)}>
                    <img src={row['image']} alt={row['name']}
                        width={46}
                        height={46}
                        className="padding-6px-all border-radius-8px border-all border-bright-gray object-fit-contain" />
                    <div>
                        <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                            Transfer Out
                        </p>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px  mb-0">{row['name']}</p>
                        <img src={require('../../Assets/Images/orders/stp-arrow.png')}
                            className="e-arrow-down my-3 w-35px" />
                        <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                            Transfer In
                        </p>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-2">{row['switch_in_fund_name']}</p>
                    </div>

                </div>
        },
        {
            name: 'Type',
            sortable: false,
            left: true,
            width: "150px",
            style: {
                color: Colors.black,
                fontFamily: "Poppins-SemiBold",
                fontSize: "16px",
                lineHeight: "18px",
            },
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-24px  mb-2">STP</p>
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: false,
            left: true,
            width: "100px",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-medium ">₹</span>{row['amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Frequency',
            selector: row => row['frequency'],
            sortable: false,
            left: true,
            width: "100px",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    {row['frequency']}
                </p>
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: false,
            left: true,
            width: "150px",
            style: {
                color: Colors.black,
                fontFamily: "Poppins-Medium",
                fontSize: "14px",
                lineHeight: " 18px"
            }
        },
        {
            name: orderStatus === 1 ? 'STP date' : 'Cancelled date',
            selector: row => orderStatus === 1 ? row['next_sip_date'] : row['cancelled_on'],
            sortable: false,
            left: true,
            width: "200px",
            center: true,
            style: {
                color: Colors.black,
                fontFamily: "Poppins-Medium",
                fontSize: "14px",
                lineHeight: " 18px"
            }

        },
        {
            name: '',
            sortable: false,
            left: true,
            width: "24px",
            cell: row =>
                <div className={`e-fund-order-hover text-start ${showArrow === row.stp_id ? 'show' : ''}`} data-tag="allowRowEvents"
                    onClick={() => _handleCardClick(row)}>
                    <Icon icon="arrow-right1"
                        size={24}
                        color={Colors.black} />
                </div>

        }

    ];

    const SWP_COLUMNS = [
        {
            name: 'Funds',
            selector: row => row['name'],
            left: true,
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name"
                    onClick={() => _handleCardClick(row)}>
                    <img src={row['image']} alt={row['name']}
                        width={46}
                        height={46}
                        className="padding-6px-all border-radius-8px border-all border-bright-gray object-fit-contain" />
                    <div>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px  mb-2">{row['name']}</p>
                        {
                            row['sip_tag'] !== null || row['sip_tag'] !== undefined && (
                                <span className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px ">
                                    {row['sip_tag']}
                                </span>
                            )
                        }

                    </div>

                </div>
        },
        {
            name: 'Type',
            sortable: false,
            left: true,
            width: "150px",
            style: {
                color: Colors.black,
                fontFamily: "Poppins-SemiBold",
                fontSize: "16px",
                lineHeight: "18px",
            },
            cell: row =>
                <p className="e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                    SWP
                </p>
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: false,
            left: true,
            width: "100px",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-medium ">₹</span>{row['amount'].toLocaleString('en-IN')}
                </p>
        },
        {
            name: 'Frequency',
            selector: row => row['frequency'],
            sortable: false,
            left: true,
            width: "120px",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    {row['frequency']}
                </p>
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: false,
            left: true,
            width: "180px",
            style: {
                color: Colors.black,
                fontFamily: "Poppins-SemiBold",
                fontSize: "16px",
                lineHeight: "18px",
            },
            cell: row =>
                <p className=" e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                    {row['status']}
                </p>
        },
        {
            name: orderStatus === 1 ? 'SWP date' : 'Cancelled date',
            selector: row => orderStatus === 1 ? row['next_sip_date'] : row['cancelled_on'],
            sortable: false,
            left: true,
            width: "200px",
            center: true,
            style: {
                color: Colors.black,
                fontFamily: "Poppins-Medium",
                fontSize: "14px",
                lineHeight: " 18px"
            }

        },
        {
            name: '',
            sortable: false,
            left: true,
            width: "24px",
            cell: row =>
                <div className={`e-fund-order-hover text-start ${showArrow === row.swp_id ? 'show' : ''}`} data-tag="allowRowEvents"
                    onClick={() => _handleCardClick(row)}>
                    <Icon icon="arrow-right1"
                        size={24}
                        color={Colors.black} />
                </div>

        }

    ];

    useEffect(() => {
        function _handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleResize);

        return () => {
            window.removeEventListener('resize', _handleResize);
        }
    }, []);

    useEffect(() => {
        if (location.state) {
            setApiLoader(true);
            if (location.state.status) {
                setOrderStatus(location.state.status);
            }
            if (location.state.tabIndex) {
                setTabIndex(location.state.tabIndex);
            }

        } else {
            setTabIndex(1);
        }
    }, [location]);

    useEffect(() => {
        if (tabIndex === 1) {
            _getSTPList();
        } else if (tabIndex === 2) {
            _getSWPList();
        }

    }, [tabIndex])


    // handle tab change
    function _handleTabSelect(index) {
        setApiLoader(true);
        setOrderStatus(1);
        setTabIndex(index);
    }

    // handler for chip filter
    function _handleChipFilter(filter) {

        if (filter !== orderStatus) {
            setOrderStatus(filter);
            if (tabIndex === 1) {
                _getSTPList(filter)
            } else {
                _getSWPList(filter)
            }
        }
    };

    function _handleCardClick(row) {
        if (orderStatus !== 1) {
            return;
        }
        if (tabIndex === 1) {
            navigator("summary", { state: { fund_id: row['stp_id'], type: 'stp' } });
        } else {
            navigator("summary", { state: { fund_id: row['swp_id'], type: 'swp' } });
        }

    }

    function _handleRowHover(row) {
        if (tabIndex === 1) {
            setShowArrow(row.stp_id);
        } else {
            setShowArrow(row.swp_id);
        }
    }

    function _handleRowRemoveHover() {
        setShowArrow(0);
    }

    function _handleAPIResponse(response) {
        if (response.status_code === 200) {
            setListData(response.data);
        } else {
            setListData([]);
        }
        setApiLoader(false);
    }

    // API - get STP List
    function _getSTPList(filter = orderStatus) {
        setApiLoader(true);
        let url = 'stp/active-list';
        let payload = JSON.stringify({
            is_goal: null
        });
        if (filter === 2) {
            url = 'stp/cancelled-list';
        }
        if (filter === 1) {
            APIService(true, url, payload).then((response) => {
                _handleAPIResponse(response);
            })
        } else {
            APIService(false, url).then((response) => {
                _handleAPIResponse(response);
            })
        }

    }

    // API - get SWP List
    function _getSWPList(filter = orderStatus) {
        setApiLoader(true);
        let url = 'swp/active-list';
        let payload = JSON.stringify({
            is_goal: null
        });
        if (filter === 2) {
            url = 'swp/cancelled-list';
        }
        if (filter === 1) {
            APIService(true, url, payload).then((response) => {
                _handleAPIResponse(response);
            })
        } else {
            APIService(false, url).then((response) => {
                _handleAPIResponse(response);
            })
        }
    }

    const HeadingCard = (props) => {
        return (
            <div className="border-radius-10px border-all border-primary-color padding-22px-lr py-2 text-center">
                <img src={props.image}
                    alt={props.title}
                    width={24}
                    height={24} />
                <p className="color-gray e-poppins-medium e-font-14 line-height-sm-14px-20px mb-2 mt-1">{props.title}</p>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-16px mb-0">
                    <span className="e-inter-semi-bold">{props.rupee === true && "₹"}
                    </span>
                    {props.value}
                </p>
            </div>

        )
    }

    const Description = (props) => {
        return (
            <>
                {

                    <div className={`d-flex ${props.className}`}>
                        <span className="color-gray e-poppins-medium e-font-12 line-height-16px">{props.title}&nbsp;</span>
                        <span className={`e-poppins-medium e-font-12 line-height-16px color-outer-space`}>
                            {props.symbol === true && <span className="e-inter-medium">₹</span>}
                            {props.description}
                        </span>
                    </div>
                }
            </>

        )
    }

    function STPCard({ data, type }) {
        return (
            <>
                {
                    data.map((item, key) => {
                        return (
                            <div className=" border-bottom border-bright-gray py-3" key={key}>
                                <div className="d-flex gap-8px e-fund-name"
                                    onClick={() => _handleCardClick(item)}>
                                    <img src={item.image} alt={item.name}
                                        width={48}
                                        height={48}
                                        className="padding-6px-all border-radius-8px border-all border-bright-gray object-fit-contain" />
                                    <div>
                                        <p className="color-black e-poppins-regular e-font-8 line-height-20px mb-sm-2 mb-0">
                                            Transfer Out
                                        </p>
                                        <p className="color-black e-poppins-medium e-font-12 line-height-24px mb-0">{item.name}</p>
                                        <img src={require('../../Assets/Images/orders/stp-arrow.png')}
                                            className="e-arrow-down my-3 w-35px" 
                                            width={24}/>
                                        <p className="color-black e-poppins-regular e-font-8 line-height-20px mb-sm-2 mb-0">
                                            Transfer In
                                        </p>
                                        <p className="color-black e-poppins-medium e-font-12 line-height-24px mb-2">{item.switch_in_fund_name}</p>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <Description title="Type :" description="STP" />
                                    </div>
                                    <div className="col-4">
                                        <div className="d-flex">
                                            <span className="color-gray e-poppins-medium e-font-12 line-height-16px me-1">Invested:</span>
                                            {
                                                item.amount > 0 ?
                                                    <p className="color-black e-poppins-semi-bold e-font-12 line-height-16px mb-0 "
                                                        onClick={() => _handleCardClick(item)}>
                                                        <span className="e-inter-medium">₹</span>
                                                        {item.amount.toLocaleString('en-IN')}</p> :
                                                    <p className="color-black e-poppins-semi-bold e-font-12 line-height-16px mb-0 "
                                                        onClick={() => _handleCardClick(item)}>-</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <Description title="Status :" description={item.status} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6 ">
                                        <Description title={type === 1 ? "STP date:" : "Cancelled date: "} description={type === 1 ? item.next_sip_date : item.cancelled_on} />
                                    </div>
                                    <div className="col-6 ">
                                        <Description title="Frequency" description={item.frequency} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </>
        )
    }

    function SwitchCard({ data, type }) {
        console.log(data)
        return (
            <>
                {
                    data.map((item, key) => {
                        return (
                            <div className=" border-bottom border-bright-gray py-3" key={key}>
                                <div className="d-flex align-items-center gap-12px e-fund-name"
                                    onClick={() => _handleCardClick(item)}>
                                    <img src={item.image} alt={item.name}
                                        width={48}
                                        height={48}
                                        className="padding-6px-all border-radius-8px border-all border-bright-gray object-fit-contain" />
                                    <div>
                                        <p className="color-black e-poppins-medium e-font-12 line-height-24px  mb-2">{item.name}</p>
                                        {
                                            item.sip_tag !== null ||item.sip_tag !== undefined && (
                                                <span className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px ">
                                                    {item.sip_tag}
                                                </span>
                                            )
                                        }

                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col-4">
                                        <Description title="Type :" description="SWP" />
                                    </div>
                                    <div className="col-4">
                                        <div className="d-flex">
                                            <span className="color-gray e-poppins-medium e-font-12 line-height-16px me-1">Invested:</span>
                                            {
                                                item.amount > 0 ?
                                                    <p className="color-black e-poppins-semi-bold e-font-12 line-height-16px mb-0 "
                                                        onClick={() => _handleCardClick(item)}>
                                                        <span className="e-inter-medium">₹</span>
                                                        {item.amount.toLocaleString('en-IN')}</p> :
                                                    <p className="color-black e-poppins-semi-bold e-font-12 line-height-16px mb-0 "
                                                        onClick={() => _handleCardClick(item)}>-</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <Description title="Status :" description={item.status} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6 ">
                                        <Description title={type === 1 ? "SWP date:" : "Cancelled date: "} description={type === 1 ? item.next_sip_date : item.cancelled_on} />
                                    </div>
                                    <div className="col-6 ">
                                        <Description title="Frequency" description={item.frequency} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </>
        )
    }

    return (
        <>
            <div className="e-page-container margin-80px-bottom e-order">

                {/* Heading */}
                <InvestmentHeader />

                <BreadCrumb data={PAGE_NAVIGATION} />

                <h5 className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px mt-sm-4 mt-2 mb-sm-4 mb-0">Manage STP & SWP</h5>
                {/* For preload the image */}
                <img src={NoData} className="d-none" />

                {/* Tab select */}
                <CustomTab data={TAB_DATA}
                    selectedTab={tabIndex}
                    wrapperClass={'gap-16px'}
                    className="px-1 e-order-tab"
                    onSelectTab={_handleTabSelect} />

                {
                    apiLoader === false && orderStatus === 1 &&
                    <div className="row">
                        <div className="mt-4  d-flex  gap-16px">
                            <HeadingCard image={require("../../Assets/Images/Sidebar/manage-sips-color.png")}
                                title={tabIndex === 1 ? "Active STPs" : "Active SWPs"}
                                value={tabIndex === 1 ? listData.total_active_stp : listData.total_active_swp}
                                rupee={false} />
                            <HeadingCard image={require("../../Assets/Images/manage-sip/total-amount.png")}
                                title={"Total amount"}
                                value={tabIndex === 1 ? listData.amount_active_stp.toLocaleString('En-IN') : listData.amount_active_swp.toLocaleString('En-IN')}
                                rupee={true} />
                            <HeadingCard image={require("../../Assets/Images/manage-sip/amc-invested.png")}
                                title="AMC invested"
                                value={listData.amc_counts}
                                rupee={false} />
                        </div>
                    </div>
                }


                <div className="row mt-4">
                    <div className="col-12">
                        <div className="d-flex gap-12px flex-wrap"
                            data-disabled={`${apiLoader}`}>
                            {
                                FILTER_DATA.map((tag, index) => {
                                    return (
                                        <Chip data={tag.label}
                                            key={index}
                                            type={2}
                                            chipIndex={tag.id}
                                            activeChipIndex={orderStatus}
                                            onPress={() => _handleChipFilter(tag.id)} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                {/* Table  */}
                <div className="row">
                    {
                        apiLoader === true ? (
                            <div className="mt-5">
                                <CustomLoader />
                            </div>
                        ) : (
                            <>
                                {
                                    (orderStatus === 1 && listData.fund_data.length === 0) || (orderStatus === 2 && listData.length === 0) ? (
                                        <div className="col-12 text-center mt-5">
                                            <EmptyScreen image={NoData}
                                                title={"No transactions!"}
                                                description="Start investment today"
                                                buttonLabel={'Explore Schemes'}
                                                onPress={() => { navigator('/explore') }} />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="col-12 text-end mt-2 d-sm-block d-none e-table-scroll-indicator-watchlist">
                                                <p className="color-outer-space e-poppins-regular e-font-12 mb-0">
                                                    <img draggable={false} src={require('../../../src/Assets/Images/explore/scroll-gif.gif')} alt="scroll"
                                                        width='24px'
                                                        height='24px'
                                                        className='e-rotate-gif e-filter-invert-1' />
                                                    Scroll horizontally for view more  information</p>
                                            </div>
                                            {
                                                screenWidth > 576 ?
                                                    <DataTable columns={tabIndex === 1 ? STP_COLUMNS : SWP_COLUMNS}
                                                        data={orderStatus === 1 ? listData.fund_data : listData}
                                                        pagination={false}
                                                        customStyles={customStyles}
                                                        onRowMouseEnter={_handleRowHover}
                                                        onRowMouseLeave={_handleRowRemoveHover}
                                                        rowClick={(row) => _handleCardClick(row)} />
                                                    :
                                                    <>
                                                        {
                                                            tabIndex === 1 ?
                                                                <STPCard data={orderStatus === 1 ? listData.fund_data : listData}
                                                                    type={orderStatus} />
                                                                :
                                                                <SwitchCard data={orderStatus === 1 ? listData.fund_data : listData}
                                                                    type={orderStatus} />
                                                        }
                                                    </>
                                            }

                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </div>

            </div>

            {/* Footer */}
            <Footer />
        </>
    )

}