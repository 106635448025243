import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.min.js";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './Styles/styles.scss';
import './Styles/global.scss';
import './Styles/responsive.scss';

import reportWebVitals from './reportWebVitals';

import App from './App';
import Store from './Store';

import { DarkModeProvider } from './Context/dark-mode-context';

// replace console.* for disable log on production
if (process.env.REACT_APP_ENV === 'PROD') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     // Register the service worker from the public folder
//     navigator.serviceWorker.register('/service-worker.js').then((registration) => {
//       console.log('Service Worker registered with scope: ', registration.scope);
//     }).catch((error) => {
//       console.log('Service Worker registration failed: ', error);
//     });
//   });
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DarkModeProvider>
    <Provider store={Store}>
      <App />
    </Provider>
  </DarkModeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
