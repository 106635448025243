/*
 *   File : amount-input.js
 *   Author : https://evoqins.com
 *   Description : Text input component fro amount input
 *   Integrations : null
 *   Version : 1.0.0
*/


import React, { useEffect, useState } from 'react';

import Icon from '../Icon/icon';
import style from '../../Styles/Component/custom-textbox.module.scss';

const CustomAmountInput = React.memo((props) => {
    const [isInputFocused, setInputFocus] = useState(false);
    const [value, setValue] = useState(props.value);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(()=>{
        function _handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleResize);

        return () => {
            window.removeEventListener('resize', _handleResize);
        }
    },[]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    // useEffect(()=>{console.log('riewew', props.hideErrorMessage)},[props.hideErrorMessage])

    const _handleChange = (event) => {
        const input_value = event.target.value;

        // Removing space and comma
        const formatted_value = parseFloat(input_value.replace(/,/g, ''));

        if (!isNaN(formatted_value)) {
            if (formatted_value <= props.maxValue) {
                setValue(formatted_value);
                props.handleChange(formatted_value);
            }
        } else {
            setValue('');
            props.handleChange('');
        }
    };



    const _validateNumber = (e) => {
        const key_code = e.keyCode || e.which;
        const is_ctrl_pressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed
        const allowed_key_codes = [9, 8, ...Array.from({ length: 10 }, (_, i) => 48 + i), ...Array.from({ length: 10 }, (_, i) => 96 + i), ...Array.from({ length: 4 }, (_, i) => 37 + i), 190];

        if (is_ctrl_pressed && [65, 67, 88, 86].includes(key_code)) {
            // Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V
            return null;
        }

        if (!allowed_key_codes.includes(key_code)) {
            e.preventDefault();
        }
    };




    const _handleFocus = () => {
        setInputFocus(true);
    };

    const _handleBlur = () => {
        setInputFocus(false);
    };

    return (
        <div className={
            props.error && props.error.length !== 0 ?
                `${props.className} ${style.e_input_wrapper} ${style.e_error_input} position-relative w-100` :
                `${props.className} ${style.e_input_wrapper} position-relative w-100`}
            data-disabled={props.disabled}>
            {/* Input box */}
            {/* Rupee prefix */}
            <>
                {
                    props.fixedRupee === true ?
                        <span className={`position-absolute color-black e-inter-medium e-font-16 no-outline pe-4 ${style.e_phone_number}`}>
                            ₹
                        </span>
                        :
                        <>
                            {
                                (isInputFocused === true || props.value.length !== 0) &&
                                <span className={`position-absolute color-black e-inter-medium e-font-16 no-outline pe-4 ${style.e_phone_number}`}>
                                    ₹
                                </span>
                            }
                        </>
                }
            </>

            <input value={value.toLocaleString('en-IN')}
                ref={props.inputRef}
                id={props.id ? props.id : props.label}
                type={props.type ? props.type : 'text'}
                required
                className={`${style.e_input} ${props.inputClass}  e-poppins-regular e-font-16 w-100 no-outline`}
                onChange={_handleChange}
                onFocus={_handleFocus}
                onBlur={_handleBlur}
                onKeyDown={_validateNumber} />

            {/* label */}
            {
                props.label &&
                <label className={`${props.labelClass} position-absolute left-15px e-poppins-regular e-font-14 cursor-text p-0`}
                    htmlFor={props.label}>
                    {props.label}
                    <span className='color-red'>
                        {props.postfix}
                    </span>
                </label>
            }
            {
                props.description !== "" && !props.error &&
                <div className='position-absolute d-flex align-items-center mt-1 '>
                    <p className='color-steel-gray e-font-12 '>{props.description}</p>
                </div>
            }

            {/* Error text */}
            {
                props.error && props.hideErrorMessage !== true &&
                <div className={`${props.errorClass} position-absolute d-flex align-items-center mt-sm-1 mt-0`}>
                    <Icon icon="info-circle"
                        size={screenWidth > 576 ? 16 : 12}
                        className="me-1" />
                    <p className='color-red margin-block-end-0 e-font-12-sm-10 '>
                        {props.error}
                    </p>
                </div>
            }
        </div>
    )
})

CustomAmountInput.defaultProps = {
    value: null,
    className: '',
    error: null,
    labelClass: 'bg-body-color',
    inputRef: null,
    disabled: false,
    handleChange: () => { },
    fixedRupee: false,
    maxValue: 99999999,
    hideErrorMessage: false,
    description: ""
};

export default CustomAmountInput;