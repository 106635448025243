/*
 *   File : cart.js
 *   Author URI : https://evoqins.com
 *   Description : Card component for items added to the Cart from Explore and Fund details.
 *   Integrations : null
 *   Version : v1.1
 */

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Icon from "../Icon/icon"
import style from "../../Styles/Component/cart.module.scss"

const CartCard = (props) => {

    const navigate = useNavigate();
    const [showEditHover, setShowEditHover] = useState(null);

    const _handleClick = (event) => {
        event.stopPropagation();
        props.toggleItem(props.data);
    }

    const _handleShowEditToggle = () => {
        setShowEditHover(props.index)
    }

    const _handleHideEditHover = () => {
        setShowEditHover(null);
    }

    const _handleEdit = (event) => {
        event.stopPropagation();
        props.editAmount(props.data);
    }

    const _handleNavigate = () => {
        navigate("/explore/fund", {
            state: {
                fund_id: props.data.mf_id
            }
        });
    }

    return (
        <div className="border-radius-24px border-all border-bright-gray p-3 cursor-pointer"
            onClick={_handleNavigate}>
            <div className="d-flex align-items-start gap-8px">
                <Icon icon={props.selectedItems.some((obj) => obj.id === props.index) ? "cart-selected" : "cart-unselected"}
                    size={24}
                    className="cursor-pointer"
                    onClick={(e) => _handleClick(e)} />

                <div className="w-100">
                    {/* fund name and image */}
                    <div className="row pb-3 border-bottom-1px border-bright-gray">
                        <div className="col-xl-8 col-sm-7 ">
                            <div className="d-flex align-items-center gap-12px">
                                <div className="border-radius-8px border-all border-bright-gray p-2">
                                    <img src={props.data.image}
                                        alt={props.data.name}
                                        width={36}
                                        height={36}
                                        className="object-fit-contain" />
                                </div>
                                <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-0">{props.data.name}</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-5 d-flex justify-content-sm-end mt-sm-0 mt-3">
                            <div className={`border-radius-12px border-all border-columbia-blue padding-14px-tb px-3 d-flex align-items-center gap-10px ${style.e_cart_card}`}
                                onClick={(e) => _handleEdit(e)}>
                                <p className="color-outer-space e-poppins-semi-bold e-font-20-sm-18 line-height-20px mb-0"><span className="e-inter-semi-bold">
                                    ₹ </span>{props.data.amount.toLocaleString('en-IN')}
                                </p>
                                <div className="position-relative"
                                    onMouseEnter={_handleShowEditToggle}
                                    onMouseLeave={_handleHideEditHover}>
                                    <Icon icon="Pen-square"
                                        size={24}
                                        className="cursor-pointer" />
                                    {
                                        showEditHover === props.index &&
                                        <p className={`position-absolute border-radius-4px bg-body-color color-outer-space e-poppins-regular e-font-12 line-height-12px mb-0  ${style.e_cart_edit_hover}`}>Edit</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* amount to purchase*/}
                    <div className="d-flex gap-16px flex-wrap mt-3">
                        <p className="color-black e-poppins-semi-bold e-font-sm-16-14 mb-0">NAV: &nbsp;
                            <span className="color-sea-green">
                                <span className="e-inter-semi-bold">
                                    ₹{' '}
                                </span>
                                {props.data.nav.toLocaleString('en-IN')} <span className={`${props.data.nav_change < 0 && "color-red"}`}>({props.data.nav_change_percentage})</span>
                            </span>
                        </p>

                        <div className="d-flex align-items-center">
                            <Icon icon="suit-case"
                                size={24} />
                            <p className="ms-1 color-black e-poppins-semi-bold e-font-16 mb-0">Expected units* : <span className="e-inter-semi-bold">{props.data.expected_units}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartCard