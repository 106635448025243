
/*
 *   File : fund-redeem.js
 *   Author URI : https://evoqins.com
 *   Description : create a redeem request from an invested MF
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useRef, useState, useContext } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import Icon from "../Icon/icon";
import { CustomRedeemTextInput, OTPInput, CustomRedeemInput } from "../Form-elements";
import { useClickOutside, _getDayOfMonth } from "../../Helper";
import { GradientButton } from "../CTA";
import APIService from "../../Service/api-service";
import { _consent2FA } from "../../Helper/api";
import { DarkModeContext } from "../../Context/dark-mode-context";

import style from "../../Styles/Component/fund-redeem.module.scss"
import Colors from '../../Styles/color.module.scss';

const FundRedeemModal = (props) => {
    const { darkMode } = useContext(DarkModeContext);
    const PROFILE_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const dropDownRef = useRef();
    const amountRef = useRef(null);

    const { date } = _getDayOfMonth();

    const [selectedFolio, setSelectedFolio] = useState([]);
    const [folioError, setFolioError] = useState("");
    const [availableUnits, setAvailableUnits] = useState(0);
    const [showFolioDropDown, setShowFolioDropDown] = useState(false);
    const [displayFolioText, setDisplayFolioText] = useState("");
    const [folioData, setFolioData] = useState([]);
    const [withdrawUnits, setWithDrawUnits] = useState("")
    const [withdrawUnitsError, setWithdrawUnitsError] = useState("");
    const [withdrawAll, setWithdrawAll] = useState(false);
    const [tax, setTax] = useState(0);
    const [showBank, setShowBank] = useState(false);
    const [selectedBank, setSelectedBank] = useState({});
    const [proceed, setProceed] = useState(false);
    const [pinString, setPinString] = useState("");
    const [pinError, setPinError] = useState("");
    const [apiLoader, setApiLoader] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState("");
    const [withdrawAmountError, setWithdrawAmountError] = useState('');
    const [mfData, setMfData] = useState(props.data);
    const [seconds, setSeconds] = useState(30);
    // Modal close listener
    useEffect(() => {
        var my_modal = document.getElementById("fund-redeem");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    useEffect(() => {
        if (proceed === true) {
            const countdown = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    clearInterval(countdown);
                }
            }, 1000);

            return () => {
                clearInterval(countdown);
            };
        } else {
            setSeconds(30);
        }
    }, [seconds, proceed]);

    useEffect(() => {
        const default_bank_index = props.data.banks.findIndex(bank => bank.is_default === true);
        if (default_bank_index != -1) {
            let default_bank = props.data.banks[default_bank_index];
            setSelectedBank(default_bank);
        }

        //Commented this because, the units field is getting populate on modal render.
        // setWithDrawUnits(props.data.units);
        setTax(props.data.tax);
        setFolioData(props.data.folios);
        // auto select only folio from the list if there is only one item in the list 
        if (props.data.folios.length !== 0) {
            setSelectedFolio([props.data.folios[0]]);
            setAvailableUnits(parseFloat(props.data.folios[0].units).toFixed(4));
            setDisplayFolioText(props.data.folios[0].folio_no);
            setWithDrawUnits(parseFloat(props.data.folios[0].units).toFixed(4));
            setWithdrawAmount(Math.floor(props.data.folios[0].units * props.data.mf_data.nav.current_nav))
        }
    }, [props.data]);

    useEffect(() => {
        if (withdrawAll === true) {
            setWithDrawUnits(parseFloat(props.data.units).toFixed(4));
            setSelectedFolio([]); // clear selected folios
            setDisplayFolioText(''); // reset back to empty text
            setAvailableUnits(parseFloat(props.data.units).toFixed(4));
            setWithdrawUnitsError(''); // clear error message if any exists
            setWithdrawAmount(Math.floor(props.data.current_amount))
        }
    }, [withdrawAll]);

    // custom hook called when clicked outside the popup to close the pop up
    useClickOutside(dropDownRef, () => {
        setShowFolioDropDown(false);
    });

    const _handleShowFolio = () => {
        setShowFolioDropDown(!showFolioDropDown);
    }


    // handler for checkbox items
    const _handleFolio = (item) => {
        setFolioError("");
        let selected_folio = [...selectedFolio];
        let selected_folie_index = selectedFolio.findIndex(item => item.folio_no == item.folio_no);
        if (selected_folie_index == -1) {
            selected_folio.push(item);
        } else {
            selected_folio = selectedFolio.filter(item => item.folio_no != item.folio_no);
            selected_folio.push(item);
        }

        setSelectedFolio([...selected_folio]);
        setAvailableUnits(parseFloat(item.units).toFixed(4));
        setDisplayFolioText(item.folio_no);
        setShowFolioDropDown(false);
        setWithDrawUnits(parseFloat(item.units).toFixed(4));
        setWithdrawAmount(Math.floor(item.units * props.data.mf_data.nav.current_nav));
        _getRedeemData(item);
        // removing units to withdraw field error
        if (item.units !== '') {
            setWithdrawUnitsError('');
            setWithdrawAmountError('');
        }
    }

    // Callback function to process the units
    const _handleUnitsInput = (value) => {
        setWithDrawUnits(value);
        setWithdrawUnitsError("");
        // if any folio selected then available units from that folio else fund available units
        let available_units = selectedFolio.length !== 0 ? selectedFolio[0].units : props.data.units;
        if (value > available_units) {

            // toast.dismiss();
            // toast.error(`Entered unit cannot be greater than ${available_units} units`,{
            //     type: 'error'
            // });
            // return;
        }
        if (value == '') {
            setWithdrawAmount('');
        } else {
            setWithdrawAmount(Math.floor(value * props.data.mf_data.nav.current_nav));
        }
    }

    const _handleAmountInput = (value) => {
        setWithdrawAmount(value);
        setWithdrawAmountError('');
        // if any folio selected then available units from that folio else fund available units
        let available_units = selectedFolio.length !== 0 ? selectedFolio[0].units : props.data.units;
        if (withdrawUnits > available_units) {

            // toast.dismiss();
            // toast.error(`Entered unit cannot be greater than ${available_units} units`,{
            //     type: 'error'
            // });
            // return;
        }
        if (value == '') {
            setWithDrawUnits('');
        } else {
            setWithDrawUnits((value / props.data.mf_data.nav.current_nav).toFixed(4));
        }
    }

    const _handleWithdrawAll = () => {
        setWithdrawAll(!withdrawAll);

    }

    const _handleShowBank = () => {
        setShowBank(!showBank);
    }

    const _handleSelectedBank = (data) => {
        setSelectedBank(data);
    }

    const _handlePin = (value) => {
        setPinString(value);
        setPinError("");
    }

    const _handleGoback = () => {
        setPinString("");
        setPinError("");
        setProceed(false);
    }

    // navigate to landing
    function _navigateToLanding(path) {
        window.open(path, "_blank", "noreferrer");
    }


    // validate pin
    const _validatePin = () => {
        let valid = true;
        if (pinString.length === 0) {
            setPinError("OTP Required");
            valid = false;
        }
        if (valid === true) {
            _confirmRedeem();
        }
    }

    const _validateProceed = () => {
        // if any folio selected then available units from that folio else fund available units
        let available_units = selectedFolio.length !== 0 ? selectedFolio[0].units : props.data.units;
        let valid = true;
        if (withdrawUnits.length === 0) {
            setWithdrawUnitsError("Units is required");
            valid = false;
        }
        if (parseInt(withdrawUnits) > available_units) {
            setWithdrawUnitsError(`Entered unit cannot be greater than ${available_units} units`);
            valid = false;
        }
        if (selectedFolio.length === 0 && withdrawAll === false) {
            setFolioError("Folio is required");
            valid = false;
        }
        if (withdrawAmount === '') {
            setWithdrawAmountError('Amount is required');
            valid = false;
        }
        if (valid === true) {
            _confirmProceed();
        }
    }

    // API - confirm proceed to get otp
    function _confirmProceed() {

        setApiLoader(true);
        let url = 'mf/confirm-redeem';
        let payload = JSON.stringify({
            mf_id: props.data.mf_data.id,
            amount: withdrawAll === true ? null : withdrawAmount,
            redeem_all: withdrawAll,
            bank_id: selectedBank.bank_id,
            folios: selectedFolio.map(item => item.folio_no)
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setProceed(true);
                toast.dismiss();
                toast.success("OTP sent successfully", {
                    type: "success",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    // API - confirm redeem
    function _confirmRedeem() {
        setApiLoader(true);
        let url = 'mf/redeem';
        let payload = JSON.stringify({
            mf_id: props.data.mf_data.id,
            amount: withdrawAll === true ? null : withdrawAmount,
            redeem_all: withdrawAll,
            bank_id: selectedBank.bank_id,
            otp: pinString,
            folios: selectedFolio.map(item => item.folio_no)
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.redeemStatus(1);
            } else {
                // if (response.message !== '2FA OTP is not matching. Try sending again.') {
                //     document.getElementById("close-modal").dispatchEvent(new Event("click"));
                //     props.redeemStatus(2);
                // }
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        });
    }

    const _getRedeemData = (folio) => {
        let url = 'mf/redeem-data';
        let payload = JSON.stringify({
            id: props.data.mf_data.id,
            units: folio.units,
            sip_date: date,
            transaction_type: 3,
            folio: folio.folio_no
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setMfData(response.data);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        });
    }

    function _handleResendOTP() {
        _consent2FA("MF")
            .then((response) => {
                toast.dismiss();
                toast.success("An OTP has been sent to your registered phone number", {
                    type: "success",
                });
                setSeconds(30);
                setPinString("");
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error"
                });
            });
    }


    const SelectBank = ({ item }) => {

        return (
            <div className={`d-flex justify-content-between align-items-center cursor-pointer gap-8px h-100
                ${item.bank_id === selectedBank.bank_id ?
                    `border-radius-8px border-all padding-12px-all border-primary-color`
                    :
                    `border-radius-8px border-all padding-12px-all border-bright-gray`
                }`}

                onClick={() => _handleSelectedBank(item)}>
                <div className="d-flex align-items-center">
                    <img src={item.image} alt={item.name}
                        width={35}
                        height={35}
                        className="border-radius-100 me-1" />
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <span className="color-black e-poppins-semi-bold e-font-14 line-height-14px me-1 ">
                                {item.name}
                            </span>
                            <span className="color-outer-space e-poppins-medium e-font-14 line-height-14px ">{item.account_number}</span>
                        </div>
                        {
                            item.is_default === true &&
                            <p className="color-primary-color e-poppins-medium e-font-12  line-height-12px mb-0 mt-1">Primary bank</p>
                        }
                    </div>
                </div>

                <Icon icon={selectedBank.bank_id === item.bank_id ? 'radio-selected' : 'radio-unselected'}
                    size={20} />
            </div>
        )
    }

    return (
        <div className={`modal fade ${style.e_fund_redeem}`}
            id="fund-redeem"
            tabIndex="-1"
            aria-labelledby="fund-redeem"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-modal border-radius-24px padding-20px-top ps-4 padding-32px-right pb-4 position-relative ${style.e_content} `}>
                    <span id="close-modal" data-bs-dismiss="modal" className='d-none'></span>
                    {
                        proceed === false ?
                            <Fragment>

                                <p className="color-eerie-black e-poppins-medium e-font-16 line-height-16px mb-3">Withdraw details</p>
                                <Icon icon="close"
                                    size={24}
                                    data-bs-dismiss="modal"
                                    className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />

                                <div className={style.e_redeem}>
                                    <div className={style.e_body}>
                                        <div className="border-radius-16px bg-light-sky-blue pt-3 pb-4 px-4 ">
                                            <div className="d-lg-flex d-md-flex justify-content-between border-bottom-1px border-shade-light-blue pb-3 mb-3 gap-32px">
                                                <div className="d-flex gap-16px">
                                                    <div className="p-2 bg-white border-all border-bright-gray border-radius-4px">
                                                        <img src={props.data.mf_data.image}
                                                            alt={props.data.mf_data.name}
                                                            width={40}
                                                            height={40}
                                                            className="object-fit-contain" />
                                                    </div>
                                                    <div>
                                                        <p className="color-black e-poppins-medium e-font-14 line-height-20px mb-2">
                                                            {props.data.mf_data.name}
                                                        </p>
                                                        <p className="color-black e-poppins-black e-font-10 line-height-19px mb-0 border-all border-bright-gray py-1 padding-6px-lr border-radius-16px w-fit-content">
                                                            {props.data.mf_data.class_name}
                                                        </p>
                                                    </div>

                                                </div>

                                                <div className="">
                                                    <p className="color-black e-poppins-semi-bold e-font-14 line-height-26px mb-0 mt-lg-0 mt-md-0 mt-3">
                                                        <span className="e-inter-semi-bold">₹</span>{props.data.mf_data.nav.current_nav.toLocaleString("en-IN")} <span className=
                                                            {`e-font-10 ${props.data.mf_data.nav.nav_change < 0 ? "color-red" : "color-sea-green"}`}>
                                                            ({props.data.mf_data.nav.nav_change < 0 ? `${props.data.mf_data.nav.nav_change_percentage}` : `${props.data.mf_data.nav.nav_change_percentage}`})
                                                        </span>
                                                    </p>
                                                    <p className="color-gray e-poppins-regular e-font-10 line-height-12px mb-0 text text-lg-end text-md-end text-start">as on {props.data.mf_data.nav.nav_date}</p>
                                                </div>
                                            </div>

                                            <div className="d-lg-flex d-md-flex gap-24px">
                                                <p className="color-gray e-poppins-medium e-font-14 mb-lg-0 mb-md-0 mb-3">Units: <span className="color-outer-space">{props.data.units.toFixed(4)}</span></p>
                                                <p className="color-gray e-poppins-medium e-font-14 mb-lg-0 mb-md-0 mb-3">Current: <span className="e-inter-medium color-outer-space">₹</span><span className="color-outer-space">{props.data.current_amount.toLocaleString("en-IN")}</span></p>
                                                <p className="color-gray e-poppins-medium e-font-14 mb-0">Redemption time: <span className="color-outer-space">{props.data.redemption_time}</span></p>
                                            </div>

                                            <div className="d-flex mt-3">
                                                <p className="color-gray e-poppins-medium e-font-14 mb-0">Exit load: <span className="color-outer-space e-inter-medium"> ₹ {mfData.mf_data.exit_load_amount.toLocaleString('en-IN')}</span></p>
                                            </div>
                                        </div>

                                        {/* folio dropdown */}
                                        {
                                            folioData.length !== 0 &&
                                            <div className="position-relative margin-38px-bottom margin-26px-top">
                                                <div className="mt-1 p-2 bg-pale-yellow d-flex align-items-center border-radius-8px gap-12px mb-3">
                                                    <p className="color-jett-black e-poppins-regular e-font-12 line-height-16px mb-0">Your investments in {props.data.mf_data.name} are split into multiple folios.</p>
                                                </div>
                                                <div ref={dropDownRef}>
                                                    <div className={`py-3 padding-22px-lr cursor-pointer border-radius-12px border-all d-flex align-items-center position-relative 
                                                        ${selectedFolio.length !== 0 ? "justify-content-between" : "justify-content-end"}
                                                        ${folioError.length !== 0 ? "border-red" : "border-columbia-blue"}`}
                                                        data-disabled={withdrawAll || PROFILE_DATA.demat}
                                                        onClick={_handleShowFolio}>
                                                        <p className={`e-poppins-regular e-font-16px line-height-16px mb-0 bg-modal z-index-1 position-absolute
                                                            ${selectedFolio.length !== 0 ? style.e_transition_top : style.e_transition_center}
                                                            ${folioError ? "color-red" : "color-black"}`}>
                                                            Select folio<span className="color-red">*</span>
                                                        </p>
                                                        {
                                                            displayFolioText.length !== 0 &&
                                                            <p className="color-black e-poppins-semi-bold e-font-16 line-height-26px mb-0">{displayFolioText}</p>
                                                        }
                                                        <div className="d-flex align-items-center">
                                                            <p className="color-gray e-poppins-medium e-font-14 mb-0">Available units: <span className="color-outer-space">{availableUnits}</span></p>
                                                            <Icon icon="calender-arrow-right"
                                                                size={24}
                                                                color={Colors.white}
                                                                className={showFolioDropDown === true ? style.e_rotate_left_90 : style.e_rotate_right_90} />
                                                        </div>
                                                    </div>
                                                    {
                                                        folioError.length !== 0 &&
                                                        <div className='position-absolute d-flex align-items-center mt-1 '>
                                                            <Icon icon="info-circle"
                                                                size={16}
                                                                className="me-1" />
                                                            <p className='color-red margin-block-end-0 e-font-12 '>
                                                                {folioError}
                                                            </p>
                                                        </div>
                                                    }
                                                    {
                                                        showFolioDropDown === true &&
                                                        <div className={`border-radius-8px border-all border-bright-gray bg-modal position-absolute w-100 z-index-1 ${style.e_folio_dropdown}`}>
                                                            {
                                                                folioData.map((item, key) => {
                                                                    let contains_all_folio = selectedFolio.some(selectedBank => selectedBank.folio_no === item.folio_no);
                                                                    return (
                                                                        <div key={key}
                                                                            className={`d-flex justify-content-between align-items-center py-3 ps-2 pe-4 ${key !== folioData.length - 1 && "border-bottom-1px border-bright-gray"} cursor-pointer`}
                                                                            onClick={() => _handleFolio(item)}>
                                                                            <div className="d-flex align-items-center gap-8px">
                                                                                <Icon icon={contains_all_folio ? "radio-selected" : "radio-unselected"}
                                                                                    size={16} />
                                                                                <p className="color-outer-space e-poppins-medium e-font-12 line-height-10px mb-0">
                                                                                    {item.folio_no}
                                                                                </p>
                                                                            </div>

                                                                            <div>
                                                                                <p className="color-outer-space e-poppins-regular e-font-12 line-height-10px mb-0">
                                                                                    Available units: <span className="color-black e-poppins-medium">{parseFloat(item.units).toFixed(4)}</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className="row gx-0">
                                            <div className="col-lg-6 col-md-6 col-12 pe-2" data-disabled={PROFILE_DATA.demat === true ? 'true' : 'false'}>
                                                <CustomRedeemTextInput label="Units to withdraw"
                                                    postfix="*"
                                                    type="number"
                                                    className="margin-38px-top"
                                                    inputClass={`e-poppins-semi-bold bg-transparent line-height-26px py-3 border-radius-12px ${style.e_redeem_input_box}`}
                                                    value={withdrawUnits}
                                                    error={withdrawUnitsError}
                                                    disabled={withdrawAll}
                                                    handleChange={_handleUnitsInput} />
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-12 ps-2" data-disabled={PROFILE_DATA.demat === true ? 'true' : 'false'}>
                                                <CustomRedeemInput value={withdrawAmount}
                                                    label="Approx. redeem value"
                                                    postfix="*"
                                                    className="margin-38px-top"
                                                    inputRef={amountRef}
                                                    // approxValue={withdrawUnits * props.data.mf_data.nav.current_nav}
                                                    inputClass={`e-poppins-semi-bold bg-transparent line-height-26px py-3  border-radius-12px padding-28px-left`}
                                                    error={withdrawAmountError}
                                                    disabled={withdrawAll}
                                                    allowDecimal={true}
                                                    handleChange={_handleAmountInput} />
                                            </div>
                                        </div>


                                        <div className="margin-32px-top d-lg-flex d-md-flex justify-content-between align-items-center"
                                            data-disabled={PROFILE_DATA.demat === true ? 'true' : 'false'}  >
                                            <div className="d-flex align-items-center gap-8px cursor-pointer"
                                                onClick={_handleWithdrawAll}>
                                                <Icon icon={withdrawAll === true ? "checked" : "unchecked"}
                                                    size={24} />
                                                <p className="color-outer-space e-poppins-medium e-font-14 mb-0">Withdraw all units</p>
                                            </div>
                                            <p className="color-gray e-poppins-medium e-font-14 mb-0 mt-lg-0 mt-md-0 mt-3">Approx. tax implication: <span className="e-inter-medium color-outer-space">₹</span><span></span><span className="color-outer-space">{tax.toLocaleString("en-IN")}</span></p>
                                        </div>
                                        {
                                            PROFILE_DATA.demat === true ?
                                                <div className="color-red-orange e-poppins-medium e-font-14 bg-soft-pink mb-3  mt-4 p-3 border-radius-24px ">
                                                    <img src={require('../../Assets/Images/orders/clock-failed.png')}
                                                        className="me-2" />
                                                    Currently DEMAT redemption is not allowed for DEMAT holdings.
                                                    <a href="tel:8069991111" className="color-primary-color  text-decoration-underline d-block ms-4 ps-2">080-69991111</a>
                                                </div>
                                                :
                                                <>
                                                    <div className="cursor-pointer d-flex align-items-center gap-8px justify-content-end mt-3"
                                                        onClick={_handleShowBank}>
                                                        <p className="color-sea-green e-poppins-medium e-font-12 mb-0">Select bank</p>
                                                        <Icon icon="right-arrow"
                                                            size={24}
                                                            className={showBank === true ? style.e_rotate_left_90 : style.e_rotate_right_90} />
                                                    </div>

                                                    {
                                                        showBank === true &&
                                                        <div className="border-radius-8px border-all border-bright-gray p-2 mt-3">
                                                            <p className="color-outer-space e-poppins-regular e-font-12 mb-1">Select bank</p>
                                                            <div className="d-flex gap-8px flex-wrap">
                                                                {
                                                                    props.data.banks.map((item, key) =>
                                                                        <div className={style.select_bank_radio} key={key}>
                                                                            <SelectBank item={item} />
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="mt-4 p-2 bg-pale-yellow d-flex align-items-center border-radius-8px gap-12px">
                                                        <Icon icon="info"
                                                            size={24} />
                                                        <p className="color-jett-black e-poppins-regular e-font-10 line-height-16px mb-0">Order will be processed as per the cut-off time for each funds.</p>

                                                    </div>
                                                </>
                                        }
                                    </div>
                                    {
                                        PROFILE_DATA.demat === false &&
                                        <>
                                            <div className="col-12 pt-2 mb-3">
                                                <p className="color-outer-space e-poppins-regular e-font-10 mb-1">a) Lock-in & exit load will be applicable as per scheme details.</p>
                                                <p className="color-outer-space e-poppins-regular e-font-10 mb-0">b) The current amount shown may vary as per NAV applicable to the successful processing of transaction.</p>
                                            </div>
                                            <div className="col-12 text-center  pb-2">
                                                <p className="color-outer-space e-poppins-regular e-font-10 mb-0">By continuing, I agree with the <span className="color-primary-color cursor-pointer" onClick={() => _navigateToLanding('https://www.mastertrust.co.in/disclaimer')}>Disclaimers</span> and <span className="color-primary-color cursor-pointer" onClick={() => _navigateToLanding(`${process.env.REACT_APP_LANDING_PAGE_URL}/terms`)}>T&C</span> of Mastertrust</p>
                                            </div>
                                            <div className="mx-lg-5 mx-md-5 px-5 mt-4">
                                                <GradientButton label="Request OTP & redeem"
                                                    loading={apiLoader}
                                                    className="e-font-16 padding-12px-tb w-100"
                                                    handleClick={_validateProceed} />

                                            </div>
                                        </>
                                    }

                                </div>
                            </Fragment>
                            :
                            <Fragment>
                                <Icon icon="close"
                                    size={24}
                                    id="close-modal"
                                    data-bs-dismiss="modal"
                                    className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_proceed_close}`} />
                                <Icon icon="arrow-right1"
                                    size={24}
                                    color={Colors.black}
                                    className={`position-absolute cursor-pointer ${style.e_back} ${apiLoader === true && "no-events"}`}
                                    onClick={_handleGoback} />
                                <div className="text-center mt-4">
                                    <img src={require("../../Assets/Images/portfolio/redeem-confirm.png")}
                                        alt="Confirm payment"
                                        width={72}
                                        height={72} />
                                    <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">Enter OTP you have received in registered email and phone number</p>

                                    {/* <p className="color-jett-black e-poppins-regular e-font-16 mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                                    <OTPInput pinString={pinString}
                                        id="redeem-input"
                                        autoFocus={true}
                                        disabled={apiLoader}
                                        pinError={pinError}
                                        handleChange={_handlePin} />

                                </div>
                                <div className="margin-22px-top mx-5 px-5">
                                    <GradientButton label="Confirm withdrawal"
                                        className=" e-font-16 padding-12px-tb w-100"
                                        loading={apiLoader}
                                        handleClick={_validatePin} />
                                    {
                                        <div className='d-flex justify-content-center mt-4'>
                                            {
                                                seconds === 0 ?
                                                    <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 e-forgot-pin position-relative w-max-content'
                                                        onClick={_handleResendOTP}>Resend OTP</p>
                                                    :
                                                    <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 '>
                                                        Resend OTP in <span className="color-black">{seconds}s</span>
                                                    </p>
                                            }
                                        </div>
                                    }
                                </div>
                            </Fragment>
                    }
                </div>
            </div>
        </div>

    )
}

export default FundRedeemModal