/*
 *   File : amount-input.js
 *   Author : https://evoqins.com
 *   Description : Text input component fro amount input
 *   Integrations : null
 *   Version : 1.0.0
*/


import React, { useEffect, useState } from 'react';

import Icon from '../Icon/icon';
import style from '../../Styles/Component/custom-textbox.module.scss';

const CustomRedeemInput = React.memo((props) => {
    const max_value = props.allowDecimal === true? 999999999999 : 99999999
    const [isInputFocused, setInputFocus] = useState(false);
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const _handleChange = (event) => {
        const input_value = event.target.value;
        
        if (input_value <= 999999999999) {
            setValue(parseInt(input_value));
            props.handleChange(parseInt(input_value));
        } 
    };
    


    const _validateNumber = (e) => {
        const key_code = e.keyCode || e.which;
        const is_ctrl_pressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed
        const allowed_key_codes = [9, 8, ...Array.from({ length: 10 }, (_, i) => 48 + i), ...Array.from({ length: 10 }, (_, i) => 96 + i), ...Array.from({ length: 4 }, (_, i) => 37 + i)];

        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
         }
         
        if (is_ctrl_pressed && [65, 67, 88, 86].includes(key_code)) {
            // Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V
            return null;
        }

        if (!allowed_key_codes.includes(key_code)) {
            e.preventDefault();
        }
    };




    const _handleFocus = () => {
        setInputFocus(true);
    };

    const _handleBlur = () => {
        setInputFocus(false);
    };

    return (
        <div className={
            props.error && props.error.length !== 0 ?
                `${props.className} ${style.e_input_wrapper} ${style.e_error_input} position-relative w-100` :
                `${props.className} ${style.e_input_wrapper} position-relative w-100`}
            data-disabled= {props.disabled}>
            {/* Input box */}
            {/* Rupee prefix */}
            {
                (isInputFocused === true || props.value.length !== 0) &&
                <span className={`position-absolute color-black e-inter-medium e-font-16 no-outline pe-4 ${style.e_phone_number}`}>
                    ₹
                </span>
            }
            <input value={value}
                ref={props.inputRef}
                id={props.label}
                type='number'
                required
                className={`${style.e_input} ${props.inputClass} color-charleston-green e-poppins-regular e-font-16 w-100 no-outline`}
                onChange={_handleChange}
                onFocus={_handleFocus}
                onBlur={_handleBlur}
                onWheel={(e) => e.target.blur()}
                onKeyDown={_validateNumber}  />

            {/* label */}
            <label className={`${props.labelClass} position-absolute left-15px e-poppins-regular e-font-14 cursor-text p-0`}
                htmlFor={props.label}>
                {props.label}
                <span className='color-red'>
                    {props.postfix}
                </span>
            </label>

            {/* Error text */}
            {
                props.error &&
                    <div className='position-absolute d-flex align-items-center mt-1'>
                        <Icon icon="info-circle"
                            size={16}
                            className="me-1" />
                        <p className='color-red margin-block-end-0 e-font-12 '>
                            {props.error}
                        </p>
                    </div>
            }
        </div>
    )
})

CustomRedeemInput.defaultProps = {
    value: null,
    className: '',
    error: null,
    labelClass: 'bg-modal',
    inputRef: null,
    disabled: false,
    handleChange: () => { },
};

export default CustomRedeemInput;