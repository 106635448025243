/*
 *   File :  kyc-confirmation.js
 *   Author URI : https://evoqins.com
 *   Description : Modal component to show the KYC details of existing MF users from MT platform.
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icon from '../Icon/icon';
import { toast } from 'react-toastify';
import Store from '../../Store';

import { GradientButton, OutlineButton } from '../CTA';

import style from '../../Styles/Component/kyc-confirm.module.scss';

import APIService from '../../Service/api-service';

export default function KYCConfirmationModal(props) {

    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const navigate = useNavigate();
    const [apiLoader, setApiLoader] = useState(false);
    const [accept, setAccept] = useState(true);

    // Modal close listener
    useEffect(() => {
        var my_modal = document.getElementById("kyc-confirmation");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close(accept);
        });
    }, []);

    // API - confirming kyc data;
    function _resetKycStep(status) {
        setApiLoader(true);
        setAccept(status);
        let url = 'kyc/reset';
        let payload = JSON.stringify({
            accept: status
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                let profile_data = USER_DATA;
                profile_data.kyc_confirmation_required = false;
                Store.dispatch({
                    type: 'UPDATE_PROFILE_DATA',
                    payload: profile_data
                });
                let close_button = document.getElementById("close-button");
                close_button.click();
                if (status === false) {
                    navigate('/kyc')
                }

            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false);
        })
    }



    return (
        <div className={`modal fade ${style.e_kyc_confirm_modal}`}
            id="kyc-confirmation"
            tabIndex="-1"
            aria-labelledby="kyc-confirmation"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false">
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-modal position-relative text-center d-flex align-items-center p-sm-4 p-3 ${style.e_content} `}>
                    <button className='d-none' id="close-button" data-bs-dismiss="modal"></button>
                    <div className="container gx-0">
                        {/* Modal title */}
                        <div className="row px-3">
                            <div className="col-12 text-start">
                                <h4 className="color-black e-poppins-medium e-font-16 cursor-pointer w-max-content">
                                    KYC Confirmation
                                </h4>
                            </div>
                        </div>

                        {/* Modal body */}
                        <div className={`${style.modal_body} px-4 py-2`}>
                            <div className='row text-start'>
                                <div className="border-all border-bright-gray padding-24px-all border-radius-16px">
                                    <h5 className="mb-0 e-poppins-medium e-font-16 line-height-16px color-light-black ">
                                        Personal information
                                    </h5>
                                    <div className="row padding-24px-top">
                                        <div className="col-lg-3 col-md-4 col-6 pb-lg-0 pb-md-3 pb-4">
                                            <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                Name
                                            </p>
                                            <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                {
                                                    USER_DATA.name ? USER_DATA.name : '-'
                                                }
                                            </h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-6 pb-lg-0 pb-md-3 pb-4">
                                            <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                PAN number
                                            </p>
                                            <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                {USER_DATA.pan}
                                            </h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-6 pb-lg-0 pb-md-3 pb-4">
                                            <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                Date of birth
                                            </p>
                                            <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                {USER_DATA.dob}
                                            </h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-6 pb-lg-0 pb-md-3 pb-4">
                                            <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                Gender
                                            </p>
                                            <h6 className="e-poppins-medium e-font-14  line-height-14px color-light-black mb-0 mt-2">
                                                {USER_DATA.gender}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="row padding-24px-top">
                                        <div className="col-lg-3 col-md-4 col-6 pb-lg-0 pb-md-3 pb-4">
                                            <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                Mobile number
                                            </p>
                                            <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                {USER_DATA.mobile}
                                            </h6>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-6 pb-lg-0 pb-md-3 pb-4">
                                            <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                Email
                                            </p>
                                            <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                {USER_DATA.email}
                                            </h6>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-6 pb-lg-0 pb-md-3 pb-4">
                                            <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                Marital status
                                            </p>
                                            <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                {USER_DATA.marital_status}
                                            </h6>
                                        </div>
                                        <div className="col-md-6 col-12 mt-4">
                                            <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                Address
                                            </p>
                                            <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                {USER_DATA.address}
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                {/* Bank info */}
                                <div className="border-all border-bright-gray padding-24px-lr  padding-24px-top border-radius-16px mt-3">
                                    <h5 className="mb-0 e-poppins-medium e-font-16 line-height-16px color-light-black">
                                        Bank details
                                    </h5>
                                    <div className={style.e_nominee_container}>
                                        <div className=" d-flex padding-24px-top">
                                            <div className="col-sm-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                    Bank
                                                </p>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                    Account No.
                                                </p>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                    Account type
                                                </p>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                    IFSC
                                                </p>
                                            </div>
                                            <div className="col-5 pb-lg-0 pb-md-4 pb-4">
                                                <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                    Branch
                                                </p>
                                            </div>

                                        </div>
                                        {
                                            USER_DATA.banks?.map((item, key) => {
                                                return (
                                                    <div className="d-flex  padding-24px-top" key={key}>
                                                        <div className="col-sm-4 col-6 d-flex flex-wrap row-gap-8px pb-lg-0 pb-md-4 pb-4">
                                                            <h6 className="e-poppins-medium white-space-nowrap e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                {item.bank_name}
                                                            </h6>
                                                            {
                                                                item.primary === true &&
                                                                <p className="border-radius-24px w-max-content bg-alice-blue ms-2 color-primary-color e-poppins-regular e-font-12 line-height-16px mb-0 p-2 white-space-nowrap">Primary bank</p>
                                                            }
                                                        </div>
                                                        <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                            <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                {item.masked_account_number}
                                                            </h6>
                                                        </div>
                                                        <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                            <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                {item.account_type_label}
                                                            </h6>
                                                        </div>
                                                        <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                            <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                {item.ifsc_code}
                                                            </h6>
                                                        </div>
                                                        <div className="col-5 pb-lg-0 pb-md-4 pb-4">
                                                            <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                {item.branch_name}
                                                            </h6>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                {/* Nominee data */}
                                <div className="border-all border-bright-gray padding-24px-lr  padding-24px-top border-radius-16px mt-3">
                                    <h5 className="mb-0 e-poppins-medium e-font-16 line-height-16px color-light-black">
                                        Nominees({USER_DATA.nominees.length})
                                    </h5>
                                    <div className='my-3'>
                                        <p className="color-black e-poppins-medium e-font-16 line-height-16 mb-1">Nominee type selected</p>
                                        <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0">{USER_DATA.skip_nominee === false ? 'Opt-in' : 'Opt-out'}</p>
                                    </div>
                                    {
                                        USER_DATA.nominees.length > 0 &&
                                        <div className={style.e_nominee_container}>
                                            <div className="d-flex ">
                                                <div className="col-lg-3 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                        Name
                                                    </p>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                        Relation
                                                    </p>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                        Date of Birth
                                                    </p>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                        Allocation
                                                    </p>
                                                </div>
                                                <div className="col-lg-3  col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                        Guardian name
                                                    </p>
                                                </div>
                                                <div className="col-lg-3  col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                        Guardian relation
                                                    </p>
                                                </div>
                                                <div className="col-lg-3  col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                        Guardian pan
                                                    </p>
                                                </div>

                                            </div>
                                            {
                                                USER_DATA.nominees?.map((item, key) => {
                                                    return (
                                                        <div className="d-flex padding-24px-top" key={key}>
                                                            <div className="col-lg-3 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                                <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                    {item.name ? item.name : '-'}
                                                                </h6>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                                <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                    {item.relationship ? item.relationship : '-'}
                                                                </h6>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                                <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                    {item.date_of_birth_str ? item.date_of_birth_str : '-'}
                                                                </h6>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                                <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                    {item.allocation_text ? item.allocation_text : '-'}
                                                                </h6>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                                <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                    {item.guardian_name ? item.guardian_name : '-'}
                                                                </h6>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                                <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                    {item.guardian_relationship ? item.guardian_relationship : '-'}
                                                                </h6>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                                <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                    {item.guardian_pan ? item.guardian_pan : '-'}
                                                                </h6>
                                                            </div>

                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                </div>

                                {/* Help */}
                                <div className="border-all border-bright-gray padding-24px-lr  padding-24px-top border-radius-16px mt-3">
                                    <h5 className="mb-0 e-poppins-medium e-font-16 line-height-16px color-light-black">
                                        Seeking assistance
                                    </h5>

                                    <div className="row mt-3 pb-3">
                                        <div className="col-lg-6 col-md-4 col-12 pb-lg-0 pb-md-4 pb-4">
                                            <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                Email
                                            </p>
                                            <a className="e-poppins-medium e-font-14 line-height-14px color-black hover-underline mb-0 mt-3" href="mailto:helpdesk@mastertrust.co.in">
                                                helpdesk@mastertrust.co.in
                                            </a>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-12 pb-lg-0 pb-md-4 pb-4">
                                            <p className="e-font-14 e-poppins-regular line-height-14px color-black my-0">
                                                Phone number
                                            </p>
                                            <a className="e-poppins-medium e-font-14 line-height-14px color-black hover-underline mb-0 mt-3" href="tel:08069991111">
                                                080-69991111
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${style.modal_footer} d-lg-flex d-md-flex d-sm-flex justify-content-end gap-8px pt-3 px-3`}>
                            <OutlineButton label="Accept and Continue"
                                className="e-font-16 e-poppins-regular px-4 padding-12px-tb w-mobile-100"
                                loading={accept === true && apiLoader === true ? true : false}
                                handleClick={() => _resetKycStep(true)} />
                            <GradientButton label="Update Details"
                                className="e-font-16 px-3 padding-12px-tb w-mobile-100 mt-lg-0 mt-md-0 mt-sm-0 mt-3"
                                loading={accept === false && apiLoader === true ? true : false}
                                handleClick={() => _resetKycStep(false)} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};