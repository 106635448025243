
/*
 *   File : update-mobile-relation.js
 *   Author URI : https://evoqins.com
 *   Description : Modal to update mobile relation
 *   Integrations : null
 *   Version : v1.1
 */

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { GradientButton, OutlineButton } from "../CTA";
import { Icon } from "../Icon";

import APIService from "../../Service/api-service";
import { _getMobileOrEmailRelation } from "../../Helper/api";

import style from '../../Styles/Component/update-mobile-relation.module.scss';

export default function UpdateMobileRelation(props) {
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const [relationOptions, setRelationOptions] = useState([]);
    const [selectedRelation, setSelectedRelation] = useState("");
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(()=>{
        var my_modal = document.getElementById("update-relation");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    },[]);

    useEffect(()=>{
        if (props.type === 1) {
            if (USER_DATA.email_relation_id) {
                setSelectedRelation(USER_DATA.email_relation_id);
            }
        }else{
            if (USER_DATA.mobile_relation_id) {
                setSelectedRelation(USER_DATA.mobile_relation_id);
            }
        }
    },[props.type]);

    useEffect(() => {
        _getRelationData();
    }, []);

    // API - get relations
    async function _getRelationData() {
        let data = await _getMobileOrEmailRelation();
        setRelationOptions(data);
        
    }

    // handle radio select
    function _handleRadio (value) {
        setSelectedRelation(value);
    }

    function _updateRelation () {
        setApiLoader(true);
        let url = 'profile/change-relation';
        let payload = JSON.stringify({
            type: props.type === 1 ? 2 : 1,
            relation: selectedRelation
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success('Relation updated successfully', {
                    type: 'success'
                });
                let button = document.getElementById('close-button');
                button.click();
                props.success();
            }else{
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false);
        })
    }

    return (
        <div className={`modal fade ${style.update_relation}`}
            id="update-relation"
            tabIndex="-1"
            aria-labelledby="update-relation"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content border-radius-24px border-all border-anti-flash-white bg-modal padding-32px-all pt-3 position-relative ${style.e_content} `}>
                    <div className="row mt-2">
                        <div className="col-10">
                            <h4 className="e-poppins-medium e-font-16 color-eerie-black">
                                {`Edit ${props.type === 1 ? 'email' : 'mobile'} relation`}
                            </h4>
                        </div>
                        <div className="col-2 text-end">
                            <button className="d-none" data-bs-dismiss="modal" id="close-button"></button>
                            <Icon icon="close"
                                size={24}
                                data-bs-dismiss="modal"
                                className={`cursor-pointer e-modal-close-btn ${style.e_close}`} />
                        </div>
                    </div>
                    <div className={`row mt-2 ${style.e_content_wrapper}`}>
                        {
                            relationOptions.map((relation, key) => {
                                return (
                                    <div className="col-12 mt-2" key={key}
                                        onClick={()=>_handleRadio(relation.value)}>
                                        <div className="d-flex w-max-content cursor-pointer">
                                            <Icon icon={relation.value === selectedRelation ?
                                                'radio-selected' :
                                                'radio-unselected'
                                            }
                                                size={24}
                                                className="me-2" />
                                            <p className={`${relation.value === selectedRelation ? 'e-poppins-medium' : 'e-poppins-regular'} e-font-14 color-black margin-2px-top`}>
                                                {relation.label}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex gap-8px bg-antique-white border-radius-8px p-2 mt-2">
                                <Icon icon="info"
                                    size={30} />
                                <p className="e-font-16 e-poppins-regular line-height-24px color-jett-black mb-0 ">
                                    *Email and phone number relation can be edited only once. Once it is done, the edit option will be hidden.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-end">
                            <div className="d-flex justify-content-end gap-8px margin-34px-top w-100">
                                <OutlineButton label="Cancel"
                                    cancel="modal"
                                    disabled={apiLoader}
                                    className="padding-12px-tb px-4 color-black e-font-16 e-poppins-regular "/>
                                <GradientButton label="Save changes"
                                    className="padding-12px-tb padding-56px-lr e-font-16  "
                                    loading={apiLoader}
                                    handleClick={_updateRelation} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}