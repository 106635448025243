/*
 *   File : area.js
 *   Author URI : https://evoqins.com
 *   Description : Area chart to show portfolio returns
 *   Integrations : chart.js
 *   Version : v1.1
 */
import { useEffect, useContext } from "react";
import Chart from "chart.js/auto";

import Colors from '../../Styles/color.module.scss';
import { hover } from "@testing-library/user-event/dist/hover";
import { DarkModeContext } from "../../Context/dark-mode-context";

const AreaChart = (props) => {

    const { darkMode } = useContext(DarkModeContext);
    // useEffect(() =>{
    //     var ctx = document.getElementById("areaChart").getContext('2d');
    //     var myChart = new Chart(ctx, {
    //         type: 'line',
    //         data: {
    //             labels: ["Tokyo", "Mumbai", "Mexico City", "Shanghai", "Sao Paulo", "New York", "Karachi", "Buenos Aires", "Delhi", "Moscow"],
    //             datasets: [{
    //                 label: 'Series 1', // Name the series
    //                 data: [500, 50, 2424, 14040, 14141, 4111, 4544, 47, 5555, 6811], // Specify the data values array
    //                 fill: false,
    //                 borderColor: '#2196f3', // Add custom color border (Line)
    //                 backgroundColor: '#2196f3', // Add custom color background (Points and Fill)
    //                 borderWidth: 1 // Specify bar border width
    //             }]
    //         },
    //         options: {
    //             responsive: true, // Instruct chart js to respond nicely.
    //             maintainAspectRatio: true, // Add to prevent default behaviour of full-width/height 
    //         }
    //     });

    // }, [props.data]);

    useEffect(() => {
        
        // Adding data and setting position of the tool tip
        const externalTooltipHandler = (context) => {
            // Tooltip Element
            const { chart, tooltip } = context;
            const tooltipEl = getOrCreateTooltip(chart);
            let hoverLine = chart.canvas.parentNode.querySelector('div.line-hover');
            let dataPointer = chart.canvas.parentNode.querySelector('div.data-pointer');
            if (!hoverLine) {
                hoverLine = document.createElement('div');
                hoverLine.className = 'line-hover'; // Add a class for styling
                chart.canvas.parentNode.appendChild(hoverLine);
            }

            if (!dataPointer) {
                dataPointer = document.createElement('div');
                dataPointer.className = 'data-pointer'; // Add a class for styling
                chart.canvas.parentNode.appendChild(dataPointer);
            }

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
                // tooltipEl.style.opacity = 0;
                // hoverLine.style.opacity = 0;
                // dataPointer.style.opacity = 0;
                return;
            }

            if (tooltip.body) {
                const title = document.createElement('div'); //tooltip title
                const body = document.createElement('div'); // tooltip body
                // taking data from chart tooltip object and create text node
                const toolTipTitle = document.createTextNode(tooltip.title[0]);
                const toolTipBody = document.createTextNode(`NAV: ₹${tooltip.body[0].lines[0]}`);
                // append the title to title div and style
                title.appendChild(toolTipTitle);
                title.style.color = Colors.gray;
                title.style.fontSize = '12px';
                title.style.fontFamily = 'Poppins-Regular';
                // append the body content to the div and style
                body.appendChild(toolTipBody);
                body.style.color =  Colors.black;
                body.style.fontSize = '14px';
                body.style.fontFamily = 'Inter-Medium';
                tooltipEl.innerHTML = ''; // Clear previous content
                // appending tooltip to the tooltip element
                tooltipEl.appendChild(title);
                tooltipEl.appendChild(body);
            }

            const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
            const { dataIndex } = tooltip.dataPoints[0];

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = positionX + tooltip.caretX + 'px';
            tooltipEl.style.top = positionY + tooltip.caretY + 'px';

            // Apply styles to the tooltip
            tooltipEl.classList.add('area-chart-tooltip');
            tooltipEl.style.background = Colors.common_card_color;
            tooltipEl.style.borderRadius = '6px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.top = '10px';
            tooltipEl.style.transform = 'translate(-50%, -50%)';
            tooltipEl.style.transition = 'all .1s ease';
            tooltipEl.style.padding = '5px 10px';
            tooltipEl.style.width = 'max-content';
            tooltipEl.style.zIndex = '2';

            // hoverLine
            hoverLine.style.opacity = 1;
            hoverLine.style.position = 'absolute';
            hoverLine.style.left = positionX + tooltip.caretX + 'px';
            hoverLine.style.bottom = '16px';
            hoverLine.style.width = '2px';
            hoverLine.style.height = '85%';
            hoverLine.style.backgroundColor = Colors.blue_sapphire;
            hoverLine.style.zIndex = 1;

            // Data pointer
            dataPointer.style.opacity = 1;
            dataPointer.style.position = 'absolute';
            dataPointer.style.left = tooltip.caretX - 8 + 'px';
            dataPointer.style.top = tooltip.caretY - 10 + 'px';
            dataPointer.style.backgroundColor = Colors.blue_sapphire;
            dataPointer.style.border = '3px solid #fff';
            dataPointer.style.width = '18px';
            dataPointer.style.height = '18px';
            dataPointer.style.borderRadius = '100%';
            dataPointer.style.zIndex = 2;
        };


        // Chart configuration
        var ctx = document.getElementById('areaChart').getContext('2d');
        ctx.canvas.style.zIndex = 1;
        const newChart = new Chart(ctx, {
            type: 'line',
            data: { //chart area configuration
                labels: props.data && props.data.label.length > 0 ? props.data.label : [],
                datasets: [{
                    data: props.data && props.data.data.length > 0 ? props.data.data : [],
                    order: 1,
                    backgroundColor: function (context) {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;

                        return _getGradient(ctx, chartArea);
                    },
                    borderColor: [
                        Colors.deep_blue
                    ],
                    borderWidth: 1,
                    tension: 0.5,
                    fill: 'start',
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    pointBorderWidth: 0,
                    pointHoverBorderWidth: 0,
                    pointHoverBorderColor: Colors.white,
                    pointBorderColor: Colors.white,
                    pointBackgroundColor: Colors.blue_sapphire,
                    padding: 3,
                }
                ]
            },
            // plugins: [hoverLine], //custom plugins (hover line)
            options: { //chart  options 
                hover: {
                    mode: 'index',
                    intersect: false,
                },
                interaction: {
                    mode: 'dataset',
                },
                onHover: (e, activeElements, chart) => {
                    const {
                        bottom,
                        top,
                        right,
                        left
                    } = chart.chartArea;
                    let tooltipEl = chart.canvas.parentNode.querySelector('div.tooltip');
                    if (e.x >= left && e.x <= right && e.y <= bottom && e.y >= top) {
                        if (tooltipEl) {
                            tooltipEl.style.display = 'block';
                        }
                    } else {
                        if (tooltipEl) {
                            tooltipEl.style.display = 'none';
                        }
                    }

                },
                scales: { // grid lines
                    x: {
                        beginAtZero: true,
                        offset: false,
                        border: {
                            display: true
                        },
                        ticks: {
                            callback: function (value, index, values) {
                                if (index === 0 || index === values.length - 3) {
                                    // return props.data.label[index].replace(/^(\d+)\s/, '');
                                } else {
                                    return; // Hide other labels
                                }
                            },
                            offsetPixels: 100,
                            labelOffset: 10,
                            maxRotation: 0, // Set maximum rotation to 0 degrees
                            minRotation: 0,
                            color: Colors.area_chart_label,
                            padding: 10,
                            font: {
                                size: 12,
                                weight: 400,
                                family: 'Poppins-Regular'
                            }

                        },
                        grid: {
                            drawTicks: false,
                            drawBorder: false,
                            display: false,
                            // tickBorderDashOffset: 5,
                            offset: false,
                        },
                    },
                    y: {
                        beginAtZero: true,
                        offset: false,
                        min: 0,
                        // type: 'category',
                        // labels: ['100', '200', '300', '400', '500'],
                        ticks: {
                            maxTicksLimit: 5,
                            color: darkMode === true ? Colors.chinese_white : Colors.light_black,
                            padding: 10,
                            callback: function (value, index, ticks) {
                                return '₹ ' + value;
                            },
                            font: {
                                size: 10,
                                weight: 400,
                                family: 'Inter-Regular'
                            }

                        },
                        border: {
                            display: true,
                            color: darkMode === true ? Colors.white : Colors.pale_blue,
                            dash: [8, 4]
                        },
                        grid: {
                            drawTicks: false,
                            drawBorder: false,
                            color: function (context) {
                                if (darkMode === true) {
                                    return '#f0f0f0';
                                }else {
                                    return Colors.pale_blue
                                }

                            },
                            // tickBorderDashOffset: 5,
                            // offset: true, 
                        },
                    },
                },
                plugins: {
                    legend: { // remove legend
                        display: false,
                    },
                    corsair: { //  hover line
                        // color: Colors.deep_blue,
                    },
                    tooltip: { //tooltip
                        mode: 'index',
                        intersect: false,
                        enabled: false,
                        position: 'nearest',
                        external: externalTooltipHandler
                    },

                },
                // responsive: true,
            }

        });

        if (props.data) {
            newChart.data.labels = props.data.label;
            newChart.data.datasets[0].data = props.data.data;
            newChart.update(); // Call chart.update() when data changes
        }
        return () => {
            newChart.destroy()
        }
    }, [props.data, darkMode]);

    // useEffect(() => {
    //     document.addEventListener("mouseleave", _hideToolTip);

    //     return () => {
    //         document.removeEventListener("mouseleave", _hideToolTip);
    //     };
    // }, []);
    // Chart area gradient color
    const _getGradient = (ctx, chartArea) => {
        if (chartArea) {
            let width, height, gradient;
            const chartWidth = chartArea.right - chartArea.left;
            const chartHeight = chartArea.bottom - chartArea.top;
            if (gradient === null || width !== chartWidth || height !== chartHeight) {
                width = chartWidth;
                height = chartHeight;
                gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                gradient.addColorStop(1, 'rgba(42, 100, 217, 1)');
                gradient.addColorStop(0, 'rgba(42, 100, 217, 0)');
            }
            return gradient;
        }
    };

    // Creating custom tool tip
    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div.tooltip');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.className = 'tooltip'; // Add a class for styling
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    // function _hideToolTip(event) {
    //     let tooltip = document.querySelector('.tooltip');
    //     let hoverLine = document.querySelector('.line-hover');
    //     let dataPointer = document.querySelector('.data-pointer');
    //     if (tooltip && hoverLine && dataPointer) {
    //         tooltip.style.opacity = 0;
    //         hoverLine.style.opacity = 0;
    //         dataPointer.style.opacity = 0;
    //     }
    // }

    return (
        <div className="e-chart-container position-relative"
            id='areaChartContainer'>
            <canvas id="areaChart"
                width="400"
                className="position-relative "
                height="200"
            ></canvas>
            {
                props.data.label && (
                    <div className="d-flex justify-content-between ms-5 ps-3 position-relative top--14">
                        <span className="color-black e-poppins-regular e-font-12 opacity-6">{props.data.label[0]}</span>
                        <span className="color-black e-poppins-regular e-font-12 opacity-6">{props.data.label[props.data.label.length - 1]}</span>
                    </div>
                )
            }

        </div>
    )
}

export default AreaChart;



