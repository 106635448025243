/*
 *   File : listing.js
 *   Author URI : https://evoqins.com
 *   Description : Invest in goals
 *   Integrations : null
 *   Version : v1.1
 */

import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { toast } from 'react-toastify';

import { InvestmentHeader } from "../../Component/Header";
import Icon from "../../Component/Icon/icon";
import { updateNavigation } from "../../Store/Action/action";
import { GoalCard, PendingGoalCard, CreateAccountBanner } from "../../Component/Card";
import Footer from "../../Component/Footer/footer";
import { RemoveGoalModal } from "../../Component/Modal";
import { CustomLoader } from "../../Component/Other";

import APIService from "../../Service/api-service";

const InvestInGoals = React.memo(() => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const [viewMore, setViewMore] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState("");
    const [pageLoader, setPageLoader] = useState(true);
    const [goalList, setGoalList] = useState({});

    // scroll to top
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    // listener for opening delete modal
    useEffect(() => {
        if (showDeleteModal.length !== 0) {
            _openDeleteModal();
        }
    }, [showDeleteModal]);

    // listing api call
    useEffect(() => {
        _getGoalList();
    }, []);

    const _handleNavigateToDashboard = () => {
        navigate("/");
        dispatch(updateNavigation("/"));
    }

    const _handleViewMore = () => {
        setViewMore(!viewMore);
    }

    const _handleContinue = (id) => {
        let page = ""
        switch (id) {
            case 1:
                page = "retirement"
                break;
            case 2:
                page = "house"
                break;
            case 3:
                page = "education"
                break;
            case 4:
                page = "car"
                break;
            case 5:
                page = "vacation"
                break;
            case 6:
                page = "custom-goal"
                break;

            default:
                break;
        }
        navigate(`/goal/${page}`, {
            state: {
                id: id
            }
        });
    }

    const _handleDelete = (value) => {
        setShowDeleteModal(value);
    }

    //to open delete modal
    const _openDeleteModal = () => {
        const modal = new Modal(document.getElementById("remove-goal"), {});
        modal.show();
    };


    // API - will return details of any goal with default data.
    const _getGoalDetail = (id) => {
        let page = ""
        switch (id) {
            case 1:
                page = "retirement"
                break;
            case 2:
                page = "house"
                break;
            case 3:
                page = "education"
                break;
            case 4:
                page = "car"
                break;
            case 5:
                page = "vacation"
                break;
            case 6:
                page = "custom-goal"
                break;

            default:
                break;
        }
        navigate(`/goal/${page}`, {
            state: {
                id: id
            }
        });
    }

    // API - Goal listing
    function _getGoalList() {
        let url = 'goal/list';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setGoalList(response.data);
            } else {
                toast.dismiss();
                setGoalList([]);
                toast.error(response.message, {
                    type: "error"
                });
            }
            setPageLoader(false);
        })
    };

    // API - Delete goal
    const _confirmDelete = () => {
        setPageLoader(true);
        console.log("SHow delete", showDeleteModal)
        let url = 'goal/delete';
        let payload = JSON.stringify({
            goal_id: showDeleteModal
        })
        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                toast.success("Deleted Successfully", {
                    type: "success",
                });
                _getGoalList();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
                setPageLoader(false);
            }
        })
    }

    // (USER_DATA.is_kyc_done === false && false) ? (
    //     <div className="col-8 mb-4 mt-4">
    //         <CreateAccountBanner/>
    //     </div>


    return (
        <Fragment>
            <div className="e-invest-goals e-page-container margin-80px-bottom pb-sm-0 pb-4">
                {/* Header */}
                <InvestmentHeader />

                <p className="mb-sm-0 mb-3 mt-sm-0 mt-3"><span className="color-gray e-poppins-regular e-font-12 cursor-pointer"
                    onClick={_handleNavigateToDashboard}>Home
                    <Icon icon="gray-right-arrow"
                        size={16} />
                </span>
                    <span className="color-black e-poppins-medium e-font-12">Invest in Goals</span></p>

                {
                    pageLoader === true ?
                        <div className="row">
                            <div className="col-12 h-80vh">
                                <CustomLoader />
                            </div>
                        </div>
                        :
                        <>
                            {/* Banner */}
                            <div className="d-sm-block d-none e-goals-banner padding-64px-top-sm-16 padding-94px-bottom-sm-16 padding-40px-left-sm-16 padding-32px-right-sm-16 border-radius-20px  margin-24px-top margin-40px-bottom-sm-16 position-relative">
                                <div className="col-lg-8 col-md-8 col-12">
                                    <img src={require('../../Assets/Images/invest-in-goals/banner.svg').default}
                                        alt="Banner"
                                        draggable={false}
                                        className=" img-fluid e-goal-listing-banner-img d-xl-none d-lg-none d-block" />
                                    <h1 className="color-black e-inter-semi-bold e-font-32-sm-24 margin-12px-bottom position-relative z-index-1">Are you planning to improve your wealth.</h1>
                                    <h6 className="color-outer-space e-inter-regular e-font-18 mb-0 position-relative line-height-160 z-index-1">The secret to long-term wealth is, starting early. Invest your money for smooth sailing through your life goals</h6>
                                </div>
                                <img src={require('../../Assets/Images/invest-in-goals/banner.svg').default}
                                    alt="Banner"
                                    draggable={false}
                                    className="position-absolute bottom-0 e-banner d-lg-block d-none" />

                            </div>

                            {/* Goals */}
                            {
                                goalList.pending_goals.length !== 0 &&
                                <Fragment>
                                    <input type="checkbox" id="goal-check" hidden />
                                    <div className="e-goals-animation">
                                        <div className="d-flex flex-column gap-sm-24px-16px " >
                                            {
                                                goalList.pending_goals.slice(0, viewMore ? goalList.pending_goals.length : 2).map((item, key) =>
                                                    <PendingGoalCard data={item}
                                                        index={item.goal_type_id}
                                                        goalId={item.goal_id}
                                                        key={key}
                                                        continue={_handleContinue}
                                                        delete={_handleDelete} />
                                                )
                                            }
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            {
                                goalList && goalList.pending_goals.length > 2 ?
                                <label className="d-flex justify-content-center align-items-center w-100 mt-4"
                                    htmlFor="goal-check">
                                    <p className="color-sea-green e-poppins-medium e-font-14 line-height-24px mb-0 me-1 cursor-pointer"
                                        onClick={_handleViewMore}>
                                        {viewMore === false && goalList.pending_goals.length > 2 && "View all pending goals"}
                                        {viewMore === true && goalList.pending_goals.length > 2 && "Hide all pending goals"}
                                    </p>
                                    {
                                        goalList.pending_goals.length > 2 &&
                                        <Icon icon="right-arrow"
                                            size={24}
                                            data-view-more={viewMore}
                                            className="e-view-pending cursor-pointer"
                                            onClick={_handleViewMore} />
                                    }
                                </label>
                                :
                                null
                            }


                            {/* Income */}
                            {
                                goalList && goalList.goals.length && (
                                    <>
                                        <div className="margin-48px-top-sm-16">
                                            <p className="color-black e-poppins-medium e-font-sm-24-20 line-height-32px mb-sm-2 mb-0">Invest for you Life Goals</p>
                                        </div>
                                        <div className="row gx-2 gx-sm-4">
                                            {
                                                goalList.goals.map((item, key) =>
                                                    <div className="col-lg-4 col-md-4 col-6  pt-sm-4 pt-2" key={key}>
                                                        <GoalCard data={item}
                                                            type={2}
                                                            handleClick={_getGoalDetail} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </>
                                )
                            }
                        </>
                }
            </div>
            <Footer />

            {
                showDeleteModal.length !== 0 ?
                    <RemoveGoalModal confirmDelete={_confirmDelete}
                        close={() => setShowDeleteModal("")} />
                    :
                    null
            }
        </Fragment>
    )
})

export default InvestInGoals