/*
 *   File : toast.js
 *   Author URI : https://evoqins.com
 *   Description : Alert messages
 *   Integrations : null
 *   Version : v1.1
 */
import { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import Icon from "../Icon/icon";

import "react-toastify/dist/ReactToastify.css";
import { DarkModeContext } from "../../Context/dark-mode-context";
import Color from '../../Styles/color.module.scss';

function Toast(props) {
  const { darkMode } = useContext(DarkModeContext);
  function CloseButton () {
    return (
      <Icon icon='close'
        size={20}
        className="position-absolute right-10px"
        color={darkMode === true && Color.white}/>
    )
  }
  return (
    <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        closeButton={CloseButton}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
  );
}

export default Toast;
