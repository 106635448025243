/*
 *   File : portfolio-summary.js
 *   Author URI : https://evoqins.com
 *   Description : UI card to show the investment summary of personal accounts and family accounts. 
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useRef, useState, useContext } from "react"
import { Modal } from "bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { Toggle } from "../Form-elements"
import Icon from "../Icon/icon"
import { GradientButton } from "../CTA"
import { ManageExternalFundConfig } from "../Popups"
import { useClickOutside } from "../../Helper"
import { ExternalFundStatusModal, ImportExternalFundModal, RemoveExternalFundModal } from "../Modal";
import { DarkModeContext } from "../../Context/dark-mode-context";

import Color from "../../Styles/color.module.scss"

// const props.data = {
//     members: 7,
//     invested_amount: 5000000.00,
//     current_amount: 5000000.00,
//     returns: 5000000.00,
//     return_percent: 35,
//     xirr: 18.24
// }

const PortfolioSummaryCard = (props) => {
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const navigate = useNavigate();
    const { darkMode } = useContext(DarkModeContext);
    const optionsRef = useRef();
    const [showAllReturns, setShowAllReturns] = useState(true);
    const [showOptions, setShowOptions] = useState(false);
    const [importFunds, setImportFunds] = useState(false);
    const [deleteFunds, setDeleteFunds] = useState(false);
    const [initiateExternalFunds, setInitiateExternalFunds] = useState(false);
    const [isFundImported, setIsFundImported] = useState(props.isFundImported);
    const [lastFundImported, setLastFundImported] = useState(props.lastCASImport);
    const [isUpdateAvailable, setIsUpdateAvailable] = useState(props.isUpdateAvailable)

    useClickOutside(optionsRef, () => {
        setShowOptions(false);
    });

    useEffect(() => {
        if (importFunds === true) {
            _openImportFundsModal();
        }
    }, [importFunds])

    useEffect(() => {
        if (deleteFunds === true) {
            _openDeleteFundsModal();
        }
    }, [deleteFunds])

    useEffect(() => {
        if (initiateExternalFunds === true) {
            const modal = new Modal(document.getElementById("initiate-external-funds-modal"), {});
            modal.show();
        }
    }, [initiateExternalFunds])

    const _showOptions = () => {
        setShowOptions(true);
    }


    const _handleImportExternalFunds = () => {
        // setImportFunds(true);
        if (USER_DATA.mobile === '-' || USER_DATA.mobile === null) {
            toast.error("Please link the mobile number before importing fund", {
                type: 'error'
            });
            setTimeout(()=>{
                navigate('/profile');
            },3000);
        }else{
            setInitiateExternalFunds(true);
        }
    }

    const _handleDeleteFunds = () => {
        setDeleteFunds(true);
    }

    //to open import funds modal
    const _openImportFundsModal = () => {
        const modal = new Modal(document.getElementById("external-funds-status-modal"), {});
        modal.show();
    };

    //to open delete modal
    const _openDeleteFundsModal = () => {
        const modal = new Modal(document.getElementById("remove-external-fund-modal"), {});
        modal.show();
    };

    const _handleUpdate = () => {
        setInitiateExternalFunds(true);
        setImportFunds(false)
    }

    const _handleClose = () => {
        setImportFunds(false);
        setDeleteFunds(false);
    }

    const _handleUpdateExternalFunds = () => {
        setImportFunds(true);
    }

    const _handleDelete = () => {
        setDeleteFunds(false);
    }

    const _handleInitiateClose = () => {
        setInitiateExternalFunds(false);
    }

    // update external fund menu after success of fund import
    const _updateUIAfterImport = () => {
        var today = new Date();
        var day = today.getDate().toString().padStart(2, '0');
        var month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        var year = today.getFullYear();
        var fund_imported_date = `${day}-${month}-${year}`;
        setIsFundImported(true);
        setLastFundImported(fund_imported_date);
        setIsUpdateAvailable(false);
        props.callBackExternalFunds();
    }

    // update external fund menu after success of removing external funds
    const _updateUIAfterDeleteFund = () => {
        setLastFundImported(null);
        setIsFundImported(false);
    }
    return (
        <Fragment>
            {showAllReturns === true ?
                <div className="border-radius-24px bg-portfolio-summary-card padding-56px-bottom-sm-16 px-sm-4 px-3 pt-sm-4 pt-3 h-100 position-relative">
                    <img src={darkMode === true ? require("../../Assets/Images/dashboard/investment-card-bg-dark.png") : require("../../Assets/Images/portfolio/portfolio-banner.svg").default}
                        alt="Circle"
                        draggable={false}
                        className="position-absolute top-0 left-0  e-bg-image" />
                    <div className="d-flex justify-content-between align-items-center mb-sm-4 mb-2">
                        <p className="color-black e-poppins-semi-bold e-font-16 mb-0">Self investment summary</p>
                        <div className="d-flex align-items-center gap-8px position-relative"
                            ref={optionsRef}>
                            {/* <span className="color-black e-poppins-regular e-font-12">
                                Self details
                            </span>
                            <Toggle check={showAllReturns}
                                leftColor={Color.primary}
                                barColor={Color.pearl_aqua}
                                onChange={(e) => setShowAllReturns(e)} />
                            <span className="color-black e-poppins-regular e-font-12">
                                Family details
                            </span> */}
                            <img src={require("../../Assets/Images/profile/bank-menu-dots.png")}
                                alt="Drop down"
                                width={24}
                                height={24}
                                className="cursor-pointer e-filter-invert-1"
                                onClick={_showOptions} />
                            {
                                showOptions === true &&
                                <ManageExternalFundConfig importExternalFunds={_handleImportExternalFunds}
                                    updateExternalFunds={_handleUpdateExternalFunds}
                                    deleteExternalFunds={_handleDeleteFunds}
                                    isCASImported={isFundImported}
                                    lastCASImport={lastFundImported} />
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="border-radius-16px bg-cards position-relative p-3 z-index-1">
                                <p className="color-black e-poppins-regular e-font-14 mb-3">Invested </p>
                                <Icon icon="invested-icon"
                                    size={24}
                                    className="position-absolute e-icon" />
                                <p className="color-black e-poppins-semi-bold e-font-18-sm-14 line-height-20px mb-0"><span className="e-inter-semi-bold">₹</span>{props.data.invested_amount.toLocaleString('en-IN')}</p>
                            </div>
                        </div>
                        <div className="col-6 ps-0">
                            <div className="border-radius-16px bg-cards position-relative p-3 z-index-1">
                                <p className="color-black e-poppins-regular e-font-14 mb-3">Current value</p>
                                <Icon icon="invested-icon"
                                    size={24}
                                    className="position-absolute e-icon" />
                                <p className="color-black e-poppins-semi-bold e-font-18-sm-14 line-height-20px mb-0"><span className="e-inter-semi-bold">₹</span>{props.data.current_value.toLocaleString('en-IN')}</p>

                            </div>
                        </div>
                        <div className="col-12 mt-sm-4 mt-3">
                            <div className="border-radius-16px bg-cards p-3 position-relative z-index-1 ">
                                <div className="row">
                                    {/* border-right-sm border-cool-gray */}
                                    <div className="col-6  ">
                                        <div className="position-relative">
                                            <p className={`color-black e-poppins-regular e-font-14 mb-3`}>Returns </p>
                                            <img src={require("../../Assets/Images/profile/total-returns.svg").default}
                                                alt="Total returns"
                                                className="position-absolute e-returns"
                                                width={24}
                                                height={24} />
                                            <div className="d-flex">
                                                <p className={`color-black e-poppins-semi-bold e-font-18-sm-14 line-height-20px mb-0 me-1 `}>
                                                    <span className="e-inter-semi-bold">₹</span>
                                                    <span className={`${props.data.returns < 0 ? "color-red" : "color-sea-green"}`}>
                                                        {props.data.returns.toLocaleString('en-IN')}
                                                    </span>
                                                </p>
                                                <div className="d-flex align-items-center">
                                                    <Icon icon="green-up-arrow"
                                                        width={15.5}
                                                        height={7.5}
                                                        color={props.data.returns_percentage < 0 && Color.red}
                                                        className={props.data.returns_percentage < 0 && "e-transform-returns"} />
                                                    <p className={`${props.data.returns_percentage < 0 ? "color-red" : "color-sea-green"} e-poppins-regular e-font-14 mb-0`}>{props.data.returns_percentage}%</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    {/* <div className="col-6  ps-sm-4 ps-3">
                                        <div className="position-relative">
                                            <p className="color-black e-poppins-regular e-font-14 mb-3">XIRR </p>
                                            <img src={require("../../Assets/Images/profile/xirr.svg").default}
                                                alt="XIRR"
                                                width={24}
                                                height={24}
                                                className="position-absolute e-returns " />
                                            <p className={`${props.data.xirr < 0 ? "color-red" : "color-sea-green"} e-poppins-semi-bold e-font-18-sm-14 line-height-20px mb-0`}>{props.data.xirr}%</p>

                                        </div>

                                    </div> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div> :
                <div className="border-radius-24px bg-pale-blueish px-4 pt-4 padding-14px-bottom position-relative">
                    <img src={require("../../Assets/Images/portfolio/portfolio-blue-banner.svg").default}
                        alt="Circle"
                        draggable={false}
                        className="position-absolute top-0 left-0  e-bg-image" />
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className="color-black e-poppins-semi-bold e-font-16 mb-0">Family investment summary</p>
                        <div className="d-flex align-items-center gap-8px">
                            <span className="color-black e-poppins-regular e-font-12">
                                Self details
                            </span>
                            <Toggle check={showAllReturns}
                                leftColor={Color.primary}
                                barColor={Color.pearl_aqua}
                                onChange={(e) => setShowAllReturns(e)} />
                            <span className="color-black e-poppins-regular e-font-12">
                                Family details
                            </span>
                        </div>
                    </div>
                    <div className="border-radius-16px bg-white position-relative z-index-1 p-3">
                        <div className="d-flex align-items-center gap-8px">
                            <img src={require("../../Assets/Images/profile/family-icon.png")}
                                alt="Family"
                                width={24}
                                height={24} />
                            <p className="color-black e-poppins-semi-bold e-font-16 mb-0">Family investment</p>
                        </div>
                        <div className="row my-3">
                            <div className="col-3">
                                <div className="p-2 border-right-1px">
                                    <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px margin-10px-bottom">Members</p>
                                    <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0">{props.data.members}</p>
                                </div>
                            </div>
                            <div className="col-3 ps-0">
                                <div className="p-2 border-right-1px">
                                    <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px margin-10px-bottom">Total invested</p>
                                    <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0"><span className="e-inter-medium">₹</span>{props.data.invested_amount.toLocaleString("en-IN")}</p>
                                </div>
                            </div>
                            <div className="col-3 ps-0">
                                <div className="p-2 border-right-1px">
                                    <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px margin-10px-bottom">Current value</p>
                                    <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0"><span className="e-inter-medium">₹</span>{props.data.current_amount.toLocaleString("en-IN")}</p>
                                </div>
                            </div>
                            <div className="col-3 ps-0">
                                <div className="p-2">
                                    <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px margin-10px-bottom">Returns</p>
                                    <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0"><span className="e-inter-medium">₹</span>{props.data.returns.toLocaleString("en-IN")}</p>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-3">
                                <div className="p-2 border-right-1px">
                                    <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px margin-10px-bottom">Returns %</p>
                                    <p className={`${props.data.return_percent < 0 ? "color-red" : "color-black"} e-poppins-medium e-font-14 line-height-16px mb-0`}>{props.data.return_percent}</p>
                                </div>
                            </div>
                            {/* <div className="col-3 ps-0">
                                <div className="p-2 ">
                                    <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px margin-10px-bottom">XIRR</p>
                                    <p className={`${props.data.xirr < 0 ? "color-red" : "color-black"} e-poppins-medium e-font-14 line-height-16px mb-0`}>{props.data.xirr}</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <GradientButton label="View family details"
                            className="px-3 py-2" />
                    </div>
                </div>
            }
            {
                importFunds === true ?
                    <ExternalFundStatusModal updateNow={_handleUpdate}
                        lastCASImport={lastFundImported}
                        isUpdateAvailable={isUpdateAvailable}
                        close={_handleClose} />
                    :
                    null
            }
            {
                deleteFunds === true ?
                    <RemoveExternalFundModal onDelete={_handleDelete}
                        deleteSuccess={_updateUIAfterDeleteFund}
                        close={_handleClose} />
                    :
                    null
            }

            {
                initiateExternalFunds === true ?
                    <ImportExternalFundModal close={_handleInitiateClose}
                        fundImported={_updateUIAfterImport} />
                    :
                    null
            }
        </Fragment>
    )
}


export default PortfolioSummaryCard