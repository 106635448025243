
/*
 *   File : date-picker.js
 *   Author URI : https://evoqins.com
 *   Description : Date of birth component
 *   Integrations : react-calendar
 *   Version : v1.1
 */

import { Fragment, useState, useRef, useEffect, useContext } from 'react';
import Calendar from 'react-calendar';
import { format, parse, subYears } from "date-fns";
import { enUS } from 'date-fns/locale';

import Icon from '../Icon/icon';
import { DarkModeContext } from '../../Context/dark-mode-context';

import 'react-calendar/dist/Calendar.css';
import Color from '../../Styles/color.module.scss';

const DatePicker = (props) => {
    const {darkMode} = useContext(DarkModeContext);
    const calendarRef = useRef(null);
    const [value, setValue] = useState(new Date());
    const [showValue, setShowValue] = useState("");
    const [showCalender, setShowCalender] = useState(false);

    useEffect(() => {
        document.addEventListener("mousedown", closeDropdownOnClickOutside);

        return () => {
            document.removeEventListener("mousedown", closeDropdownOnClickOutside);
        };
    }, [])

    useEffect(() => {
        // console.log("props.value", props.value);
        if (props.value !== null) {
            let parsedDate = parse(props.value, 'dd/MM/yyyy', new Date(), { locale: enUS });
            if (props.displayFormat) {
                parsedDate = parse(props.value, 'dd-MM-yyyy', new Date(), { locale: enUS });
            }
            console.log("parsed", parsedDate);
            setShowValue(props.value);
            setValue(parsedDate);
        }
        else if (props.isAdult === true) {
            setShowValue("");
            setValue(subYears(new Date(), 18));
        }
        else {
            setShowValue("");
            setValue(new Date());
        }
    }, [props.value]);

    useEffect(() => {
        // console.log("date value", value)
    }, [value])

    const closeDropdownOnClickOutside = (event) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target)) {
            setShowCalender(false);
        }
    };

    function onChange(e) {
        setValue(e);
        if (props.displayFormat) {
            setShowValue(format(e, props.displayFormat));
            props.dob(format(e, props.dateFormat));
        } else {
            props.dob(format(e, "dd/MM/yyyy"));
            setShowValue(format(e, "dd/MM/yyyy"));
        }
        setShowCalender(false);
    };

    // format day according to the design
    function formatWeekDay(locale, date) {
        const day_format = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
        const day_index = date.getDay();
        const formatted_day = day_format[day_index];
        return formatted_day;
    }

    function _openDateModal () {
        setShowCalender(!showCalender);
        props.openDateModal();
    }

    return (
        <Fragment>
            <div className='position-relative'
                ref={calendarRef}>
                <div className={`border-radius-8px border-all position-relative e-custom-select-box e-date-label cursor-pointer position-relative ${props.error ? "border-red" : "border-columbia-blue"}`}
                    onClick={_openDateModal}>
                    {
                        props.removeLabelOnFocus === true ?
                            <>
                                {
                                    showValue === '' && (
                                        <p className={`e-poppins-regular e-font-14 line-height-14 position-absolute z-index-1 h-fit-content margin-auto e-label 
                                    ${showValue ? "e-transition-top" : "e-transition-center"}
                                    ${props.error ? "color-red" : "color-black"}`}>
                                            {props.label}<span className='color-red'>{props.postFix}</span>
                                        </p>
                                    )
                                }
                            </>
                            : (
                                <p className={`e-poppins-regular e-font-14 line-height-14 position-absolute z-index-1 bg-body-color h-fit-content margin-auto e-label 
                                ${showValue ? "e-transition-top" : "e-transition-center"}
                                ${props.error ? "color-red" : "color-black"}`}>
                                    {props.label}<span className='color-red'>{props.postFix}</span>
                                </p>
                            )
                    }

                    <p className="color-black e-poppins-regular e-font-14 line-height-14 mb-0 ">
                        {
                            showValue && format(value, "dd MMMM yyyy")
                        }&nbsp;
                    </p>
                    {props.error &&
                        <div className='position-absolute d-flex align-items-center e-error left-0'>
                            <Icon icon="info-circle"
                                size={16} />
                            <p className={`color-red e-font-12 mb-0 ms-1`}>{props.error}</p>
                        </div>}
                    <Icon icon="calendarIcon"
                        className='position-absolute right-10px top-14px'
                        size={20} 
                        color={darkMode === true && Color.white}/>
                </div>
                {
                    showCalender === true &&
                    <div className={` z-index-10 w-100 left-0 border-all border-bright-gray border-radius-8px e-date-of-birth ${props.calendarClass}`}>
                        <Calendar value={value}
                            minDate={props.minDate}
                            maxDate={props.maxDate}
                            nextLabel={<Icon icon="calender-arrow-right" size={24} color={darkMode === true && Color.white}/>}
                            next2Label={null}
                            prevLabel={<Icon icon="calender-arrow-left" size={24} color={darkMode === true && Color.white}/>}
                            prev2Label={null}
                            showNeighboringMonth={false}
                            navigationLabel={({ date, label, locale, view }) => { return label.split(' ')[0] }}
                            tileClassName="color-black e-poppins-regular e-font-16 "
                            locale="en-US"
                            formatShortWeekday={formatWeekDay}
                            onChange={onChange} />
                    </div>
                }
            </div>

        </Fragment>
    );
}

DatePicker.defaultProps = {
    removeLabelOnFocus: false,
    isAdult: false,
    openDateModal: () => {},
    calendarClass: "position-absolute"
}

export default DatePicker;