/*
 *   File : manage-email.js
 *   Author URI : https://evoqins.com
 *   Description : Modal to add or update email
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";

import { GradientButton, SecondaryButton } from "../CTA";
import Icon from "../Icon/icon";
import { OTPInput, CustomTextInput, CustomSelectBox } from "../Form-elements";
import { useKeyHandler } from "../../Helper";

import style from "../../Styles/Component/manage.module.scss";
import Color from "../../Styles/color.module.scss";
import APIService from "../../Service/api-service";
import { _getMobileOrEmailRelation } from "../../Helper/api";

const ManageEmailModal = (props) => {
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const [email, setEmail] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [oldEmailOtp, setOldEmailOtp] = useState("");
    const [newEmailOtp, setNewEmailOtp] = useState("");
    const [newMailError, setNewMailError] = useState("");
    const [loader, setLoader] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [oldMailError, setOldMailError] = useState("");
    const [emailExisted, setEmailExisted] = useState(false);
    const [pinString, setPinString] = useState('');
    const [pinError, setPinError] = useState('');
    const [isEditflow, setEditFlowStatus] = useState(false);
    const [selectedRelation, setSelectedRelation] = useState("");
    const [relationError, setRelationError] = useState("");
    const [relationOptions, setRelationOptions] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("manage-email");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.onCloseModal();
        });
    }, []);

    useEffect(() => {
        _getRelation();
    }, []);

    useEffect(() => {
        if (props.email && props.email !== "-") {
            setEmailExisted(true);
            if (props.nft === true) {
                setEmail(props.email);
            }
        }
    }, [props.email]);


    useEffect(() => {
        if (otpSent === true) {
            const countdown = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    clearInterval(countdown);
                    // Handle expiration logic here (e.g., show a resend button)
                }
            }, 1000);
            return () => {
                clearInterval(countdown);
            };
        } else {
            setSeconds(30);
        }
    }, [seconds, otpSent]);

    // useKeyHandler('manage-email', 'a', (event) => {
    //     if (event.ctrlKey || event.metaKey) {
    //         event.preventDefault();
    //     }
    // });

    const _handleGoBack = (type) => {
        setOldMailError("")
        setOldEmailOtp("");
        setNewEmailOtp("");
        setNewMailError("");
        setOtpSent(false);
    }

    const _handleChange = (type, value) => {

        switch (type) {
            case 1:
                setOldEmailOtp(value);
                setOldMailError("");
                break;
            case 2:
                setNewEmailOtp(value);
                setNewMailError("");
                break;
            case 3:
                setPinString(value);
                setPinError("");
                break;

        }
    };

    function _validateOTP() {
        let valid = true
        if (oldEmailOtp.length != 4) {
            valid = false;
            setOldMailError('Old OTP is required');
        } else if (newEmailOtp.length != 4) {
            valid = false;
            setNewMailError('New OTP is required');
        }

        if (valid === true) {
            _verifyChangeEmail();
            setLoader(true);
        }
    }

    function _validatePIN() {
        if (pinString.length < 4) {
            setPinError('OTP required');
        } else {
            _verifyEmail();
        }

    }

    const _handleInput = (value) => {
        setEmailError("");
        setEmail(value);
    }

    const _handleSelectBox = (value) => {
        setSelectedRelation(value);
        setRelationError("");
    }

    // close modal without any update in data 
    const _closeModal = () => {
        props.onCloseModal();
        document.getElementById("close-modal").dispatchEvent(new Event("click"));
    }

    const _handleSendOTP = () => {
        let valid = true;
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            valid = false;
            setEmailError('Enter valid email');
        }
        if (valid === true) {
            if (emailExisted) {
                _changeEmail();
            } else {
                _addEmail();
            }
        }
    }

    const _handleResendOtp = () => {
        setSeconds(30);
        setPinString("");
        _changeEmail();
    }

    const _getRelation = async () => {
        let data = await _getMobileOrEmailRelation();
        setRelationOptions(data);
        let relation_index = data.findIndex((item) => item.value === USER_DATA.email_relation_id);
        if (relation_index !== -1) {
            setSelectedRelation(data[relation_index]);
        } else {
            setSelectedRelation(data[0]);
        }
    }

    // API -  Getting OTP in the newly added Email
    const _addEmail = () => {
        setLoader(true);
        let url = 'profile/add-email';
        let data = {
            "email": email.toLowerCase()
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setOtpSent(true);
                toast.dismiss();
                toast.success('OTP has been sent successfully', {
                    type: "success"
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
    }

    // API - Send OTP to existing email and new email
    const _changeEmail = () => {
        setLoader(true);
        let url = 'profile/change-email';
        let data = {
            "email": email.toLowerCase(),
            "relation": selectedRelation.value
        }
        if (props.nft === true) {
            url = 'mf-services/nft/change-email';
            data = {
                "folio_numbers": [props.folio],
                "email": email.toLowerCase(),
                "relation": selectedRelation.value
            }
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setOtpSent(true);
                toast.dismiss();
                toast.success('OTP has been sent successfully', {
                    type: "success"
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
    }


    // API - Verify new mail and update profile
    const _verifyEmail = () => {
        setLoader(true);
        let url = 'profile/verify-email';
        let data = {
            "email": email.toLowerCase(),
            "otp": pinString
        }
        if (props.nft === true) {
            url = 'mf-services/nft/verify-email-otp';
            data = {
                "otp": pinString
            }
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                if (props.nft === true) {
                    setShowSuccess(true);
                } else {
                    toast.dismiss();
                    toast.success('Email updated successfully', {
                        type: "success"
                    });
                    props.mailAdded();
                    document.getElementById("close-modal").dispatchEvent(new Event("click"));
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
    }

    // API - Verify Email OTP in update email flow
    const _verifyChangeEmail = () => {
        setLoader(true);
        let url = 'profile/verify-email-otp';
        let data = {
            "current_email_otp": oldEmailOtp,
            "new_email_otp": newEmailOtp,
            "relation": selectedRelation.value
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success('Email updated successfully', {
                    type: "success"
                });
                props.mailAdded();
                document.getElementById("close-modal").dispatchEvent(new Event("click"));

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
    }


    return (
        <div className={`modal fade ${style.e_manage_modal}`}
            id="manage-email"
            tabIndex="-1"
            aria-labelledby="manage-email"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content p-4 ${style.e_content} `}>
                    <span id="close-modal" data-bs-dismiss="modal" className='d-none'></span>
                    {
                        otpSent ?

                            emailExisted ?
                                // OTP screen If email already existed
                                <Fragment>
                                    {/* title */}
                                    <>
                                        <Icon icon="close"
                                            size={24}
                                            className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                                            onClick={_closeModal} />

                                        <h3 className="color-black e-poppins-medium e-font-16 line-height-16px d-flex align-items-center mb-3 mt-2">
                                            <Icon icon="back-arrow"
                                                size={24}
                                                color={Color.black}
                                                className={`me-2 cursor-pointer`}
                                                onClick={() => _handleGoBack(1)} />Verify OTP
                                        </h3>
                                    </>

                                    {/* description */}
                                    <p className="color-outer-space e-poppins-regular e-font-14 mb-4 margin-20px-top pe-5">
                                        Submit OTPs received in existing and new emails
                                    </p>

                                    {/* Email and OTP input for existing email */}
                                    <>
                                        <p className={`color-black e-poppins-medium e-font-14 mb-1 text-start`}>
                                            Enter OTP received in existing mail, <span className="color-outer-space e-poppins-regular">{props.email}</span>
                                        </p>
                                        <OTPInput id="Pin"
                                            autoFocus={true}
                                            className={`${props.nft === true && 'e_width_386'} m-0 w-100`}
                                            pinError={oldMailError}
                                            Otp={oldEmailOtp}
                                            handleChange={(value) => _handleChange(1, value)} />
                                    </>

                                    {/* new Email and OTP input */}
                                    <>
                                        <p className={`color-black e-poppins-medium e-font-14 mb-1 text-start mt-4`}>
                                            Enter OTP received in new mail, <span className="color-outer-space e-poppins-regular">{email} </span>
                                            <span className="color-primary-color e-poppins-regular e-font-14 line-height-16px mx-1 cursor-pointer"
                                                onClick={_handleGoBack}>
                                                Edit
                                            </span>
                                            <Icon icon="Pen-square"
                                                size={24}
                                                className='cursor-pointer'
                                                onClick={_handleGoBack} />
                                        </p>

                                        <OTPInput id="Confirm-Pin"
                                            autoFocus={false}
                                            className="mx-0  margin-32px-bottom w-100"
                                            Otp={newEmailOtp}
                                            pinError={newMailError}
                                            handleChange={(value) => _handleChange(2, value)} />
                                    </>

                                    {/* cancel and update OTP */}
                                    <div className="padding-32px-top d-flex justify-content-center gap-8px">
                                        <SecondaryButton label="Cancel"
                                            className="e-font-16 e-poppins-regular px-4 padding-12px-tb"
                                            handleClick={_closeModal} />
                                        <GradientButton label="Verify & update"
                                            loading={loader}
                                            className="e-font-16 padding-72px-lr padding-12px-tb"
                                            handleClick={_validateOTP} />

                                    </div>
                                    {
                                        seconds === 0 ?
                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 mt-3 text-center'
                                                onClick={_handleResendOtp}>Resend OTP</p>
                                            :
                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 mt-3 text-center'>Resend OTP in <span className="color-black">{seconds}s</span></p>
                                    }
                                </Fragment>
                                :
                                // OTP screen to add Email
                                <Fragment>

                                    {/* header with title and close icon */}
                                    <Icon icon="close"
                                        size={24}
                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                                        onClick={_closeModal} />

                                    <h3 className="color-black e-poppins-medium e-font-16 line-height-16px d-flex align-items-center mb-3 mt-2">
                                        <Icon icon="back-arrow"
                                            size={24}
                                            color={Color.black}
                                            className={`me-2 cursor-pointer`}
                                            onClick={() => _handleGoBack(1)} />Verify OTP
                                    </h3>

                                    {/* email message */}
                                    <p className={`color-black e-poppins-medium e-font-14 mb-1 text-start`}>
                                        Enter OTP received at <span className="color-outer-space e-poppins-regular">{email}</span>
                                    </p>

                                    {/* OTP */}
                                    <OTPInput title="OTP*"
                                        id="configure-email-otp"
                                        autoFocus={true}
                                        pinString={pinString}
                                        className="m-0 w-100 mb-3"
                                        pinError={pinError}
                                        handleChange={(value) => _handleChange(3, value)} />

                                    {/* Cancel or add email to profile  */}
                                    <div className="padding-32px-top d-flex justify-content-center gap-8px">
                                        <SecondaryButton label="Cancel"
                                            className="e-font-16 e-poppins-regular px-4 padding-12px-tb"
                                            handleClick={_closeModal} />
                                        <GradientButton label="Verify & update"
                                            loading={loader}
                                            className="e-font-16 padding-72px-lr padding-12px-tb"
                                            handleClick={_validatePIN} />
                                    </div>

                                    {
                                        seconds === 0 ?
                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 mt-3 text-center'
                                                onClick={_handleResendOtp}>Resend OTP</p>
                                            :
                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 mt-3 text-center'>Resend OTP in <span className="color-black">{seconds}s</span></p>
                                    }
                                </Fragment>
                            :
                            <Fragment>
                                <Icon icon="close"
                                    size={24}
                                    className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                                    onClick={_closeModal} />

                                <h3 className="color-black e-poppins-medium e-font-16 line-height-16px mb-0 mt-2">
                                    {emailExisted ? "Edit Email" : "Add Email"}
                                </h3>

                                <p className="color-outer-space e-poppins-regular e-font-14 mb-4 mt-4 pe-5">
                                    {emailExisted ? 'Update current email' : 'Add Email to your profile'}
                                </p>

                                <div className="row">
                                    <div className="col-md-8 col-12 pb-4">
                                        <CustomTextInput label="Enter new mail id"
                                            postfix="*"
                                            error={emailError}
                                            value={email}
                                            handleChange={_handleInput} />
                                    </div>
                                    <div className="col-md-8 mt-3">
                                        <CustomSelectBox value={selectedRelation}
                                            label="Relation"
                                            error={relationError}
                                            postfix="*"
                                            className="margin-32px-bottom "
                                            options={relationOptions}
                                            onSelectChange={_handleSelectBox} />
                                    </div>



                                    <div className="col-12 ">
                                        <div className="d-flex align-items-center mb-2 border-radius-8px bg-antique-white p-2 padding-12px-lr">
                                            <Icon icon="info"
                                                alt="Danger"
                                                width={24}
                                                height={24} />
                                            <p className="color-outer-space e-poppins-regular e-font-14 mb-0 ms-1">
                                                {
                                                    emailExisted == true ?
                                                        "*We will send an OTP to exiting mail and new mail to verify your profile"
                                                        :
                                                        "*Confirm email by verifying the OTP"
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* send OTP or Close teh modal */}
                                <div className="padding-32px-top d-flex justify-content-center gap-8px">
                                    <SecondaryButton label="Cancel"
                                        className="e-font-16 e-poppins-regular px-4 padding-12px-tb"
                                        handleClick={_closeModal} />

                                    <GradientButton label="Send OTP"
                                        loading={loader}
                                        className="e-font-16 padding-72px-lr padding-12px-tb"
                                        handleClick={_handleSendOTP} />

                                </div>
                            </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

ManageEmailModal.defaultProps = {
    nft: false
}

export default ManageEmailModal