/*
 *   File : signature-preview.js
 *   Author URI : https://evoqins.com
 *   Description : Preview modal for uploaded image
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect } from "react";
import style from "../../Styles/Component/signature-preview.module.scss"
import Icon from "../Icon/icon";

const SignaturePreviewModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("signature-preview");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);


    return (
        <div className={`modal fade ${style.e_signature_modal}`}
            id="signature-preview"
            tabIndex="-1"
            aria-labelledby="signature-preview"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content p-4 ${style.e_content} `}>
                    <div className="mb-4 d-flex align-items-center justify-content-between">
                        <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">Preview your document</p>
                        <Icon icon="close"
                            size={24}
                            data-bs-dismiss="modal"
                            className="cursor-pointer e-modal-close-btn" />
                    </div>
                    <div className="mx-5 border-radius-16px border-all border-bright-gray">
                        <img src={props.file}
                            alt="File preview"
                            className="object-fit-contain w-100 h-100 border-radius-16px" />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SignaturePreviewModal