/*
 *   File : link-text.js
 *   Author URI : https://evoqins.com
 *   Description : Link text button
 *   Integrations : null
 *   Version : v1.1
 */


import Icon from "../Icon/icon"

import style from "../../Styles/Component/link-text.module.scss"

const LinkText = (props) => {
    return (
        <p className={` e-poppins-medium e-font-14 line-height-24px mb-0 ${style.e_see_more} ${props.className}`}
            data-bs-toggle={props.toggle}
            data-bs-target={props.target}
            onClick={() => props.onClick()}>
            {
                props.iconPosition === 1 && (
                    <Icon icon={props.icon}
                        alt="See more"
                        width="24px"
                        height="24px"
                        className={`${style.e_icon} me-3 position-relative cursor-pointer`} />
                )
            }
            <span >{props.title ? props.title : "See more"}</span>
            {
                props.iconPosition === 2 && (
                    <Icon icon={props.icon}
                        alt="See more"
                        width="24px"
                        height="24px"
                        className={`${style.e_icon} ms-3 position-relative cursor-pointer`} />
                )
            }
        </p>
    )
}

LinkText.defaultProps = {
    onClick: () => null,
    iconPosition: 2
}

export default LinkText