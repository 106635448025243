/*
 *   File : order-listing.js
 *   Author URI : www.evoqins.com
 *   Description : Order page
 *   Integrations : date-fns
 *   Version : v1.0
 *   Created : 11-09-2023
 */

import { Fragment, useEffect, useRef, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { format } from "date-fns";

import { useClickOutside } from "../../Helper";
import Icon from "../../Component/Icon/icon";
import BreadCrumb from "../../Component/BreadCrumb/breadcrumb"
import Footer from "../../Component/Footer/footer";
import { InvestmentHeader } from "../../Component/Header";
import { Chip } from "../../Component/Other";
import { CustomTab } from "../../Component/Tab";
import { FundOrderTable, GoalOrderTable } from "../../Component/Table";
import { DateRangeFilterPopup } from "../../Component/Popups";
import { CustomLoader, EmptyScreen } from "../../Component/Other";

import APIService from "../../Service/api-service";
import { CheckBoxGroup } from "../../Component/Form-elements";
import { GradientButton, OutlineButton } from "../../Component/CTA";
import { DarkModeContext } from "../../Context/dark-mode-context";

import NoData from '../../Assets/Images/nodata/order.svg';
import Color from '../../Styles/color.module.scss';

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Orders', url: null },
];

const TAB_DATA = ['Mutual funds', 'Life goals'];

const Orders = () => {

    const { darkMode } = useContext(DarkModeContext);
    const location = useLocation();
    const navigator = useNavigate();
    const menuRef = useRef();
    const popupRef = useRef(null);

    const [tabIndex, setTabIndex] = useState(1);
    const [filterBy, setFilterBy] = useState([null]);
    const [showFilter, setShowFilter] = useState(false);
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [apiLoader, setApiLoader] = useState(true);
    const [listData, setListData] = useState([]);
    const [filerOptions, setFilterOptions] = useState([]);
    const [orderStatusOption, setOrderStatusOption] = useState([]);
    const [orderStatus, setOrderStatus] = useState(null);
    const [selectedFiltersCount, setSelectedFiltersCount] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {

        function _handleScroll() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('scroll', _handleScroll);

        return () => {
            window.removeEventListener('scroll', _handleScroll);
        }
    }, []);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        _getFilterData();
        if (location.state) {
            let order_type = location.state.order_type ? location.state.order_type : 1;
            let order_status = location.state.order_status ? location.state.order_status : null;
            let filter_type = location.state.filter_type ? [location.state.filter_type] : null;
            _getOrderList(order_type, null, null, null, order_status, [], filter_type);
            if (location.state.filter_type) {
                setSelectedFiltersCount(1);
            }
            if (location.state && location.state.order_type) {
                setTabIndex(location.state.order_type);
            }
        } else {
            // Fetch default order list
            _getOrderList();
        }
    }, []);

    // API call :: filters 
    function _getFilterData() {
        let url = 'order/filter-data';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const updated_filter_options = response.data.filter_data.map((item) => {
                    return {
                        id: item.id,
                        name: item.label
                    }
                })
                if (location.state && location.state.filter_type) {
                    setFilterBy([location.state.filter_type]);
                } else {
                    setFilterBy(updated_filter_options.map((item) => item.id));
                }
                setFilterOptions(updated_filter_options);
                // let array = orderStatus;.
                if (location.state && location.state.order_status) {
                    setOrderStatus(1);
                } else {
                    setOrderStatus(response.data.order_status[0].id);
                }
                setOrderStatusOption(response.data.order_status);
            }
        })
    }

    // API call :: List orders
    function _getOrderList(tab = 1, from_date = null, end_date = null, payment_status = null, order_status = null, goal_id = [], filter_data = null) {
        setApiLoader(true);

        let url = 'order/list';
        let payload = JSON.stringify({
            is_goal: tab === 1 ? false : true,
            from_date,
            to_date: end_date,
            payment_status,
            order_status: [order_status],
            goal_id,
            filter_data: filter_data === null ? null : filter_data
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setListData(response.data);
            } else {
                setListData([]);
            }
            setApiLoader(false);
        })
    }

    useClickOutside(menuRef, () => {
        setShowFilter(false);
    });


    useClickOutside(popupRef, () => {
        setShowDateFilter(false);
    });

    // handler for chip filter
    const _handleChipFilter = (filter) => {
        if (filter !== orderStatus) {
            setOrderStatus(filter);
        }
        _getOrderList(tabIndex, startDate, endDate, null, filter, [], filterBy);
    };

    // handler for tab change
    const _handleTabSelect = (index) => {
        // Resetting filters
        setTabIndex(index);
        setOrderStatus(null);
        setFilterBy([]);
        setStartDate(null);
        setEndDate(null);
        setSelectedRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        });
        // call list () with current index
        _getOrderList(index);
    }

    // handler for filter dropdown
    // const _handleFilter = (value) => {
    //     setFilterBy(value.id);
    //     _getOrderList(tabIndex, startDate, endDate, null, orderStatus, [], value.id);
    // };

    // helper function for checkbox items
    const _handleFilter = (id) => {
        let selected_detail = [...filterBy];
        if (id === null) {
            if (filterBy.includes(null)) {
                setFilterBy([]);
            } else {
                setFilterBy(filerOptions.map(item => item.id))
            }
        }
        else {
            selected_detail = filterBy.filter(item => item && item.id !== null);
            if (selected_detail.includes(id) == true) {
                let selected_detail_index = selected_detail.findIndex(item => item === id);
                if (selected_detail_index != -1) {
                    selected_detail.splice(selected_detail_index, 1);
                }
                selected_detail.filter(item => item !== null);
            } else {
                selected_detail.push(id);
                if (selected_detail.length === filerOptions.length - 1 && !selected_detail.includes(null)) {
                    selected_detail = filerOptions.map(item => item.id);
                }
            }
            setFilterBy([...selected_detail]);
        }
    }



    // Toggle filter dropdown
    const _showFilterHandler = () => {
        window.scrollTo({
            behavior: "smooth",
            top: 150
        });
        setShowFilter(!showFilter);
    }

    // handle Date picker change
    const _handleDateRangeChange = (newRange) => {
        setShowDateFilter(false);
        let start_date = format(newRange.startDate, "dd-MM-yyyy");
        let end_date = format(newRange.endDate, "dd-MM-yyyy");
        setStartDate(start_date);
        setEndDate(end_date);
        setSelectedRange(newRange);
        _getOrderList(tabIndex, start_date, end_date, null, orderStatus, [], filterBy);
        // tab = 1, from_date = null, end_date = null, payment_status = null, order_status = null, goal_id = [], filter_data = null
    };

    const _handleShowDateFilter = (clear = false) => {
        window.scrollTo({
            behavior: "smooth",
            top: 150
        });
        if (clear === true) {
            setStartDate(null);
            setEndDate(null);
            setSelectedRange({
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            })
            _getOrderList(tabIndex, null, null, null, orderStatus, [], filterBy);
        }

        setShowDateFilter(!showDateFilter);
    }

    const _handleApplyFilter = () => {
        setShowFilter(false);
        if (filterBy[0] === null) {
            setSelectedFiltersCount(0);
        } else {
            setSelectedFiltersCount(filterBy.length);
        }
        _getOrderList(tabIndex, startDate, endDate, null, orderStatus, [], filterBy)
    }

    const _handleFilterClear = () => {
        setShowFilter(false);
        setSelectedFiltersCount(0);
        setFilterBy(filerOptions.map((item) => item.id));
        _getOrderList(tabIndex, startDate, endDate, null, orderStatus, [], [])
    }


    return (
        <Fragment>
            <div className="e-page-container margin-80px-bottom e-order">

                {/* Heading */}
                <InvestmentHeader />

                <BreadCrumb data={PAGE_NAVIGATION} />

                <h5 className="color-black e-poppins-medium e-font-20-sm-18  my-sm-4 my-3">My orders</h5>
                {/* For preload the image */}
                <img src={NoData} className="d-none" />

                {/* Tab select */}
                <CustomTab data={TAB_DATA}
                    selectedTab={tabIndex}
                    wrapperClass={'gap-16px'}
                    className="px-1 padding-10px-tb e-order-tab"
                    onSelectTab={_handleTabSelect} />

                {/* Chip filter */}
                <div className="mt-4 d-lg-flex justify-content-between gap-16px">
                    <div className="overflow-auto pb-sm-0 pb-2">
                        <div className="d-flex gap-12px "
                            data-disabled={`${apiLoader}`}>
                            {
                                orderStatusOption.map((tag, index) => {
                                    return (
                                        <Chip data={tag.label}
                                            key={index}
                                            type={2}
                                            chipIndex={tag.id}
                                            activeChipIndex={orderStatus}
                                            onPress={() => _handleChipFilter(tag.id)} />
                                    )
                                })
                            }
                        </div>
                    </div>


                    <div className="d-flex gap-16px  e-margin-left-auto mt-lg-0 mt-3">
                        <div className="position-relative">
                            <p className={`border-radius-6px border-all bg-cards p-2 mb-0 color-outer-space e-poppins-regular e-font-14 line-height-24px d-flex justify-content-between align-items-center cursor-pointer white-space-nowrap ${startDate !== null && endDate !== null ? 'border-sea-green' : 'border-bright-gray'}`}
                                onClick={_handleShowDateFilter}>
                                <span>{startDate === null ? 'Start date' : format(selectedRange.startDate, "dd MMM yy")} - {endDate === null ? 'End date' : format(selectedRange.endDate, "dd MMM yy")}</span>
                                <Icon icon="calendarIcon"
                                    className='ms-5'
                                    color={darkMode === true && Color.white}
                                    size={20} />
                            </p>
                            {
                                showDateFilter === true &&
                                <div ref={popupRef}>
                                    <DateRangeFilterPopup
                                        maxDate={new Date()}
                                        initialRange={selectedRange}
                                        cancel={() => _handleShowDateFilter(true)}
                                        onUpdate={_handleDateRangeChange} />
                                </div>
                            }
                        </div>
                        <div className=" position-relative ">
                            <div className={`d-flex align-items-center border-radius-6px border-all p-2 cursor-pointer ${showFilter === true ? 'border-primary-color' : 'border-bright-gray'}`}
                                onClick={_showFilterHandler}>
                                <p className="color-charleston-green e-poppins-medium e-font-14 line-height-24px mb-0 me-1">
                                    Filter
                                </p>
                                <img src={require('../../Assets/Images/explore/filter.png')}
                                    alt="Filter"
                                    width={24}
                                    height={24} />
                                {
                                    selectedFiltersCount !== 0 &&
                                    <p class="color-white border-radius-100px e-poppins-bold e-font-12 line-height-12px e-badge position-absolute mb-0 bg-sea-green d-flex justify-content-center align-items-center">
                                        {selectedFiltersCount}
                                    </p>
                                }
                            </div>

                            {
                                showFilter === true ?
                                    <Fragment>
                                        <div className="position-absolute right-0 d-flex flex-column gap-12px border-radius-12px border-solid border-width-2px border-gainsboro e-order-filter z-index-10 bg-cards"
                                            ref={menuRef}>
                                            {
                                                filerOptions.map((item, key) =>
                                                    <CheckBoxGroup selectedType={filterBy}
                                                        item={item}
                                                        spanClassName="e-poppins-medium"
                                                        key={key}
                                                        onHandleClick={_handleFilter} />
                                                )
                                            }
                                            <div className="d-flex gap-8px justify-content-end">
                                                <OutlineButton label="Clear"
                                                    className="py-2 px-3 e-poppins-regular e-font-14 line-height-14px"
                                                    handleClick={_handleFilterClear} />
                                                <GradientButton label="Filter"
                                                    className="py-2 px-3 e-poppins-regular e-font-14 line-height-14px"
                                                    handleClick={_handleApplyFilter} />
                                            </div>
                                        </div>
                                        <div className="position-fixed w-100 z-index-8 h-100 left-0 top-0 cursor-default"
                                            onClick={() => setShowFilter(false)}></div>
                                    </Fragment>
                                    :
                                    null
                            }
                        </div>
                    </div>

                </div>
                {
                    apiLoader === true ? (
                        <div className="mt-5">
                            <CustomLoader />
                        </div>
                    ) : (
                        <>
                            {
                                listData.length === 0 ? (
                                    <div className="col-12 text-center mt-5">
                                        <EmptyScreen image={NoData}
                                            title={"No orders!"}
                                            description="Start investment today"
                                            buttonLabel={tabIndex === 1 ? 'Explore Schemes' : 'Explore Life Goals'}
                                            onPress={() => {
                                                if (tabIndex === 1) {
                                                    navigator('/explore');
                                                } else {
                                                    navigator('/goal');
                                                }
                                            }} />
                                    </div>
                                ) : (
                                    <>
                                        {
                                            screenWidth > 576 &&
                                            <div className="col-12 text-end mt-2 e-table-scroll-indicator-watchlist">
                                                <p className="color-outer-space e-poppins-regular e-font-12 mb-0">
                                                    <img draggable={false} src={require('../../../src/Assets/Images/explore/scroll-gif.gif')} alt="scroll"
                                                        width='24px'
                                                        height='24px'
                                                        className='e-rotate-gif' />
                                                    Scroll horizontally for view more  information</p>
                                            </div>
                                        }

                                        {
                                            tabIndex === 1 ?
                                                // Mutual fund table
                                                <div className="mt-sm-0 mt-4 pt-sm-0 pt-2">
                                                    {
                                                        listData.length > 0 &&
                                                        <FundOrderTable data={listData} />
                                                    }

                                                </div>
                                                :
                                                // Life goals table
                                                <div className="mt-sm-0 mt-4 pt-sm-0 pt-2">
                                                    {
                                                        listData.length > 0 &&
                                                        <GoalOrderTable data={listData} />
                                                    }
                                                </div>
                                        }
                                    </>
                                )
                            }
                        </>
                    )

                }

            </div>

            {/* Footer */}
            <Footer />
        </Fragment>
    )
}

export default Orders