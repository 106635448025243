/*
 *   File : sip.js
 *   Author URI : https://evoqins.com
 *   Description : UI element to show the Scheduled cancelled or paused SIP.
 *   Integrations : null
 *   Version : v1.1
 */

import Icon from "../Icon/icon"
import Color from "../../Styles/color.module.scss";

const SIPCard = (props) => {

    function _handleResumeButton() {
        let concent_type = props.data.goal_id === null ? 'MF' : 'GOAL';
        props.resumeSIP(props.data.sip_id, concent_type);
    }
    return (

        props.type === 1 ?
            <div className="border-radius-24px border-all border-bright-gray p-4">
                <div className="d-flex align-items-center gap-12px border-bottom-1px border-bright-gray pb-3">
                    <div className="border-all border-bright-gray p-2 border-radius-8px">
                        <img src={props.data.image}
                            alt={props.data.name}
                            width={36}
                            height={36}
                            className="object-fit-contain" />
                    </div>
                    <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-16px mb-0">{props.data.name}</p>
                </div>

                <div className="mt-3 d-lg-flex d-md-flex d-sm-flex justify-content-between align-items-center">
                    <div className="d-flex gap-8px align-items-center">
                        <div className="border-all border-bright-gray px-3 padding-12px-tb d-flex gap-8px align-items-center border-radius-6px cursor-pointer d-mob-none"
                            onClick={_handleResumeButton}>
                            <Icon icon="resume-sip"
                                size={16} />
                            <p className="color-black e-poppins-medium e-font-14-sm-12 mb-0">Resume SIP</p>

                        </div>
                        <p className="color-gray e-poppins-regular e-font-14-sm-12 line-height-24px mb-0">Start date: <span className="color-black">{props.data.start_date}</span> </p>
                        <p className="color-gray e-poppins-regular e-font-14-sm-12 line-height-24px mb-0">End date: <span className="color-black">{props.data.end_date}</span> </p>
                    </div>
                    <div className="d-flex align-items-center gap-16px d-mob-none">
                        <p className="color-gray e-poppins-medium e-font-sm-16-14 line-height-16px mb-0">SIP amount: <span className="e-inter-medium">₹</span>
                            <span className="color-black e-poppins-medium e-font-sm-16-14 line-height-16px">{props.data.amount.toLocaleString('en-IN')}</span></p>
                      
                    </div>

                    {/* for mobile */}
                    <div className="d-flex e-justify-content-space-between align-items-center mt-2 d-lg-none d-md-none d-sm-none">
                        <div className="border-all border-bright-gray px-3 padding-12px-tb d-flex gap-8px align-items-center border-radius-6px cursor-pointer"
                            onClick={_handleResumeButton}>
                            <Icon icon="resume-sip"
                                size={16} />
                            <p className="color-black e-poppins-medium e-font-14-sm-12 mb-0">Resume SIP</p>

                        </div>
                        <p className="color-gray e-poppins-medium e-font-sm-16-14 line-height-16px mb-0">SIP amount: <span className="e-inter-medium">₹</span>
                            <span className="color-black e-poppins-medium e-font-sm-16-14 line-height-16px">{props.data.amount.toLocaleString('en-IN')}</span></p>
                        
                    </div>

                </div>

            </div>
            :
            <div className="border-radius-24px border-all border-bright-gray p-4">
                <div className="d-flex justify-content-between align-items-center flex-wrap gap-16px border-bottom-1px border-bright-gray pb-3">
                    <div className="d-flex align-items-center gap-12px">
                        <div className="border-all border-bright-gray p-2 border-radius-8px">
                            <img src={props.data.image}
                                alt={props.data.name}
                                width={36}
                                height={36}
                                className="object-fit-contain" />

                        </div>
                        <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-16px mb-0">{props.data.name}</p>
                    </div>
                    <p className="color-gray e-poppins-medium e-font-16-sm-12 line-height-16px mb-0">SIP amount: <span className="color-black e-inter-medium">
                        ₹</span><span className="color-black">{props.data.amount.toLocaleString('en-IN')}
                        </span></p>
                </div>
                <div className="mt-3">
                    <div className="row">
                        <div className="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-12 d-sm-block d-flex justify-content-between pb-sm-0 pb-3">
                            <p className="color-gray e-poppins-medium e-font-14-sm-12 line-height-20px mb-sm-3 mb-0">Created on: <span className="color-outer-space ">{props.data.start_date}</span></p>
                            <p className="color-gray e-poppins-medium e-font-14-sm-12 line-height-20px mb-0">Cancelled on: <span className="color-outer-space">{props.data.cancelled_on}</span></p>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 d-sm-block d-flex justify-content-between">
                            <p className="color-gray e-poppins-medium e-font-14-sm-12 line-height-20px mb-sm-3 mb-0 d-flex align-items-center">SIP status:&nbsp;<span className="color-lust me-1">{props.data.status}</span><Icon icon="cancelled"
                                size={24} /></p>
                            <p className="color-gray e-poppins-medium e-font-14-sm-12 line-height-20px mb-0">Order ID: <span className="color-outer-space">{props.data.order_id}</span></p>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <p className="color-gray e-poppins-medium e-font-14-sm-12 line-height-20px mb-0">Frequency: <span className="color-outer-space">{props.data.frequency}</span></p>
                    </div>

                </div>

            </div>


    )
}

export default SIPCard