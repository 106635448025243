/*
 *   File :  portfolio.js
 *   Author URI : https://evoqins.com
 *   Description : Container file which shows investments summary.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { _getDayOfMonth, useClickOutside } from "../../Helper"
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { BreadCrumb } from "../../Component/BreadCrumb"
import { GradientButton, OutlineButton } from "../../Component/CTA"
import { DataTable } from "../../Component/Form-elements"
import { InvestmentHeader } from "../../Component/Header"
import Icon from "../../Component/Icon/icon"
import Footer from "../../Component/Footer/footer"
import { PortfolioOptions } from "../../Component/Popups";

import { _getPortFolioDetail } from "../../Helper/api"
import { CustomLoader } from "../../Component/Other"

import Colors from "../../Styles/color.module.scss";
import { Modal } from "bootstrap"
import { FundRedeemModal, InvestGoalModal, PaymentStatusModal, STPFundModal, SWPFundModal, SwitchFundModal, MfInProcessStatus } from "../../Component/Modal";
import { _getMFInvestmentSummary, _getMFDetails } from '../../Helper/api';

import APIService from "../../Service/api-service"

const MF_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Portfolio', url: '/portfolio' },
    { page: 'Investment detail', url: null }
]

const OPTIONS = [
    {
        image: require("../../Assets/Images/portfolio/switch.png"),
        label: "Switch"
    },
    {
        image: require("../../Assets/Images/portfolio/swp.png"),
        label: "SWP"
    },
    {
        image: require("../../Assets/Images/portfolio/stp.png"),
        label: "STP"
    }]

const CUSTOM_STYLES = {
    headCells: {
        style: {
            '&:not(:first-child)': {
                justifyContent: 'center',
            },
        },
    },
    headRow: {
        style: {
            paddingBottom: "8px !important",
        }
    },
    rows: {
        style: {
            padding: "1.5rem 1rem",
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            color: Colors.black,
            fontFamily: "Poppins-Medium",
            fontSize: "1rem",
            fontWeight: "500",
            lineHeight: "1.125rem",
            justifyContent: "center",
        }
    }
};

const SUCCESS_MESSAGE = {
    title: 'Redeem successful',
    message: 'You have successfully redeemed'
}

const FAILED_MESSAGE = {
    title: 'Redeem failed',
    body: 'We regret to inform you that your redemption was failed.'
}

const SUCCESS_SWP = {
    title: 'SWP created',
    message: 'Your SWP has been successfully created'
}

const SWP_FAILED = {
    title: 'SWP failed',
    message: 'We regret to inform you that your recent SWP has failed'
}

const FundPortfolioDetail = () => {

    const { date } = _getDayOfMonth();
    const optionsRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const [showArrow, setShowArrow] = useState(0);
    const [showOptions, setShowOptions] = useState(false);
    const [loader, setLoader] = useState(true);
    const [portFolioDetail, setPortFolioDetail] = useState({});
    const [apiLoader, setApiLoader] = useState(false);
    const [fundRedeemData, setFundRedeemData] = useState({});
    const [openFundRedeemModal, setOpenFundRedeemModal] = useState(false);
    const [investmentType, setInvestmentType] = useState(1);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [mfInvestmentData, setMfInvestmentData] = useState({});
    const [mountInvestModal, setMountInvestModal] = useState(false);
    const [fundDetail, setFundDetail] = useState({});
    const [paymentMessage, setPaymentMessage] = useState(null);
    const [paymentStatusText, setPaymentStatusText] = useState("");
    const [openPaymentStatusModal, setOpenPaymentStatusModal] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showSWPModal, setShowSWPModal] = useState(false);
    const [swpFund, setSWPFund] = useState({});
    const [showSTPModal, setShowSTPModal] = useState(false);
    const [stpFund, setSTPFund] = useState({});
    const [showSwitchModal, setShowSwitchModal] = useState(false);
    const [switchFund, setSwitchFund] = useState({});
    const [tableData, setTableData] = useState([]);
    const [optionConfig, setOptionConfig] = useState({});
    const [sipLoader, setSipLoader] = useState(false);
    const [lumpsumLoader, setLumpsumLoader] = useState(false);
    const [transactionType, setTransactionType] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [inProcessMessage, setInProcessMessage] = useState('');
    const [showMfInProcessModal, setShowMfInProcessModal] = useState(false);
    const [isNextMonth, setIsNextMonth] = useState(false);

    useEffect(() => {
        const _handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', _handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', _handleResize);
        };
    }, []);

    useClickOutside(optionsRef, () => {
        setShowPopUp(false);
    });

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    // listener for opening invest modal
    useEffect(() => {
        if (mountInvestModal === true) {
            _openInvestmentModal();
        }
    }, [mountInvestModal]);

    useEffect(() => {
        if (openPaymentStatusModal === true) {
            const modal = new Modal(document.getElementById("payment-status"), {});
            modal.show();
        }
    }, [openPaymentStatusModal]);

    useEffect(() => {
        if (location.state !== null) {
            // API - get portfolio details
            _getPortFolioDetail(location.state.id, location.state.is_goal)
                .then((response) => {
                    setPortFolioDetail(response);
                    let data = {
                        switch_enabled: response.investment_details.switch_enabled,
                        swp_enabled: response.investment_details.swp_enabled,
                        stp_enabled: response.investment_details.stp_enabled,
                        lumpsum_enabled: response.investment_details.lumpsum_enabled,
                        sip_enabled: response.investment_details.sip_enabled
                    }
                    setOptionConfig(data);
                    setLoader(false);
                }).catch((response) => {
                    toast.dismiss();
                    toast.error(response, {
                        type: "error"
                    });
                });
        } else {
            navigate("/portfolio");
        }
    }, [location]);

    useEffect(() => {
        if (openFundRedeemModal === true) {
            const modal = new Modal(document.getElementById("fund-redeem"), {});
            modal.show();
        }
    }, [openFundRedeemModal]);

    useEffect(() => {
        if (showSWPModal === true) {
            _openSWPModal();
        }
    }, [showSWPModal]);

    useEffect(() => {
        if (showSTPModal === true) {
            _openSTPModal();
        }
    }, [showSTPModal]);

    useEffect(() => {
        if (showSwitchModal === true) {
            _openSwitchModal();
        }
    }, [showSwitchModal]);

    useEffect(()=>{
        if (showMfInProcessModal === true) {
            const modal = new Modal(document.getElementById("mf-inprocess-status"), {});
            modal.show();
        }
    },[showMfInProcessModal]);

    const _openMfInProcessModal = (message) => {
        setInProcessMessage(message);
        setShowMfInProcessModal(true);
    }

    // open swp modal
    function _openSWPModal() {
        const modal = new Modal(document.getElementById("swp-fund"), {});
        modal.show();
    }

    function _openSTPModal() {
        const modal = new Modal(document.getElementById("stp-fund"), {});
        modal.show();
    }

    function _openSwitchModal() {
        const modal = new Modal(document.getElementById("switch-fund"), {});
        modal.show();
    }

    // Investment modal
    const _openInvestmentModal = () => {
        const modal = new Modal(document.getElementById("invest-goal"), {});
        modal.show();
    }

    //selected tab
    function _handleInvestButton(type) {
        setInvestmentType(type);
        _getDetailData();
    }

    function _navigateToProfile() {
        navigate('/profile', {
            state: { tab: 5, type: 2 },
        });
    }

    const _handleSWP = () => {
        if (portFolioDetail.investment_details.type === 'mt' && portFolioDetail.investment_details.holding_type === 'Demat') {
            toast.dismiss();
            toast.error("Redemption is currently not allowed for demat mode. Please contact customer support for assistance. We are working to make it available soon", {
                type: "error"
            });
            return;
        }
        setTransactionType(5);
        setShowPopUp(false);
        let url = 'mf/redeem-data';
        let payload = JSON.stringify({
            id: portFolioDetail.investment_details.id,
            units: portFolioDetail.investment_details.units,
            transaction_type: 5,
            generate_today: true,
            sip_date: date,
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setSWPFund(response.data);
                setShowSWPModal(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        });
    }

    const _handleSTP = (row) => {
        if (portFolioDetail.investment_details.type === 'mt' && portFolioDetail.investment_details.holding_type === 'Demat') {
            toast.dismiss();
            toast.error("Redemption is currently not allowed for demat mode. Please contact customer support for assistance. We are working to make it available soon", {
                type: "error"
            });
            return;
        }
        setTransactionType(6);
        setShowPopUp(false);
        let url = 'mf/redeem-data';
        let payload = JSON.stringify({
            id: portFolioDetail.investment_details.id,
            units: portFolioDetail.investment_details.units,
            transaction_type: 6,
            generate_today: true,
            sip_date: date,
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setSTPFund(response.data);
                setShowSTPModal(true);

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        });
    }

    // API - track redeem drop off user
    function _saveRedeemUser() {
        let url = 'portfolio/save-redemption-request';
        let payload = JSON.stringify({
            id: location.state.id,
            is_goal: false
        });
        APIService(true, url, payload).then((response) => {
            console.log(response);
        })
    }

    const _handleSwitch = (row) => {
        if (portFolioDetail.investment_details.type === 'mt' && portFolioDetail.investment_details.holding_type === 'Demat') {
            toast.dismiss();
            toast.error("Redemption is currently not allowed for demat mode. Please contact customer support for assistance. We are working to make it available soon", {
                type: "error"
            });
            return;
        }
        setTransactionType(4);
        setShowPopUp(false);
        let url = 'mf/redeem-data';
        let payload = JSON.stringify({
            id: portFolioDetail.investment_details.id,
            units: portFolioDetail.investment_details.units,
            transaction_type: 4,
            generate_today: true,
            sip_date: date,
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setSwitchFund(response.data);
                setShowSwitchModal(true);

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        });
    }

    function _closeSWPModal() {
        setShowSWPModal(false);
    }

    function _closeSTPModal() {
        setShowSTPModal(false);
    }

    function _closeSwithModal() {
        setShowSwitchModal(false);
    }


    // API - Get fund details
    async function _getDetailData() {
        setLumpsumLoader(true);
        let params = {
            'id': portFolioDetail.investment_details.id
        };

        _getMFDetails(params)
            .then((response) => {
                setFundDetail(response);
                _getInvestmentData();
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error",
                });
                setLumpsumLoader(false);
            });
    };

    // API fund detail 
    const _getInvestmentData = () => {
        _getMFInvestmentSummary(portFolioDetail.investment_details.id, true, date)
            .then((response) => {
                setMfInvestmentData(response);
                setMountInvestModal(true);
                setLumpsumLoader(false);
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error",
                });
                setLumpsumLoader(false);
            });
    };

    function _customSort(rowA, rowB) {
        if (rowA.amount > rowB.amount) {
            return 1;
        }
        if (rowB.amount > rowA.amount) {
            return -1;
        }
        // Use row index as a tie-breaker to maintain the original order
        return rowA.index - rowB.index;
    }

    const INVESTMENT_COLUMN = [
        {
            name: 'Date',
            selector: row => row['created'],
            sortable: true,
            width: windowWidth > 575 ? 'max-width' : '120px',
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    {row['created']}
                </p>
        },
        {
            name: 'Trans. type',
            selector: row => row['transaction_type'],
            sortable: true,
            width: windowWidth > 575 ? 'max-width' : '130px',
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    {row['transaction_type']}
                </p>
        },
        {
            name: 'Type',
            selector: row => row['order_type'],
            sortable: true,
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    {row['order_type']}
                </p>
        },
        {
            name: 'Units',
            selector: row => row['units'],
            sortable: true,
            cell: row => <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                onClick={() => _handleCardClick(row)}>
                {row['units'] > 0 ? row['units'] : "-"}
            </p>
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: true,
            sortFunction: (a, b) => _customSort(a, b),
            cell: row => row.amount > 0 ?
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-medium">₹</span>
                    {row['amount'].toLocaleString('en-IN')}</p> :
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>-</p>
        },
        {
            name: 'NAV',
            selector: row => row['nav'],
            sortable: true,

            cell: row => row.nav > 0 ?
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-medium">₹</span>
                    {row['nav'].toLocaleString('en-IN')}</p> :
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>-</p>
        },
        {
            name: 'Status',
            selector: row => row['order_status'],
            sortable: true,
            width: "150px",
            cell: (row) => {
                // define colouur and select icon from here
                return (
                    row['order_status'] === 'Failed' ?
                        <div className="d-flex align-items-center justify-content-center"
                            onClick={() => _handleCardClick(row)}>
                            <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0 me-1 w-max-content">
                                {row['order_status']}
                            </p>
                            <img src={require("../../Assets/Images/orders/failed.png")} alt="Failed"
                                width={24}
                                height={24}
                                draggable={false} />
                        </div>
                        :
                        row['order_status'] === "Success" ?
                            <div className="d-flex align-items-center justify-content-center "
                                onClick={() => _handleCardClick(row)}>
                                <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0 me-1 w-max-content">
                                    {row['order_status']}
                                </p>
                                <img src={require("../../Assets/Images/orders/success.png")} alt="Success"
                                    width={24}
                                    height={24}
                                    draggable={false} />
                            </div>
                            :
                            row['order_status'] === "Payment pending" ?
                                <div className="d-flex align-items-center justify-content-center"
                                    onClick={() => _handleCardClick(row)}>
                                    <p className="color-sunset-blaze e-poppins-medium e-font-14 line-height-18px mb-0 me-1 w-max-content">
                                        {row['order_status']}
                                    </p>
                                    <img src={require("../../Assets/Images/orders/payment-pending.svg").default} alt="Processing"
                                        width={24}
                                        height={24}
                                        draggable={false} />
                                </div>
                                :
                                <div className="d-flex align-items-center justify-content-center"
                                    onClick={() => _handleCardClick(row)}>
                                    <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0 me-1 w-max-content">
                                        {row['order_status']}
                                    </p>
                                    <img src={require("../../Assets/Images/orders/processing.png")} alt="Processing"
                                        width={24}
                                        height={24}
                                        draggable={false} />
                                </div>

                )
            }
        },
        {
            name: '',
            sortable: true,
            width: "48px",
            cell: row =>
                <div className={`e-fund-order-hover text-start ${showArrow === row.id ? 'show' : ''}`}
                    onClick={() => _handleCardClick(row)}>
                    <Icon icon="arrow-right1"
                        size={24}
                        color={Colors.black} />
                </div>

        }

    ];

    const _handleRowHover = (row) => {
        setShowArrow(row.id)
    }

    const _handleRowRemoveHover = () => {
        setShowArrow(0);
    }

    const _showOptions = () => {
        setShowOptions(true);
    }

    const _handleRedeemModalClose = () => {
        setOpenFundRedeemModal(false);
        setFundRedeemData({});
    }

    const _handleCardClick = (selected_order) => {
        navigate("/orders/fund-order-summary", { state: { order_id: selected_order.id } });
    }

    const _handleRedeem = () => {
        setApiLoader(true);
        let url = 'mf/redeem-data';
        let payload = JSON.stringify({
            id: portFolioDetail.investment_details.id,
            units: portFolioDetail.investment_details.units,
            transaction_type: 4,
            generate_today: true,

        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setFundRedeemData(response.data);
                setOpenFundRedeemModal(true);
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });

    }

    // API:: get payment status after investment 
    function _getPaymentStatus(id, is_next_month, mode_of_payment) {
        if (id === null) {
            console.log('is_next_month',is_next_month);
            setOpenPaymentStatusModal(true);
            setIsNextMonth(is_next_month);
            setPaymentStatus(true);
            setPaymentStatusText('Success');
            return
        }
        let url = 'payment/check-status';
        let payload = JSON.stringify({
            payment_id: id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOpenPaymentStatusModal(true);
                if (mode_of_payment === 2 && is_next_month !== true) {
                    setPaymentStatus(false);
                    setPaymentStatusText('Processing');
                }
                else {
                    setPaymentStatus(response.data.payment_status);
                    setPaymentStatusText(response.data.payment_status_txt);
                }
            }
        })
    }

    const _handleRedeemStatus = (type) => {
        setOpenFundRedeemModal(false);
        setFundRedeemData({});
        setPaymentStatus(true);
        switch (type) {
            case 1:
                setPaymentMessage(SUCCESS_MESSAGE)
                break;
            case 2:
                setPaymentMessage(FAILED_MESSAGE)
                break;
        }
    }

    function _handleSWPtatus(type) {
        setSWPFund({});
        setSTPFund({});
        setSwitchFund({});
        setShowSWPModal(false);
        setShowSTPModal(false);
        setShowSwitchModal(false);
        setOpenPaymentStatusModal(true);
        switch (type) {
            case 1:
                setPaymentMessage(SUCCESS_SWP);
                setPaymentStatus(true);
                break;
            case 2:
                setPaymentMessage(SWP_FAILED);
                setPaymentStatus(false);
                break;
        }
    }

    const FundDetail = (props) => {
        return (
            <div className="d-flex align-items-center">
                <p className="color-gray e-poppins-medium e-font-sm-16-14 line-height-16px mb-0 me-1">{props.heading}:</p>
                {
                    !props.returns ? (
                        <p className="color-outer-space e-poppins-medium e-font-sm-16-14 line-height-16px mb-0">
                            {props.rupee && <span className="e-inter-medium">₹</span>}{props.description.toLocaleString("en-IN")}
                        </p>
                    ) : (
                        <p className={`${props.returns < 0 ? "color-red" : "color-sea-green"} e-poppins-medium e-font-sm-16-14 line-height-16px mb-0`}>({props.returns}%)</p>
                    )
                }

            </div>
        )
    }

    const Description = (props) => {
        return (
            <>
                {

                    <div className={`d-flex ${props.className}`}>
                        <span className="color-gray e-poppins-medium e-font-12 line-height-16px">{props.title}&nbsp;</span>
                        <span className={`e-poppins-medium e-font-12 line-height-16px color-outer-space`}>
                            {props.symbol === true && <span className="e-inter-medium">₹</span>}
                            {props.description}
                        </span>
                    </div>
                }
            </>

        )
    }


    return (
        <>
            {

                <Fragment>
                    <div className="e-fund-portfolio-detail e-page-container margin-72px-bottom">
                        <InvestmentHeader />
                        {
                            loader === true ?
                                <div className="h-80vh">
                                    <CustomLoader />
                                </div>
                                :
                                <>
                                    <BreadCrumb data={MF_NAVIGATION} />

                                    <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px my-4">Investments details</p>

                                    <div className="border-radius-24px border-all border-bright-gray p-sm-4 p-3 position-relative">
                                        <div className="position-absolute d-sm-none d-block  right-10px">
                                            {
                                                optionConfig.stp_enabled === false && optionConfig.swp_enabled === false && optionConfig.switch_enabled === false ?
                                                    null :
                                                    <img src={require("../../Assets/Images/profile/bank-menu-dots.png")}
                                                        width={24}
                                                        height={24}
                                                        className="cursor-pointer e-filter-invert-1"
                                                        onClick={() => setShowPopUp(true)} />
                                            }

                                            {
                                                showPopUp === true &&
                                                <div ref={optionsRef}>
                                                    <PortfolioOptions data={optionConfig}
                                                        id={`option-id-detail`}
                                                        swp={() => _handleSWP(portFolioDetail)}
                                                        stp={() => _handleSTP(portFolioDetail)}
                                                        switch={() => _handleSwitch(portFolioDetail)}
                                                        fromDetail={true} />
                                                </div>
                                            }

                                        </div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap gap-sm-16px-8px padding-26px-bottom border-bottom-1px border-bright-gray">
                                            {/* amc image and fund name */}
                                            <div className="d-flex align-items-center gap-sm-12px-8px">
                                                <div className="p-2 border-radius-8px border-all border-bright-gray ">
                                                    <img src={portFolioDetail.investment_details.image}
                                                        alt={portFolioDetail.investment_details.fund_name}
                                                        className="object-fit-contain"
                                                        width={40}
                                                        height={40} />
                                                </div>
                                                <p className="color-black e-poppins-medium e-font-16 line-height-32px mb-0 pe-sm-0 pe-2">{portFolioDetail.investment_details.fund_name}</p>

                                            </div>

                                            {/* nav, units and total invested amount */}
                                            <div className="d-flex gap-sm-24px-16px justify-content-between row-gap-16px mt-sm-0 mt-3 flex-wrap">
                                                <FundDetail heading="Avg. NAV"
                                                    description={portFolioDetail.investment_details.avg_nav}
                                                    rupee={true} />
                                                <FundDetail heading="Units"
                                                    description={portFolioDetail.investment_details.units}
                                                    rupee={false} />
                                                <FundDetail heading="Total invested"
                                                    description={portFolioDetail.investment_details.invested_amount}
                                                    rupee={true} />
                                            </div>
                                        </div>

                                        <div className="d-sm-flex align-items-center justify-content-md-between mt-3 flex-wrap gap-16px">
                                            <div className="d-flex flex-wrap gap-sm-36px-16px">
                                                <FundDetail heading="Current amount"
                                                    description={portFolioDetail.investment_details.current_amount}
                                                    rupee={true} />

                                                <FundDetail heading="Returns"
                                                    description={portFolioDetail.investment_details.returns}
                                                    rupee={false}
                                                    returns={portFolioDetail.investment_details.returns_percentage} />
                                            </div>

                                            <div className="d-flex gap-sm-16px-8px flex-wrap align-items-center position-relative mt-sm-0 mt-4">
                                                <OutlineButton label="SIP"
                                                    disabled={!portFolioDetail.investment_details.sip_enabled}
                                                    className="py-2 px-sm-4 px-3 e-font-sm-16-14 "
                                                    handleClick={() => _handleInvestButton(1)}
                                                    loading={lumpsumLoader && investmentType === 1 ? true : false} />
                                                <OutlineButton label="Redeem"
                                                    className="padding-12px-lr py-2 e-font-sm-16-14 color-black "
                                                    loading={apiLoader}
                                                    handleClick={_handleRedeem} />
                                                <GradientButton label="Lumpsum"
                                                    disabled={!portFolioDetail.investment_details.lumpsum_enabled}
                                                    className="px-3 padding-10px-tb e-font-sm-16-14"
                                                    handleClick={() => _handleInvestButton(2)}
                                                    loading={lumpsumLoader && investmentType === 2 ? true : false} />
                                                {
                                                    windowWidth > 576 &&
                                                    <div className="position-relative d-sm-block d-none">
                                                        {
                                                            optionConfig.stp_enabled === false && optionConfig.swp_enabled === false && optionConfig.switch_enabled === false ?
                                                                null :
                                                                <img src={require("../../Assets/Images/profile/bank-menu-dots.png")}
                                                                    width={24}
                                                                    height={24}
                                                                    className="cursor-pointer e-filter-invert-1"
                                                                    onClick={() => setShowPopUp(true)} />
                                                        }

                                                        {
                                                            showPopUp === true &&
                                                            <div ref={optionsRef}>
                                                                <PortfolioOptions data={optionConfig}
                                                                    id={`option-id-detail`}
                                                                    swp={() => _handleSWP(portFolioDetail)}
                                                                    stp={() => _handleSTP(portFolioDetail)}
                                                                    switch={() => _handleSwitch(portFolioDetail)}
                                                                    fromDetail={true} />
                                                            </div>
                                                        }

                                                    </div>
                                                }



                                                {/* uncomment for more options */}
                                                {/* <img src={require("../../Assets/Images/profile/bank-menu-dots.png")}
                alt=""
                width={24}
                height={24}
                className="cursor-pointer"
                ref={optionsRef}
                onClick={_showOptions} /> */}
                                                {
                                                    showOptions === true &&
                                                    <div className="border-radius-12px border-width-2px border-solid border-bright-gray padding-10px-lr padding-6px-tb d-flex flex-column gap-12px position-absolute white-space-nowrap bg-white z-index-1 e-portfolio-options">
                                                        {OPTIONS.map((item, key) =>
                                                            <div className="d-flex align-items-center gap-8px" key={key}>
                                                                <img src={item.image} alt={item.label}
                                                                    width={24}
                                                                    height={24} />
                                                                <p className="color-black e-poppins-regular e-font-14 mb-0">{item.label}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                    <p className="color-black e-poppins-medium e-font-16 line-height-34px mt-3 mb-sm-4 mb-2">Transaction history</p>
                                    <div className="col-12 d-sm-block d-none text-end mt-sm-2 mt-0 e-table-scroll-indicator">
                                        <p className="color-outer-space e-poppins-regular e-font-12 mb-0">
                                            <img draggable={false} src={require('../../../src/Assets/Images/explore/scroll-gif.gif')} alt="scroll"
                                                width='24px'
                                                height='24px'
                                                className='e-rotate-gif' />
                                            Scroll horizontally for view more  information</p>
                                    </div>
                                    {
                                        windowWidth > 576 ?
                                            <DataTable columns={INVESTMENT_COLUMN}
                                                data={portFolioDetail.orders}
                                                pagination={false}
                                                CUSTOM_STYLES={CUSTOM_STYLES}
                                                onRowMouseEnter={_handleRowHover}
                                                onRowMouseLeave={_handleRowRemoveHover}
                                                rowClick={_handleCardClick} />
                                            :
                                            <>
                                                {
                                                    portFolioDetail.orders.map((item, key) => {
                                                        return (
                                                            <div className=" border-bottom border-bright-gray py-3" key={key}>
                                                                <div className="row  gx-0">
                                                                    <div className="col-4 pe-0">
                                                                        <Description title="Date :" description={item.created} />
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <Description title="Trans. type:" description={item.transaction_type} />
                                                                    </div>
                                                                    <div className="col-3 text-end">
                                                                        <Description title="NAV :" description={item.nav ? item.nav : '-'}
                                                                            className="justify-content-end" />
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3 gx-0">
                                                                    <div className="col-4 pe-0">
                                                                        <Description title="Type:" description={item.order_type} />
                                                                    </div>
                                                                    <div className="col-4 pe-0">
                                                                        <div className="d-flex">
                                                                            <span className="color-gray e-poppins-medium e-font-12 line-height-16px me-1">Amount:</span>
                                                                            {
                                                                                item.amount > 0 ?
                                                                                    <p className="color-black e-poppins-semi-bold e-font-12 line-height-16px mb-0 "
                                                                                        onClick={() => _handleCardClick(item)}>
                                                                                        <span className="e-inter-medium">₹</span>
                                                                                        {item.amount.toLocaleString('en-IN')}</p> :
                                                                                    <p className="color-black e-poppins-semi-bold e-font-12 line-height-16px mb-0 "
                                                                                        onClick={() => _handleCardClick(item)}>-</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 text-end">
                                                                        <Description title="Units :" description={item.units ? item.units : '-'} 
                                                                            className="justify-content-end"/>
                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 text-start mt-2 pt-1">
                                                                        {
                                                                            item.order_status === 'Failed' ?
                                                                                <div className="d-flex align-items-center justify-content-start"
                                                                                    onClick={() => _handleCardClick(item)}>
                                                                                    <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0 me-1 w-max-content">
                                                                                        {item.order_status}
                                                                                    </p>
                                                                                    <img src={require("../../Assets/Images/orders/failed.png")} alt="Failed"
                                                                                        width={18}
                                                                                        height={18}
                                                                                        draggable={false} />
                                                                                </div>
                                                                                :
                                                                                item.order_status === "Success" ?
                                                                                    <div className="d-flex align-items-center justify-content-start "
                                                                                        onClick={() => _handleCardClick(item)}>
                                                                                        <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0 me-1 w-max-content">
                                                                                            {item.order_status}
                                                                                        </p>
                                                                                        <img src={require("../../Assets/Images/orders/success.png")} alt="Success"
                                                                                            width={18}
                                                                                            height={18}
                                                                                            draggable={false} />
                                                                                    </div>
                                                                                    :
                                                                                    item.order_status === "Payment pending" ?
                                                                                        <div className="d-flex align-items-center justify-content-start"
                                                                                            onClick={() => _handleCardClick(item)}>
                                                                                            <p className="color-sunset-blaze e-poppins-medium e-font-14 line-height-18px mb-0 me-1 w-max-content">
                                                                                                {item.order_status}
                                                                                            </p>
                                                                                            <img src={require("../../Assets/Images/orders/payment-pending.svg").default} alt="Processing"
                                                                                                width={18}
                                                                                                height={18}
                                                                                                draggable={false} />
                                                                                        </div>
                                                                                        :
                                                                                        <div className="d-flex align-items-center justify-content-start"
                                                                                            onClick={() => _handleCardClick(item)}>
                                                                                            <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0 me-1 w-max-content">
                                                                                                {item.order_status}
                                                                                            </p>
                                                                                            <img src={require("../../Assets/Images/orders/processing.png")} alt="Processing"
                                                                                                width={18}
                                                                                                height={18}
                                                                                                draggable={false} />
                                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                    }

                                </>
                        }
                    </div>
                    <Footer />

                    {
                        openFundRedeemModal === true ?
                            <FundRedeemModal data={fundRedeemData}
                                redeemStatus={_handleRedeemStatus}
                                close={_handleRedeemModalClose} />
                            :
                            null
                    }
                </Fragment>

            }

            {
                mountInvestModal === true &&
                <InvestGoalModal data={fundDetail}
                    mfInvestmentData={mfInvestmentData}
                    selectedTab={investmentType}
                    isPortfolio={true}
                    close={() => {
                        setMfInvestmentData({})
                        setFundDetail({})
                        setMountInvestModal(false);
                    }}
                    navigateToProfile={_navigateToProfile}
                    showInProcessModal={_openMfInProcessModal}
                    getPaymentStatus={_getPaymentStatus} />
            }

            {
                openPaymentStatusModal === true && (
                    <PaymentStatusModal status={paymentStatus}
                        close={() => {
                            setOpenPaymentStatusModal(false);
                            setTransactionType(null)
                        }}
                        type={isNextMonth === true ? 5 : paymentMessage !== null ? 1 : 2}
                        transactionType={transactionType}
                        paymentStatus={paymentStatusText} />
                )
            }

            {
                showSWPModal === true && (
                    <SWPFundModal data={swpFund}
                        close={_closeSWPModal}
                        redeemStatus={_handleSWPtatus} />
                )
            }

            {
                showSTPModal === true && (
                    <STPFundModal data={stpFund}
                        close={_closeSTPModal}
                        redeemStatus={_handleSWPtatus} />
                )
            }

            {
                showSwitchModal === true && (
                    <SwitchFundModal data={switchFund}
                        redeemStatus={_handleSWPtatus}
                        close={_closeSwithModal} />
                )
            }
            {
                showMfInProcessModal === true &&
                    <MfInProcessStatus message={inProcessMessage} 
                        close={()=> setShowMfInProcessModal(false)}/>
            }
        </>
    )
}

export default FundPortfolioDetail