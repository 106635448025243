/*
 *   File : update-mf-lumpusm.js
 *   Author URI : https://evoqins.com
 *   Description : Modal to add lumpsum amount
 *   Integrations : null
 *   Version : v1.1
 */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Icon from "../Icon/icon";
import { CustomAmountInput } from "../Form-elements";
import { GradientButton } from "../CTA";
import { Amountchip } from "../Other";
import { _mfAddToBasket } from "../../Helper/api";

import style from "../../Styles/Component/lumpsum.module.scss"

const CHIP_AMOUNT = [500, 1000, 2000, 5000];

const UpdateMfLumpusm = React.memo((props) => {

    const location = useLocation();

    const [amount, setAmount] = useState("");
    const [amountError, setAmountError] = useState("");
    const [nav, setNav] = useState("");
    const [percent, setPercent] = useState("");
    const [navDate, setNavDate] = useState("");
    const [loader, setLoader] = useState(false);
    const [suggestedAmount, setSuggestedAmount] = useState([]);
    const [ selectedActiveAmountChip, setSelectedActiveAmountChip] = useState(null);
    useEffect(() => {
        var my_modal = document.getElementById("lumpsum-amount");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);



    useEffect(() => {
        console.log("props.data :: ", props.explore)
        if (props.data.min_lumpsum_amount) {
            setAmount(props.data.min_lumpsum_amount);
        }
        if (props.data.amount) {
            setAmount(props.data.amount);
        }


        if (props.data.nav.current_nav) {
            setNav(props.data.nav.current_nav)
            setPercent(props.data.nav.nav_change_percentage)
            setNavDate(props.data.nav.nav_date)
        }

        if (props.explore != undefined) {
            setPercent(props.data.nav.nav_change_percentage)
        }

        if (props.data.lumpsum_chips) {
            setSuggestedAmount(props.data.lumpsum_chips);
        }

        // else {
        //     console.log('here 222')
        //     setNav(props.data.nav)
        //     setPercent(props.data.nav_change_percentage)
        //     setNavDate(props.data.nav_date)
        // }
    }, [props.data])

    const _handleInputChange = (value) => {
        setAmount(value);
    }

    const _handleChipSelect = (index) => {
        setAmount(suggestedAmount[index]);
        setSelectedActiveAmountChip(index);
    }

    const _handleValidateAmount = () => {
        let valid = true;
        if (amount.length === 0) {
            setAmountError("Enter a amount")
            valid = false;
        }
        if (parseInt(amount, 10) === 0) {
            setAmountError("Amount must be greater than 0");
            valid = false;
        }
        if (parseInt(amount, 10) < props.data.min_lumpsum_amount) {
            setAmountError("Entered amount must be equal or greater than minimum investment amount");
            valid = false;
        }
        if (valid === true) {
            _handleUpdateAmount();
            setLoader(true);
        }
    }


    // API - add or update MF to cart
    const _handleUpdateAmount = () => {
        let mf_id = location.pathname === "/cart" ? props.data.mf_id : props.data.id;
        _mfAddToBasket(mf_id, amount)
            .then((response) => {
                toast.dismiss();
                toast.success(`${props.data.name} successfully ${props.updateFund === true ? "updated" : "added to"} the cart`, {
                    type: "success"
                });
                setLoader(false);
                props.handleUpdateAmount(response);
                document.getElementById("close-modal").dispatchEvent(new Event("click"));

            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error"
                });
                setLoader(false);
            });

    }

    return (
        <div className={`modal fade ${style.e_lumpsum_modal}`}
            id="lumpsum-amount"
            tabIndex="-1"
            aria-labelledby="lumpsum-amount"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_lumpsum_dialog}`}>
                <div className={`modal-content border-radius-24px border-all border-anti-flash-white bg-modal padding-32px-all pt-3 position-relative ${style.e_lumpsum_content} `}>
                    <div className="d-flex justify-content-between align-items-center margin-10px-bottom">
                        <p className="color-eerie-black e-poppins-medium e-font-16 line-height-16px mb-0">Add Lumpsum amount</p>
                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer position-relative right--3 e-modal-close-btn"
                            data-bs-dismiss="modal"
                            onClick={() => props.close()} />
                    </div>

                    <div className="d-flex justify-content-between align-items-center flex-md-nowrap flex-wrap gap-16px">
                        {/* amc logo */}
                        <div className="d-flex align-items-top gap-16px">
                            <div className="p-2 border-radius-4px border-all border-bright-gray">
                                <img src={props.data.image}
                                    alt={props.data.name}
                                    width={52}
                                    height={52}
                                    className="object-fit-contain" />
                            </div>
                            <p className="color-black e-poppins-medium e-font-18 line-height-26px mb-0 text-start">{props.data.name}</p>
                        </div>

                        <div >
                            {/* amc name */}

                            {/* nav date, nav and change % */}

                            <p className="e-font-10 mb-0 w-max-content">{`Current NAV (${navDate})`}</p>
                            <p className="color-black e-poppins-semi-bold e-font-16 line-height-26px mb-0 w-max-content">
                                <span className="e-inter-semi-bold">₹ </span>
                                {nav} <span className={`color-sea-green e-poppins-semi-bold e-font-12 line-height-26px mb-0 ${props.data.nav.nav_change < 0 && 'color-red'}`}>({percent})</span>
                            </p>
                        </div>
                    </div>

                    {/*  min lumpsum amount and investing amount*/}
                    <div className="col-sm-6 text-end margin-26px-top-sm-8">
                        {
                            props.data.min_lumpsum_amount && (
                                <label className="color-black e-font-12 e-poppins-regular">Min. investment:
                                    <span className="e-inter-regular"> ₹</span> {props.data.min_lumpsum_amount.toLocaleString("en-IN")}
                                </label>
                            )
                        }
                        <CustomAmountInput value={amount}
                            label="Amount"
                            error={amountError}
                            rupeeStyle="pt-1"
                            inputClass="py-3 bg-transparent e-poppins-semi-bold padding-28px-left"
                            labelClass="bg-modal"
                            handleChange={_handleInputChange} />
                    </div>

                    {/* amount chips */}
                    <div className="d-flex gap-8px margin-26px-top">
                        {
                            suggestedAmount.map((item, key) =>
                                <Amountchip value={item}
                                    key={key}
                                    activeChip={selectedActiveAmountChip}
                                    amount={amount}
                                    index={key}
                                    handleChipSelect={_handleChipSelect} />
                            )
                        }
                    </div>

                    <span id="close-modal" data-bs-dismiss="modal" className='d-none'></span>

                    {/* submit button */}
                    <GradientButton label={props.updateFund === true ? "Update fund" : "Add fund"}
                        loading={loader}
                        className="e-font-16 padding-12px-tb margin-26px-top"
                        handleClick={_handleValidateAmount} />
                </div>
            </div>
        </div>
    )
})

export default UpdateMfLumpusm