/*
 *   File : goal.js
 *   Author URI : https://evoqins.com
 *   Description : Goals card for life goals section
 *   Integrations : null
 *   Version : v1.1
 */

import React, {useContext} from "react";

import Icon from "../Icon/icon"

import style from '../../Styles/Component/goals.module.scss'
import Color from "../../Styles/color.module.scss";
import { DarkModeContext } from "../../Context/dark-mode-context";

const GoalCard = React.memo((props) => {
    const {darkMode} = useContext(DarkModeContext);
    return (
        props.type === 1 ?
            <div className={`border-radius-16px padding-24px-top padding-16px-lr padding-12px-bottom position-relative z-index-1 h-100 cursor-pointer ${style.e_life_goals} ${props.className}`}
                onClick={() => props.handleClick(props.data.goal_id)} >
                <Icon icon="arrow-right"
                    color={darkMode == false ? Color.charleston_green : Color.white}
                    size={24}
                    className={`position-absolute ${style.e_icon} d-lg-block d-md-block d-none`} />
                <img src={props.data.image}
                    width={props.bgSize ? props.bgSize : 120}
                    height={props.bgSize ? props.bgSize : 120}
                    className={`position-absolute border-radius-16px ${style.e_bg_image}`}
                    draggable={false}
                    alt="" />
                <img src={props.data.icon}
                    alt={props.data.name}
                    width={props.cardImg ? props.cardImg : 40}
                    height={props.cardImg ? props.cardImg : 40}
                    className="margin-20px-bottom e-mobile-margin"
                    draggable={false} />
                <p className={`color-black e-poppins-medium ${props.titleClass ? `${props.titleClass} mb-1` : "e-font-16 line-height-26px mb-2"}`}>{props.data.name}</p>
                {/* <p className={`color-old-silver e-poppins-regular  mb-0 ${props.subTextTitle ? props.subTextTitle : "e-font-14"}`}>{props.data.description}</p> */}

            </div>
            :
            <div className={`border-radius-16px bg-icy-blue pt-4 px-4 padding-40px-bottom-sm-24 position-relative z-index-1 h-100 cursor-pointer ${style.e_invest_goals} `}
                onClick={() => props.handleClick(props.data.goal_id)}>
                {/* <Icon icon="arrow-right"
                    color={Color.charleston_green}
                    size={24}
                    className={`position-absolute ${style.e_icon_invest}`} /> */}
                <img src={props.data.image}
                    width={props.bgSize ? props.bgSize : 120}
                    height={props.bgSize ? props.bgSize : 120}
                    className={`position-absolute border-radius-16px ${style.e_bg_image}`}
                    draggable={false}
                    alt="" />
                <img src={props.data.icon}
                    alt={props.data.heading}
                    width={props.cardImg ? props.cardImg : 40}
                    height={props.cardImg ? props.cardImg : 40}
                    className="mb-2"
                    draggable={false} />
                <div className={`d-flex align-items-center ${style.e_goal_title}`}>
                    <span className="color-black e-poppins-bold e-font-20-sm-16 mb-0 ">{props.data.name}</span>
                    <Icon icon="arrow-right1"
                        className={`${style.e_fund_order_title_icon} ms-1`}
                        size={24} />
                </div>

                {/* <p className={`color-outer-space e-poppins-medium e-font-12  mb-0`}>{props.data.description}</p> */}
            </div>
    )
})

export default GoalCard